import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  Base,
  MdContainer,
  MdFixedContainer,
  NotesContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";

import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import {
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import { EmptyValidationFunction } from "@/types/NoFunction";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { GroupByCostCodeToggle } from "../../components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { ReleaseDeliveryUnits } from "../../release-items-list/components/ReleaseDeliveryUnits";
import { ReleaseItemActions } from "../../release-items-list/components/ReleaseItemActions";
import { ReleaseItemCostTypeSelector } from "../../release-items-list/components/ReleaseItemCostTypeSelector";
import { ReleaseItemDeliveryDate } from "../../release-items-list/components/ReleaseItemDeliveryDate";
import { ReleaseItemExtPrice } from "../../release-items-list/components/ReleaseItemExtPrice";
import { ReleaseItemExtPriceGroup } from "../../release-items-list/components/ReleaseItemExtPriceGroup";
import { ReleaseItemInstructionsDetails } from "../../release-items-list/components/ReleaseItemInstructionsDetails";
import { ReleaseItemManufacturerSelector } from "../../release-items-list/components/ReleaseItemManufacturerSelector";
import { ReleaseItemQuantityInput } from "../../release-items-list/components/ReleaseItemQuantityInput";
import { ReleaseItemUnitPrice } from "../../release-items-list/components/ReleaseItemUnitPrice";
import { ReleaseTagsPicker } from "../../release-items-list/components/ReleaseTagsPicker";
import { filterByMaterialNamePriceAndQty } from "../../utils/filters/filterByMaterialNamePriceAndQty";
import { ReleaseCostCodeManufacturerSelector } from "./components/ReleaseCostCodeManufacturerSelector";
import { ReleaseGroupCheckbox } from "./components/ReleaseGroupCheckbox";
import { ReleaseItemCheckbox } from "./components/ReleaseItemCheckbox";
import { ReleaseItemMaterialEditableView } from "./components/ReleaseItemMaterialEditableView";
import { VendorSplitLogo } from "./components/VendorSplitLogo";
import { ReleaseReceivedQuantityInput } from "./components/release-received-quantity-input/ReleaseReceivedQuantityInput";
import { useOrderItemPoItemReferences } from "./hooks/useOrderItemPoItemReferences";
import { useReleaseItemsDecorator } from "./hooks/useReleaseItemsDecorator";

const READ_ONLY_RELEASE_STATUSES = [ReleaseStatus.Canceled];

const RequestedItemsContainer = tw(Base)`
  col-span-full truncate flex-1
`;
const DateWrapper = tw(MdContainer)`2xl:basis-36 lg:basis-28`;
const DeliveryUnitsHeader = tw.div`pr-5 text-center w-full`;

const ReceivingStatuses = [
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

const SubmittedStatuses = [
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

export const useSpecifyDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { inputErrors } = useReleaseActions();
  const { calcExtPrice } = usePriceCalculation();
  const { uoms } = useUomOptions();
  const extraOptions = useReleaseItemsDecorator();
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { connectedSourceSystem, settings, hasPhaseCodes } = useOrgSettings();

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const configuration: Array<
    GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => (
          <VendorSplitLogo sellerOrgLocationId={item.sellerOrgLocation?.id} />
        ),
      },
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => <ReleaseItemCheckbox item={item} />,
        group: (category) => <ReleaseGroupCheckbox group={category} />,
        subgroup: (category) => <ReleaseGroupCheckbox group={category} />,
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterialEditableView
            count={count}
            readonly={
              !!item.poItemLink &&
              !release?.poLink?.retroactive &&
              !!item.poItemLink
            }
            item={item}
            expandable={false}
            extraDetails={
              <If isTrue={hasOrderItemPoItemReferences(item, release)}>
                <Tooltip
                  id="item-manually-added-to-external-po"
                  element={<ErrorOutlineOutlined className="text-red-500" />}
                >
                  <FormattedMessage
                    id="ITEM_WILL_NEED_TO_BE_MANUALLY_ADDED_TO_EXTERNAL_PO"
                    values={{ sourceSystem: connectedSourceSystem }}
                  />
                </Tooltip>
              </If>
            }
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEM_NAME" />
            <GroupByCostCodeToggle />
          </>
        ),
        includesCounter: true,
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="MANUFACTURER" />,
        hidden: !hasManufacturersSetting,
        position: "center",
        item: ({ item, error, index }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <ReleaseItemManufacturerSelector
              error={error && !item.manufacturer}
              item={item}
              index={index}
            />
          </NotNullableRenderer>
        ),
        subgroup: (category) => (
          <ReleaseCostCodeManufacturerSelector category={category} />
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="COST_TYPE" />,
        item: ({ item }) => <ReleaseItemCostTypeSelector item={item} />,
        position: "center",
        hidden: !settings?.display?.itemCostTypes,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <ReleaseTagsPicker item={item} visibleTags={1} />;
        },
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => (
          <ReleaseDeliveryUnits
            readonly={
              (!!release && item.invoiceItems.length > 0) ||
              (!!item.poItemLink && !!release?.poLink?.retroactive)
            }
            item={item}
          />
        ),
        position: "center",
        header: <FormattedMessage id="UOM" tagName={DeliveryUnitsHeader} />,
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_RECEIVED_SO_FAR" />,
        item: ({ item }) => (
          <ReleaseReceivedQuantityInput item={item} readonly />
        ),
        hidden:
          !checkReleaseStatus(release, ReceivingStatuses) ||
          release?.deliverySlips.length === 0,
      },
      {
        wrapper: SmWideContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <ReleaseItemQuantityInput
              readonly={!!release && item.invoiceItems.length > 0}
              item={item}
            />
          </NotNullableRenderer>
        ),
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
      },
      {
        wrapper: SmWideContainer,
        item: ({ item, index }) => (
          <NotNullableRenderer value={!isLumpSumItem(item)}>
            <ReleaseItemUnitPrice
              item={item}
              release={release}
              index={index}
              readonly={item.invoiceItems.length > 0}
            />
          </NotNullableRenderer>
        ),
        header: <FormattedMessage id="UNIT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
      },
      {
        header: <FormattedMessage id="EXT_PRICE" />,
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item, index }) =>
          isLumpSumItem(item) ? (
            <ReleaseItemExtPrice item={item} index={index} release={release} />
          ) : (
            <If isTrue={item.isIncluded}>
              <NotNullableRenderer value={item.unitPrice !== null}>
                <Price
                  className="text-xs"
                  price={calcExtPrice(item.quantityDecimal, item.unitPrice)}
                />
              </NotNullableRenderer>
            </If>
          ),
        subgroup: (category) => (
          <ReleaseItemExtPriceGroup items={category.items} />
        ),
        hidden: !hasPermissions,
      },
      {
        header: (
          <FormattedMessage id={hasEndDate ? "END_DATE" : "DELIVERY_DATE"} />
        ),
        wrapper: DateWrapper,
        position: "center",
        item: ({ item }) => (
          <ReleaseItemDeliveryDate
            item={item}
            readonly={
              !!release && READ_ONLY_RELEASE_STATUSES.includes(release.status)
            }
          />
        ),
        hidden: !hasPermissions,
      },
      {
        wrapper: NotesContainer,
        item: ({ item }) => <ReleaseItemActions item={item} />,
        details: ({ item }) => {
          return (
            <ReleaseItemInstructionsDetails item={item} readonly={false} />
          );
        },
      },
    ];
  }, [
    calcExtPrice,
    connectedSourceSystem,
    hasManufacturersSetting,
    hasOrderItemPoItemReferences,
    hasPermissions,
    hasPhaseCodes,
    release,
    settings?.display?.itemCostTypes,
    hasEndDate,
  ]);

  const itemIsPartOfPoOrAssignedToInvoice = useCallback(
    (id: string, columnType: COLUMN_TYPE) => {
      const assignedToInvoiceItem = release?.items.find(
        (item) =>
          item.id === id && !!item.poItemLink && !!release?.poLink?.retroactive,
      );
      if (assignedToInvoiceItem) {
        switch (columnType) {
          case COLUMN_TYPE.Material:
            return assignedToInvoiceItem.projectItem?.material
              ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
              : "";
          case COLUMN_TYPE.UOM:
            return assignedToInvoiceItem.uom?.pluralDescription
              ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
              : "";
          default:
            return "";
        }
      }

      return "";
    },
    [intl, release?.items, release?.poLink?.retroactive],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "projectItem.material",
        columnType: COLUMN_TYPE.Material,
        extraOptions,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.Material),
      },
      {
        header: intl.$t({ id: "VENDOR" }),
        columnId: "vendorName",
        columnType: COLUMN_TYPE.Vendor,
        hidden: SubmittedStatuses.includes(release?.status as ReleaseStatus),
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        options: uoms.filter((u) => u.mnemonic).map((o) => o.pluralDescription),
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.UOM),
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
        validator:
          inputErrors.length === 0 ? EmptyValidationFunction : undefined,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
        validator: requiredValidator,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "COST_TYPE" }),
        columnId: "costType.code",
        columnType: COLUMN_TYPE.CostType,
        hidden: !settings?.display?.itemCostTypes,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
      ...(hasEndDate
        ? [
            {
              header: intl.$t({ id: "END_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.EndDate,
            },
          ]
        : [
            {
              header: intl.$t({ id: "DELIVERY_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.DeliveryDate,
            },
          ]),
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [
      intl,
      extraOptions,
      release?.status,
      release?.items,
      uoms,
      inputErrors.length,
      requiredValidator,
      itemIsPartOfPoOrAssignedToInvoice,
      settings?.display?.itemCostTypes,
      hasEndDate,
    ],
  );

  return { configuration, spreadsheetConfig };
};
