import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { SIGNATURE_PROJECT_ID } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";

import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { areDecimalsEqual } from "@/common/utils/areDecimalsEqual";
import { AssetContext } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { InvoiceBreadcrumbs } from "./components/invoice-breadcrumbs/InvoiceBreadcrumbs";
import { InvoiceUploadModal } from "./components/invoice-upload-modal/InvoiceUploadModal";
import { InvoiceMatchedOrder } from "./components/matched-order/InvoiceMatchedOrder";
import { InvoiceFooter } from "./components/matched-order/components/invoice-footer/InvoiceFooter";
import {
  DistributorInvoiceProvider,
  INVOICE_READONLY_STATUSES,
  useDistributorInvoice,
} from "./providers/DistributorInvoiceProvider";
import {
  UploadInvoiceModalProvider,
  useUploadInvoiceModal,
} from "./providers/UploadInvoiceProvider";

const Container = tw.div`mt-5`;

const DistributorInvoiceWithProvider = () => {
  const { invoice, loading } = useDistributorInvoice();
  const hasFooter = useMemo(
    () => !!invoice && !INVOICE_READONLY_STATUSES.includes(invoice?.status),
    [invoice],
  );
  const { openUploadInvoiceModal } = useUploadInvoiceModal();
  const previousInvoice = usePreviousValue(invoice);
  useEffect(() => {
    if (
      invoice?.issues.filter((i) => !i.respondedAt).length === 0 &&
      previousInvoice?.issues.filter((i) => !i.respondedAt).length
    ) {
      openUploadInvoiceModal();
    }
  }, [invoice, previousInvoice, openUploadInvoiceModal]);

  const sameTotal = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return areDecimalsEqual(invoice.release.total, invoice?.total);
  }, [invoice?.release, invoice?.total]);

  const isTotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return new Decimal(invoice?.release.total ?? 0)
      .minus(new Decimal(invoice?.total ?? 0))
      .lessThan(1);
  }, [invoice?.release, invoice?.total]);

  const sameSubtotal = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return areDecimalsEqual(invoice.release?.subtotal, invoice?.subtotal);
  }, [invoice?.release, invoice?.subtotal]);

  const isSubtotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return new Decimal(invoice?.release.subtotal ?? 0)
      .minus(new Decimal(invoice?.subtotal ?? 0))
      .lessThan(1);
  }, [invoice?.release, invoice?.subtotal]);

  const sameAdditionalCharges = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return areDecimalsEqual(
      (invoice.release?.additionalCharges || [])
        .reduce((acc, charge) => {
          return Number(new Decimal(acc).add(new Decimal(charge.amount || 0)));
        }, 0)
        .toString(),
      invoice?.chargesAmount,
    );
  }, [invoice?.release, invoice?.chargesAmount]);

  const isAdditionalChargesDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return new Decimal(
      (invoice.release?.additionalCharges || []).reduce((acc, charge) => {
        return Number(new Decimal(acc).add(new Decimal(charge.amount || 0)));
      }, 0),
    )
      .minus(new Decimal(invoice?.chargesAmount ?? 0))
      .lessThan(1);
  }, [invoice?.release, invoice?.chargesAmount]);

  const sameSalesTax = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return areDecimalsEqual(invoice.release?.taxAmount, invoice?.taxAmount);
  }, [invoice?.release, invoice?.taxAmount]);

  const isSalesTaxDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return null;
    }
    return new Decimal(invoice?.release.taxAmount ?? 0)
      .minus(new Decimal(invoice?.taxAmount ?? 0))
      .lessThan(1);
  }, [invoice?.release, invoice?.taxAmount]);

  return (
    <Loader loading={loading}>
      <Container>
        <InvoiceBreadcrumbs />
        <ResizableColumns hasFooter={hasFooter}>
          <InvoiceMatchedOrder />
          <InvoiceDetails
            invoice={invoice}
            displayInvoiceDetails={false}
            pricesReadonly
            invoiceReadonly
            invoicePoNumberReadonly
            sameTotal={sameTotal}
            sameSubtotal={sameSubtotal}
            sameAdditionalCharges={sameAdditionalCharges}
            sameSalesTax={sameSalesTax}
            isTotalDiscrepancyMinor={isTotalDiscrepancyMinor}
            isSubtotalDiscrepancyMinor={isSubtotalDiscrepancyMinor}
            isAdditionalChargesDiscrepancyMinor={
              isAdditionalChargesDiscrepancyMinor
            }
            isSalesTaxDiscrepancyMinor={isSalesTaxDiscrepancyMinor}
          />
        </ResizableColumns>
        <If
          isTrue={
            invoice && !INVOICE_READONLY_STATUSES.includes(invoice?.status)
          }
        >
          <InvoiceFooter />
        </If>
      </Container>
      <InvoiceUploadModal />
    </Loader>
  );
};

export const DistributorInvoice = () => {
  const { queryParams } = useQueryParams();
  const projectId = queryParams.get(SIGNATURE_PROJECT_ID);

  return (
    <UploadAssetProvider context={AssetContext.Invoice} projectId={projectId}>
      <DistributorInvoiceProvider>
        <UploadInvoiceModalProvider>
          <DistributorInvoiceWithProvider />
        </UploadInvoiceModalProvider>
      </DistributorInvoiceProvider>
    </UploadAssetProvider>
  );
};
