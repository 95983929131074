import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ActivateBuyoutInput,
  BuyoutFieldsFragment,
  BuyoutsBuyoutFieldsFragment,
  BuyoutStatus,
  UpdateContractorBuyoutInput,
  useActivateBuyoutMutation,
  useUpdateContractorBuyoutMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type QuotedBuyoutRelatedBuyout = Pick<
  BuyoutsBuyoutFieldsFragment,
  "id" | "clientIdentifier" | "status"
> & {
  sellerOrgLocation: {
    org: {
      name: string;
      photoUrl?: string | null | undefined;
    };
  };
};

type ContextType = {
  hasMultipleBuyouts: boolean;
  relatedBuyoutsQueue: Array<QuotedBuyoutRelatedBuyout>;
  currentBuyoutQueueIndex: number;
  nextNonActivatedBuyout: QuotedBuyoutRelatedBuyout | undefined;
  activatedBuyoutClientIdentifiers: Array<string>;
  activateBuyout: (input: ActivateBuyoutInput) => Promise<boolean>;
  updateContractorBuyout: (
    input: UpdateContractorBuyoutInput,
  ) => Promise<boolean>;
};

const Context = createContext<ContextType>({
  hasMultipleBuyouts: false,
  relatedBuyoutsQueue: [],
  currentBuyoutQueueIndex: 0,
  nextNonActivatedBuyout: undefined,
  activatedBuyoutClientIdentifiers: [],
  activateBuyout: NoFunctionBooleanPromise,
  updateContractorBuyout: NoFunctionBooleanPromise,
});

export const BuyoutQuotedProvider: FC<
  PropsWithChildren<{ buyout: BuyoutFieldsFragment }>
> = ({ children, buyout }) => {
  const [activateBuyoutMutation] = useActivateBuyoutMutation();
  const [updateContractorBuyoutMutation] = useUpdateContractorBuyoutMutation();
  const { setError } = useGlobalError();

  const [
    activatedBuyoutClientIdentifiers,
    setActivatedBuyoutClientIdentifiers,
  ] = useState<Array<string>>([]);

  const relatedBuyoutsQueue = useMemo(() => {
    const relatedBuyouts = (buyout.quote?.rfq.quotes || []).reduce<
      Array<QuotedBuyoutRelatedBuyout>
    >((buyouts, quote) => {
      if (quote.buyout && quote.buyout.status === BuyoutStatus.Draft) {
        buyouts.push(quote.buyout);
      }
      return buyouts;
    }, []);
    return relatedBuyouts.sort(
      (b1, b2) => Number(b1.clientIdentifier) - Number(b2.clientIdentifier),
    );
  }, [buyout.quote?.rfq.quotes]);

  const currentBuyoutQueueIndex = useMemo(
    () => relatedBuyoutsQueue.findIndex((b) => b.id === buyout.id),
    [buyout, relatedBuyoutsQueue],
  );

  const nextNonActivatedBuyout = useMemo<
    QuotedBuyoutRelatedBuyout | undefined
  >(() => {
    let nextNonActivatedBuyoutInQueue;
    nextNonActivatedBuyoutInQueue = relatedBuyoutsQueue.find(
      (buyout, index) => {
        if (
          index > currentBuyoutQueueIndex &&
          buyout.status === BuyoutStatus.Draft
        ) {
          return buyout;
        }
        return undefined;
      },
    );
    if (!nextNonActivatedBuyoutInQueue) {
      nextNonActivatedBuyoutInQueue = relatedBuyoutsQueue.find(
        (buyout, index) => {
          if (
            index < currentBuyoutQueueIndex &&
            buyout.status === BuyoutStatus.Draft
          ) {
            return buyout;
          }
          return undefined;
        },
      );
    }
    return nextNonActivatedBuyoutInQueue;
  }, [relatedBuyoutsQueue, currentBuyoutQueueIndex]);

  const activateBuyout = async (
    input: ActivateBuyoutInput,
  ): Promise<boolean> => {
    try {
      const { errors } = await activateBuyoutMutation({
        variables: { input },
      });
      setError(errors);
      if (!errors) {
        setActivatedBuyoutClientIdentifiers((ids) => [
          ...ids,
          buyout.clientIdentifier,
        ]);
      }
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateContractorBuyout = async (
    input: UpdateContractorBuyoutInput,
  ): Promise<boolean> => {
    try {
      const { errors } = await updateContractorBuyoutMutation({
        variables: { input },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return (
    <Context.Provider
      value={{
        hasMultipleBuyouts: relatedBuyoutsQueue.length > 1,
        relatedBuyoutsQueue,
        currentBuyoutQueueIndex,
        nextNonActivatedBuyout,
        activatedBuyoutClientIdentifiers,
        activateBuyout,
        updateContractorBuyout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBuyoutQuoted = () => useContext(Context);
