import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { InfoText } from "@/common/components/dialog/Dialog.styles";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import {
  ReleaseFieldsFragment,
  ReleaseReassignmentFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { InputLabelProps } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { PickerCustomActionBar } from "../PickerCustomActionBar";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";

const Container = tw.div`
  grid gap-1 mt-5 px-30 items-start justify-center
`;

const InfoTextNoBg = tw(InfoText)`bg-transparent text-gray-600 max-w-xs p-0`;

const InfoIconStyled = tw(InfoOutlined)`
  text-blue-800
`;

const FooterText = tw.div`
  text-xs w-fit grid grid-flow-col gap-x-1 items-center font-normal place-self-center
  py-1.5 px-4 col-span-3 rounded-3xl 
`;

const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

const DateTimeContainer = tw.div`flex items-center justify-center pt-5`;

type CustomCallbackParams = {
  requestedDate: Date | null;
};

type SubmitDialogParams = {
  includeVendorConfirmation?: boolean;
  title?: string;
  confirmButtonText?: string;
  dateLabelText?: string;
  release?: Pick<ReleaseFieldsFragment, "id" | "version">;
  customCallback?: (
    params?: CustomCallbackParams,
  ) => Promise<boolean> | boolean;
  disabledConfirmButton?: boolean;
};

type SubmitDialogProps = {
  visible: boolean;
  callback?: ({
    skip,
    receive,
    addDeliveryRecord,
    version,
    callback,
    skipVendorNotification,
  }: {
    skip: boolean;
    receive?: boolean;
    addDeliveryRecord?: boolean;
    version?: number;
    callback?: (
      result: boolean,
      vendorReassignmentReleases?: ReleaseReassignmentFieldsFragment[],
    ) => void;
    skipVendorNotification: boolean;
  }) => Promise<boolean | ReleaseReassignmentFieldsFragment[]> | void | boolean;
  setVisible: (visible: boolean) => void;
  params?: SubmitDialogParams;
};

export const SubmitReleaseDialog: FC<SubmitDialogProps> = ({
  visible,
  callback,
  setVisible,
  params = {
    includeVendorConfirmation: true,
  },
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setWarningAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { release } = useRelease();
  const { requestedDate, setRequestedDate, inputErrors, setInputError } =
    useReleaseActions();
  const { updateRelease, updating } = useReleaseUpdate();
  const { syncReleaseItems, saving: syncingReleaseItems } =
    useSyncReleaseItems();
  const { orderTypeConfig } = useOrderTypesConfig({ release });

  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const [addDeliveryRecord, setAddDeliveryRecord] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isDateSpecified] = useState<boolean>(!!requestedDate);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const InputLabelPropsMemoized: InputLabelProps = useMemo(() => {
    return {
      classes: {
        shrink: "text-base",
        root: "text-sm pt-1",
      },
    };
  }, []);

  const navigateAfterSubmit = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      vendorReassignmentReleases: ReleaseReassignmentFieldsFragment[],
    ) => {
      if (result) {
        if (typeof result === "boolean" || vendorReassignmentReleases.length) {
          const currentIndex = vendorReassignmentReleases.findIndex(
            (r) => r.id === release?.id,
          );
          if (
            currentIndex !== -1 &&
            currentIndex < vendorReassignmentReleases.length - 1
          ) {
            navigate(
              generatePath(routes.specifyDeliveryDetails, {
                deliveryId: vendorReassignmentReleases[currentIndex + 1].id,
              }),
            );
          } else {
            navigate(
              generatePath(routes.delivery, { deliveryId: release?.id }),
            );
          }
        } else {
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: result[0].id,
            }),
          );
        }
      }
    },
    [navigate, release],
  );

  const displayDialog = useCallback(
    (
      result: boolean | ReleaseReassignmentFieldsFragment[],
      vendorReassignmentReleases: ReleaseReassignmentFieldsFragment[],
    ) => {
      setSaving(false);
      setVisible(false);
      if (result) {
        openDialog({
          content: (
            <SuccessModal
              message={intl.$t({ id: "ORDER_SUCCESSFULLY_SUBMITTED" })}
            />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigateAfterSubmit(result, vendorReassignmentReleases);
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    },
    [intl, navigateAfterSubmit, openDialog, setVisible],
  );

  const handleConfirm = async () => {
    if (!callback) {
      if (params.customCallback) {
        const result = await params.customCallback({ requestedDate });
        setVisible(!result);
      } else {
        setVisible(false);
      }
      return;
    }

    if (!requestedDate) {
      setInputError(ReleaseErrorType.REQUESTED_FULFILLMENT_DATE);
      setWarningAlert(
        intl.$t({ id: "DELIVERY_SUBMIT_WARNING_NO_RELEASE_DATE" }),
      );
      return false;
    }

    const updatedRelease = await syncReleaseItems();
    if (!updatedRelease?.version) {
      return;
    }

    setSaving(true);
    const result = await callback({
      skip: (release?.vendorContacts || []).length ? skipConfirmation : true,
      receive: retroactiveRelease || undefined,
      addDeliveryRecord,
      version: updatedRelease?.version,
      callback: (result, vendorReassignmentReleases = []) =>
        displayDialog(result, vendorReassignmentReleases),
      skipVendorNotification: (release?.vendorContacts || []).length
        ? skipVendorNotification
        : true,
    });

    if (result) {
      displayDialog(result, []);
    }
  };

  const splitItems = useMemo(() => {
    return release?.items.reduce((acc, item) => {
      if (
        item.sellerOrgLocation?.id &&
        item.sellerOrgLocation?.id !== release.sellerOrgLocation?.id
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [release]);

  const newOrders = useMemo(() => {
    const locations = release?.items.reduce((acc, item) => {
      if (
        item.sellerOrgLocation?.id &&
        item.sellerOrgLocation?.id !== release.sellerOrgLocation?.id &&
        !acc.includes(item.sellerOrgLocation?.id)
      ) {
        acc.push(item.sellerOrgLocation?.id);
      }
      return acc;
    }, [] as string[]);
    return locations?.length;
  }, [release]);

  const onDateConfirm = async (date: Date | null) => {
    setRequestedDate(date);
    const currentRelease = params.release ?? release;

    if (currentRelease) {
      const input: UpdateContractorReleaseInput = {
        releaseId: currentRelease?.id,
        version: currentRelease?.version,
      };

      if (date) {
        input.requestedTime = date.getTime();
      }

      await updateRelease(input);
    }
  };

  const retroactiveRelease = useMemo(() => {
    return requestedDate
      ? requestedDate?.getTime() < new Date().getTime()
      : false;
  }, [requestedDate]);

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
      setSaving(false);
    }
  }, [visible, retroactiveRelease]);

  useEffect(() => {
    setSkipConfirmation(skipVendorNotification);
  }, [skipVendorNotification]);

  return (
    <Dialog
      icon={<WarningIcon />}
      title={
        params.title ?? (
          <FormattedMessage
            id={
              retroactiveRelease
                ? "SAVE_THE_ORDER_QUESTION"
                : "SUBMIT_ORDER_CONFIRMATION"
            }
            values={{ br: <br /> }}
          />
        )
      }
      saving={saving || updating || syncingReleaseItems}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      disabledConfirmButton={params.disabledConfirmButton}
      confirmButtonText={
        params.confirmButtonText ??
        intl.$t({
          id: splitItems
            ? "SUBMIT_AND_PROCEED_TO_NEXT_ORDER"
            : retroactiveRelease
              ? "SAVE_ORDER"
              : "SUBMIT",
        })
      }
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      buttonsConfiguration={{
        confirm: {
          wide: !splitItems,
        },
        cancel: {
          wide: !splitItems,
        },
      }}
      loading={false}
      show={visible}
      content={
        <>
          <Container>
            <If
              isTrue={params.includeVendorConfirmation && !retroactiveRelease}
            >
              <InfoText>
                <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={!skipVendorNotification}
                  onChange={() =>
                    setSkipVendorNotification((prevValue) => !prevValue)
                  }
                />
                <If isTrue={!skipVendorNotification}>
                  <FormattedMessage id="REQUIRE_VENDOR_CONFIRM_ORDER" />
                  <Switch
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    value={!skipConfirmation}
                    onChange={() =>
                      setSkipConfirmation((prevValue) => !prevValue)
                    }
                  />
                </If>
              </InfoText>
              <If isTrue={skipConfirmation}>
                <AdditionalInfo className="mt-2">
                  <FormattedMessage id="ORDER_WILL_TRANSITION_AUTOMATICALLY_TO_SCHEDULED" />
                </AdditionalInfo>
              </If>
            </If>
            <If
              isTrue={!params.includeVendorConfirmation && !retroactiveRelease}
            >
              <InfoTextNoBg>
                <InfoOutlined className="h-5 w-5" />
                <FormattedMessage id="SCHEDULE_ORDER_DESCRIPTION" />
              </InfoTextNoBg>
            </If>
            <If isTrue={retroactiveRelease}>
              <InfoText>
                <FormattedMessage id="INCLUDE_DELIVERY_RECORD" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={addDeliveryRecord}
                  onChange={() => setAddDeliveryRecord(!addDeliveryRecord)}
                />
              </InfoText>
            </If>
            <If isTrue={splitItems}>
              <FooterText>
                <InfoIconStyled />
                <FormattedMessage
                  id="VENDOR_REASSIGN_ITEMS_DETAILS"
                  values={{ itemsCount: splitItems }}
                />{" "}
                <FormattedMessage
                  id="ORDER_WITH_COUNT"
                  values={{ ordersCount: newOrders }}
                />
              </FooterText>
            </If>
          </Container>
          <If isTrue={!isDateSpecified}>
            <DateTimeContainer className="mt-3">
              <DateTimePicker
                label={
                  params.dateLabelText ?? orderTypeConfig.labels.datePicker
                }
                date={requestedDate}
                onChange={onDateConfirm}
                onAccept={onDateConfirm}
                required
                error={inputErrors.includes(
                  ReleaseErrorType.REQUESTED_FULFILLMENT_DATE,
                )}
                InputLabelProps={InputLabelPropsMemoized}
                actionBar={
                  retroactiveRelease ? PickerCustomActionBar : undefined
                }
              />
            </DateTimeContainer>
          </If>
        </>
      }
    />
  );
};
