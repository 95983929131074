import { Price } from "@/common/components/price/Price";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { InvoiceFieldsFragment, TaxType } from "@/generated/graphql";
import { ErrorOutline } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesContainer,
  AdditionalChargesItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../additional-charges/AdditionalCharges.styles";
import { If } from "../if/If";
import { InfoTooltip } from "../info-tooltip/InfoTooltip";
import {
  OrgRolesWrapper,
  Permission,
} from "../org-roles-wrapper/OrgRolesWrapper";
import { ExclamationCircleRed } from "../release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import { Tooltip } from "../tooltip/Tooltip";
import { useInvoiceTotals } from "./hooks/useInvoiceTotals";

const SubTotalItemContainer = tw(
  AdditionalChargesItemContainer,
)`text-base pr-10`;
const Item = tw.div`flex flex-row gap-1 justify-end items-center font-medium text-sm`;
const TotalItem = tw.div`-mr-1 flex flex-row gap-1 justify-end items-center`;
const ExclamationCircleOrange = tw(ErrorOutline)`text-2xl text-orange-500`;

type Props = {
  release: ExpandedRelease | null | undefined;
  invoice: InvoiceFieldsFragment | null;
};

export const InvoiceTotals: FC<Props> = ({ release, invoice }) => {
  const intl = useIntl();
  const useTax = useMemo(
    () => invoice?.release?.taxType === TaxType.Use,
    [invoice],
  );
  const { invoiceSubtotal } = useInvoiceTotals(release);

  const total = useMemo(
    () =>
      invoiceSubtotal
        .plus(invoice?.chargesAmount || 0)
        .plus(invoice?.taxAmount || 0),
    [invoiceSubtotal, invoice?.chargesAmount, invoice?.taxAmount],
  );

  const totalsAreNotEqual = useMemo(
    () =>
      !new Decimal(total)
        .toDP(2)
        .equals(new Decimal(invoice?.total || 0).toDP(2)),
    [total, invoice?.total],
  );

  const subTotalsAreNotEqual = useMemo(
    () =>
      !new Decimal(invoiceSubtotal)
        .toDP(2)
        .equals(new Decimal(invoice?.subtotal || 0).toDP(2)),
    [invoiceSubtotal, invoice?.subtotal],
  );

  const invoicedSalesTaxGreaterThanOrderSalesTax = useMemo(
    () =>
      new Decimal(invoice?.taxAmount || 0)
        .toDP(2)
        .greaterThan(new Decimal(invoice?.release?.taxAmount || 0).toDP(2)),
    [invoice?.taxAmount, invoice?.release?.taxAmount],
  );

  const invoicedAdditionalChargesExceeds = useMemo(
    () =>
      !new Decimal(invoice?.chargesAmount || 0).greaterThan(
        new Decimal(
          invoice?.release?.additionalCharges.reduce(
            (acc, charge) => acc.plus(charge.amount),
            new Decimal(0),
          ) || 0,
        ),
      ),
    [invoice?.chargesAmount, invoice?.release?.additionalCharges],
  );

  return (
    <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
      <AdditionalChargesContainer>
        <SubTotalItemContainer>
          <Item>
            <FormattedMessage id="INVOICED_SUBTOTAL" />
            <InfoTooltip
              message={intl.formatMessage({ id: "INVOICED_SUBTOTAL_TOOLTIP" })}
            />
            <If isTrue={subTotalsAreNotEqual}>
              <Tooltip
                id="INVOICED_TOTAL_WARNING_TOOLTIP"
                element={<ExclamationCircleRed />}
              >
                <FormattedMessage id="INVOICED_TOTAL_WARNING_TOOLTIP" />
              </Tooltip>
            </If>
          </Item>
          <Price price={invoiceSubtotal} className="text-sm font-normal" />
        </SubTotalItemContainer>
        <SubTotalItemContainer>
          <Item>
            <FormattedMessage id="INVOICED_ADDITIONAL_CHARGES" />
            <If isTrue={!invoicedAdditionalChargesExceeds}>
              <InfoTooltip
                message={intl.formatMessage({
                  id: "INVOICED_ADDITIONAL_CHARGES_TOOLTIP",
                })}
              />
            </If>
            <If isTrue={invoicedAdditionalChargesExceeds}>
              <Tooltip
                id="INVOICED_ADDITIONAL_CHARGES_WARNING_TOOLTIP"
                element={<ExclamationCircleOrange />}
              >
                <FormattedMessage id="INVOICED_ADDITIONAL_CHARGES_TOOLTIP" />
                {". "}
                <FormattedMessage id="INVOICED_ADDITIONAL_CHARGES_WARNING_TOOLTIP" />
              </Tooltip>
            </If>
          </Item>
          <Price
            price={invoice?.chargesAmount || 0}
            className="text-sm font-normal"
          />
        </SubTotalItemContainer>
        <If isTrue={!useTax}>
          <SubTotalItemContainer>
            <Item>
              <FormattedMessage id="INVOICED_SALES_TAX" />
              <If isTrue={!invoicedSalesTaxGreaterThanOrderSalesTax}>
                <InfoTooltip
                  message={intl.formatMessage({
                    id: "INVOICED_SALES_TAX_TOOLTIP",
                  })}
                />
              </If>
              <If isTrue={invoicedSalesTaxGreaterThanOrderSalesTax}>
                <Tooltip
                  id="INVOICED_SALES_TAX_WARNING_TOOLTIP"
                  element={<ExclamationCircleOrange />}
                >
                  <FormattedMessage id="INVOICED_SALES_TAX_TOOLTIP" />
                  {". "}
                  <FormattedMessage id="INVOICED_SALES_TAX_WARNING_TOOLTIP" />
                </Tooltip>
              </If>
            </Item>
            <Price
              price={invoice?.taxAmount || 0}
              className="text-sm font-normal"
            />
          </SubTotalItemContainer>
        </If>
        <TotalItemOuter>
          <TotalItemContainer $highlightTotal>
            <TotalItem>
              <FormattedMessage id="INVOICED_TOTAL" />
              <InfoTooltip
                message={intl.formatMessage({
                  id: "INVOICED_TOTAL_TOOLTIP",
                })}
              />
              <If isTrue={totalsAreNotEqual}>
                <Tooltip
                  id="INVOICED_TOTAL_WARNING_TOOLTIP"
                  element={<ExclamationCircleRed />}
                >
                  <FormattedMessage id="INVOICED_TOTAL_WARNING_TOOLTIP" />
                </Tooltip>
              </If>
            </TotalItem>
            <Price price={total} className="font-bold" />
          </TotalItemContainer>
        </TotalItemOuter>
      </AdditionalChargesContainer>
    </OrgRolesWrapper>
  );
};
