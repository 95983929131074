import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { routes } from "@/config/routes";
import {
  AuthorizationStatus,
  ProjectProjectsFieldsFragment,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

type Props = {
  items: ProjectProjectsFieldsFragment[];
  columns: Array<GridCol<ProjectProjectsFieldsFragment>>;
  loading: boolean;
  emptyList?: string | ReactNode;
};

export const ProjectsListItems: FC<Props> = ({ columns, ...props }) => {
  const navigate = useNavigate();

  const openProjectPanel = useCallback(
    (project: ProjectProjectsFieldsFragment) => {
      navigate(
        generatePath(
          project.permissions.report === AuthorizationStatus.Authorized
            ? routes.projectBudgetReport
            : routes.projectDetails,
          { id: project.id },
        ),
      );
    },
    [navigate],
  );

  const itemFn = useCallback((item: ProjectProjectsFieldsFragment) => {
    if (item.deletedAt) {
      return {
        className: "bg-gray-300 opacity-50",
      };
    }
    return { className: "" };
  }, []);

  return (
    <GridTable
      configuration={{
        columns,
        classNames: {
          header: "top-[90px] lg:top-[72px] xl:top-[90px]",
          root: "pt-2",
          item: "cursor-pointer",
          itemFn,
        },
        toggle: {
          item: openProjectPanel,
        },
        hasCardItems: true,
      }}
      readonly
      {...props}
    />
  );
};
