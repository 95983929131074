import { If } from "@/common/components/if/If";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectPhaseCodes } from "@/contractor/pages/home/project/hooks/useProjectPhaseCodes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../../providers/ReceiptProvider";
import { ReceiptCreateReleaseFormValues } from "../../ReceiptVerificationForm";
import { DashedDivider } from "../itemized/DashedDivider";

const Container = tw.div`flex flex-row items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-5`;

export const ReceiptNonItemizedView: FC = () => {
  const { handleSubmit, setValue, watch, trigger, ...formProps } =
    useFormContext<ReceiptCreateReleaseFormValues>();
  const { hasReleaseRecorded } = useReceipt();
  const intl = useIntl();
  const subtotal = watch("subtotal");
  const projectId = watch("projectId");
  const { costCodes, loading } = useProjectCostCodes();
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const orderTypeId = watch("orderTypeId");

  const { hasPhaseCodes } = useOrgSettings();
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const { phaseCodes } = useProjectPhaseCodes();

  const { calcTotalPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
    ],
  );

  useSetCurrentProjectId(projectId);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "0");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
    }),
    [
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      setValue={setValue}
      watch={watch}
      trigger={trigger}
      {...formProps}
    >
      <Container>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={costCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                disableClearable={false}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="subtotal"
              label={intl.$t({ id: "RECEIPT_SUBTOTAL" })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ReleaseAdditionalChargesAndTaxes
            classes={{
              tax: "grid-cols-[auto_164px]",
              root: "mr-5",
              total: "-mr-7",
            }}
            total={`${total}`}
            releaseInput={releaseInput}
            editableByContractor
            includeSubtotal={false}
            includeAdditionalCharges={hasReleaseRecorded}
            editableAdditionalCharges={false}
            updateRelease={(values) =>
              handleUpdateRelease({ ...values, version: -1 })
            }
            hideTotalBorder
            totalDivider={<DashedDivider />}
          />
        </Item>
      </Container>
    </FormProvider>
  );
};
