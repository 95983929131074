import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";

export const useInvoiceCreateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const intl = useIntl();
    const { requiredValidator } = useTableValidators();
    const { importedItems, importedPoItemized } = useInvoiceImportExternalPO();
    const { zones } = useProjectZones();

    const itemIsPartOfPo = useCallback(
      (id: string, columnType: COLUMN_TYPE) => {
        const importedItem = importedItems?.find((item) => item.id === id);
        if (importedItem) {
          switch (columnType) {
            case COLUMN_TYPE.Material:
              return importedItem.description
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            case COLUMN_TYPE.UOM:
              return importedItem.UOM
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            default:
              return "";
          }
        }

        return "";
      },
      [importedItems, intl],
    );

    if (!importedPoItemized) {
      return [
        {
          header: intl.$t({ id: "COST_CODE" }),
          columnId: "description",
          columnType: COLUMN_TYPE.CostCode,
          readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.Material),
        },
        {
          header: intl.$t({ id: "VALUE" }),
          columnId: "unitPrice",
          columnType: COLUMN_TYPE.UnitPrice,
          validator: requiredValidator,
        },
      ];
    }

    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "description",
        columnType: COLUMN_TYPE.Material,
        readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.Material),
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: (id) => itemIsPartOfPo(id, COLUMN_TYPE.UOM),
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        validator: requiredValidator,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        columnType: COLUMN_TYPE.ExtPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
        hidden: zones?.length > 0,
      },
    ];
  };
