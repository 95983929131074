import {
  addHours,
  isToday,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";

export const defaultReleaseDate = (initialDate?: Date | null): Date => {
  const now = new Date();
  const newDate = initialDate ? new Date(initialDate) : null;

  if (!newDate || isToday(newDate)) {
    return addHours(now, 2);
  }

  return setMilliseconds(setSeconds(setMinutes(setHours(newDate, 8), 0), 0), 0);
};
