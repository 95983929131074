import {
  COLUMN_TYPE,
  RendererFunctionType,
} from "@/common/providers/ColumnMapperProvider";

import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { textRenderer } from "handsontable/renderers";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { useRenderHelpers } from "./useRenderHelpers";

export const usePrefillPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getPrefilledValue, isBuyoutItem, getPhysicalColumnIndex } =
    useTableHelpers();
  const { applyTooltip, applyClasses, formatPrice, checkReadOnly } =
    useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      let formattedValue = formatPrice(row, instance, value);
      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      applyClasses(td, "htCenter htMiddle relative");
      const rows = instance?.getData();

      const uom = rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      if (isLumpSumUomText(uom)) {
        return;
      }

      const price = getPrefilledValue({
        material:
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
        vendor: rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
        manufacturer:
          rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)],
        uom: rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      });

      const materialOptions = instance.getCellMeta(
        row,
        getPhysicalColumnIndex(instance, COLUMN_TYPE.Material),
      ).extraOptions;

      const buyoutItem = materialOptions
        ? isBuyoutItem(
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)
            ],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.PrefilledPrice)
            ],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
          )
        : null;

      if (buyoutItem || (value && String(value) === String(price.value))) {
        const priceIconCont = document.createElement("span");
        priceIconCont.className = `
          absolute inline-grid top-0 right-2 place-items-center h-full
        `;
        const priceIcon = document.createElement("span");
        if (buyoutItem) {
          priceIcon.className = `
            text-center border font-semibold border-green-800 rounded-full 
            text-2xs text-white w-3.5 h-3.5
            bg-green-800 hover:text-green-800 hover:bg-green-200
          `;
        } else {
          priceIcon.className = `
            text-center border font-semibold border-blue-500 rounded-full 
            text-2xs text-blue-500 w-3.5 h-3.5
            hover:bg-blue-500 hover:text-white 
          `;
        }
        priceIcon.innerText = `i`;
        priceIconCont.appendChild(priceIcon);
        td.appendChild(priceIconCont);

        applyTooltip(
          priceIcon,
          intl.formatMessage({
            id: buyoutItem
              ? "BUYOUT_ITEM_PRICE_NOTICE"
              : price.isVendorPrice
                ? "VENDOR_PRICE_NOTICE"
                : "ESTIMATED_PRICE_NOTICE",
          }),
        );
      }

      const hasExtPriceColumn = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );
      if (hasExtPriceColumn !== -1) {
        return;
      }
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const priceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.PrefilledPrice,
      );

      const extendedPrice = rows[row][quantityIndex] * rows[row][priceIndex];
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPrice = document.createElement("div");
        extPrice.className = "text-gray-500 text-3xs select-none mb-1";
        const formattedValue = extendedPrice
          ? formatCurrency(extendedPrice)
          : "";
        extPrice.innerText = intl.formatMessage(
          { id: "EXT_WITH_PRICE" },
          { price: formattedValue },
        );
        td.appendChild(extPrice);

        applyTooltip(
          extPrice,
          intl.formatMessage(
            { id: "EXT_PRICE_TOOLTIP" },
            { price: formattedValue },
          ),
        );
      }
      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      formatPrice,
      applyClasses,
      getPhysicalColumnIndex,
      getPrefilledValue,
      isBuyoutItem,
      checkReadOnly,
      applyTooltip,
      intl,
      formatCurrency,
    ],
  );

  return renderer;
};
