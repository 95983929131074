import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceCreateRelease } from "../../../../providers/InvoiceCreateReleaseProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "./InvoiceCreateRelease.config";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const InvoiceCreateReleaseItemizedView = () => {
  const {
    importedItems,
    importedPoItemized,
    isNonItemizedPO,
    importedTaxCodeId,
    importedPoIsImmutable,
  } = useInvoiceImportExternalPO();
  const { setValue, watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { loading, invoiceItems } = useInvoiceCreateRelease();
  const spreadsheetViewColumns = useInvoiceCreateReleaseSpreadsheetConfig();
  const { calcTableTotal, sumTableUnitPrices } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const customTaxAmount = watch("customTaxAmount");
  const orderTypeId = watch("orderTypeId");
  const taxRate = watch("taxRate");
  const subtotal = watch("subtotal");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");
  const { setGlobalVendorId } = useVendorPrices();

  const vendorId = watch("vendorId");
  const projectId = watch("projectId");
  useSetCurrentProjectId(projectId);
  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = importedPoItemized
        ? calcTableTotal(data)
        : sumTableUnitPrices(data);
      if (newTotal !== subtotal) {
        setValue("subtotal", newTotal);
      }
    },
    [
      importedPoItemized,
      calcTableTotal,
      sumTableUnitPrices,
      setValue,
      subtotal,
    ],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || invoice?.taxAmount || "0",
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, invoice?.taxAmount, setValue],
  );

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const items = useMemo(() => {
    if (matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER) {
      if (importedPoItemized && isNonItemizedPO) {
        return invoiceItems;
      }
      return importedItems || [];
    }
    return importedItems ?? invoiceItems;
  }, [
    importedItems,
    invoiceItems,
    importedPoItemized,
    matchedOrderViewState,
    isNonItemizedPO,
  ]);
  const { getTaxAmount } = useTaxCalculation();
  const { calcAdditionalChargesPrice } = usePriceCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <>
      <SpreadSheetView>
        <SpreadSheetTable
          items={items}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="380px"
          rowNumber={17}
          onChanges={updateSubtotal}
          includePermissionCheck={false}
          readOnly={importedPoIsImmutable}
        />
      </SpreadSheetView>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={watch("total")}
          releaseInput={releaseInput}
          customPaymentTerm={watch("paymentTerm")}
          editableByContractor={!importedPoIsImmutable}
          updateRelease={(values) =>
            handleUpdateRelease({ ...values, version: -1 })
          }
          includePaymentTerms
          editablePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
      </Footer>
    </>
  );
};
