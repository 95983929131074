import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import {
  ProjectProjectsFieldsFragment,
  QueryProjectsFilter,
  useProjectsQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useCallback, useEffect } from "react";

type ReturnType = {
  projects: ProjectProjectsFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  setPage: ({
    page,
    pagination,
    queryParams,
  }: {
    page: number;
    pagination?: PaginationArgs;
    queryParams?: Record<
      string,
      string | null | boolean | Array<unknown> | undefined | number
    >;
  }) => void;
  refetchProjects: () => Promise<void>;
};

export const useProjectsWithPagination = (
  filter?: QueryProjectsFilter,
): ReturnType => {
  const { paginationArgs, setPageInfo, setPage } = usePagination();
  const { data, loading, error, refetch } = useProjectsQuery({
    variables: { ...paginationArgs, filter },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.projects?.pageInfo) {
      setPageInfo({ ...data.projects.pageInfo });
    }
  }, [data, setPageInfo]);

  const refetchProjects = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return {
    projects: data?.projects.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.projects.totalCount || 0,
    setPage,
    refetchProjects,
  };
};
