import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item: ReleaseItemFieldsFragment;
  readonly: boolean;
};

const NotesDetailsWithProvider: FC<Props> = ({ item, readonly }) => {
  const { setExpandedItems, expandedItems } = useReleaseActions();
  const { updateRelease } = useReleaseUpdate();
  const { release } = useRelease();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string, assets?: AssetFieldsFragment[]) => {
      await updateRelease({
        releaseId: release?.id ?? "",
        version: release?.version ?? 0,
        updates: [
          {
            releaseItemId: item.id,
            instructions: {
              text: notes,
              assetUrls: assets?.map((a) => a.url) ?? [],
            },
          },
        ],
      });
      closeItem();
    },
    [closeItem, item.id, release, updateRelease],
  );

  return (
    <InlineNotesAndAttachments
      closeItem={closeItem}
      saveItem={handleOnSave}
      item={{
        ...item,
        notes: item.instructions?.text,
      }}
      readonly={readonly}
      labelKey="CONTRACTOR_NOTES"
    />
  );
};

export const ReleaseItemInstructionsDetails: FC<Props> = (props) => {
  const { item } = props;
  const { release } = useRelease();

  return (
    <UploadAssetProvider
      initialAssets={item.instructions?.assets}
      context={AssetContext.Instruction}
      projectId={release?.project?.id}
    >
      <NotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
