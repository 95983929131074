import { Address } from "@/common/components/address/Address";
import { PROJECT_STATUSES } from "@/common/components/display-project-status/DisplayProjectStatus";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { ListDetailsHeaderItem } from "@/common/components/info-header/components/ListDetailsHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { BuyoutVendorContactSelector } from "@/distributor/pages/buyout/components/BuyoutVendorContactSelector";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { BuyoutInlineOrderType } from "../../non-quoted/header/BuyoutInlineOrderType";
import { BuyoutPONumber } from "../../non-quoted/header/BuyoutPONumberInput";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";

type Props = {
  buyout: BuyoutFieldsFragment;
};

const LinkStyled = tw(LinkLike)`
  font-medium text-xs
`;

export const BuyoutQuotedHeaderInfo: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const { isEditable } = useBuyoutGrouped();

  return (
    <InfoHeader>
      <ListDetailsHeaderItem
        title={
          <>
            <FormattedMessage
              tagName="div"
              id="BUYOUT"
              values={{ number: buyout.clientIdentifier }}
            />
            {intl.$t({ id: "FROM" }).toLowerCase()}
            <LinkStyled
              to={generatePath(routes.rfqById, {
                id: buyout.quote?.rfq.id || "",
              })}
            >
              <FormattedMessage
                tagName="div"
                id="RFQ_NUMBER_WITH_SYMBOL"
                values={{ number: buyout.quote?.rfq.clientIdentifier }}
              />
            </LinkStyled>
          </>
        }
        list={[
          {
            label: <FormattedMessage tagName="div" id="PROJECT_TITLE" />,
            value: (
              <Tooltip
                id="buyout_project_name"
                element={
                  <LinkLike
                    to={generatePath(routes.projectEstimatedItems, {
                      id: buyout.project.id || "",
                    })}
                  >
                    {buyout.project.name}
                    {buyout.project.jobNumber
                      ? ` (${buyout.project.jobNumber})`
                      : ""}
                  </LinkLike>
                }
              >
                <Address address={buyout.project.address} />
              </Tooltip>
            ),
          },
          {
            label: <FormattedMessage id="STAGE_TITLE" />,
            value: (
              <FormattedMessage
                id={PROJECT_STATUSES[buyout.project.status].tooltip}
              />
            ),
          },
          {
            label: <FormattedMessage id="START_DATE_TITLE" />,
            value: buyout.project.startDate ? (
              <DateView date={buyout.project.startDate} />
            ) : (
              <FormattedMessage id="NOT_SET" />
            ),
          },
        ]}
      />
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={buyout.preferredVendor?.contacts.filter(
              (c) => c.receivesBuyoutNotifications,
            )}
          />
        }
        orgName={buyout.sellerOrgLocation.org.name}
        logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
        address={buyout.sellerOrgLocation.address}
      />
      <InfoHeaderItem title={<FormattedMessage id="VENDOR_CONTACT" />}>
        <BuyoutVendorContactSelector />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="ORDER_TYPE" />}>
        <BuyoutInlineOrderType readonly={!isEditable} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="PO_HASH" />}>
        <PoNumberingSettingsCheck>
          <BuyoutPONumber buyout={buyout} readonly={!isEditable} />
        </PoNumberingSettingsCheck>
      </InfoHeaderItem>
    </InfoHeader>
  );
};
