import { useExportToCsv } from "@/common/components/csv-export/hooks/useExportToCsv";
import { useExportToCsvInvoiceData } from "@/common/components/csv-export/hooks/useExportToCsvInvoiceData";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Group } from "@/common/components/groups/common/types/types";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import {
  UNASSIGNED_ACTION,
  useSideActionsStore,
} from "@/common/components/side-action-bar/useSideActionsStore";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { InvoiceType } from "@/generated/graphql";
import { DeleteOutline, Download } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineFolderAdd, HiOutlineFolderRemove } from "react-icons/hi";
import { PiFileCsv } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useInvoicesDownload } from "../../../invoice-verification/hooks/useInvoicesDownload";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { InvoiceFolderSelector } from "./InvoiceFolderSelector";

export const InvoicesSideActions = () => {
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { sequenceIds, selectedInvoices, setSelectedInvoices } =
    useInvoiceSequence();
  const { setSelectedAction } = useSideActionsStore();
  const { archiveInvoices, refetchInvoices } = useInvoices();
  const { downloadItems } = useInvoicesDownload(InvoiceType.Invoice);
  const { exportToCsv } = useExportToCsv();
  const { getInvoiceExportToCsvData } = useExportToCsvInvoiceData();

  const [loading, setLoading] = useState(false);

  const exportItemsToCsv = useCallback(() => {
    const { data, fileName, headers } =
      getInvoiceExportToCsvData(selectedInvoices);

    exportToCsv({
      data,
      fileName,
      headers,
    });

    setSystemAlert(
      intl.$t(
        { id: "INVOICES_EXPORT_CSV_SUCCESS" },
        { count: selectedInvoices.length },
      ),
      { type: SystemAlertType.MANUAL },
    );
  }, [
    getInvoiceExportToCsvData,
    selectedInvoices,
    exportToCsv,
    setSystemAlert,
    intl,
  ]);

  const deleteItems = useCallback(() => {
    if (sequenceIds) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text: intl.$t(
          { id: "DELETE_INVOICES_MESSAGE_WITH_NUMBER" },
          { number: sequenceIds.length },
        ),
        handleConfirm: async () => {
          setLoading(true);
          await archiveInvoices(sequenceIds);
          await refetchInvoices();
          setLoading(false);
          setSelectedInvoices([]);
        },
      });
    }
  }, [
    sequenceIds,
    setLoading,
    setSelectedInvoices,
    archiveInvoices,
    intl,
    openDialog,
    refetchInvoices,
  ]);

  const actions = useMemo(() => {
    const existingFolders = selectedInvoices
      .map((invoice) => invoice.folders)
      .flat()
      .reduce((acc: Group[], group) => {
        if (!acc.find((g) => g.id === group?.id) && group) {
          acc.push(group);
        }
        return acc;
      }, []);
    return [
      {
        icon: <HiOutlineFolderAdd className="h-6 w-6" />,
        tooltipKey: "ADD_TO_GROUP",
        component: (
          <InvoiceFolderSelector
            onClose={() => {
              setSelectedAction(UNASSIGNED_ACTION);
            }}
          />
        ),
        preventClose: true,
      },
      {
        icon: <HiOutlineFolderRemove className="h-6 w-6" />,
        tooltipKey: "REMOVE_FROM_GROUP",
        component: (
          <InvoiceFolderSelector
            removing
            onClose={() => {
              setSelectedAction(UNASSIGNED_ACTION);
            }}
            existingFolders={existingFolders}
          />
        ),
        preventClose: true,
      },
      {
        icon: <Download />,
        tooltipKey: "DOWNLOAD_INVOICES",
        callback: downloadItems,
      },
      {
        icon: <PiFileCsv className="h-6 w-6" />,
        tooltipKey: "EXPORT_TO_CSV",
        callback: exportItemsToCsv,
      },
      {
        icon: <DeleteOutline />,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      },
    ];
  }, [
    deleteItems,
    downloadItems,
    exportItemsToCsv,
    selectedInvoices,
    setSelectedAction,
  ]);

  return (
    <SideActionBar
      loading={loading}
      visible={sequenceIds.length > 0}
      actions={actions}
    />
  );
};
