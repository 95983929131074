import { InputBaseComponentProps, InputLabelProps } from "@mui/material";
import {
  DateOrTimeView,
  DateTimePicker as DateTimePickerMaterial,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { FC, ReactNode, useMemo } from "react";
import { TextField, TextFieldProps } from "../../textfield/TextField";

const DATE_TIME_FORMAT = "MM/‎dd‎/yyyy - hh:mm a";

export type DateTimePickerProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
  onAccept?: (date: Date | null) => void;
  label?: string | ReactNode;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  required?: boolean;
  staticText?: boolean;
  open?: boolean;
  error?: boolean;
  InputLabelProps?: InputLabelProps;
  inputProps?: InputBaseComponentProps | undefined;
  minTime?: Date;
  testId?: string;
  actionBar?: React.ElementType<PickersActionBarProps> | undefined;
  ref?: React.Ref<HTMLDivElement>;
  disabled?: boolean;
  hideTime?: boolean;
};

const DateTimePickerTextField: FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      size="small"
      testId="date-time-picker-text-field"
      sx={{
        " .MuiInputBase-root": {
          backgroundColor: "white",
        },
        " .MuiInputBase-input": {
          paddingTop: "8.5px",
          paddingBottom: "8.5px",
          lineHeight: "1em",
        },
        " .MuiIconButton-root": {
          color: "#0070C0",
        },
        " .MuiIconButton-root:hover": {
          color: "#23B1ED",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

export const DateTimePicker: FC<DateTimePickerProps> = ({
  date,
  onChange,
  onAccept,
  label,
  minDate,
  maxDate,
  className,
  required,
  staticText,
  open,
  error,
  InputLabelProps,
  inputProps,
  minTime,
  actionBar,
  disabled,
  hideTime = true,
  ref,
}) => {
  const views = useMemo(
    () =>
      hideTime ? (["year", "month", "day"] as DateOrTimeView[]) : undefined,
    [hideTime],
  );
  const format = useMemo(
    () => (hideTime ? undefined : DATE_TIME_FORMAT),
    [hideTime],
  );

  return (
    <DateTimePickerMaterial
      className={className}
      open={open}
      ref={ref}
      value={date}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(newDate) => !staticText && onChange(newDate as Date)}
      onAccept={(value) => !staticText && onAccept?.(value)}
      disableOpenPicker={staticText}
      minTime={minTime}
      views={views}
      format={format}
      slots={{
        textField: DateTimePickerTextField,
        actionBar,
      }}
      slotProps={{
        textField: {
          required,
          disabled,
          onBlur: () => onAccept && onAccept(date),
          error,
          InputLabelProps,
          inputProps,
        },
        popper: {
          placement: "bottom",
        },
      }}
    />
  );
};
