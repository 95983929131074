import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { PoNumberInputControlled } from "@/common/components/po-number/PoNumberInputControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Popover } from "@/common/components/popover/Popover";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutFromQuote } from "../../../../providers/BuyoutFromQuoteProvider";
import { CreateBuyoutFromQuoteFormValues } from "./CreateBuyoutFromQuoteForm";

const Container = tw.div`w-full grid grid-cols-[1fr_1fr_1fr] grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;
const VendorContactTooltip = tw.div`max-w-[300px]`;

export const CreateBuyoutFromQuoteHeader: FC = () => {
  const intl = useIntl();
  const { locations, loading: loadingLocations } = useUserLocations();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const {
    vendors,
    loadingVendors,
    predictedPoNumber,
    loadingPredictedPoNumber,
  } = useBuyoutFromQuote();
  const { shouldShowVendorCode, getVendorCode } = useVendors();
  const { setValue, watch, trigger } =
    useFormContext<CreateBuyoutFromQuoteFormValues>();

  const projectId = watch("projectId");
  const businessLocationId = watch("businessLocationId");
  const vendorId = watch("vendorId");
  const projectOptions = useMemo(
    () =>
      projects.filter((project) => project.location.id === businessLocationId),
    [projects, businessLocationId],
  );

  useEffect(() => {
    if ((locations ?? []).length === 1) {
      setValue("businessLocationId", (locations ?? [])[0].id);
    }
  }, [locations, setValue]);

  useEffect(() => {
    if (projects.length === 1 && !projectId) {
      setValue("projectId", projects[0].id);
    }
  }, [projectId, projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  useSetCurrentProjectId(projectId);

  useEffect(() => {
    if (vendorId) {
      const vendor = vendors.find((v) => v.sellerOrgLocation.id === vendorId);
      setValue(
        "vendorContactIds",
        (vendor?.contacts?.filter((c) => c.receivesBuyoutNotifications)
          .length === 1
          ? vendor?.contacts?.filter((c) => c.receivesBuyoutNotifications)
          : []
        ).map((c) => c.id) || [],
      );
    }
  }, [vendorId, vendors, setValue]);

  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === vendorId),
    [vendors, vendorId],
  );

  return (
    <Container>
      <If isTrue={locations.length > 1}>
        <SelectControlled
          name="businessLocationId"
          options={locations ?? []}
          getValue={(o) => o.id}
          getLabel={(o) => o.name}
          placeholder={intl.$t({ id: "BUSINESS_LOCATION" })}
          loading={loadingLocations}
          rules={{
            required: true,
          }}
        />
      </If>
      <SelectControlled
        name="projectId"
        options={projectOptions}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        rules={{
          required: true,
        }}
      />
      <OrderTypePickerControlled name="releaseTypeId" />
      <PoNumberingSettingsCheck>
        <PoNumberInputControlled
          setValue={(poNumber) => setValue("poNumber", poNumber)}
          trigger={() => trigger("poNumber")}
          predictedPoNumber={predictedPoNumber}
          loading={loadingPredictedPoNumber}
          includeMagicWand
          disabled={!projectId}
        />
      </PoNumberingSettingsCheck>
      <SelectControlled
        name="vendorId"
        options={vendors}
        getValue={(o) => o.sellerOrgLocation.id}
        getLabel={(o) =>
          vendorLabelFormatter(
            o.sellerOrgLocation,
            o.contacts.filter((c) => c.receivesBuyoutNotifications),
            { vendorCode: getVendorCode(o) },
          )
        }
        placeholder={intl.$t({ id: "VENDOR" })}
        loading={loadingVendors}
        rules={{
          required: true,
        }}
        customRender={(item) =>
          VendorPickerCustomRender(item, {
            filter: (c) => c.receivesBuyoutNotifications,
            shouldShowVendorCode,
          })
        }
        limitResults={MAX_VENDOR_NUMBER}
      />
      {!!selectedVendor && selectedVendor.contacts.length === 0 ? (
        <Popover
          id="vendor-contact-ids-tooltip"
          $arrow
          classes={{}}
          element={
            <MultiselectControlled
              name="vendorContactIds"
              options={selectedVendor?.contacts || []}
              getValue={(o) => o.id}
              getLabel={(o) => o.name}
              placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
              rules={{
                required: selectedVendor
                  ? selectedVendor.contacts.filter(
                      (contact) => contact.receivesBuyoutNotifications,
                    ).length > 0
                  : true,
              }}
              noWrap
              limitTags={1}
              chipSize="small"
              includeCheckbox
              disableCloseOnSelect
            />
          }
        >
          <VendorContactTooltip>
            {intl.$t({ id: "VENDOR_WITHOUT_CONTACT_DETAILS" })}
          </VendorContactTooltip>
        </Popover>
      ) : (
        <MultiselectControlled
          name="vendorContactIds"
          options={selectedVendor?.contacts || []}
          getValue={(o) => o.id}
          getLabel={(o) => o.name}
          placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
          rules={{
            required: selectedVendor
              ? selectedVendor.contacts.filter(
                  (contact) => contact.receivesBuyoutNotifications,
                ).length > 0
              : true,
          }}
          noWrap
          limitTags={1}
          chipSize="small"
          includeCheckbox
          disableCloseOnSelect
        />
      )}
    </Container>
  );
};
