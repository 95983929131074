import { MAX_VENDOR_NUMBER } from "@/common/const";
import { FC } from "react";
import { useIntl } from "react-intl";
import { StyledSelect } from "../select/components/single/StyledSelect";
import { VendorPickerCustomRender } from "./VendorPickerCustomRender";
import { useVendorOptions } from "./useVendorOptions";

const ALL_VENDORS = "-1";

type Props = {
  vendorId: string | null;
  setVendorId: (vendorId: string | null) => void;
  placeholder?: string;
  disabledTooltip?: string;
  staticText?: boolean;
  required?: boolean;
  includeAllOption?: boolean;
  xs?: boolean;
  includePlaceholder?: boolean;
  inputPropsClassName?: string;
};

export const VendorPicker: FC<Props> = ({
  vendorId,
  setVendorId,
  placeholder,
  staticText,
  required,
  includeAllOption = true,
  xs = true,
  includePlaceholder = true,
  inputPropsClassName = "",
}) => {
  const intl = useIntl();
  const { vendorOptions, loading } = useVendorOptions({ includeAllOption });

  return (
    <StyledSelect
      className="w-full"
      testId="form-vendors-selector"
      placeholder={
        includePlaceholder
          ? placeholder || intl.$t({ id: "VENDOR" })
          : undefined
      }
      inputProps={{
        className: inputPropsClassName,
      }}
      options={vendorOptions || []}
      loading={loading}
      value={vendorId || ALL_VENDORS}
      required={required}
      onChange={(value) =>
        setVendorId(value !== ALL_VENDORS ? (value as string) : null)
      }
      customRender={VendorPickerCustomRender}
      getLabel={(option) => option.name}
      getValue={(option) => option.value as string}
      noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
      staticText={staticText}
      size={xs ? "xs" : undefined}
      limitResults={MAX_VENDOR_NUMBER}
    />
  );
};
