import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  formattedDate,
  SHORT_DATE_OPTION,
} from "@/common/utils/dates/DateView";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useUnlinkInvoice } from "../../../scanned-invoices/hooks/useUnlinkInvoice";

type DotColor = "green" | "red" | "orange";

const COLOR_MAP = {
  green: "bg-green-800",
  red: "bg-red-500",
  orange: "bg-orange-500",
};

enum InvoiceExportStatusEnum {
  OK,
  InProgress,
  Failed,
}

const INVOICE_EXPORT_STATUSES = {
  [InvoiceExportStatusEnum.OK]: {
    color: "green",
    label: "EXPORTED_AT",
  },
  [InvoiceExportStatusEnum.InProgress]: {
    color: "orange",
    label: "INVOICE_EXPORT_IN_PROGRESS",
  },
  [InvoiceExportStatusEnum.Failed]: {
    color: "red",
    label: "INVOICE_EXPORT_FAILED",
  },
};

const Container = tw.div`flex flex-row gap-3`;
const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;
const Status = tw.div`w-2 h-2 rounded-full inline-block mr-2 bg-green-800
${({ status }: { status: InvoiceExportStatusEnum }) =>
  COLOR_MAP[INVOICE_EXPORT_STATUSES[status].color as DotColor]}
`;
const ErrorMessage = tw.div`whitespace-break-spaces`;

type Props = {
  invoice: Pick<InvoiceFieldsFragment, "status" | "id" | "link" | "number">;
  toggleExportPanel: () => void;
};

export const InvoiceExportStatus: FC<Props> = ({
  invoice,
  toggleExportPanel,
}) => {
  const intl = useIntl();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { unlinkInvoice } = useUnlinkInvoice();
  const { openDialog } = useDialog();
  const unlink = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "DISCONNECT_INVOICE" }),
      content: intl.$t({ id: "DISCONNECT_INVOICE_CONFIRMATION" }),
      handleConfirm: async () => {
        await unlinkInvoice(invoice.link?.id);
      },
      icon: <WarningIcon />,
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DISCONNECT" }),
    });
  }, [openDialog, intl, unlinkInvoice, invoice.link?.id]);

  const status = useMemo(() => {
    if (invoice.link?.failedAt) {
      return InvoiceExportStatusEnum.Failed;
    }
    if (invoice.link?.exportedAt) {
      return InvoiceExportStatusEnum.OK;
    }
    return InvoiceExportStatusEnum.InProgress;
  }, [invoice.link?.failedAt, invoice.link?.exportedAt]);

  return (
    <Container>
      <ExportedAt>
        <Status status={status} />
        <PoContainer>
          <FormattedMessage
            id="INVOICE_NUMBER_INTEGRATION"
            values={{
              number: invoice.number,
              integration: intl.$t({
                id: connectedSourceSystem
                  ? `INTEGRATION_${connectedSourceSystem}`
                  : `INTEGRATION_${connectedIntegrationType}`,
              }),
            }}
            tagName={Item}
          />
          <FormattedMessage
            id={INVOICE_EXPORT_STATUSES[status].label}
            values={{
              date: formattedDate({
                date:
                  status === InvoiceExportStatusEnum.Failed
                    ? invoice.link?.failedAt
                    : invoice.link?.exportedAt,
                options: SHORT_DATE_OPTION,
              }),
              message: (
                <Tooltip
                  id="link-failed"
                  className="inline"
                  element={intl.$t({ id: "AUTOMATED_EXPORT_FAILED" })}
                >
                  {invoice.link?.failedMessage ? (
                    <FormattedMessage
                      id="AUTOMATED_EXPORT_FAILED_BECAUSE_OF"
                      values={{ error: invoice.link?.failedMessage }}
                      tagName={ErrorMessage}
                    />
                  ) : undefined}
                </Tooltip>
              ),
            }}
            tagName={Item}
          />
        </PoContainer>
      </ExportedAt>
      <If isTrue={!invoice.link?.failedAt}>
        <OutlinedButton $small onClick={unlink}>
          {intl.$t({ id: "DISCONNECT" })}
        </OutlinedButton>
      </If>
      <If isTrue={invoice.link?.failedAt}>
        <OutlinedButton $small onClick={toggleExportPanel}>
          {intl.$t({ id: "RETRY" })}
        </OutlinedButton>
      </If>
    </Container>
  );
};
