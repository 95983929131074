import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { BuyoutVendorContactSelector } from "@/distributor/pages/buyout/components/BuyoutVendorContactSelector";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { ChevronRight } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { BuyoutInlineOrderType } from "./BuyoutInlineOrderType";
import { BuyoutPONumber } from "./BuyoutPONumberInput";
import { ToggleBuyoutForm } from "./ToggleEditBuyoutButton";

type Props = {
  buyout: BuyoutFieldsFragment;
  readonly: boolean;
};

const ChevronRightStyled = tw(ChevronRight)`
  text-sm
`;

const BuyoutName = tw.div`
  font-medium text-base
`;

const TitleWrapper = tw.div`flex gap-1`;

const Label = tw.div``;

const LabelWrapper = tw.div`flex flex-col`;
const Detail = tw.div`flex gap-1`;

export const BuyoutNonQuotedHeaderInfo: FC<Props> = ({ buyout, readonly }) => {
  return (
    <InfoHeader>
      <InfoHeaderItem
        title={
          <TitleWrapper>
            <ToggleBuyoutForm
              buyout={buyout}
              staticText={buyout.status !== BuyoutStatus.Draft}
            >
              <FormattedMessage
                id="BUYOUT"
                tagName={BuyoutName}
                values={{ number: buyout.clientIdentifier }}
              />
            </ToggleBuyoutForm>
            <If isTrue={!buyout.quoteDocument}>
              <ChevronRightStyled />
              <FormattedMessage id="BUYOUT_WITHOUT_QUOTE" />
            </If>
          </TitleWrapper>
        }
      >
        <LabelWrapper>
          <FormattedMessage
            id="BY_USERNAME"
            tagName={Label}
            values={{
              username: getUserName(buyout.createdBy),
            }}
          />
          <Detail>
            <FormattedMessage id="PROJECT" />:
            <Tooltip
              id="project-name"
              element={
                <LinkLike
                  to={generatePath(routes.projectEstimatedItems, {
                    id: buyout.project.id,
                  })}
                >
                  {buyout.project.name}
                  {buyout.project.jobNumber
                    ? ` (${buyout.project.jobNumber})`
                    : ""}
                </LinkLike>
              }
            >
              <Address address={buyout.project.address} />
            </Tooltip>
          </Detail>
          <Detail>
            <FormattedMessage id="START_DATE" />:{" "}
            <DateView date={buyout.project.startDate} />
          </Detail>
        </LabelWrapper>
      </InfoHeaderItem>
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={buyout.preferredVendor?.contacts.filter(
              (c) => c.receivesBuyoutNotifications,
            )}
          />
        }
        orgName={buyout.sellerOrgLocation.org.name}
        logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
        address={buyout.sellerOrgLocation.address}
      />
      <If isTrue={!readonly}>
        <InfoHeaderItem title={<FormattedMessage id="VENDOR_CONTACT" />}>
          <BuyoutVendorContactSelector />
        </InfoHeaderItem>
      </If>
      <InfoHeaderItem title={<FormattedMessage id="ORDER_TYPE" />}>
        <BuyoutInlineOrderType readonly={readonly} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="PO_HASH" />}>
        <PoNumberingSettingsCheck>
          <BuyoutPONumber buyout={buyout} readonly={readonly} />
        </PoNumberingSettingsCheck>
      </InfoHeaderItem>
    </InfoHeader>
  );
};
