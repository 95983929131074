import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProjectOrderedQuantity } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { Modal } from "@mui/material";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ValueWithTax } from "../cost-codes-totals/CostCodeTotal.Styles";
import { ReportModalReleaseItem } from "./ReportModalReleaseItem";

const ModalStyled = tw(Modal)`flex flex-1 mx-[calc(10vw)] my-[calc(10vh)]`;
const Container = tw.div`flex flex-1 bg-white flex-col rounded-xl pt-5`;
const Title = tw.div`flex flex-col mx-8 mb-6 mt-1 text-xs gap-1`;
const ItemName = tw.div`text-lg font-medium`;
const DeliveriesContainer = tw.div`mx-5 border-1 border border-gray-300 rounded-md mb-5 overflow-y-auto`;
const Header = tw.div`sticky top-0 z-10 grid grid-cols-[2fr_1fr_1fr_1px_1fr_1fr] px-5 py-3 rounded-t-md
${({ $type }: { $type?: HeaderType }) =>
  $type === HeaderType.Total
    ? "bg-blue-100"
    : $type === HeaderType.CostCode
      ? "bg-yellow-200"
      : "bg-yellow-300"}`;
const HeaderName = tw.div`text-sm font-medium flex items-center justify-center`;
const StyledPrice = tw(Price)`text-sm font-medium text-center`;
const FlexOne = tw.div`flex flex-1`;
const Footer = tw.div`flex px-4 bg-gray-100 py-4 items-center justify-end rounded-b-xl`;
const Separator = tw.div`w-[1px] bg-gray-200`;
const Row = tw.div`flex`;
const VendorContainer = tw.div`flex flex-row items-center gap-x-2 my-4 mx-8`;
const VendorName = tw.div`text-sm font-medium`;
const PriceContainer = tw.div`flex flex-col gap-y-0.5 justify-center`;
const ListFooter = tw.div`sticky bottom-0 z-10 grid grid-cols-[2fr_1fr_1fr_1px_1fr_1fr] px-5 py-3 rounded-b-md bg-gray-100`;
const ListFooterValue = tw.div`text-sm font-normal flex items-center justify-center`;

export enum HeaderType {
  Total,
  CostCode,
  Zone,
}

export enum ReportType {
  Material,
  Vendor,
}

type Props = {
  opened: boolean;
  close: () => void;
  reportType: ReportType;
  headerType: HeaderType;
  itemCostCodeId?: string;
};

export const ReportItemOrderedInvoicedModal: FC<Props> = ({
  opened,
  close,
  reportType,
  headerType,
  itemCostCodeId,
}) => {
  const intl = useIntl();
  const {
    item,
    zoneId,
    costCodeId,
    ordered,
    orderedTotal,
    invoiced,
    invoicedTotal,
    loading,
  } = useProjectOrderedQuantity();
  const { project } = useProject();
  const { zoneMap, costCodeMap } = useProjectMaps(project);
  const { formatCurrency } = useFormatNumberToCurrency();

  const filteredItems = useMemo(
    () =>
      item?.elements?.filter(
        (element) =>
          !itemCostCodeId ||
          itemCostCodeId === UNSPECIFIED_COST_CODE_ID ||
          element.releaseItems.some(
            (releaseItem) => releaseItem.costCode?.id === itemCostCodeId,
          ),
      ),
    [item?.elements, itemCostCodeId],
  );

  return (
    <ModalStyled open={opened}>
      <Loader loading={loading}>
        <Container>
          <Row>
            <If isTrue={reportType === ReportType.Vendor && !!itemCostCodeId}>
              <VendorContainer>
                <VendorLogo
                  logoImageUrl={
                    item?.elements?.[0]?.release.sellerOrgLocation?.org.photoUrl
                  }
                  orgName={
                    item?.elements?.[0]?.release.sellerOrgLocation?.org
                      .name as string
                  }
                  address={
                    item?.elements?.[0]?.release.sellerOrgLocation?.address
                  }
                />
                <VendorName>
                  {
                    item?.elements?.[0]?.release.sellerOrgLocation?.org
                      .name as string
                  }
                </VendorName>
              </VendorContainer>
            </If>
            <If isTrue={reportType === ReportType.Material && itemCostCodeId}>
              <Title>
                <FormattedMessage id="ITEM_NAME" />
                <ItemName>{item?.orgMaterial?.material?.name}</ItemName>
              </Title>
            </If>
          </Row>
          <DeliveriesContainer>
            <If
              isTrue={
                headerType === HeaderType.Zone ||
                headerType === HeaderType.Total
              }
            >
              <Header $type={zoneId ? HeaderType.Zone : HeaderType.Total}>
                {zoneId ? (
                  <ProductCategory
                    type={ProductCategoryType.Zone}
                    category={{
                      id: zoneId,
                      name: zoneMap.get(zoneId)?.name || "",
                      isOpened: false,
                    }}
                    includeToggle={false}
                  />
                ) : (
                  <HeaderName />
                )}
                <HeaderName />
                <HeaderName>{intl.$t({ id: "INVOICE_ORDERED" })}</HeaderName>
                <Separator />
                <HeaderName />
                <HeaderName>
                  {intl.$t({ id: "ORDER_ITEM_INVOICED" })}
                </HeaderName>
              </Header>
            </If>
            <If isTrue={headerType === HeaderType.CostCode}>
              <Header $type={HeaderType.CostCode}>
                <ProductCategory
                  type={ProductCategoryType.CostCode}
                  category={{
                    id: costCodeId || "",
                    name:
                      costCodeMap.get(costCodeId as string)?.description || "",
                    isOpened: false,
                  }}
                  includeToggle={false}
                  projectId={project?.id}
                />
                <HeaderName />
                <HeaderName>{intl.$t({ id: "INVOICE_ORDERED" })}</HeaderName>
                <Separator />
                <HeaderName />
                <HeaderName>
                  {intl.$t({ id: "ORDER_ITEM_INVOICED" })}
                </HeaderName>
              </Header>
            </If>
            {filteredItems?.map((item, index) => (
              <ReportModalReleaseItem
                key={index}
                item={item}
                withBorder={index !== filteredItems.length - 1}
                includeVendorHeader={
                  reportType === ReportType.Material || !itemCostCodeId
                }
              />
            ))}
            <ListFooter>
              <ListFooterValue />
              <FormattedMessage
                id={
                  headerType === HeaderType.CostCode ||
                  reportType === ReportType.Vendor
                    ? "TOTAL"
                    : "SUBTOTAL"
                }
                tagName={ListFooterValue}
              />
              {headerType === HeaderType.CostCode ? (
                <PriceContainer>
                  <StyledPrice price={orderedTotal} />
                  <If
                    isTrue={orderedTotal && ordered && orderedTotal !== ordered}
                  >
                    <ValueWithTax>
                      <FormattedMessage
                        id="VALUE_TAX_AND_FEES"
                        values={{
                          value: formatCurrency(
                            new Decimal(orderedTotal ?? 0).minus(ordered ?? 0),
                          ),
                        }}
                      />
                    </ValueWithTax>
                  </If>
                </PriceContainer>
              ) : (
                <StyledPrice price={ordered} />
              )}
              <Separator />
              <ListFooterValue />
              {headerType === HeaderType.CostCode ? (
                <PriceContainer>
                  <StyledPrice price={invoicedTotal} />
                  <If
                    isTrue={
                      invoicedTotal && invoiced && invoicedTotal !== invoiced
                    }
                  >
                    <ValueWithTax>
                      <FormattedMessage
                        id="VALUE_TAX_AND_FEES"
                        values={{
                          value: formatCurrency(
                            new Decimal(invoicedTotal ?? 0).minus(
                              invoiced ?? 0,
                            ),
                          ),
                        }}
                      />
                    </ValueWithTax>
                  </If>
                </PriceContainer>
              ) : (
                <StyledPrice price={invoiced} />
              )}
            </ListFooter>
          </DeliveriesContainer>
          <FlexOne />
          <Footer>
            <OutlinedButton onClick={close}>
              <FormattedMessage id="CLOSE" />
            </OutlinedButton>
          </Footer>
        </Container>
      </Loader>
    </ModalStyled>
  );
};
