import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { CreateOrderFromNoteForm } from "./create-order-from-note/components/CreateOrderFromNoteForm";
import { CreateOrderFromNote } from "./create-order-from-note/CreateOrderFromNote";
import { NoteDocumentProvider } from "./note-document/providers/NoteDocumentProvider";

export const OrderFromNote = () => {
  return (
    <CreateOrderFromNoteForm>
      <WarehousesProvider>
        <NoteDocumentProvider>
          <CreateOrderFromNote />
        </NoteDocumentProvider>
      </WarehousesProvider>
    </CreateOrderFromNoteForm>
  );
};
