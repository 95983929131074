import { useExternalPOUtils } from "@/common/components/import-external-po/hooks/useExternalPOUtils";
import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { ExternalPoImportVariables } from "@/common/components/import-external-po/types/ExternalPoImportVariables";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import {
  ExternalPoFieldsFragment,
  ExternalPoImportPossibility,
  TaxMode,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useExternalPoItemsValidation } from "../../../../../../../common/components/import-external-po/import-external-po-wizard/importable-external-po-items/hooks/useExternalPoItemsValidation";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-verification/components/delivery-slip-form/DeliverySlipVerificationForm";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../providers/DeliverySlipVerificationProvider";

export const useDeliverySlipImportExternalPO = () => {
  const { showModal, variables, getPages, ...props } = useImportExternalPos();
  const {
    setImportedItems,
    setSelectedBaseExternalPO,
    selectedBaseExternalPO,
    openModal,
    closeModal,
    setVariables,
  } = props;
  const { getImportableItems } = useExternalPOUtils();
  const { deliverySlip, setPackingSlipMatchViewState, setSelectedRelease } =
    useDeliverySlipVerification();
  const { orderTypes } = useOrderTypes();
  const { checkIfPOIsItemized } = useExternalPOUtils();
  const { validate } = useExternalPoItemsValidation({
    release: variables.release,
  });
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { setValue } = useFormContext<DeliverySlipReleaseFormValues>();

  useEffect(() => {
    if (deliverySlip?.release) {
      setVariables({
        release: deliverySlip.release,
      });
    }
  }, [deliverySlip?.release, setVariables]);

  const importExternalPO = useCallback(
    (externalPO: ExternalPoFieldsFragment | null | undefined) => {
      if (externalPO) {
        const isNonItemizedPO = !checkIfPOIsItemized(externalPO);
        setSelectedBaseExternalPO(externalPO);
        setSelectedRelease(null);
        const variables: Partial<ExternalPoImportVariables> = {
          importedDescription: externalPO.description,
          importedTaxAmount:
            orderTypes.find(
              (orderType) => orderType.id === externalPO?.releaseType?.id,
            )?.taxMode !== TaxMode.Column
              ? externalPO.itemGroups.taxAmount
              : "",
          importedTaxType: externalPO.itemGroups.taxType,
          importedTaxCodeId: externalPO.itemGroups.taxCodeId,
          ...(externalPO.itemGroups.importPossibility ===
            ExternalPoImportPossibility.Immutable && {
            importedPoIsImmutable: true,
          }),
          importedPoItemized: !isNonItemizedPO,
          importedSellerOrgLocationId:
            externalPO?.vendor?.orgPreferredVendors?.[0].sellerOrgLocation.id,
          importedLocationId: externalPO.project?.project?.location?.id,
          importedProjectId: externalPO.project?.project?.id,
          importedOrderTypeId: externalPO.releaseType?.id,
          importedPoExternalId: externalPO.externalId,
          importedPoNumber: externalPO.number,
          importedPoDate: externalPO.date,
          importedAdditionalCharges:
            externalPO.itemGroups.additionalCharges.map((i) => ({
              amount: i.amount,
              description: i.description,
              id: i.externalId,
            })),
          importedCustomTaxAmount: externalPO.itemGroups?.salesTax.length
            ? externalPO.itemGroups.salesTax
                ?.reduce(
                  (acc, next) =>
                    new Decimal(acc).add(new Decimal(next.amount)).toNumber(),
                  0,
                )
                .toString()
            : undefined,
        };
        setVariables(variables);
        setImportedItems(getImportableItems(externalPO));
        setPackingSlipMatchViewState(PackingSlipMatchViewState.CREATE_ORDER);
        if (variables.importedSellerOrgLocationId) {
          setValue("vendorId", variables.importedSellerOrgLocationId);
        }
        if (variables.importedProjectId) {
          setValue("projectId", variables.importedProjectId);
        }
        if (variables.importedOrderTypeId) {
          setValue("orderTypeId", variables.importedOrderTypeId);
        }
        if (variables.importedPoNumber) {
          setValue("poNumber", variables.importedPoNumber);
        }
        if (!validate(selectedBaseExternalPO ?? externalPO, externalPO)) {
          setSystemAlert(
            intl.$t({
              id: isNonItemizedPO
                ? "NON_ITEMIZED_EXTERNAL_PO_ASSOCIATED"
                : "EXTERNAL_PO_IMPORTED",
            }),
          );
          closeModal();
        } else {
          openModal({
            skipPoList: true,
          });
        }
      }
    },
    [
      checkIfPOIsItemized,
      setSelectedBaseExternalPO,
      setSelectedRelease,
      orderTypes,
      setVariables,
      setImportedItems,
      getImportableItems,
      setPackingSlipMatchViewState,
      validate,
      selectedBaseExternalPO,
      setValue,
      setSystemAlert,
      intl,
      closeModal,
      openModal,
    ],
  );

  const pages = useMemo(
    () => getPages({ importSelectedExternalPO: importExternalPO }),
    [getPages, importExternalPO],
  );

  return {
    ...variables,
    ...props,
    importModalOpened: showModal,
    importExternalPO,
    pages,
  };
};
