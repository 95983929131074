import { useUser } from "@/common/providers/UserProvider";
import Decimal from "decimal.js";
import { FC, useCallback } from "react";
import { UpdateSalesTaxFunction } from "../../types/SalesTaxInputProps";
import { SalesTaxAmountInput } from "./input/SalesTaxAmountInput";
import { SalesTaxPercentageInput } from "./input/SalesTaxPercentageInput";
import { SalesTaxType } from "./toggle/AmountPercentageSwitch";

type Props = {
  salesTax: string | number | null;
  setSalesTax: (value: number | null) => void;
  salesAmount: number;
  setSalesAmount: (value: number) => void;
  formattedTaxAmount: string;
  type: SalesTaxType;
  update?: UpdateSalesTaxFunction;
};

export const UseSalesTaxInput: FC<Props> = ({
  salesTax,
  setSalesTax,
  salesAmount,
  setSalesAmount,
  formattedTaxAmount,
  update,
  type,
}) => {
  const { isContractor } = useUser();

  const onTaxPercentageBlur = useCallback(async () => {
    const input = {
      taxRate: salesTax ? new Decimal(salesTax).dividedBy(100).toString() : "0",
      clearCustomTaxAmount: true,
      customTaxAmount: null,
      ...(isContractor && { clearTaxCode: true }),
    };

    await update?.(input);
  }, [salesTax, isContractor, update]);

  const onTaxAmountBlur = useCallback(
    async (newSalesAmount: string | null) => {
      setSalesAmount(Number(newSalesAmount || "0"));
      const input = {
        customTaxAmount: newSalesAmount || "0",
        clearCustomTaxAmount: false,
        ...(isContractor && { clearTaxCode: true }),
      };

      await update?.(input);
    },
    [setSalesAmount, isContractor, update],
  );

  return type === SalesTaxType.Percent ? (
    <SalesTaxPercentageInput
      value={salesTax}
      setValue={setSalesTax}
      formattedTaxAmount={formattedTaxAmount}
      onBlur={onTaxPercentageBlur}
    />
  ) : (
    <SalesTaxAmountInput value={salesAmount} onBlur={onTaxAmountBlur} />
  );
};
