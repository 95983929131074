import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { OutlinedButton } from "../button/OutlinedButton";

export const PageBackButton = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (window.history.length <= 1) {
    return null;
  }

  return (
    <OutlinedButton onClick={handleClick}>
      <FormattedMessage id="BACK" />
    </OutlinedButton>
  );
};
