import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterProps,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";
import { BuyoutInlineAllowance } from "./BuyoutInlineAllowance";

const marginRightClass = "pr-2";

export const BuyoutReceivedTotalFooter: FC<FooterProps> = ({ Wrapper }) => {
  const { receivedSalesTax, receivedSubtotal, receivedTotal } =
    useBuyoutGrouped();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  if (!canViewPrices) {
    return null;
  }

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end">
        <FooterItemInputContainer>
          <BuyoutInlineAllowance />
        </FooterItemInputContainer>
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="RECEIVED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price
            price={receivedSubtotal}
            className="font-light"
            testId="receivedSubtotal"
          />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={receivedSalesTax}>
            <Price
              price={receivedSalesTax}
              className="font-light"
              testId="receivedSalesTax"
            />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol
            className={marginRightClass}
            data-testid="receivedTotal"
          >
            <Price price={receivedTotal} />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
