import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InvoiceDownloadButton } from "@/common/components/invoices/invoice-details/header/InvoiceDownloadButton";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoices } from "../../providers/InvoicesProvider";
import { InvoiceWarnings } from "./InvoiceWarnings";

const Container = tw.div`flex flex-row gap-1.5 items-center`;

type Props = {
  invoice: InvoiceSummaryFieldsFragment;
  includeTrash: boolean;
};

export const InvoiceActions: FC<Props> = ({ invoice, includeTrash }) => {
  const { archiveInvoice, exportEnabled } = useInvoices();
  const intl = useIntl();
  const { openDialog } = useDialog();

  const handleDelete = useCallback(() => {
    return archiveInvoice(invoice.id);
  }, [archiveInvoice, invoice.id]);

  return (
    <Container>
      <InvoiceWarnings invoice={invoice} />
      <InvoiceDownloadButton invoice={invoice} simpleButton />
      <If
        isTrue={!exportEnabled && invoice.permissions.archive && includeTrash}
      >
        <DeleteButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              icon: <WarningIcon />,
              title: intl.$t({ id: "DELETE_INVOICE_DETAILS" }),
              text: intl.$t({ id: "DELETE_INVOICE" }),
              handleConfirm: handleDelete,
            })
          }
        />
      </If>
    </Container>
  );
};
