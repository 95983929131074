import { If } from "@/common/components/if/If";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  QuoteStatus,
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useBidsPrices } from "../../../../../providers/BidsPricesProvider";
import { useRfqQuotes } from "../../../../../providers/RfqQuotesProvider";
import { AlternativeDetailsRow } from "./AlternativeDetailsRow";
import { ComponentsRow } from "./ComponentsRow";
import { ExpirationRow } from "./ExpirationRow";
import { ManufacturerRow } from "./ManufacturerRow";
import { PricePositionRow } from "./PricePositionRow";
import { UnitPriceRow } from "./UnitPriceRow";

type Props = {
  rfqItem: RfqItemFieldsFragment;
  quoteItem?: RfqQuotesQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
  showItemDescription: boolean;
  hasComponents: boolean;
  hasExpiration: boolean;
};

type ContainerProps = {
  $selected: boolean;
  $canBeSelected: boolean;
};

const Container = tw.div<ContainerProps>`
  grid relative w-full transition z-10 content-start h-fit
  rounded-3xl overflow-hidden border 
  ${(p) =>
    p.$selected
      ? `border-2 border-green-600 p-[7px] bg-green-600 bg-opacity-10`
      : `border-gray-400 bg-white p-2`}
  ${(p) => p.$canBeSelected && `cursor-pointer hover:border-green-600`}  
`;

const Separator = tw.div`
  border-b border-gray-400 border-dashed h-0.5 mx-4
`;

export const QuoteItemCard: FC<Props> = ({
  rfqItem,
  quoteItem,
  quote,
  showItemDescription,
  hasComponents,
  hasExpiration,
}) => {
  const missingItem = !quoteItem || quoteItem.status === QuoteStatus.Withdrawn;
  let quantityDiff = 0;
  const {
    rfq,
    selectedQuotes,
    setSelectedQuotes,
    multiQuote,
    setSelectedAuxiliaryQuoteItems,
  } = useRfqQuotes();
  const { findPriceByItemAndQuoteItem, selectEntireQuoteByBestPrice } =
    useBidsPrices();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const quoteItemPrice = findPriceByItemAndQuoteItem(
    rfqItem.id,
    quoteItem?.id || "",
  );

  const isLess = new Decimal(quoteItem?.quantityDecimal || 0).lessThan(
    rfqItem.quantityDecimal,
  );

  if (isLess) {
    quantityDiff = new Decimal(rfqItem.quantityDecimal || 0)
      .sub(quoteItem?.quantityDecimal || 0)
      .toNumber();
  }

  const manufacturerName = useMemo(
    () => quoteItem?.manufacturer?.name,
    [quoteItem?.manufacturer?.name],
  );

  const isManufacturerMismatch = useMemo(() => {
    return (
      quoteItem?.manufacturer?.id !== rfqItem?.manufacturer?.id &&
      rfqItem.manufacturer !== null
    );
  }, [quoteItem?.manufacturer?.id, rfqItem?.manufacturer]);

  const isSelected = useMemo(() => {
    return selectedQuotes.some((qi) => qi.quoteItemId === quoteItem?.id);
  }, [quoteItem?.id, selectedQuotes]);

  const selectQuoteItem = useCallback(() => {
    if (rfqItem && !missingItem) {
      if (isSelected) {
        setSelectedQuotes(
          selectedQuotes.filter((quote) => quote.quoteItemId !== quoteItem?.id),
        );
      } else if (multiQuote) {
        const filteredQuotes = selectedQuotes.filter(
          (item) => item.rfqItemId !== rfqItem.id,
        );

        setSelectedQuotes([
          ...filteredQuotes,
          {
            rfqItemId: rfqItem.id,
            quoteId: quote?.id || "",
            quoteItemId: quoteItem?.id || "",
          },
        ]);
      } else {
        const sameQuoteSelected =
          selectedQuotes.length &&
          selectedQuotes.every((qi) => qi.quoteId === quote?.id);

        const entireQuote = selectEntireQuoteByBestPrice(quote?.id, rfqItem.id);
        setSelectedQuotes([
          ...(sameQuoteSelected ? selectedQuotes : entireQuote.quoteItems),
          {
            rfqItemId: rfqItem.id,
            quoteId: quote?.id || "",
            quoteItemId: quoteItem?.id || "",
          },
        ]);

        setSelectedAuxiliaryQuoteItems(entireQuote.auxQuoteItems);
      }
    }
  }, [
    rfqItem,
    isSelected,
    missingItem,
    multiQuote,
    quote,
    quoteItem?.id,
    selectedQuotes,
    setSelectedQuotes,
    selectEntireQuoteByBestPrice,
    setSelectedAuxiliaryQuoteItems,
  ]);

  const totalPrice = useMemo(() => {
    return (
      new Decimal(quoteItem?.unitPrice ?? 0)
        .mul(quoteItem?.quantityDecimal ?? 0)
        .toNumber() || 0
    );
  }, [quoteItem?.quantityDecimal, quoteItem?.unitPrice]);

  const isAlternative = useMemo(() => {
    const group = quote.itemGroups.find(
      (itemGroup) => itemGroup.rfqItem.id === rfqItem?.id,
    );
    if (group) {
      return (
        group.quoteItems.length > 1 ||
        group.quoteItems[0].description !== rfqItem.description
      );
    } else {
      return false;
    }
  }, [quote.itemGroups, rfqItem.description, rfqItem?.id]);

  const index = useMemo(() => {
    return (
      quote.itemGroups
        .find((itemGroup) => itemGroup.rfqItem.id === rfqItem?.id)
        ?.quoteItems.findIndex((item) => item.id === quoteItem?.id) || 0
    );
  }, [quote.itemGroups, rfqItem?.id, quoteItem?.id]);

  const isSingleAlternative = useMemo(() => {
    return (
      quote.itemGroups.find((itemGroup) => itemGroup.rfqItem.id === rfqItem?.id)
        ?.quoteItems.length === 1
    );
  }, [quote.itemGroups, rfqItem?.id]);

  return (
    <Container
      data-testid="quote-item-card"
      $selected={isSelected}
      $canBeSelected={rfq?.status === RfqStatus.Active}
      onClick={selectQuoteItem}
      style={{
        maxWidth: `${MAX_COLUMN_WIDTH}px`,
      }}
    >
      <If isTrue={!missingItem}>
        <If isTrue={showItemDescription}>
          <AlternativeDetailsRow
            name={quoteItem?.description || rfqItem.description}
            isAlternative={isAlternative}
            isSingleAlternative={isSingleAlternative}
            index={index}
          />
        </If>
        <If isTrue={hasManufacturersSetting}>
          <ManufacturerRow
            quoteItemId={quoteItem?.id}
            manufacturerName={manufacturerName}
            notes={quoteItem?.notes}
            assets={quoteItem?.assets}
            manufacturerMismatch={isManufacturerMismatch}
          />
        </If>
        <Separator />
        <UnitPriceRow
          quantityDiff={quantityDiff}
          quantityDecimal={quoteItem?.quantityDecimal}
          unit={rfqItem.projectItem.estimateUom?.mnemonic || ""}
          unitPrice={Number(quoteItem?.unitPrice)}
        />
        <If isTrue={hasExpiration}>
          <Separator />
          <ExpirationRow expirationDate={quoteItem?.expirationDate} />
        </If>
        <If isTrue={hasComponents}>
          <Separator />
          <ComponentsRow components={[]} />
        </If>
        <PricePositionRow
          quoteItemPrice={quoteItemPrice}
          price={totalPrice}
          isSelected={isSelected}
          leadTimeDays={quoteItem?.leadTimeDays}
        />
      </If>
    </Container>
  );
};
