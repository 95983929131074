import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import {
  ProjectExtendedFieldsFragment,
  SourceSystem,
  ZoneFieldsFragment,
} from "@/generated/graphql";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormGroup } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  InfoIconContainer,
  ListItemContainer,
} from "../../project-form-with-stepper/ProjectFormWithStepper.styles";
import { ProjectZone } from "./project-zones/ProjectZone";

const Container = tw.div`flex flex-col gap-4`;
const ImportedZonesHeader = tw.div`pt-4 mt-4 pb-2 mx-3 font-medium border border-b-0 border-l-0 border-r-0 border-dashed border-black`;

type Props = {
  project: ProjectExtendedFieldsFragment;
  readonly?: boolean;
};

const Info = tw.div`bg-gray-100 rounded-3xl p-4 text-gray-500 flex max-w-[450px] items-center`;
const InfoText = tw.div`text-sm`;
const StyledInfoOutlinedIcon = tw(InfoOutlinedIcon)`text-4xl mr-2`;
const StyledFormGroup = tw(FormGroup)`max-w-[450px]`;
const StyledListItemContainer = tw(ListItemContainer)`max-w-[450px]`;

export const ProjectFormZones: FC<Props> = ({ project, readonly }) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { connectedSourceSystem } = useOrgSettings();
  const { updateZone, addZone, deleteZone, zones } = useProjectZones();

  const [newZone, setNewZone] = useState("");
  const [updateZoneName, setUpdateZoneName] = useState("");
  const [zoneId, setZoneId] = useState<string | null>();

  const saveNewZone = useCallback(
    async (value: string) => {
      await addZone({
        name: value,
        projectId: project.id,
      });
      setNewZone("");
    },
    [addZone, project?.id],
  );

  const reset = useCallback(() => {
    setZoneId(null);
    setUpdateZoneName("");
  }, [setZoneId, setUpdateZoneName]);

  const removeZone = useCallback(
    (id: string) => {
      deleteZone(id);
      reset();
    },
    [deleteZone, reset],
  );

  const setDeleteZone = useCallback(
    (zone: ZoneFieldsFragment) => {
      if (zone.inUse) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CLOSE" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "DELETE_ZONE_NOT_ALLOWED" }),
          text: intl.$t({ id: "DELETE_ZONE_NOT_ALLOWED_INFO" }),
        });
      } else {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "DELETE" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "DELETE_ZONE" }),
          text: intl.$t(
            { id: "DELETE_ZONE_INFO" },
            { name: <strong>{zone.name}</strong> },
          ),
          handleConfirm: () => removeZone(zone.id),
        });
      }
    },
    [intl, openDialog, removeZone],
  );

  const setUpdateName = useCallback(
    (id: string) => {
      setZoneId(id);
      setUpdateZoneName(zones.find((z) => z.id === id)?.name || "");
    },
    [zones],
  );

  const saveUpdatedZoneName = useCallback(() => {
    if (project?.id && zoneId) {
      updateZone({
        id: zoneId,
        name: updateZoneName,
      });
    }
    reset();
  }, [project?.id, reset, updateZone, zoneId, updateZoneName]);

  const disabled = useMemo(
    () =>
      zones.some(
        (zone) =>
          zone.name === newZone ||
          (zone.name === updateZoneName && zone.id !== zoneId),
      ),
    [zones, newZone, updateZoneName, zoneId],
  );

  const importedZonesHeaderText = useMemo(
    () =>
      intl.$t({
        id:
          connectedSourceSystem &&
          connectedSourceSystem === SourceSystem.Sage300
            ? "IMPORTED_ZONES_HEADER_SAGE300"
            : "IMPORTED_ZONES_HEADER_TEXT",
      }),
    [intl, connectedSourceSystem],
  );

  return (
    <Container>
      <Info>
        <StyledInfoOutlinedIcon />
        <FormattedMessage id="ZONE_INFO" tagName={InfoText} />
      </Info>
      <StyledFormGroup>
        <If isTrue={!readonly}>
          <TextfieldWithActions
            label={intl.$t({ id: "ZONES" })}
            placeholder={intl.$t({ id: "CREATE_NEW_ZONE" })}
            value={newZone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNewZone(event.target.value)
            }
            disableSave={disabled}
            onClickSave={() => saveNewZone(newZone)}
            onClickClose={() => setNewZone("")}
            showActions={newZone !== ""}
            additionalActions={
              <InfoIconContainer>
                <InfoTooltip
                  message={intl.$t({ id: "ADD_PROJECT_ZONES_INFO" })}
                  id="zones-info"
                />
              </InfoIconContainer>
            }
            shrink
          />
        </If>
      </StyledFormGroup>
      <StyledListItemContainer>
        {zones
          .filter((zone) => !zone.externalSubJob)
          .map((zone, key) => (
            <ProjectZone
              zone={zone}
              key={key}
              zoneId={zoneId}
              readonly={readonly}
              updateZoneName={updateZoneName}
              disabled={disabled}
              setUpdateName={setUpdateName}
              setUpdateZoneName={setUpdateZoneName}
              saveUpdatedZoneName={saveUpdatedZoneName}
              setDeleteZone={setDeleteZone}
              reset={reset}
            />
          ))}
        <If isTrue={zones.filter((zone) => !!zone.externalSubJob).length > 0}>
          <ImportedZonesHeader>{importedZonesHeaderText}</ImportedZonesHeader>
          {zones
            .filter((zone) => !!zone.externalSubJob)
            .map((zone, key) => (
              <ProjectZone
                zone={zone}
                key={key}
                zoneId={zoneId}
                readonly={readonly}
                updateZoneName={updateZoneName}
                disabled={disabled}
                setUpdateName={setUpdateName}
                setUpdateZoneName={setUpdateZoneName}
                saveUpdatedZoneName={saveUpdatedZoneName}
                setDeleteZone={setDeleteZone}
                reset={reset}
              />
            ))}
        </If>
      </StyledListItemContainer>
    </Container>
  );
};
