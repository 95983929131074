import { CostTypeSelector } from "@/common/components/cost-type-selector/CostTypeSelector";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { FC, useCallback } from "react";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const ReleaseItemCostTypeSelector: FC<Props> = ({ item, readonly }) => {
  const { updateRelease } = useReleaseUpdate();
  const { formatCostType } = useCostTypes();
  const { release } = useRelease();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { costTypes } = useCostTypes();

  const saveCostType = useCallback(
    async (value: string | null) => {
      if (item.id) {
        if (release) {
          await updateRelease({
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                costTypeId: value,
                clearCostType: value ? undefined : true,
              },
            ],
          });
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "costTypeId",
            value: value,
          },
        ]);
      }
    },
    [item.id, updateRelease, release, setNewProjectEstimatedItem],
  );

  return (
    <CostTypeSelector
      placeholder={formatCostType(release?.type.costType?.id)}
      costTypes={costTypes}
      costType={
        item.costType?.id ?? (readonly ? release?.type.costType?.id : undefined)
      }
      onChange={saveCostType}
      readonly={readonly}
    />
  );
};
