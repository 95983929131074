import { LoadingButton } from "@/common/components/button/LoadingButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { FC, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useReleaseFooterActionsStore } from "../store/useReleaseFooterActionsStore";
import { SubmitReleaseDialog } from "./SubmitReleaseDialog";
import { UpdateReleaseDialog } from "./UpdateReleaseDialog";
import { useSubmitReleaseButton } from "./useSubmitReleaseButton";

type Props = {
  release: ReleaseFieldsFragment;
  items: ExpandedReleaseItem[];
  disabled?: boolean;
};

const PrimaryButtonStyled = tw(PrimaryButton)`py-1 px-4 leading-3`;

export const SubmitReleaseButton: FC<Props> = ({
  release,
  items,
  disabled,
}) => {
  const { submitting, approving } = useReleaseActions();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const { setUpdateDialogVisible, updateDialogVisible } =
    useReleaseFooterActionsStore(
      useShallow((state) => ({
        updateDialogVisible: state.updateDialogVisible,
        setUpdateDialogVisible: state.setUpdateDialogVisible,
      })),
    );
  const hasChanges = useReleaseStore((state) => state.hasChanges);

  const retroactiveRelease = useMemo(() => {
    return !!release.time && new Date(release.time || 0) < new Date();
  }, [release.time]);

  const submitButton = useSubmitReleaseButton({
    release,
    items,
    setSubmitDialogVisible,
    setUpdateDialogVisible,
  });

  if (!submitButton) {
    return null;
  }
  return (
    <>
      <If
        isTrue={
          !retroactiveRelease ||
          release.status === ReleaseStatus.AwaitingApproval ||
          release.status === ReleaseStatus.Canceled
        }
      >
        <LoadingButton
          button={PrimaryButtonStyled}
          onClick={async () => await submitButton.onClick()}
          loading={submitting || approving}
          disabled={disabled}
          testId="submit-release"
        >
          <FormattedMessage id={submitButton.label} />
        </LoadingButton>
      </If>
      <If
        isTrue={
          retroactiveRelease &&
          release.status !== ReleaseStatus.AwaitingApproval &&
          release.status !== ReleaseStatus.Canceled
        }
      >
        <LoadingButton
          button={PrimaryButtonStyled}
          onClick={async () => await submitButton.onClick()}
          loading={submitting || approving}
          testId="save-order"
          disabled={disabled || !hasChanges}
        >
          <FormattedMessage id="SAVE_ORDER" />
        </LoadingButton>
      </If>
      <SubmitReleaseDialog
        visible={submitDialogVisible}
        setVisible={setSubmitDialogVisible}
        callback={submitButton.callback}
        params={{
          includeVendorConfirmation:
            (
              release.preferredVendor?.contacts?.filter(
                (contact) => contact.receivesOrderNotifications,
              ) || []
            ).length > 0,
        }}
      />
      <UpdateReleaseDialog
        visible={updateDialogVisible}
        setVisible={setUpdateDialogVisible}
        callback={submitButton.callback}
        status={release.status}
      />
    </>
  );
};
