import { If } from "@/common/components/if/If";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useUser } from "@/common/providers/UserProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ReleaseStatus,
  ServiceType,
  TransactionKind,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { useMemo } from "react";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { ReleaseGroups } from "../release-details/ReleaseGroups";
import { PickerCustomActionBar } from "./PickerCustomActionBar";
import { ReleaseVendorContactSelector } from "./ReleaseVendorContactSelector";
import { ReleaseVendorSelector } from "./ReleaseVendorSelector";
import { SiteContactPicker } from "./components/site-contact-picker/components/SiteContactPicker";
import { useCanEditReleaseVendor } from "./hooks/useCanEditReleaseVendor";
import { useReleasePredictedPoNumber } from "./hooks/useReleasePredictedPoNumber";

export const DeliveryInformation = () => {
  const { release } = useRelease();
  const { predictedPoNumber, loading } = useReleasePredictedPoNumber(
    release?.id || "",
  );
  const { warehouses } = useWarehouses();
  const { updateRelease } = useReleaseUpdate();
  const { requestedDate, setRequestedDate, inputErrors } = useReleaseActions();
  const { includeOrderTypes } = useOrderTypeOptions();
  const { viewer } = useUser();
  const { canEditVendor } = useCanEditReleaseVendor();

  const { orderTypeConfig } = useOrderTypesConfig({ release });

  const update = async (poNumber?: string) => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release.id,
        version: release.version,
        poNumber,
      };

      await updateRelease(input);
    }
  };

  const onDateConfirm = async (date: Date | null) => {
    setRequestedDate(defaultReleaseDate(date));
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
      };

      if (date) {
        input.requestedTime = date.getTime();
      }

      await updateRelease(input);
    }
  };

  const retroactiveRelease = useMemo(() => {
    return requestedDate && requestedDate?.getTime() < new Date().getTime();
  }, [requestedDate]);

  const predictedPoNumberPlaceholder = useMemo(() => {
    if (
      checkReleaseStatus(release, [
        ReleaseStatus.Draft,
        ReleaseStatus.Reserved,
        ReleaseStatus.AwaitingApproval,
        ReleaseStatus.Rejected,
      ])
    ) {
      return predictedPoNumber || undefined;
    }
  }, [predictedPoNumber, release]);

  return (
    <>
      <FormControl fullWidth>
        <If
          isTrue={
            release?.includeServices.includes(ServiceType.Delivery) ||
            release?.type.transactionKind === TransactionKind.Purchase
          }
        >
          <WarehouseSelector
            value={release?.warehouse}
            options={warehouses}
            projectAddress={release?.project?.address}
            onChange={(warehouseId) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                warehouseId:
                  warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
                clearWarehouse: warehouseId === PROJECT_ADDRESS_ID,
              })
            }
          />
        </If>
      </FormControl>
      <If isTrue={canEditVendor}>
        <ReleaseVendorSelector />
      </If>
      <If isTrue={includeOrderTypes}>
        <FormControl fullWidth>
          <OrderTypePicker
            orderTypeId={release?.type.id}
            setOrderTypeId={(id) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                typeId: id,
              })
            }
            disabled={!!release?.buyout}
          />
        </FormControl>
      </If>
      <PoNumberingSettingsCheck alternativeCondition={!!release?.poNumber}>
        <FormControl fullWidth>
          <ReleasePONumber
            update={(_, poNumber?: string) => update(poNumber)}
            release={release}
            sourceSystem={release?.poLink?.sourceSystem}
            autoSize
            placeholder={predictedPoNumberPlaceholder}
            loading={loading}
            size="medium"
          />
        </FormControl>
      </PoNumberingSettingsCheck>

      <FormControl fullWidth>
        <SiteContactPicker
          value={release?.siteContact?.id}
          onChange={(contactId) =>
            updateRelease({
              releaseId: release?.id || "",
              version: release?.version,
              siteContactId: contactId || undefined,
              clearSiteContact: !contactId,
            })
          }
          countryCode={viewer?.org.hqAddress?.country}
        />
      </FormControl>
      <ReleaseVendorContactSelector />
      <FormControl fullWidth>
        <DateTimePicker
          label={orderTypeConfig?.labels.datePicker}
          date={requestedDate}
          onChange={onDateConfirm}
          onAccept={onDateConfirm}
          required
          error={inputErrors.includes(
            ReleaseErrorType.REQUESTED_FULFILLMENT_DATE,
          )}
          actionBar={retroactiveRelease ? PickerCustomActionBar : undefined}
        />
      </FormControl>
      <ReleaseGroups includeLabel />
    </>
  );
};
