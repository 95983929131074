import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { PhoneInput } from "@/common/components/phone-input/PhoneInput";
import { PhoneView } from "@/common/components/phone-input/PhoneView";
import { formatPhone } from "@/common/components/phone-input/formatPhone";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import { isValidCellPhone } from "@/common/utils/validationUtils";
import {
  SiteContactFieldsFragment,
  SiteContactInput,
} from "@/generated/graphql";
import { Add, DeleteOutline } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { InputBaseComponentProps, Popover } from "@mui/material";
import {
  FC,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useSiteContacts } from "../hooks/useSiteContacts";

const Item = tw.div`w-full py-2 grid grid-flow-col gap-x-2 justify-between items-center`;
const NewItem = tw(Item)`justify-start -ml-4 gap-x-0`;
const ItemLabel = tw.div`flex flex-col pr-20`;
const Name = tw.div`text-sm`;
const Phone = tw.div`text-2xs`;
const NewItemLabel = tw.div`text-sm font-medium`;
const NewContact = tw.div`z-50 flex flex-row justify-between items-center pl-4 bg-blue-100 border border-gray-300 rounded-t-lg border-b-0 text-sm`;
const NewContactItem = tw.div`truncate 2xl:whitespace-normal`;
const SiteContactForm = tw.div`flex flex-row gap-2 p-3 bg-blue-100`;
const Container = tw.div``;
const TextFieldStyled = tw(TextField)`bg-white min-w-48`;
const PhoneInputStyled = tw(PhoneInput)`bg-white min-w-48`;
const IconButtonBorderlessStyled = tw(IconButtonBorderless)`my-0 h-6 min-h-6`;

const ADD_NEW_SITE_CONTACT_ID = "ADD_NEW_SITE_CONTACT";

type Props = {
  value: string | null | undefined;
  onChange: (contactId: string | null) => Promise<boolean>;
  label?: string;
  inputProps?: InputBaseComponentProps | undefined;
  countryCode?: string;
};

export const SiteContactPicker: FC<Props> = ({
  value,
  onChange,
  label,
  inputProps,
  countryCode,
}: Props) => {
  const intl = useIntl();
  const { siteContacts, createSiteContact, archiveSiteContact } =
    useSiteContacts();
  const [newContact, setNewContact] = useState<SiteContactInput>();
  const [hasError, setHasError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const ref = createRef<HTMLDivElement>();

  const emptyContact: SiteContactFieldsFragment = useMemo(
    () => ({
      id: ADD_NEW_SITE_CONTACT_ID,
      name: intl.$t({ id: "ADD_NEW_SITE_CONTACT" }),
    }),
    [intl],
  );

  const options = useMemo(() => {
    return siteContacts.toSorted((a, b) =>
      (a.name || "").localeCompare(b.name || ""),
    );
  }, [siteContacts]);
  const [text, setInputText] = useState<string>(
    options.find((v) => v.id === value)
      ? options.find((v) => v.id === value)?.name ||
          (options.find((v) => v.id === value)?.phone
            ? formatPhone(options.find((v) => v.id === value)?.phone || "")
            : "") ||
          ""
      : "",
  );

  useEffect(() => {
    const option = siteContacts.find((v) => v.id === value);
    if (option) {
      setInputText(
        option.name || (option.phone ? formatPhone(option.phone) : "") || "",
      );
    }
  }, [siteContacts, value]);

  const handleArchive = useCallback(
    async (id: string) => {
      if (await archiveSiteContact(id)) {
        onChange(null);
      }
    },
    [archiveSiteContact, onChange],
  );

  const customRender = useCallback(
    (option: SiteContactFieldsFragment) => {
      if (option.id === ADD_NEW_SITE_CONTACT_ID) {
        return (
          <NewItem>
            <IconButtonBorderlessStyled className="my-0 h-6 min-h-6">
              <Add />
            </IconButtonBorderlessStyled>
            <NewItemLabel>{option.name}</NewItemLabel>
          </NewItem>
        );
      }

      return (
        <Item>
          <ItemLabel>
            <Name>{option.name}</Name>
            <Phone>
              {option.phone ? <PhoneView value={option.phone} /> : null}
            </Phone>
          </ItemLabel>
          <IconButtonBorderlessStyled onClick={() => handleArchive(option.id)}>
            <DeleteOutline />
          </IconButtonBorderlessStyled>
        </Item>
      );
    },
    [handleArchive],
  );

  const handleChange = useCallback(
    (contactId: string | null) => {
      if (contactId === ADD_NEW_SITE_CONTACT_ID) {
        setNewContact({
          name: text,
          phone: "",
        });
        setAnchorEl(ref.current);
      } else {
        onChange(contactId);
        if (!contactId) {
          setInputText("");
        }
      }
    },
    [onChange, ref, text],
  );

  const handleCreate = useCallback(async () => {
    if (newContact && newContact.phone && isValidCellPhone(newContact.phone)) {
      setLoading(true);
      const result = await createSiteContact(newContact);
      if (result) {
        setNewContact(undefined);
        setAnchorEl(null);
        setHasError(false);

        await onChange(result);
      }
    } else {
      setHasError(true);
    }
    setLoading(false);
  }, [createSiteContact, newContact, onChange]);

  const onClose = useCallback(() => {
    setInputText("");
    setNewContact(undefined);
    setAnchorEl(null);
    setHasError(false);
  }, []);

  return (
    <Container ref={ref}>
      <If isTrue={!newContact}>
        <Select
          data-testid="site-contact-picker"
          label={label || intl.$t({ id: "SITE_CONTACT" })}
          getLabel={(contact) =>
            contact.name || (contact.phone ? formatPhone(contact.phone) : "")
          }
          getValue={(contact) => contact.id}
          creatableFn={() => emptyContact}
          creatableTextKey="ADD_NEW_SITE_CONTACT"
          customFilter={(options, text) => {
            if (text) {
              return options.filter(
                (v) =>
                  v.name
                    ?.toLowerCase()
                    .includes(text.inputValue.toLowerCase()) ||
                  v.phone?.includes(text.inputValue),
              );
            }
            return options;
          }}
          inputProps={inputProps}
          creatable
          creatableFirstOption
          creatableAlwaysVisible
          value={value}
          options={options}
          onChange={handleChange}
          handleInputChange={(text) => setInputText(text || "")}
          customRender={customRender}
          loading={loading}
          inputValue={text}
          forcePopupIcon
          onBlur={() => {
            setInputText(
              siteContacts.find((v) => v.id === value)?.name ||
                (siteContacts.find((v) => v.id === value)?.phone
                  ? formatPhone(
                      siteContacts.find((v) => v.id === value)?.phone || "",
                    )
                  : "") ||
                "",
            );
          }}
          disableClearable={!value}
        />
      </If>
      <If isTrue={newContact}>
        <NewContact>
          <FormattedMessage
            id="ADD_NEW_SITE_CONTACT"
            tagName={NewContactItem}
          />
          <IconButtonBorderless onClick={() => setNewContact(undefined)}>
            <Close />
          </IconButtonBorderless>
        </NewContact>
      </If>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!newContact}
        classes={{
          paper:
            "mt-10 xl:-ml-[61px] md:-ml-[9px] shadow-none border border-gray-300 border-t-0",
        }}
      >
        <SiteContactForm>
          <TextFieldStyled
            label={intl.$t({ id: "SITE_CONTACT_NAME" })}
            value={newContact?.name}
            onChange={(event) =>
              setNewContact({
                ...newContact,
                name: event.target.value,
              })
            }
          />
          <PhoneInputStyled
            label={intl.$t({ id: "SITE_CONTACT_PHONE" })}
            value={newContact?.phone}
            onChange={(event) =>
              setNewContact({
                ...newContact,
                phone: event.target.value,
              })
            }
            countryCode={countryCode}
            error={
              hasError &&
              (!newContact?.phone || !isValidCellPhone(newContact?.phone))
            }
          />
          <PrimaryButton onClick={handleCreate}>
            <FormattedMessage id="ADD" />
          </PrimaryButton>
        </SiteContactForm>
      </Popover>
    </Container>
  );
};
