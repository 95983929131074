import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  InstructionFieldsFragment,
  InstructionInput,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetItem } from "../asset-item/AssetItem";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { TextField } from "../textfield/TextField";
import { ATTACHMENTS_MIME_TYPES } from "../upload/FileUploadArea";

const TextFieldWithBackground = tw(TextField)<{ staticText?: boolean }>`
  w-full mb-1
  ${({ staticText }: { staticText?: boolean }) =>
    staticText ? "" : "bg-white"}
`;

const InstructionLabel = tw.div`
  text-gray-600 text-[9px] font-normal
`;

const InstructionText = tw.div`
  text-sm font-light whitespace-pre-line mb-1 pl-0.5
`;

const Content = tw.div<{ $mode?: "horizontal" | "vertical" }>`
  grid gap-2
  ${({ $mode }) => ($mode === "horizontal" ? "grid-cols-[2fr_1fr]" : "")}
`;

const Group = tw.div`col-span-full`;

const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;

type InstructionsWithProviderProps = {
  instruction: InstructionFieldsFragment | null | undefined;
  saveInstruction?: (instruction: InstructionInput) => void;
  readonly?: boolean;
  children?: ReactNode;
};

const InstructionsWithProvider: FC<InstructionsWithProviderProps> = ({
  instruction,
  saveInstruction,
  readonly,
  children,
}) => {
  const intl = useIntl();
  const [instructions, setInstructions] = useState(instruction?.text ?? "");
  const { assets, removeAsset } = useUploadAssets();

  const save = useCallback(
    async (newAssets?: AssetFieldsFragment[]) => {
      await saveInstruction?.({
        text: instructions,
        assetUrls:
          (newAssets || assets)?.map((asset) => asset.url) ?? undefined,
      });
    },
    [assets, instructions, saveInstruction],
  );

  const remove = useCallback(
    async (asset: AssetFieldsFragment) => {
      await removeAsset(asset);
      saveInstruction?.({
        text: instructions,
        assetUrls: assets.filter((a) => a.url !== asset.url).map((a) => a.url),
      });
    },
    [assets, instructions, removeAsset, saveInstruction],
  );

  const isReadOnly = useMemo(
    () => readonly || !saveInstruction,
    [readonly, saveInstruction],
  );

  return (
    <ExpandablePanel
      header={intl.$t({ id: "GENERAL_INSTRUCTIONS" })}
      isOpened={true}
    >
      <Content $mode={"horizontal"}>
        <Group>{children}</Group>
        <If isTrue={!isReadOnly}>
          <TextFieldWithBackground
            multiline
            placeholder={intl.$t({ id: "INSTRUCTIONS_PLACEHOLDER" })}
            minRows={3}
            maxRows={10}
            value={instructions}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setInstructions(ev.target.value)
            }
            onBlur={() => save()}
            staticText={isReadOnly}
          />
        </If>
        <If isTrue={instruction?.text && isReadOnly}>
          <Group>
            <FormattedMessage
              id="INSTRUCTIONS_LABEL"
              tagName={InstructionLabel}
            />
            <InstructionText>{instruction?.text}</InstructionText>
          </Group>
        </If>
        <If isTrue={!isReadOnly}>
          <UploadAsset
            accept={ATTACHMENTS_MIME_TYPES}
            readonly={isReadOnly}
            onChange={save}
            mode={"horizontal"}
            includeAssets={false}
            classes={{
              container: "bg-white",
              dndContainer: "py-[22px]",
              uploadContainer: {
                container: "px-2",
                image: "w-10 h-10",
                text: "w-24 text-xs",
              },
            }}
          />
        </If>
        <If isTrue={assets.length}>
          <Assets>
            {assets.map((asset) => (
              <AssetItem
                key={asset.url}
                asset={asset}
                remove={!isReadOnly ? remove : undefined}
              />
            ))}
          </Assets>
        </If>
      </Content>
    </ExpandablePanel>
  );
};

type Props = {
  projectId: string | null | undefined;
  instruction: InstructionFieldsFragment | null | undefined;
  saveInstruction?: (instruction: InstructionInput) => void;
  saving?: boolean;
  readonly?: boolean;
  children?: ReactNode;
};

export const Instructions: FC<Props> = ({
  projectId,
  instruction,
  saveInstruction,
  readonly,
  children,
}) => (
  <UploadAssetProvider
    initialAssets={instruction?.assets}
    context={AssetContext.Instruction}
    projectId={projectId}
  >
    <InstructionsWithProvider
      instruction={instruction}
      saveInstruction={saveInstruction}
      readonly={readonly}
    >
      {children}
    </InstructionsWithProvider>
  </UploadAssetProvider>
);
