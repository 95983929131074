import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { PhoneInputControlled } from "@/common/components/phone-input/PhoneInputControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { DEFAULT_ADDRESS_COUNTRY, DEFAULT_ADDRESS_STATE } from "@/common/const";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { countries } from "@/common/utils/countries";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { usaStates } from "@/common/utils/usaStates";
import {
  isValidCellPhone,
  isValidPostalCode,
} from "@/common/utils/validationUtils";
import { Close } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LineItemLabel } from "../../../Vendors.styles";
import { useOrgs } from "../../../providers/OrgsProvider";
import { NEW_VENDOR_ID } from "../VendorForm";

const Row = tw.div`flex-row flex items-center gap-x-2`;
const AddressContainer = tw.div`grid grid-cols-[3fr_1fr_2fr] gap-3`;
const CloseButton = tw(Close)`text-xl text-blue-500 cursor-pointer`;

type NewVendorLocationProps = {
  isNewVendor?: boolean;
  edit?: {
    editing: boolean;
    setEditing: (editing: boolean) => void;
  };
};

export const VendorLocation: FC<NewVendorLocationProps> = ({
  isNewVendor,
  edit,
}) => {
  const intl = useIntl();
  const { watch, setValue } = useFormContext();
  const { orgs } = useOrgs();

  const preferredVendor = watch("preferredVendor");

  const vendorKey = useMemo(
    () =>
      !!isNewVendor || !edit?.editing
        ? "newVendor.location"
        : "editVendorLocation",
    [edit, isNewVendor],
  );
  const vendor = watch(vendorKey);
  const city = watch(
    !!isNewVendor || !edit?.editing
      ? "newVendor.location.city"
      : "editVendorLocation.city",
  );
  const newVendorName =
    !!isNewVendor || !edit?.editing ? watch("newVendor.name") : "";
  const stateField = useMemo(
    () =>
      !!isNewVendor || !edit?.editing
        ? "newVendor.location.state"
        : "editVendorLocation.state",
    [edit, isNewVendor],
  );
  const state = watch(stateField);

  const country = watch(
    !!isNewVendor || !edit?.editing
      ? "newVendor.location.country"
      : "editVendorLocation.country",
  );
  const previousCountry = usePreviousValue(country);

  useEffect(() => {
    if (previousCountry !== country && !isDefaultCountry(country)) {
      if (!isDefaultCountry(country)) {
        setValue(stateField, "");
      } else {
        setValue(stateField, DEFAULT_ADDRESS_STATE);
      }
    }
  }, [country, stateField, setValue, state, previousCountry]);

  const requiredStateAndCode = useMemo(
    () => country === DEFAULT_ADDRESS_COUNTRY,
    [country],
  );

  const isValidContactCellPhone = useCallback(
    (value: string | null) => {
      return !isValidCellPhone(value) && requiredStateAndCode
        ? intl.$t({ id: "PHONE_NUMBER_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  const isValidCode = useCallback(
    (value: string | null) => {
      return !isValidPostalCode(value) && requiredStateAndCode
        ? intl.$t({ id: "POSTAL_CODE_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  const locationNamePlaceholder = useMemo(() => {
    const name = orgs.find((o) => o.id === preferredVendor)?.name || "";
    const nameAndCity = [
      ...(name || newVendorName ? [name || newVendorName] : []),
      ...(city ? [city] : []),
    ].join(" ");
    return [...(nameAndCity ? [nameAndCity] : []), state].join(", ");
  }, [city, newVendorName, orgs, preferredVendor, state]);

  if (!vendor) {
    return <Loader loading />;
  }
  return (
    <AddressContainer>
      <Row>
        <FormattedMessage id="BRANCH_ADDRESS" tagName={LineItemLabel} />
        <If isTrue={edit !== undefined}>
          <If isTrue={edit?.editing === false && !isNewVendor}>
            <EditButton onClick={() => edit?.setEditing?.(true)} />
          </If>
          <If isTrue={edit?.editing === true}>
            <CloseButton onClick={() => edit?.setEditing?.(false)} />
          </If>
        </If>
      </Row>
      <TextFieldControlled
        name={
          isNewVendor || !edit?.editing
            ? "newVendor.location.name"
            : "editVendorLocation.name"
        }
        size="small"
        label={intl.$t({ id: "BRANCH_NAME" })}
        className="col-span-full"
        placeholder={locationNamePlaceholder}
        shrink
        staticText={!isNewVendor && !edit?.editing}
      />
      <TextFieldControlled
        name={
          !!isNewVendor || !edit?.editing
            ? "newVendor.location.addressLine1"
            : "editVendorLocation.addressLine1"
        }
        size="small"
        label={intl.$t({ id: "LOCATION_ADDRESS" })}
        rules={{ required: true }}
        className="col-span-full"
        staticText={!isNewVendor && !edit?.editing}
      />
      <TextFieldControlled
        name={
          !!isNewVendor || !edit?.editing
            ? "newVendor.location.city"
            : "editVendorLocation.city"
        }
        size="small"
        label={intl.$t({ id: "ADDRESS_CITY" })}
        rules={{ required: true }}
        className={
          preferredVendor === NEW_VENDOR_ID ? "col-span-2" : "col-span-1"
        }
        staticText={!isNewVendor && !edit?.editing}
      />
      {requiredStateAndCode ? (
        <SelectControlled
          options={usaStates}
          name={
            !!isNewVendor || !edit?.editing
              ? "newVendor.location.state"
              : "editVendorLocation.state"
          }
          getLabel={(option) => option.abbreviation}
          getValue={(option) => option.abbreviation}
          className="min-w-32"
          placeholder={intl.$t({ id: "STATE" })}
          rules={{ required: true }}
          staticText={!isNewVendor && !edit?.editing}
        />
      ) : (
        <TextFieldControlled
          size="small"
          name={
            !!isNewVendor || !edit?.editing
              ? "newVendor.location.state"
              : "editVendorLocation.state"
          }
          label={intl.$t({ id: "ADDRESS_STATE" })}
          rules={{ required: requiredStateAndCode }}
          staticText={!isNewVendor && !edit?.editing}
        />
      )}
      <TextFieldControlled
        size="small"
        name={
          !!isNewVendor || !edit?.editing
            ? "newVendor.location.postalCode"
            : "editVendorLocation.postalCode"
        }
        label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
        rules={{ required: requiredStateAndCode, validate: isValidCode }}
        staticText={!isNewVendor && !edit?.editing}
        className="min-w-32"
      />
      <SelectControlled
        options={countries}
        name={
          !!isNewVendor || !edit?.editing
            ? "newVendor.location.country"
            : "editVendorLocation.country"
        }
        getLabel={(option) => option.name}
        getValue={(option) => option.abbreviation}
        placeholder={intl.$t({ id: "COUNTRY" })}
        rules={{ required: true }}
        className="min-w-32"
        staticText={!isNewVendor && !edit?.editing}
      />
      <PhoneInputControlled
        size="small"
        name={
          !!isNewVendor || !edit?.editing
            ? "newVendor.location.phone"
            : "editVendorLocation.phone"
        }
        className="col-span-full"
        label={intl.$t({ id: "LOCATION_CELL_PHONE" })}
        rules={{ validate: isValidContactCellPhone }}
        staticText={!isNewVendor && !edit?.editing}
        countryCode={country}
      />
    </AddressContainer>
  );
};
