import {
  COLUMN_TYPE,
  RendererFunctionType,
} from "@/common/providers/ColumnMapperProvider";

import { textRenderer } from "handsontable/renderers";
import { isNumber } from "lodash";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { useRenderHelpers } from "./useRenderHelpers";

export const useUnitPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getPhysicalColumnIndex, isBuyoutItem } = useTableHelpers();
  const { applyTooltip, applyClasses, checkReadOnly, formatPrice } =
    useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = formatPrice(row, instance, value);

      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      applyClasses(td, "htCenter htMiddle relative");
      checkReadOnly(td, row, instance, cellProperties);
      const rows = instance?.getData();

      const materialColumnIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Material,
      );
      const materialOptions =
        materialColumnIndex !== -1
          ? instance.getCellMeta(row, materialColumnIndex).extraOptions
          : null;

      const buyoutItem = materialOptions
        ? isBuyoutItem(
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)
            ],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UnitPrice)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
          )
        : null;

      if (buyoutItem) {
        const priceIconCont = document.createElement("span");
        priceIconCont.className = `
          absolute inline-grid top-0 right-2 place-items-center h-full
        `;
        const priceIcon = document.createElement("span");
        priceIcon.className = `
          text-center border font-semibold border-green-800 rounded-full 
          text-2xs text-white w-3.5 h-3.5
          bg-green-800 hover:text-green-800 hover:bg-green-200
        `;
        priceIcon.innerText = `i`;
        priceIconCont.appendChild(priceIcon);
        td.appendChild(priceIconCont);

        applyTooltip(
          priceIcon,
          intl.formatMessage({
            id: "BUYOUT_ITEM_PRICE_NOTICE",
          }),
        );
      }

      const hasExtPriceColumn = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.ExtPrice,
      );
      if (hasExtPriceColumn !== -1) {
        return;
      }
      const quantityIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.Quantity,
      );
      const priceIndex = getPhysicalColumnIndex(
        instance,
        COLUMN_TYPE.UnitPrice,
      );
      const extendedPrice =
        quantityIndex !== -1 && priceIndex !== -1
          ? rows[row][quantityIndex] * rows[row][priceIndex]
          : 0;
      if (extendedPrice && isNumber(extendedPrice)) {
        const extPrice = document.createElement("div");
        extPrice.className = "text-gray-500 text-3xs select-none mb-1";
        const formattedValue = extendedPrice
          ? formatCurrency(extendedPrice)
          : "";
        extPrice.innerText = intl.formatMessage(
          { id: "EXT_WITH_PRICE" },
          { price: formattedValue },
        );
        td.appendChild(extPrice);

        applyTooltip(
          extPrice,
          intl.formatMessage(
            { id: "EXT_PRICE_TOOLTIP" },
            { price: formattedValue },
          ),
        );
      }
    },
    [
      formatPrice,
      applyClasses,
      getPhysicalColumnIndex,
      isBuyoutItem,
      checkReadOnly,
      applyTooltip,
      intl,
      formatCurrency,
    ],
  );

  return renderer;
};
