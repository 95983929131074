import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import tw from "tailwind-styled-components";
import {
  sxGroupHide,
  sxHiddenHide,
} from "../components/grid-table/grid-item/utils";

export const Base = tw.div`
  flex items-center print:py-1 relative
  ${({ className }: WrapperProps) => className}
`;

export const MinWidthContainer = tw(Base)`
  lg:basis-3 justify-center print:py-0
`;

export const Card = tw.div`w-full rounded-md border bg-white`;

export const CardContent = tw.div`flex justify-between`;

/**
 * @public
 */
export const XxsContainer = tw(Base)`
  2xl:basis-6 lg:basis-5 
`;

export const XsContainer = tw(Base)`
  2xl:basis-12 lg:basis-10  
`;

export const SmContainer = tw(Base)`
  2xl:basis-24 lg:basis-18 md:basis-16
`;

export const MdContainer = tw(Base)`
  2xl:basis-44 lg:basis-36
`;

export const LgContainer = tw(Base)`
  2xl:basis-64 lg:basis-48
`;

export const XlContainer = tw(Base)`
  2xl:basis-96 lg:basis-64
`;

export const SmWideContainer = tw(Base)`
  2xl:basis-32 lg:basis-22
`;

export const XxsFixedContainer = tw(Base)`
  2xl:w-6 lg:w-5
`;

export const XsFixedContainer = tw(XsContainer)`
  2xl:w-12 lg:w-10 
`;

export const SmFixedContainer = tw(SmContainer)`
  2xl:w-24 lg:w-18 md:w-16
`;

export const SmdFixedContainer = tw(Base)`
  2xl:w-36 lg:w-24 
  2xl:basis-36 lg:basis-24
`;

export const MdFixedContainer = tw(MdContainer)`
  2xl:w-44 lg:w-36 
`;

export const LgFixedContainer = tw(LgContainer)`
  2xl:w-64 lg:w-44
`;

const InputContainerReadonly = tw(Base)`
  w-24 basis-fit lg:py-0 justify-start lg:justify-center print:w-[80px] print:justify-center
  ${({ $header, $group }: WrapperProps) =>
    $group || $header ? "lg:pl-0 print:pl-0" : "print:ml-0 lg:ml-0"}
  ${({ $header }: WrapperProps) => ($header ? "cursor-default" : "")}
`;

export const InputContainer = tw(InputContainerReadonly)`
    basis-28 ${sxGroupHide} ${sxHiddenHide}
`;

export const MaterialContainer = tw(Base)`
  basis-full xl:basis-2/5 xl:w-2/5
  2xl:basis-3/5 2xl:w-3/5
  ${({ $group }: WrapperProps) => ($group ? "lg:w-1/4" : "")}
`;

export const ToggleContainer = tw(Base)`
  basis-20 justify-center
`;

export const HiddenMobile = tw.div`hidden md:inline-grid`;

export const TotalContainer = tw(Base)`
  lg:basis-[9%] justify-end text-ellipsis ${sxHiddenHide}
`;

export const NotesContainer = tw(Base)`
  basis-12 justify-center ${sxHiddenHide} col-span-full md:col-span-1
  ${({ $details }: WrapperProps) =>
    $details
      ? "lg:basis-full xl:basis-full 2xl:basis-full w-full flex-full"
      : ""}
`;

export const ExpandedNotesContainer = tw(NotesContainer)`basis-20`;

export const InlineNotesContainer = tw.div`flex flex-full col-start-1 col-span-full
    ${({ $group, $header }: WrapperProps) =>
      $group || $header ? "hidden" : ""}
  `;

export const LogoContainer = tw(Base)`w-14 basis-14`;

/**
 * @public
 */
export const ItemDetailsContainer = tw(Base)`
    col-span-full
    lg:basis-2/12 lg:w-2/12
    ${({ $group }: WrapperProps) => ($group ? "lg:w-1/4" : "")}
`;

export const RfqItemContainer = tw(Base)`
  basis-full lg:basis-2/12 lg:w-2/12
  2xl:basis-4/12 2xl:w-3/12
  ${({ $group }: WrapperProps) => ($group ? "lg:w-1/4" : "")} flex-1
`;

export const StatusContainer = tw(Base)`
  w-26
`;

export const FullWidth = tw(Base)`flex-1`;
