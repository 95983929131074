import { If } from "@/common/components/if/If";

import { OverlayPanel } from "@/common/components/panel/OverlayPanel";

import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { Select } from "@/common/components/select/components/single/Select";
import { TextField } from "@/common/components/textfield/TextField";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { routes } from "@/config/routes";
import {
  RfqCreationProvider,
  useRfqCreation,
} from "@/contractor/pages/home/rfq-master-catalog-import/components/RfqCreationProvider";
import {
  ProjectFieldsFragment,
  ProjectStatus,
  RfqFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { FormControl, Grid, InputLabel } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { DisplayProjectStatus } from "../../../../../common/components/display-project-status/DisplayProjectStatus";
import { useProjectListOptions } from "../../projects/hooks/useProjectListOptions";
import { useProjectsHooks } from "../../projects/hooks/useProjectsHooks";
import { useAddedAndRemovedVendors } from "../hooks/useAddedAndRemovedVendors";
import { RfqFormSuccessModal } from "./RfqFormSuccessModal";

type RfqFormProps = {
  label: string;
  onClose: () => void;
  rfq?: RfqFieldsFragment | undefined | null;
  project?: ProjectFieldsFragment | null;
  hideVendors?: boolean;
};

const ProjectStageLabel = tw.div`text-xs`;
const VendorTooltip = tw.div`max-w-[400px]`;

const RfqFormWithProvider: FC<RfqFormProps> = ({
  onClose,
  label,
  rfq,
  project,
  hideVendors,
}) => {
  const { vendors, shouldShowVendorCode } = useVendors();
  const { createRfq, updateRfqDetails } = useRfqCreation();
  const { updateProject } = useProjectsHooks();
  const { projects } = useProjectListOptions();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const [projectId, setProjectId] = useState<string>(
    rfq?.project.id || project?.id || "",
  );
  const [projectStatus, setProjectStatus] = useState<ProjectStatus | null>(
    rfq?.project?.status || project?.status || null,
  );
  const [rfqNumber, setRfqNumber] = useState<string>(
    rfq?.clientIdentifier || "",
  );
  const [preferredVendors, setPreferredVendors] =
    useState<Array<string> | null>(
      rfq?.targetSellersOrgLocations?.map((a) => a.id) || [],
    );

  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const { addedVendors, removedVendors } = useAddedAndRemovedVendors(
    rfq?.targetSellersOrgLocations?.map((a) => a.id) || [],
    preferredVendors || [],
    vendors,
    rfq?.quotes || [],
  );

  const onSave = async () => {
    const selectedProject = projects.find(
      (currentProject) => currentProject.id === projectId,
    );
    if (!projectStatus || !selectedProject || !preferredVendors) {
      return;
    }

    let result = null;

    if (selectedProject && projectStatus !== selectedProject.status) {
      const projectToUpdate = {
        ...selectedProject,
        team: selectedProject.team.map((a) => a.id),
        status: projectStatus,
      };
      result = await updateProject(projectToUpdate);
    }

    if (rfq?.id) {
      result = await updateRfqDetails(rfq.id, rfqNumber, preferredVendors);
    } else {
      const result = await createRfq(selectedProject.id, preferredVendors);
      if (result) {
        setSuccessAlert(
          intl.$t(
            { id: "RFQ_CREATE_SUCCESS" },
            { rfqNumber: strongify(result.clientIdentifier) },
          ),
        );
        navigate(generatePath(routes.rfqCheckout, { id: result.id }));
      } else {
        return;
      }
    }

    if (!result) {
      return;
    }

    onClose();

    if (rfq?.id && rfq.status === RfqStatus.Active) {
      openDialog({
        cancelButtonText: null,
        confirmButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        text: (
          <RfqFormSuccessModal
            addedVendors={addedVendors}
            removedVendors={removedVendors}
            rfqNumber={rfq.clientIdentifier}
          />
        ),
        icon: <SuccessIcon />,
        handleConfirm: () => {
          navigate(generatePath(routes.quotes));
        },
      });
    }
  };

  const onProjectChanged = (updatedProjectId: string | null) => {
    if (!updatedProjectId) {
      return;
    }
    setProjectId(updatedProjectId);
    const selectedProject = projects.find(
      (currentProject) => currentProject.id === updatedProjectId,
    );
    if (selectedProject) {
      const { status } = selectedProject;
      setProjectStatus(status);
    }
  };

  const statuses = useMemo(() => {
    if (rfq && rfq.id && (rfq.status !== RfqStatus.Draft || !!rfq.deletedAt)) {
      return Object.entries(ProjectStatus).filter(
        ([, s]) => s === rfq.project.status,
      );
    }
    return Object.entries(ProjectStatus).filter(
      ([, s]) => s === ProjectStatus.Active || s === ProjectStatus.Awarded,
    );
  }, [rfq]);

  const checkStatus = (statuses: RfqStatus[]) => {
    if (!rfq) {
      return true;
    }
    if (rfq.deletedAt) {
      return false;
    }

    return statuses.includes(rfq.status);
  };

  const isAllRequiredFieldsPresented = () => {
    return preferredVendors?.length !== 0;
  };

  return (
    <OverlayPanel
      title={label}
      onSave={
        checkStatus([RfqStatus.Draft, RfqStatus.Active]) && !rfq?.nextRevision
          ? onSave
          : undefined
      }
      onCancel={onClose}
      flexDirectionRow
      disableSave={!isAllRequiredFieldsPresented()}
    >
      <Grid container alignContent="space-between">
        <Grid item container spacing={2}>
          <If isTrue={!!rfq?.id}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label={intl.$t({ id: "RFQ_NUMBER" })}
                    required
                    value={rfqNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRfqNumber(event.target.value)
                    }
                    staticText={
                      !checkStatus([RfqStatus.Draft, RfqStatus.Active]) ||
                      !!rfq?.nextRevision
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </If>
          <Grid item xs={12}>
            <Select
              placeholder={intl.$t({
                id:
                  rfq || project?.id
                    ? "PROJECT_NAME"
                    : "PROJECT_SELECT_YOUR_PROJECT",
              })}
              testId="rfq-form-projects-selector"
              options={projects}
              value={projectId}
              onChange={onProjectChanged}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              required
              noOptionsText={intl.$t({
                id: "NO_PROJECTS_IN_LOCATION",
              })}
              staticText={!!rfq || !!project?.id}
            />
          </Grid>
          <If isTrue={!!projectId}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <InputLabel htmlFor="component-simple">
                  <FormattedMessage
                    tagName={ProjectStageLabel}
                    id="PROJECT_STAGE"
                  />
                </InputLabel>
                <FormControl fullWidth>
                  <Grid container spacing={1}>
                    {statuses.map(([key, enumStatus]) => (
                      <Grid item key={key} xs={3}>
                        <DisplayProjectStatus
                          status={enumStatus}
                          isEditable={checkStatus([RfqStatus.Draft])}
                          isSelected={projectStatus === enumStatus}
                          onClick={(status) => setProjectStatus(status)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <If isTrue={!hideVendors}>
              <Grid item xs={12}>
                <Multiselect
                  placeholder={intl.$t({ id: "PREFERRED_VENDORS" })}
                  options={vendors || []}
                  customRender={(item) =>
                    VendorPickerCustomRender(item, {
                      filter: (c) => c.receivesQuoteNotifications,
                      shouldShowVendorCode,
                    })
                  }
                  values={preferredVendors}
                  onMultipleChange={setPreferredVendors}
                  getLabel={(o) =>
                    vendorLabelFormatter(
                      o.sellerOrgLocation,
                      o.contacts.filter((c) => c.receivesQuoteNotifications),
                      {
                        vendorCode: shouldShowVendorCode
                          ? o.externalCode
                          : null,
                      },
                    )
                  }
                  testId="rfq-form-vendor-selector"
                  getValue={(option) => option.sellerOrgLocation.id}
                  disableCloseOnSelect
                  includeCheckboxFn={(option) => option.contacts.length > 0}
                  optionDisabledFn={(option) => option.contacts.length === 0}
                  optionTooltipRenderFn={(option) =>
                    option.contacts.length === 0 ? (
                      <VendorTooltip>
                        {intl.$t({ id: "VENDOR_DISABLED_TOOLTIP" })}
                      </VendorTooltip>
                    ) : undefined
                  }
                  required
                  staticText={
                    !checkStatus([RfqStatus.Draft, RfqStatus.Active]) ||
                    !!rfq?.nextRevision
                  }
                />
              </Grid>
            </If>
          </If>
        </Grid>
      </Grid>
    </OverlayPanel>
  );
};

export const RfqForm: FC<RfqFormProps> = (props) => (
  <RfqCreationProvider>
    <RfqFormWithProvider {...props} />
  </RfqCreationProvider>
);
