import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid gap-1 mt-5 px-30 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-4 col-span-3 rounded-3xl 
`;

const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

type SubmitDialogProps = {
  visible: boolean;
  callback?: (
    skipConfirmation: boolean,
    setSkipVendorNotification: boolean,
  ) => Promise<void>;
  setVisible: (visible: boolean) => void;
  saving?: boolean;
  forceSkipVendorConfirmation?: boolean;
};

export const SubmitBuyoutDialog: FC<SubmitDialogProps> = ({
  visible = true,
  callback,
  setVisible,
  saving,
  forceSkipVendorConfirmation = false,
}) => {
  const intl = useIntl();
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
    }
  }, [visible]);

  useEffect(() => {
    setSkipConfirmation(
      forceSkipVendorConfirmation ? true : skipVendorNotification,
    );
  }, [skipVendorNotification, forceSkipVendorConfirmation]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleConfirm = useCallback(async () => {
    if (callback) {
      await callback(skipConfirmation, skipVendorNotification);
    }
  }, [callback, skipConfirmation, skipVendorNotification]);

  return (
    <Dialog
      icon={<WarningIcon />}
      title={
        <FormattedMessage
          id={"SUBMIT_BUYOUT_CONFIRMATION"}
          values={{ br: <br /> }}
        />
      }
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SUBMIT" })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      saving={saving}
      show={visible}
      content={
        <Container>
          <InfoText>
            <FormattedMessage id="NOTIFY_VENDOR_OF_BUYOUT" />
            <Switch
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
              value={!skipVendorNotification}
              onChange={() =>
                setSkipVendorNotification((prevValue) => !prevValue)
              }
            />
            <If
              isTrue={!skipVendorNotification && !forceSkipVendorConfirmation}
            >
              <FormattedMessage id="REQUIRE_VENDOR_CONFIRM_BUYOUT" />
              <Switch
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                value={!skipConfirmation}
                onChange={() => setSkipConfirmation(!skipConfirmation)}
              />
            </If>
          </InfoText>
          <If isTrue={skipVendorNotification || forceSkipVendorConfirmation}>
            <AdditionalInfo className="mt-2">
              <FormattedMessage id="BUYOUT_WILL_TRANSITION_AUTOMATICALLY_TO_ACTIVE" />
            </AdditionalInfo>
          </If>
        </Container>
      }
    />
  );
};
