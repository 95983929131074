import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import { useSorting } from "@/common/stores/hooks/useSorting";
import { ObjectType } from "@/common/stores/useSortingStore";
import {
  QueryReleasesFilter,
  QueryReleasesSortBy,
  ReleasePartialFieldsFragment,
  useReleasesQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

type ReturnType = {
  deliveries: ReleasePartialFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  refetch: () => void;
  page?: number;
};

export const useDeliveriesWithPagination = (
  filter: QueryReleasesFilter | undefined,
): ReturnType => {
  const { paginationArgs, setPageInfo, page } = usePagination();
  const { sortEntity } = useSorting(ObjectType.Release);

  const { data, loading, error, refetch } = useReleasesQuery({
    variables: {
      filter,
      ...paginationArgs,
      sort: sortEntity
        ? {
            by: sortEntity.field as QueryReleasesSortBy,
            descending: sortEntity.descending,
          }
        : undefined,
    },
    skip: !filter,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.releases.pageInfo) {
      setPageInfo({ ...data.releases.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    deliveries: data?.releases.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.releases.totalCount || 0,
    refetch,
    page,
  };
};
