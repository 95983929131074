import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { GridTable } from "@/common/components/grid-table/GridTable";
import { Loader } from "@/common/components/loader/Loader";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useHideZones } from "@/common/hooks/useHideZones";
import { useDistributorInvoice } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  DistributorReleaseItemsZoneProvider,
  useDistributorReleaseItemsZoneProvider,
} from "@/distributor/pages/release/providers/DistributorReleaseItemsZoneProvider";
import {
  AssetFieldsFragment,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorInvoiceReleaseConfiguration } from "./InvoiceReleaseConfiguration";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
const DeliverySlipCard = tw(
  AssetsCard,
)`my-[1px] lg:border-0 mb-10 overflow-visible`;
const Separator = tw.div`flex flex-1`;
const Footer = tw.div`pr-7 mt-20`;
const ZoneItemsContainer = tw.div`flex flex-col`;

const InvoiceReleaseItemsWithProviders = () => {
  const { invoice } = useDistributorInvoice();
  const intl = useIntl();

  const configuration = useDistributorInvoiceReleaseConfiguration();
  const { zones, toggleZone, toggleCostCode, groupedByCostCode } =
    useDistributorReleaseItemsZoneProvider();
  const { hideZones } = useHideZones(zones);

  const itemFn = useCallback(() => ({ className: "bg-gray-100" }), []);

  if (!invoice?.release) {
    return <Loader loading />;
  }

  return (
    <>
      <GridTable<DistributorReleaseItemFieldsFragment>
        configuration={{
          container: ZoneItemsContainer,
          columns: configuration,
          classNames: {
            header: "-top-6 bg-gray-100",
            category: "top-7",
            subCategory: groupedByCostCode ? "top-17" : "hidden",
            detailsContent: "lg:p-0",
            itemFn,
          },
          toggle: {
            category: toggleZone,
            subCategory: toggleCostCode,
          },
        }}
        items={zones}
        hideGroup={hideZones}
        virtualized={groupedByCostCode}
        readonly
      />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={invoice.release.instructions?.assets || []}
        expanded={false}
      />
      <DeliverySlipCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "DELIVERY_SLIP_AND_DELIVERY_PHOTOS" })}
        expanded={false}
        assets={
          [
            ...(invoice.release.deliverySlips.map((slip) => slip.asset) ?? []),
            ...(invoice.release.deliveryPhotos ?? []),
          ] as AssetFieldsFragment[]
        }
      />
      <Separator />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          release={invoice.release}
          total={invoice.release.total}
          taxExempt={{
            isProjectTaxExempt: invoice.release?.project?.taxExempt,
            isVendorTaxExempt: invoice.release?.preferredVendor?.taxExempt,
            vendorName: vendorLabelFormatter(
              invoice.release?.sellerOrgLocation,
            ),
          }}
          includeNotesPanel={false}
          includePaymentTerms={true}
          readonly
        />
      </Footer>
    </>
  );
};

export const InvoiceReleaseItems = () => {
  const { invoice } = useDistributorInvoice();
  return (
    <DistributorReleaseItemsZoneProvider items={invoice?.release?.items ?? []}>
      <InvoiceReleaseItemsWithProviders />
    </DistributorReleaseItemsZoneProvider>
  );
};
