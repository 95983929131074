import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useAdminOrgPreferredVendorsQuery } from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useAdminVendors = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { setPageInfo, paginationArgs, firstPage } = usePagination();
  const { data, loading, error } = useAdminOrgPreferredVendorsQuery({
    variables: {
      ...paginationArgs,
      filter: {
        search: searchQuery,
      },
    },
  });

  useEffect(() => {
    if (data?.orgPreferredVendorsConnection) {
      setPageInfo(data.orgPreferredVendorsConnection.pageInfo);
    }
  }, [data, setPageInfo]);

  const vendors = useMemo(
    () => data?.orgPreferredVendorsConnection.edges.map((e) => e.node) ?? [],
    [data],
  );

  const setSearch = useCallback(
    (search: string) => {
      firstPage();
      setSearchQuery(search);
    },
    [firstPage],
  );

  useErrorEffect(error);

  return {
    vendors,
    loading,
    error: !!error,
    totalCount: data?.orgPreferredVendorsConnection.totalCount ?? 0,
    searchQuery,
    setSearchQuery: setSearch,
  };
};
