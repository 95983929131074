import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

export const useOrgSettings = () => {
  const { viewer, loading } = useUser();
  const {
    error,
    settings,
    refetch,
    connectedIntegrationType,
    connectedSourceSystem,
    hasPhaseCodes,
  } = useStartupDataStore(
    useShallow((state) => ({
      viewer: state.viewer,
      loading: state.loading,
      error: state.error,
      refetch: state.fetchStartupData,
      settings: state.settings,
      connectedIntegrationType: state.connectedIntegrationType,
      connectedSourceSystem: state.connectedSourceSystem,
      hasPhaseCodes: state.hasPhaseCodes,
    })),
  );
  useErrorEffect(error);

  const refetchSettings = useCallback(async () => {
    await refetch(true);
  }, [refetch]);

  return {
    orgId: viewer?.org?.id || "",
    settings,
    loading,
    connectedIntegrationType,
    connectedSourceSystem,
    refetchSettings,
    hasPhaseCodes,
  };
};
