import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import {
  SHORT_DATE_OPTION,
  formattedDate,
} from "@/common/utils/dates/DateView";
import { useUnlinkPo } from "@/contractor/pages/home/release/components/connections/hooks/useUnlinkPo";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalPOModal } from "../../../../../common/external-po/ExternalPOModal";

type DotColor = "green" | "red" | "orange" | "blue";

const COLOR_MAP = {
  green: "bg-green-800",
  red: "bg-red-500",
  orange: "bg-orange-500",
  blue: "bg-blue-450",
};
export enum BuyoutExportStatusEnum {
  OK,
  Outdated,
  ExportIssue,
  Syncing,
  SyncIssue,
  PoExists,
}

const BUYOUT_EXPORT_STATUSES = {
  [BuyoutExportStatusEnum.OK]: {
    color: "green",
    label: "LAST_EXPORT_WITH_DATE",
  },
  [BuyoutExportStatusEnum.Outdated]: {
    color: "orange",
    label: "MODIFIED_AFTER_SYNC_WITH_DATE",
  },
  [BuyoutExportStatusEnum.ExportIssue]: {
    color: "red",
    label: "EXPORT_ISSUE_WITH_DATE",
  },
  [BuyoutExportStatusEnum.Syncing]: {
    color: "orange",
    label: "SYNCING_WITH_DATE",
  },
  [BuyoutExportStatusEnum.SyncIssue]: {
    color: "red",
    label: "SYNC_ISSUE_WITH_DATE",
  },
  [BuyoutExportStatusEnum.PoExists]: {
    color: "blue",
    label: "NOT_CONNECTED",
  },
};

const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;
const Row = tw.div`flex flex-row items-center`;
const LinkLikeStyled = tw(LinkLike)`text-xs ml-2 mb-0.5`;

const Status = tw.div<{
  status: BuyoutExportStatusEnum;
}>`w-2 h-2 rounded-full inline-block mr-2
${({ status }: { status: BuyoutExportStatusEnum }) =>
  COLOR_MAP[BUYOUT_EXPORT_STATUSES[status].color as DotColor]}
`;

type Props = {
  status: BuyoutExportStatusEnum;
  poNumber?: string | null;
  projectId?: string | null;
  sourceSystem?: SourceSystem;
  date?: number | null;
  integrationType?: IntegrationType;
  poLinkId: string | undefined;
  buyoutId: string | undefined;
  poExists: boolean | null | undefined;
};

export const BuyoutExportStatus: FC<Props> = ({
  status,
  poNumber,
  projectId,
  sourceSystem,
  integrationType,
  date,
  poLinkId,
  buyoutId,
  poExists,
}: Props) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { unlinkPo } = useUnlinkPo();

  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);

  const clearPo = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      title: intl.$t({ id: "CLEAR_PO_NUMBER" }),
      text: intl.$t({ id: "CLEAR_BUYOUT_PO_NUMBER_CONFIRMATION" }),
      icon: <WarningIcon />,
      handleConfirm: async () => {
        if (!poLinkId) {
          return;
        }
        await unlinkPo(poLinkId, undefined, buyoutId);
      },
    });
  }, [openDialog, poLinkId, buyoutId, unlinkPo, intl]);

  return (
    <>
      <ExportedAt>
        <Status status={status} />
        <PoContainer>
          <LinkLike onClick={() => setExternalPOModalOpened(!!poNumber)}>
            <FormattedMessage
              id="PO_NUMBER_INTEGRATION"
              values={{
                number: poNumber,
                integration: intl.$t({
                  id: sourceSystem
                    ? `INTEGRATION_${sourceSystem}`
                    : `INTEGRATION_${integrationType}`,
                }),
              }}
            />
          </LinkLike>
          <Row>
            <If isTrue={poLinkId}>
              <FormattedMessage
                id={BUYOUT_EXPORT_STATUSES[status].label}
                values={{
                  date: formattedDate({
                    date,
                    options: SHORT_DATE_OPTION,
                  }),
                }}
                tagName={Item}
              />
              <LinkLikeStyled onClick={clearPo}>
                {intl.$t({ id: "CLEAR" })}
              </LinkLikeStyled>
            </If>
            <If isTrue={!poLinkId && poExists}>
              <FormattedMessage id="NOT_CONNECTED" tagName={Item} />
            </If>
          </Row>
        </PoContainer>
      </ExportedAt>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={poNumber}
        projectId={projectId}
      />
    </>
  );
};
