import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { readValue, setValue } from "@/common/utils/localStorage";
import { useRfqMutations } from "@/contractor/pages/home/rfq/hooks/useRfqMutations";
import {
  QueryRfQsFilter,
  RfqsRfqFieldsFragment,
  useRfqsQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";

enum RFQsFilterSelection {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
}

type ProviderContextType = {
  rfqs: RfqsRfqFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  removeRfq: (rfqId: string) => Promise<boolean>;
  filter?: QueryRfQsFilter;
  isFiltered: boolean;
  setFilter: (filter: QueryRfQsFilter) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  rfqs: [],
  totalCount: 0,
  loading: false,
  error: false,
  removeRfq: NoFunctionBooleanPromise,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const FILTER_OPTIONS = [
  {
    id: RFQsFilterSelection.Draft,
  },
  {
    id: RFQsFilterSelection.Active,
  },
  {
    id: RFQsFilterSelection.Awarded,
  },
  {
    id: RFQsFilterSelection.Cancelled,
  },
];

export const RfqsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { removeRfqMutation } = useRfqMutations();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const [filter, setFilter] = useState<QueryRfQsFilter | undefined>(
    getFiltersQueryParam(),
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryRfQsFilter>(
      LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER,
    ) as QueryRfQsFilter;
    setFilter({
      statuses: localStorageSettings?.statuses,
      deleted: localStorageSettings?.deleted,
      quotesReceived: localStorageSettings?.quotesReceived,
      closedProjects: localStorageSettings?.closedProjects,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam]);

  const setFilterAndUpdateQueryString = (updatedFilter: QueryRfQsFilter) => {
    setPage({ page: 0 });
    setFilter(updatedFilter);
    setValue(LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER, updatedFilter);
    setFiltersQueryParams(updatedFilter);
  };

  const { data, loading, error } = useRfqsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.rfqs) {
      setPageInfo(data.rfqs.pageInfo);
    }
  }, [data, setPageInfo]);

  const removeRfq = async (rfqId: string) => {
    if (page !== 0 && data?.rfqs.edges.length === 1) {
      previousPage();
    }

    return await removeRfqMutation(rfqId);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        rfqs: data?.rfqs?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.rfqs?.totalCount || 0,
        removeRfq,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered:
          !!filter?.statuses?.length ||
          !!filter?.projectIds?.length ||
          filter?.deleted !== false,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqs = (): ProviderContextType => useContext(ProviderContext);
