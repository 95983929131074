import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Loader } from "@/common/components/loader/Loader";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutItemsListConfiguration } from "../../common/table-configurations/BuyoutListItemsConfiguration";
import { useBuyoutListNonDraftedConfiguration } from "../../common/table-configurations/BuyoutListNonDraftedConfiguration";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { BuyoutItemsSideActions } from "./BuyoutItemsSideActions";

const Container = tw.div``;

type Props = {
  buyoutItems: BuyoutItemFieldsFragment[];
  loading: boolean;
  totalCount: number;
  readonly?: boolean;
};

export const BuyoutList: FC<Props> = ({
  buyoutItems,
  loading,
  totalCount,
  readonly,
}) => {
  const {
    costCodes,
    newBuyoutItem,
    toggleCategory,
    isNewBuyoutWithErrors,
    groupedByCostCode,
  } = useBuyoutGrouped();
  const { buyout, expandedItems } = useContractorBuyout();

  const costCodesItems = useMemo(() => {
    return readonly
      ? costCodes.map((c) => ({
          ...c,
          items: c.items.filter((i) =>
            buyoutItems.some((bi) => bi.id === i.id),
          ),
        }))
      : costCodes;
  }, [buyoutItems, costCodes, readonly]);

  const itemFn = useCallback(
    (item: BuyoutItemFieldsFragment) => {
      if (buyoutItems.some((i) => i.id === item.id && !i.isIncluded)) {
        return { className: "bg-gray-100" };
      }
      return { className: "" };
    },
    [buyoutItems],
  );

  const isEmpty = useMemo(
    () =>
      costCodesItems.length === 0 &&
      buyoutItems.length === 0 &&
      !loading &&
      !newBuyoutItem.isAddMode,
    [
      costCodesItems.length,
      buyoutItems.length,
      loading,
      newBuyoutItem.isAddMode,
    ],
  );

  const nonDraftedConfiguration = useBuyoutListNonDraftedConfiguration({
    buyout,
  });
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();

  const items = useMemo(() => {
    const item = newBuyoutItem?.isAddMode
      ? costCodesItems
          .find((c) => c.id === UNSPECIFIED_COST_CODE_ID)
          ?.items.find((i) => i.id === "")
      : null;
    return buyout?.items
      .toSorted((a, b) => (a.position || 0) - (b.position || 0))
      .concat(item ? [item] : []);
  }, [buyout?.items, costCodesItems, newBuyoutItem?.isAddMode]);

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <ListRenderer
      totalCount={totalCount}
      hasItemsCondition={!isEmpty}
      count={buyoutItems.length}
    >
      <GridTable
        configuration={{
          container: Container,
          columns: !readonly
            ? buyoutItemsListConfiguration.configuration
            : nonDraftedConfiguration,
          classNames: {
            header: "top-8",
            category: "top-[36px] lg:top-[74px] bg-yellow-200",
            itemFn: readonly ? itemFn : undefined,
            detailsContent: "lg:p-0",
          },
          toggle: {
            category: toggleCategory,
          },
        }}
        items={groupedByCostCode ? costCodesItems : items}
        virtualized={groupedByCostCode}
        readonly={readonly}
        loading={loading}
        error={isNewBuyoutWithErrors}
        expandedItems={(item) => getExpandedItems(item, expandedItems)}
      />
      <BuyoutItemsSideActions />
    </ListRenderer>
  );
};
