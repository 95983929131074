import { Loader } from "@/common/components/loader/Loader";
import { SAVED_LIST_VIEW_ID } from "@/common/const";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { RfqStatus } from "@/generated/graphql";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useRfqItemsListConfiguration } from "../rfq/components/RfqItemsList.configuration";
import { useRfq } from "../rfq/hooks/useRfq";
import { SyncRfqItemsProvider } from "../rfq/providers/SyncRfqItemsProvider";
import { RfqItems } from "./components/RfqItems";

const RfqCheckoutWithProvider = () => {
  const { rfq } = useRfq();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(
      rfq?.status === RfqStatus.Draft
        ? TableViewState.spreadsheet
        : TableViewState.normal,
      { id: SAVED_LIST_VIEW_ID.RFQ },
    );
    return () => {
      resetViewStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initViewStore, resetViewStore]);

  if (!rfq) {
    return <Loader loading />;
  }

  return (
    <SyncRfqItemsProvider>
      <RfqItems />
    </SyncRfqItemsProvider>
  );
};

export const RfqCheckout = () => {
  const { spreadsheetConfig } = useRfqItemsListConfiguration(true);
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <RfqCheckoutWithProvider />
    </ColumnMapperProvider>
  );
};
