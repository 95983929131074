import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import {
  Base,
  ExpandedNotesContainer,
  MdContainer,
  MdFixedContainer,
  NotesContainer,
  TotalContainer,
} from "@/common/layout/ResponsiveClasses";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { CategoryLike } from "../../../../../../../common/hooks/useToggleCategory";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { BidPriceTotalForItem } from "../../list-items/BidPriceTotalForItem";
import { BuyoutCategoryPrice } from "../../list-items/BuyoutCategoryPrice";
import { BuyoutCostCodeManufacturerSelector } from "../../non-quoted/components/BuyoutCostCodeManufacturerSelector";
import { BuyoutItemActions } from "../../non-quoted/components/BuyoutItemActions";
import { BuyoutItemCheckbox } from "../../non-quoted/components/BuyoutItemCheckbox";
import { BuyoutItemGroupCheckbox } from "../../non-quoted/components/BuyoutItemGroupCheckbox";
import { BuyoutItemInstructionsDetails } from "../../non-quoted/components/BuyoutItemInstructionsDetails";
import { BuyoutItemMaterialEditableView } from "../../non-quoted/components/BuyoutItemMaterialEditableView";
import { BuyoutManufacturerSelector } from "../../non-quoted/components/BuyoutManufacturerSelector";
import { BuyoutQuantityInput } from "../../non-quoted/components/BuyoutQuantityInput";
import { BuyoutTagsPicker } from "../../non-quoted/components/BuyoutTagsPicker";
import { BuyoutUomSelector } from "../../non-quoted/components/BuyoutUomSelector";
import { ContractorBuyoutPriceInput } from "../../non-quoted/components/ContractorBuyoutPriceInput";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";
import { GroupBuyoutByCostCodeToggle } from "../GroupBuyoutByCostCodeToggle";

export const BuyoutRfqItemContainer = tw(Base)`col-span-full flex-1`;

export const useBuyoutItemsListConfiguration = () => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const intl = useIntl();
  const { newBuyoutItem } = useBuyoutGrouped();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { buyout } = useContractorBuyout();
  const { hasPhaseCodes } = useOrgSettings();

  const configuration: Array<GridCol<BuyoutItemFieldsFragment, CategoryLike>> =
    useMemo(
      () => [
        {
          wrapper: Base,
          position: "center",
          item: ({ item }) => <BuyoutItemCheckbox item={item} />,
          group: (category) => <BuyoutItemGroupCheckbox group={category} />,
          subgroup: (category) => <BuyoutItemGroupCheckbox group={category} />,
        },
        {
          wrapper: BuyoutRfqItemContainer,
          item: ({ item, index, readonly, count, error }) => (
            <BuyoutItemMaterialEditableView
              item={item}
              readonly={readonly}
              index={index}
              count={count}
              error={error}
            />
          ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
              projectId={buyout?.project?.id}
            />
          ),
          header: (
            <>
              <FormattedMessage id="PROJECT_ITEM_ESTIMATED_ITEMS" />
              <GroupBuyoutByCostCodeToggle />
            </>
          ),
        },
        {
          header: <FormattedMessage id="MANUFACTURER" />,
          hidden: !hasManufacturersSetting,
          wrapper: MdContainer,
          position: "center",
          item: ({ item, readonly, index }) => (
            <BuyoutManufacturerSelector
              item={item}
              readonly={readonly}
              index={index}
            />
          ),
          group: (category, readonly) => (
            <BuyoutCostCodeManufacturerSelector
              category={category}
              readonly={readonly}
            />
          ),
        },
        {
          wrapper: MdFixedContainer,
          header: (
            <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />
          ),
          item: ({ item }) => {
            return <BuyoutTagsPicker item={item} visibleTags={1} />;
          },
        },
        {
          header: <FormattedMessage id="DISTRIBUTOR_QUOTE_CLIENT_ORDERED" />,
          wrapper: MdContainer,
          position: "center",
          item: ({ item, readonly, error }) => (
            <BuyoutQuantityInput
              item={item}
              readonly={readonly}
              error={error}
            />
          ),
        },
        {
          header: <FormattedMessage id="UOM" />,
          wrapper: MdContainer,
          position: "center",
          item: ({ item, error }) => (
            <BuyoutUomSelector
              item={item}
              staticText={!!item.id}
              mnemonic={false}
              error={error}
            />
          ),
        },
        {
          header: <FormattedMessage id="PRICE" />,
          wrapper: MdContainer,
          position: "center",
          item: ({ item, readonly, index }) => (
            <ContractorBuyoutPriceInput
              item={item}
              readonly={readonly}
              index={index}
            />
          ),
          hidden: !hasPermissions,
        },
        {
          header: <FormattedMessage id="TOTAL" />,
          wrapper: TotalContainer,
          position: "end",
          item: ({ item }) => <BidPriceTotalForItem item={item} />,
          group: (category) => <BuyoutCategoryPrice items={category.items} />,
          hidden: !hasPermissions,
        },
        {
          header: "",
          position: "end",
          wrapper: newBuyoutItem.isAddMode
            ? ExpandedNotesContainer
            : NotesContainer,
          item: ({ item, readonly }) => (
            <BuyoutItemActions item={item} readonly={readonly} />
          ),
          details: ({ item, readonly }) => (
            <BuyoutItemInstructionsDetails item={item} readonly={readonly} />
          ),
        },
      ],
      [
        buyout?.project?.id,
        hasManufacturersSetting,
        hasPermissions,
        newBuyoutItem.isAddMode,
        hasPhaseCodes,
      ],
    );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "projectItem.material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "projectItem.estimateUom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.PositiveQuantity,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [intl],
  );

  return { configuration, spreadsheetConfig };
};
