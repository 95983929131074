import { BulkPhaseCodeSelector } from "@/common/components/cost-code-selector/BulkPhaseCodeSelector";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useCallback } from "react";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const ReleaseBulkPhaseCodeSelector: FC<Props> = ({ setLoading }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds, updateRelease } =
    useReleaseUpdate();
  const { release } = useRelease();

  const updatePhaseCode = useCallback(
    async (code: string | null) => {
      await updateRelease(
        {
          releaseId: release?.id as string,
          version: release?.version,
          updates: selectedReleaseItemIds.map((id) => ({
            releaseItemId: id,
            tags: code ? [code] : [],
          })),
        },
        {
          batch: false,
        },
      );
      setSelectedReleaseItemIds([]);
    },
    [
      release?.id,
      release?.version,
      selectedReleaseItemIds,
      setSelectedReleaseItemIds,
      updateRelease,
    ],
  );

  return (
    <BulkPhaseCodeSelector
      count={selectedReleaseItemIds.length}
      setLoading={setLoading}
      update={updatePhaseCode}
    />
  );
};
