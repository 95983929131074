import { Switch } from "@/common/components/switch/Switch";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row justify-between border-t border-dashed mt-3 pt-4 pb-2 border-gray-300`;

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const DeliveriesDateRangePickerActionBar: FC<Props> = ({
  value,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <Row>
      <FormattedMessage id="INCLUDE_ITEM_DELIVERY_DATES" />
      <Switch
        value={value}
        onChange={onChange}
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
      />
    </Row>
  );
};
