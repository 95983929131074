import { Group } from "@/common/components/groups/common/types/types";
import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { UpdateInvoicesInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useOrderGroups } from "../../hooks/useOrderGroups";
import { useReleasesUpdate } from "../../hooks/useReleasesUpdate";
import { useReleaseSequence } from "../../providers/ReleaseSequenceProvider";

type Props = {
  removing?: boolean;
  onClose: () => void;
  existingGroups?: Group[];
};

export const ReleaseGroupSelector: FC<Props> = ({
  removing,
  onClose,
  existingGroups = [],
}) => {
  const { groups } = useOrderGroups();
  const { sequenceIds } = useReleaseSequence();
  const { createGroup } = useOrderGroups();
  const { updateReleases } = useReleasesUpdate();

  const filteredGroups = useMemo(
    () => (removing ? existingGroups : groups),
    [groups, removing, existingGroups],
  );

  const updateEntity = useCallback(
    (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      updateReleases({
        releaseIds: sequenceIds,
        assignedGroupIds: input.assignedFolderIds,
        unassignedGroupIds: input.unassignedFolderIds,
      });
    },
    [updateReleases, sequenceIds],
  );

  return (
    <GroupSelector
      createGroup={createGroup}
      groups={filteredGroups}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
