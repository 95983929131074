import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { BuyoutStatusChip } from "@/common/components/statuses/BuyoutStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BuyoutMessagesButton } from "@/contractor/pages/home/buyout/components/common/buyout-messages/BuyoutMessagesButton";
import { ExportBuyoutButton } from "@/contractor/pages/home/buyouts/components/connections/components/buttons/ExportBuyoutButton";
import {
  BuyoutFieldsFragment,
  BuyoutStatus,
  MessageContextKind,
} from "@/generated/graphql";
import { ListAlt } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBuyoutQuoted } from "../providers/BuyoutQuotedProvider";
import { BuyoutQuotedBreadcrumbItem } from "./BuyoutQuotedBreadcrumbItem";
import { BuyoutQuotedHeaderInfo } from "./BuyoutQuotedHeaderInfo";
import { BuyoutQuotedPrintButton } from "./BuyoutQuotedPrintButton";

type Props = {
  buyout: BuyoutFieldsFragment;
};

const BuyoutNoWrapper = tw.div`pr-2`;

const Headline = tw.div`
  text-lg flex items-center
`;
export const BuyoutQuotedHeader: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const { hasMultipleBuyouts, relatedBuyoutsQueue, currentBuyoutQueueIndex } =
    useBuyoutQuoted();
  const { connectedSourceSystem } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const navigate = useNavigate();

  const buyoutHeaderActions = useMemo(() => {
    const actions = [];
    if (!hasMultipleBuyouts && buyout.status !== BuyoutStatus.Draft) {
      actions.push(
        ...[
          <QuestionBox
            key="delivery-history"
            onClick={() =>
              navigate(generatePath(routes.buyoutDeliveries, { id: buyout.id }))
            }
          >
            <Tooltip id="delivery-history" element={<ListAlt />}>
              <FormattedMessage id="DELIVERY_HISTORY" />
            </Tooltip>
          </QuestionBox>,
          <BuyoutQuotedPrintButton buyout={buyout} key="print" />,
          <BuyoutMessagesButton
            key="messages"
            buyout={buyout}
            org={buyout.sellerOrgLocation.org}
            text={
              <QuestionBox>
                <Tooltip
                  id="buyout-messages"
                  element={
                    <MessageButton
                      id={buyout.id}
                      kind={MessageContextKind.Buyout}
                    />
                  }
                >
                  <FormattedMessage id="MESSENGER" />
                </Tooltip>
              </QuestionBox>
            }
          />,
        ],
      );
    } else {
      actions.push(
        ...relatedBuyoutsQueue.map((b, index) => (
          <BuyoutQuotedBreadcrumbItem
            key={b.id}
            relatedBuyout={b}
            index={index}
            currentBuyoutQueueIndex={currentBuyoutQueueIndex}
            totalBuyouts={relatedBuyoutsQueue.length}
          />
        )),
      );
    }

    if (
      (includePoNumbering || buyout.poLink || buyout.poExists) &&
      connectedSourceSystem
    ) {
      actions.push(<ExportBuyoutButton buyout={buyout} key="export-buyout" />);
    }

    return actions;
  }, [
    hasMultipleBuyouts,
    relatedBuyoutsQueue,
    buyout,
    navigate,
    currentBuyoutQueueIndex,
    connectedSourceSystem,
    includePoNumbering,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier })}
        </title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            text: intl.$t({ id: "BUYOUTS" }),
            link: routes.buyouts,
            id: "buyouts",
          },
          {
            id: "buyoutNo",
            text: (
              <Headline>
                <BuyoutNoWrapper>
                  <FormattedMessage
                    id="BUYOUT"
                    values={{ number: buyout.clientIdentifier }}
                  />
                </BuyoutNoWrapper>
              </Headline>
            ),
          },
        ]}
        appendItems={[
          <BuyoutStatusChip
            key="status"
            status={buyout.status}
            type="breadcrumb"
          />,
        ]}
        actions={buyoutHeaderActions}
        classes={{
          text: "flex items-center print:font-bold print:text-3xl print:flex-1 print:flex",
        }}
      />
      <BuyoutQuotedHeaderInfo buyout={buyout} />
    </>
  );
};
