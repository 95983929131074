import Decimal from "decimal.js";
import { useCallback } from "react";

export const useTaxCalculation = () => {
  // get tax amount based on tax rate or custom tax amount
  const getTaxAmount = useCallback(
    (
      taxRate: string | null | undefined,
      customTaxAmount: string | null | undefined,
      subtotal: string | null | undefined | number,
    ) => {
      const subtotalDecimal = new Decimal(subtotal || 0);
      const taxAmount = taxRate
        ? subtotalDecimal.mul(taxRate)
        : new Decimal(customTaxAmount || 0);

      return taxAmount.toNumber();
    },
    [],
  );

  return { getTaxAmount };
};
