import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { getUserName } from "@/common/utils/users/getUserName";
import { useUsers } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  values: string[] | null | undefined;
  setFilter: (createdByIds: string[] | undefined) => void;
  applyHeaderAccent?: boolean;
};

const Container = tw.div`w-full flex justify-between items-center gap-4`;

export const SubmittedByFilter = ({
  values,
  setFilter,
  applyHeaderAccent = false,
}: Props) => {
  const intl = useIntl();
  const { users } = useUsers();

  const submittedByOptions = useMemo<{ id: string; name: string }[]>(
    () => users.map((user) => ({ id: user.id, name: getUserName(user) })),
    [users],
  );
  const setFilterHandler = useCallback(
    (values: string[] | null) => {
      setFilter(values?.length ? values : undefined);
    },
    [setFilter],
  );

  return (
    <Container>
      <FormattedMessage id="SUBMITTED_BY" />
      <Multiselect
        options={submittedByOptions}
        getLabel={(o) => o.name}
        getValue={(o) => o.id?.toString()}
        values={values}
        chipSize="small"
        includeCheckbox
        limitTags={1}
        disableCloseOnSelect
        className="min-w-45"
        placeholder={values?.length ? "" : intl.$t({ id: "ALL_USERS" })}
        onMultipleChange={setFilterHandler}
        applyHeaderAccent={applyHeaderAccent}
      />
    </Container>
  );
};
