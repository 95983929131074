import { Group } from "@/common/components/groups/common/types/types";
import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { InvoiceType, UpdateInvoicesInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { useInvoiceFolders } from "../invoice-folder-picker/hooks/useInvoiceFolders";

type Props = {
  removing?: boolean;
  onClose: () => void;
  existingFolders?: Group[];
};

export const InvoiceFolderSelector: FC<Props> = ({
  removing,
  onClose,
  existingFolders = [],
}) => {
  const { folders } = useInvoiceFolders(InvoiceType.Receipt);
  const { sequenceIds } = useInvoiceSequence();
  const { createFolder } = useInvoiceFolders(InvoiceType.Invoice);
  const { updateInvoices } = useInvoices();
  const filteredFolders = useMemo(
    () => (removing ? existingFolders : folders),
    [folders, removing, existingFolders],
  );

  const updateEntity = useCallback(
    (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      updateInvoices({
        invoiceIds: sequenceIds,
        ...input,
      });
    },
    [updateInvoices, sequenceIds],
  );

  return (
    <GroupSelector
      createGroup={createFolder}
      groups={filteredFolders}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
