import { AddressLocation } from "@/common/components/adress-location/AddressLocation";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { checkQuoteStatus } from "@/common/utils/status-checks/checkQuoteStatus";
import {
  MessageContextKind,
  QuoteStatus,
  RfqsRfqFieldsFragment,
} from "@/generated/graphql";
import { EmailOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { VendorList } from "../../../../../common/components/vendor-list/VendorList";
import { getVendorContacts } from "../../../../../common/utils/getVendorContacts";
import { ItemCell } from "../components/vendors/ItemCell";
import { RfqMessagesButton } from "../components/vendors/RfqMessagesButton";
import {
  CenterContainer,
  Header,
  HeaderItem,
  Item,
  ItemContainer,
  ItemsContainer,
  LocationContainer,
  StatusContainer,
} from "../components/vendors/RfqVendors.styles";
import { useRfqVendors } from "./RfqVendorsProvider";

export type RfqVendorsDetailsProps = {
  onClose: () => void;
  rfq: RfqsRfqFieldsFragment;
};

const QUOTE_STATUSES_FOR_EMAILS = [
  QuoteStatus.Requested,
  QuoteStatus.Accepted,
  QuoteStatus.Rejected,
  QuoteStatus.ChangeRequested,
];

export const RfqVendorsWithProvider: FC<RfqVendorsDetailsProps> = ({
  onClose,
  rfq,
}) => {
  const intl = useIntl();
  const { vendorLocations, resendQuote } = useRfqVendors();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const getStatus = useCallback(
    (status: QuoteStatus) => {
      switch (status) {
        case QuoteStatus.Draft:
          return intl.$t({ id: "VIEWED" });
        case QuoteStatus.ChangeRequested:
        case QuoteStatus.Requested:
          return intl.$t({ id: "ENUM_REQUESTED" });
        case QuoteStatus.Submitted:
          return intl.$t({ id: "ENUM_SUBMITTED" });
        case QuoteStatus.Withdrawn:
          return intl.$t({ id: "ENUM_WITHDRAWN" });
        case QuoteStatus.Accepted:
          return intl.$t({ id: "ENUM_ACCEPTED" });
        case QuoteStatus.Rejected:
          return intl.$t({ id: "ENUM_REJECTED" });
        case QuoteStatus.CancelledByContractor:
          return intl.$t({ id: "ENUM_CANCELED" });
        default:
          return "";
      }
    },
    [intl],
  );

  return (
    <OverlayPanel
      title={intl.$t({ id: "RFQ_NR" }, { number: rfq.clientIdentifier })}
      onCancel={onClose}
      cancelLabel={intl.$t({ id: "CLOSE" })}
    >
      <Header>
        <HeaderItem>{intl.$t({ id: "CONTACTED_VENDORS" })}</HeaderItem>
        <HeaderItem className="text-center">
          {intl.$t({ id: "STATUS" })}
        </HeaderItem>
      </Header>
      <ItemsContainer>
        {vendorLocations.map((vendorLocation, index) => (
          <ItemContainer key={index}>
            <ItemCell
              name=""
              value={
                <OrgLogo
                  logoImageUrl={vendorLocation.org.photoUrl}
                  name={vendorLocation.org.name}
                  className="shadow-lg"
                  width={40}
                />
              }
              centered={true}
            />
            <ItemCell
              name={
                <VendorList
                  contacts={vendorLocation.preferredVendor?.contacts.filter(
                    (c) => c.receivesQuoteNotifications,
                  )}
                />
              }
              value={
                <LocationContainer>
                  <div>
                    <strong>{vendorLocation.org.name}</strong>
                  </div>
                  <AddressLocation address={vendorLocation.address} />
                </LocationContainer>
              }
            />
            <ItemCell
              value={
                <StatusContainer>
                  <Item>{getStatus(vendorLocation.status).toLowerCase()}</Item>
                  <If
                    isTrue={checkQuoteStatus(
                      vendorLocation,
                      QUOTE_STATUSES_FOR_EMAILS,
                    )}
                  >
                    <Item>
                      <CenterContainer>
                        <LinkLike
                          onClick={() => {
                            openDialog({
                              cancelButtonText: intl.$t({ id: "NO" }),
                              confirmButtonText: intl.$t({ id: "YES" }),
                              icon: <WarningIcon />,
                              title: intl.$t({
                                id: "CONFIRM_RESEND_EMAIL_TITLE",
                              }),
                              text: intl.$t({
                                id: "CONFIRM_RESEND_EMAIL_TEXT",
                              }),
                              handleConfirm: async () => {
                                const result = await resendQuote(
                                  vendorLocation.quote.id,
                                );
                                if (result) {
                                  setSuccessAlert(
                                    intl.$t(
                                      {
                                        id: "QUOTE_RESEND_EMAIL_SUCCESS",
                                      },
                                      {
                                        name: strongify(
                                          `${
                                            vendorLocation.org.name
                                          } - ${getVendorContacts(
                                            vendorLocation.preferredVendor?.contacts.filter(
                                              (c) =>
                                                c.receivesQuoteNotifications,
                                            ),
                                          )}`,
                                        ),
                                      },
                                    ),
                                  );
                                }
                              },
                            });
                          }}
                        >
                          <EmailOutlined />
                        </LinkLike>
                      </CenterContainer>
                    </Item>
                  </If>
                </StatusContainer>
              }
              centered={true}
            />
            <ItemCell
              value={
                <CenterContainer>
                  <LinkLike>
                    <RfqMessagesButton
                      quote={{
                        ...vendorLocation.quote,
                        rfqId: rfq.id,
                        projectId: rfq.project.id,
                      }}
                      text={
                        <MessageButton
                          id={vendorLocation.quote.id}
                          kind={MessageContextKind.Quote}
                        />
                      }
                      org={vendorLocation.org}
                    />
                  </LinkLike>
                </CenterContainer>
              }
              centered={true}
            />
          </ItemContainer>
        ))}
      </ItemsContainer>
    </OverlayPanel>
  );
};
