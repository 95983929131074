import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import { ExpandedRelease } from "../../providers/ReleaseProvider";
import { useReleaseStore } from "../../store/useReleaseStore";
import { ReleaseItemFilter } from "../../types/ReleaseItemsFilters";
import { usePrintView } from "./hooks/usePrintView";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

type PrintButtonProps = {
  release: ExpandedRelease;
};

export const ReleasePrintButton: FC<PrintButtonProps> = ({ release }) => {
  const viewState = useTableViewStore((state) => state.viewState);
  const { groupedByCostCode, filter, filteredTags } =
    useReleaseItemsZoneProvider();
  const { savePDF } = usePrintView(
    release,
    groupedByCostCode && viewState !== TableViewState.spreadsheet,
    filter[ReleaseItemFilter.FULLY_RECEIVED],
    filter[ReleaseItemFilter.FULLY_INVOICED],
    filteredTags,
  );
  const { hasChanges } = useReleaseStore();
  const { openDialog, cancelDialog: handleCancel } = useDialog();
  const intl = useIntl();

  const handleConfirm = useCallback(() => {
    return savePDF();
  }, [savePDF]);

  const openModal = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PRINT_WITHOUT_SAVING" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "UNSAVED_CHANGES_PRINT_TITLE" }),
      text: intl.$t({ id: "UNSAVED_CHANGES_PRINT_DESCRIPTION" }),
      closeOnConfirm: true,
      closeOnCustom: true,
      handleCancel,
      handleConfirm,
    });
  }, [openDialog, handleCancel, handleConfirm, intl]);

  const onClick = () => {
    if (hasChanges) {
      return openModal();
    }

    return savePDF();
  };

  return (
    <IconButtonStyled onClick={onClick}>
      <PrintButton />
    </IconButtonStyled>
  );
};
