import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NoResults } from "@/common/components/no-results/NoResults";
import { routes } from "@/config/routes";
import { isRfqDraft } from "@/contractor/pages/home/rfq/utils/utils";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

type Props = {
  items: RfqsRfqFieldsFragment[];
  columns: Array<GridCol<RfqsRfqFieldsFragment>>;
  loading?: boolean;
  isFiltered?: boolean;
};

export const RfqsListItems: FC<Props> = ({
  columns,
  items,
  loading,
  isFiltered,
}) => {
  const navigate = useNavigate();
  const onClick = useCallback(
    (rfq: RfqsRfqFieldsFragment) => {
      navigate(
        isRfqDraft(rfq)
          ? generatePath(routes.rfqCheckout, {
              id: rfq.id,
            })
          : generatePath(routes.rfqById, { id: rfq.id }),
      );
    },
    [navigate],
  );

  const itemFn = useCallback((item: RfqsRfqFieldsFragment) => {
    if (item.deletedAt) {
      return {
        className: "bg-gray-300 opacity-50",
      };
    }
    return { className: "" };
  }, []);

  return (
    <GridTable
      configuration={{
        columns,
        classNames: {
          header: "top-[90px]",
          root: "pt-2",
          item: "cursor-pointer",
          itemFn,
        },
        toggle: {
          item: onClick,
        },
        hasCardItems: true,
      }}
      items={items}
      readonly
      loading={loading}
      emptyList={
        <NoResults
          isFiltered={isFiltered}
          filteredTranslationKey="RFQ_LIST_NO_RFQS_FILTERED"
          translationKey="RFQ_LIST_NO_RFQS"
        />
      }
    />
  );
};
