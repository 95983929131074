import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Loader } from "@/common/components/loader/Loader";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { RfqStatus } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfq } from "../../rfq/hooks/useRfq";

const SelectorLabel = tw.p`mb-5`;

interface IRfqConfigVendorsDrawer {
  togglePanel: (arg0: boolean) => void;
  panelTitle: string;
  filterVendors?: Array<string>;
  saveLabel?: string;
}

const RfqConfigVendorsDrawer: FC<IRfqConfigVendorsDrawer> = ({
  togglePanel,
  filterVendors = [],
  panelTitle,
  saveLabel,
}) => {
  const { vendors, getVendorCode, shouldShowVendorCode } = useVendors();
  const { rfq, updateRfq } = useRfq();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const [preferredVendors, setPreferredVendors] =
    useState<Array<string> | null>(
      rfq?.targetSellersOrgLocations?.map((a) => a.id) || [],
    );

  const onSaveClick = useCallback(async () => {
    if (!rfq) {
      return;
    }

    const saveChanges = async () => {
      const result = await updateRfq({
        rfqId: rfq.id,
        vendorLocationIds: preferredVendors,
      });

      if (result) {
        togglePanel(false);
      }
    };

    const vendorsRemoved: boolean = rfq.targetSellersOrgLocations.some(
      ({ id }) => preferredVendors?.indexOf(id) === -1,
    );

    if (rfq.status === RfqStatus.Active && vendorsRemoved) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "REMOVE_VENDOR_S_CONFIRMATION" }),
        handleConfirm: saveChanges,
      });
    } else {
      await saveChanges();
    }
  }, [intl, openDialog, preferredVendors, rfq, togglePanel, updateRfq]);

  if (!rfq) {
    return <Loader loading />;
  }

  return (
    <OverlayPanel
      title={panelTitle}
      onCancel={() => togglePanel(false)}
      onSave={onSaveClick}
      saveLabel={saveLabel || undefined}
      disableSave={preferredVendors?.length === 0}
    >
      <SelectorLabel>
        {intl.$t({
          id: "RFQ_SELECT_VENDOR_S",
        })}
      </SelectorLabel>
      <Multiselect
        placeholder={intl.$t({
          id: "SEARCH_VENDOR",
        })}
        options={
          filterVendors.length > 0 && vendors
            ? vendors.filter(({ id }) => filterVendors.indexOf(id) < 0)
            : vendors || []
        }
        customRender={(item) =>
          VendorPickerCustomRender(item, {
            filter: (c) => c.receivesQuoteNotifications,
            shouldShowVendorCode,
          })
        }
        values={preferredVendors}
        onMultipleChange={setPreferredVendors}
        getLabel={(o) =>
          vendorLabelFormatter(
            o.sellerOrgLocation,
            o.contacts.filter((c) => c.receivesQuoteNotifications),
            {
              vendorCode: getVendorCode(o),
            },
          )
        }
        testId="rfq-form-vendor-selector"
        getValue={(option) => option.sellerOrgLocation.id}
        disableCloseOnSelect
        includeCheckbox
        required
      />
    </OverlayPanel>
  );
};

export default RfqConfigVendorsDrawer;
