import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { InfoText } from "@/common/components/dialog/Dialog.styles";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceStatus } from "@/generated/graphql";
import { useCallback, useState } from "react";
import { Form, FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReceiptKickbacks } from "../../../receipts/hooks/useReceiptKickbacks";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { useReceiptUpdateRelease } from "../../providers/ReceiptUpdateReleaseProvider";
import { ReceiptFooterState } from "../../types/ReceiptFooterState";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { ReceiptKickbackForm } from "../receipt-kickback/ReceiptKickbackForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;
const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm`;
const SuccessTitle = tw.div`font-medium text-lg`;

export const ReceiptFooter = ({ isEditMode }: { isEditMode: boolean }) => {
  const { handleSubmit, reset, formState, trigger } =
    useFormContext<ReceiptCreateReleaseFormValues>();

  const intl = useIntl();
  const {
    receipt,
    itemized,
    hasReleaseRecorded,
    refetch,
    setFooterState,
    footerState,
  } = useReceipt();
  const { syncCreateReleaseFromReceipt, createRelease, saving } =
    useReceiptCreateRelease();
  const {
    syncUpdateReleaseFromReceipt,
    updateRelease,
    saving: updating,
    approving,
  } = useReceiptUpdateRelease();
  const { createReceiptKickback } = useReceiptKickbacks();
  const { setWarningAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const [visibleModal, setVisibleModal] = useState(false);

  const methods = useForm({
    defaultValues: {
      description: "",
      assigneeIds: [] as string[],
      assetUrls: [] as string[],
      issue: "",
      markAsApproved: true,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const markAsApproved = methods.watch("markAsApproved");

  const handleCreateReleaseFromReceipt = useCallback(async () => {
    await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
      if (itemized) {
        const sync = await syncCreateReleaseFromReceipt(values, {
          markAsApproved,
        });
        if (!sync) {
          return null;
        }
      } else {
        const release = await createRelease(values, { markAsApproved });
        if (!release) {
          return null;
        }
      }
      reset();
    })();
  }, [
    handleSubmit,
    itemized,
    reset,
    syncCreateReleaseFromReceipt,
    markAsApproved,
    createRelease,
  ]);

  const handleCreateRelease = useCallback(() => {
    if (!formState.isValid) {
      trigger();
      return;
    }
    if (!receipt?.number) {
      setWarningAlert(intl.$t({ id: "RECEIPT_MISSING_NUMBER" }));
      return;
    }
    setVisibleModal(true);
  }, [formState.isValid, intl, receipt?.number, setWarningAlert, trigger]);

  const handleUpdateReleaseFromReceipt = useCallback(
    async ({ markAsApproved }: { markAsApproved: boolean }) => {
      await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
        if (itemized) {
          const sync = await syncUpdateReleaseFromReceipt(values, {
            markAsApproved,
          });
          if (!sync) {
            return null;
          }
        } else {
          const release = await updateRelease(values, { markAsApproved });
          if (!release) {
            return null;
          }
        }
        reset();
      })();
    },
    [
      handleSubmit,
      itemized,
      reset,
      syncUpdateReleaseFromReceipt,
      updateRelease,
    ],
  );

  const kickback = useCallback(async () => {
    const { description, assigneeIds } = methods.getValues();

    const result = await createReceiptKickback({
      receiptId: receipt?.id ?? "",
      description,
      assigneeIds,
      assetUrls: methods.getValues().assetUrls,
    });
    if (result) {
      refetch();
      methods.reset();
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="KICKBACK_SENT_TO_TITLE"
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="KICKBACK_SENT_TO"
                  values={{
                    kickbackTo: result.kickbacks[
                      result.kickbacks.length - 1
                    ].assignees
                      .map(getUserName)
                      .join(", "),
                  }}
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(ReceiptFooterState.DEFAULT);
    }
  }, [
    methods,
    createReceiptKickback,
    receipt?.id,
    refetch,
    openDialog,
    setFooterState,
  ]);

  const assigneeIds = methods.watch("assigneeIds");

  // TODO remove this when we add unarchive feature - right now we hide the footer entirely if archived
  if (receipt?.archivedAt) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <FloatingFooterStyled>
          <If isTrue={footerState === ReceiptFooterState.KICKBACK}>
            <ReceiptKickbackForm
              onClose={() => setFooterState(ReceiptFooterState.DEFAULT)}
              receiptId={receipt?.id}
              releaseCreatedBy={receipt?.createdBy?.id}
              hideDetails
            />
          </If>
          <ButtonContainer>
            <If isTrue={footerState === ReceiptFooterState.DEFAULT}>
              <PageBackButton />
              <If isTrue={receipt?.status !== InvoiceStatus.Paid}>
                <OutlinedButton
                  className="py-0"
                  onClick={() => {
                    setFooterState(ReceiptFooterState.KICKBACK);
                  }}
                >
                  <FormattedMessage id="INVOICE_KICKBACK" />
                </OutlinedButton>
              </If>
              <If
                isTrue={
                  receipt?.status !== InvoiceStatus.Approved || isEditMode
                }
              >
                <If isTrue={hasReleaseRecorded}>
                  <OutlinedButton
                    className="py-0"
                    onClick={() =>
                      handleUpdateReleaseFromReceipt({
                        markAsApproved: false,
                      })
                    }
                    testId="receipt-save-order-footer-edit"
                    loading={updating}
                  >
                    <FormattedMessage id="SAVE" />
                  </OutlinedButton>
                </If>
                <PrimaryButton
                  className="py-0"
                  onClick={
                    !hasReleaseRecorded
                      ? handleCreateRelease
                      : () =>
                          handleUpdateReleaseFromReceipt({
                            markAsApproved: true,
                          })
                  }
                  loading={saving || approving}
                  testId="receipt-record-order-footer-submit"
                >
                  <FormattedMessage
                    id={
                      hasReleaseRecorded
                        ? receipt?.status === InvoiceStatus.AwaitingApproval
                          ? "APPROVE"
                          : "UPDATE_RELEASE"
                        : "RECORD_ORDER"
                    }
                  />
                </PrimaryButton>
              </If>
            </If>
            <If isTrue={footerState === ReceiptFooterState.KICKBACK}>
              <OutlinedButton
                onClick={() => setFooterState(ReceiptFooterState.DEFAULT)}
              >
                <FormattedMessage id="CANCEL" />
              </OutlinedButton>
              <PrimaryButton
                className="py-0"
                onClick={kickback}
                disabled={assigneeIds.length === 0}
              >
                <FormattedMessage id="INVOICE_KICKBACK" />
              </PrimaryButton>
            </If>
          </ButtonContainer>
        </FloatingFooterStyled>
        <Dialog
          icon={<WarningIcon />}
          title={intl.$t({ id: "SAVE_EXPENSE_AS_NEW_ORDER" })}
          confirmButtonText={intl.$t({
            id: "SAVE_ORDER",
          })}
          cancelButtonText={intl.$t({
            id: "CANCEL",
          })}
          handleCancel={() => setVisibleModal(false)}
          handleConfirm={async () => {
            await handleCreateReleaseFromReceipt();
            setVisibleModal(false);
          }}
          saving={saving}
          loading={false}
          show={visibleModal}
          content={
            <InfoText>
              <FormattedMessage id="APPROVE_THE_RECEIPT" />
              <SwitchControlled
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                name="markAsApproved"
              />
            </InfoText>
          }
        />
      </Form>
    </FormProvider>
  );
};
