import { If } from "@/common/components/if/If";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { DateView } from "@/common/utils/dates/DateView";
import {
  DistributorReleaseFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  release: Pick<
    DistributorReleaseFieldsFragment,
    "time" | "requestedTime" | "status" | "includeServices" | "type"
  >;
  classes?: {
    container?: string;
    date?: string;
    label?: string;
  };
};

const FulfillmentHeaderContainer = tw.div`flex flex-col font-medium`;
const Light = tw.div`font-light text-2xs md:text-xs`;
const Strong = tw.div`text-xs md:text-sm text-center`;
const DateValue = tw.span`ml-1`;

export const ReleaseDeliveryDate: FC<Props> = ({ release, classes }) => {
  const { orderTypeConfig } = useOrderTypesConfig({ release });

  return (
    <FulfillmentHeaderContainer className={classes?.container}>
      <If
        isTrue={
          release.time &&
          release.time !== release.requestedTime &&
          release.status === ReleaseStatus.Requested
        }
      >
        <Light className="text-center">
          {orderTypeConfig.labels.datePickerReadonlyLabel}
          {": "}
          <DateValue className={classes?.date}>
            <DateView date={release.requestedTime} />
          </DateValue>
        </Light>
        <Strong>
          <FormattedMessage tagName="span" id="RESCHEDULED_DATE" />
          {": "}
          <DateValue className={classes?.date}>
            <DateView date={release.time} />
          </DateValue>
        </Strong>
      </If>
      <If
        isTrue={
          !release.time ||
          release.time === release.requestedTime ||
          release.status !== ReleaseStatus.Requested
        }
      >
        <Strong className={classes?.label}>
          {orderTypeConfig.labels.datePickerReadonlyLabel}
          {": "}
          <DateValue className={classes?.date}>
            <DateView date={release.time ?? release.requestedTime} />
          </DateValue>
        </Strong>
      </If>
    </FulfillmentHeaderContainer>
  );
};
