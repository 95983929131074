import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  CreateOrgMaterialInput,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateMaterialsAndGetMap = () => {
  const { updateMaterials } = useMaterials();

  const updateMaterialsAndGetMap = useCallback(
    async (addedMaterials: CreateOrgMaterialInput[]) => {
      const newMaterials = await updateMaterials({ addedMaterials });
      if (!newMaterials) {
        return;
      }
      return getMap(addedMaterials, newMaterials);
    },
    [updateMaterials],
  );

  const getMap = (
    input: CreateOrgMaterialInput[],
    result: OrgMaterialFieldsFragment[],
  ) => {
    const map = new Map<string, string>();
    input.forEach((material, index) => {
      if (material.newOrgCatalogSKU?.name) {
        map.set(material.newOrgCatalogSKU?.name, result[index].material.id);
      }
    });
    return map;
  };

  return { updateMaterialsAndGetMap };
};
