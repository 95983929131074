import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { routes } from "@/config/routes";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useAddDeliverySlipStore } from "../../../../../delivery-slips/store/useAddDeliverySlipStore";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";
const Container = tw.div`
  grid gap-1 px-40 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-4 col-span-3 rounded-3xl 
`;

const HelperText = tw.div`
  text-sm w-full grid grid-flow-col gap-x-2 items-center font-normal place-self-center
text-gray-600 rounded-3xl my-6 
`;

type SubmitDialogProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSubmit: (
    skipVendorNotification: boolean,
    skipConfirmation: boolean,
    retroactive: boolean,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  onEdit?: () => Promise<ReleaseFieldsFragment | undefined | null>;
  retroactiveRelease: boolean;
};

export const SubmitOrderFromQuoteDialog: FC<SubmitDialogProps> = ({
  visible = true,
  setVisible,
  onSubmit,
  onEdit,
  retroactiveRelease,
}) => {
  const intl = useIntl();
  const [skipConfirmation, seSkipConfirmation] = useState(false);
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const [addDeliveryRecord, setAddDeliveryRecord] = useState(false);
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const { loading, vendors } = useOrderFromQuote();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { watch } = useFormContext<CreateOrderFromQuoteFormValues>();

  const selectedVendorId = watch("vendorId");
  const isSchedule = useMemo(() => {
    const selectedVendor = vendors.find(
      (v) => v.sellerOrgLocation.id === selectedVendorId,
    );
    return selectedVendor && selectedVendor.contacts.length === 0;
  }, [vendors, selectedVendorId]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = useCallback(async () => {
    const result = await onSubmit(
      skipVendorNotification,
      !!(skipConfirmation || isSchedule),
      retroactiveRelease,
    );
    if (result) {
      setVisible(false);
      openDialog({
        content: (
          <SuccessModal
            message={intl.$t({
              id: isSchedule
                ? "ORDER_SUCCESSFULLY_SCHEDULED"
                : "ORDER_SUCCESSFULLY_SUBMITTED",
            })}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setTimeout(() => {
        if (addDeliveryRecord) {
          setSlipRelease(result);
          setAddSlipVisible(true);
        } else {
          navigate(
            generatePath(routes.delivery, {
              deliveryId: result.id,
            }),
          );
        }
      }, DIALOG_AUTO_CLOSE_TIMER);
    }
  }, [
    onSubmit,
    isSchedule,
    skipConfirmation,
    skipVendorNotification,
    retroactiveRelease,
    setVisible,
    openDialog,
    intl,
    addDeliveryRecord,
    setSlipRelease,
    setAddSlipVisible,
    navigate,
  ]);

  const handleEdit = useCallback(async () => {
    if (onEdit) {
      const result = await onEdit();
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
        setVisible(false);
      }
    }
  }, [onEdit, navigate, setVisible]);

  return (
    <Dialog
      icon={<WarningIcon />}
      title={
        <FormattedMessage
          id={
            isSchedule
              ? "WOULD_YOU_LIKE_TO_SCHEDULE_ORDER"
              : retroactiveRelease
                ? "SAVE_THE_ORDER_QUESTION"
                : "SUBMIT_ORDER_CONFIRMATION"
          }
          values={{ br: <br /> }}
        />
      }
      saving={loading}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({
        id: isSchedule
          ? "SCHEDULE"
          : retroactiveRelease
            ? "SAVE_ORDER"
            : "SUBMIT",
      })}
      customButtonText={
        retroactiveRelease || isSchedule ? undefined : intl.$t({ id: "EDIT" })
      }
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleCustomButtonAction={
        retroactiveRelease || isSchedule ? undefined : handleEdit
      }
      loading={false}
      show={visible}
      content={
        <Container>
          <HelperText>
            <InfoOutlined className="h-5 w-5" />
            <FormattedMessage
              id={
                isSchedule
                  ? "SCHEDULE_ORDER_DESCRIPTION"
                  : retroactiveRelease
                    ? "SAVE_THE_ORDER_DESCRIPTION"
                    : "CREATE_ORDER_FROM_QUOTE_INFO_TEXT"
              }
            />
          </HelperText>
          <If isTrue={!isSchedule && !retroactiveRelease}>
            <InfoText>
              <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER" />
              <Switch
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                value={!skipVendorNotification}
                onChange={() =>
                  setSkipVendorNotification((prevValue) => !prevValue)
                }
              />
              <If isTrue={!skipVendorNotification}>
                <FormattedMessage id="ASK_VENDOR_CONFIRM_ORDER_DATE_AND_ITEMS" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={!skipConfirmation}
                  onChange={() => seSkipConfirmation(!skipConfirmation)}
                />
              </If>
            </InfoText>
          </If>
          <If isTrue={retroactiveRelease}>
            <InfoText>
              <FormattedMessage id="INCLUDE_DELIVERY_RECORD" />
              <Switch
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                value={addDeliveryRecord}
                onChange={() => setAddDeliveryRecord(!addDeliveryRecord)}
              />
            </InfoText>
          </If>
        </Container>
      }
    />
  );
};
