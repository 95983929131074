import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import {
  MultipleSelectionProps,
  SearchableList,
  SingleSelectionProps,
} from "@/common/components/searchable-list/SearchableList";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import {
  ExternalProjectFieldsFragment,
  ProjectFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Multiselect } from "../select/components/multiple/Multiselect";
import { useExternalProjectsTableConfiguration } from "./ExternalProjectsTable.configuration";
import {
  AgaveExternalProjectsProvider,
  useAgaveExternalProjectsProvider,
} from "./providers/AgaveExternalProjectsProvider";

type Props = {
  sourceSystem: SourceSystem | null;
  project?: Pick<ProjectFieldsFragment, "id" | "name"> | null;
  singleSelection?: SingleSelectionProps;
  multipleSelection?: Pick<
    MultipleSelectionProps,
    "selectedItemIds" | "type"
  > & {
    setSelectedItems: (items: ExternalProjectFieldsFragment[]) => void;
  };
  additionalFilters?: React.ReactNode;
  title: string;
  selectedProjects?: ExternalProjectFieldsFragment[];
  filterProjectsByStatus?: boolean;
};

interface ProjectStatusOption {
  id: string;
  label: string;
}

const ExternalProjectsTableWithProviders: FC<Props> = ({
  singleSelection,
  multipleSelection,
  project,
  title,
  additionalFilters,
  selectedProjects,
  filterProjectsByStatus = false,
  sourceSystem,
}) => {
  const [projectStatusOptions, setProjectStatusOptions] =
    useState<ProjectStatusOption[]>();
  const { externalProjects, loading, totalCount, filter, setFilter } =
    useAgaveExternalProjectsProvider();
  const configuration = useExternalProjectsTableConfiguration(
    singleSelection
      ? singleSelection.selectedItemId
        ? [singleSelection.selectedItemId]
        : []
      : (multipleSelection?.selectedItemIds ?? undefined),
    project?.id,
  );
  const intl = useIntl();
  const { getSourceSystemConfig } = useSourceSystemConfig();

  useEffect(() => {
    const { activeStatuses, inactiveStatuses } =
      getSourceSystemConfig(sourceSystem);

    if (!activeStatuses || !inactiveStatuses || !filterProjectsByStatus) {
      setProjectStatusOptions(undefined);
      setFilter({ search: "", status: undefined });
      return;
    }
    setProjectStatusOptions(
      [...activeStatuses, ...inactiveStatuses].map((status) => ({
        id: status,
        label: status,
      })),
    );
    setFilter({ search: "", status: activeStatuses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSystem, filterProjectsByStatus, getSourceSystemConfig]);

  const selectProject = useCallback(
    (id: string | null) => {
      const externalProject = externalProjects.find((p) => p.id === id);
      if (!externalProject?.project?.id && singleSelection) {
        singleSelection.setSelectedItemId(id);
      }
    },
    [externalProjects, singleSelection],
  );

  const selectProjects = useCallback(
    (ids: string[]) => {
      const itemsFromOtherPages =
        selectedProjects?.filter(
          (p) => !externalProjects.map((p) => p.id).includes(p.externalId),
        ) || [];
      const extProjects = externalProjects
        .filter((p) => ids.includes(p.id))
        .filter((p) => !p.project?.id)
        .concat(
          itemsFromOtherPages.map((p) => ({ ...p, id: p.externalId })) || [],
        );

      if (multipleSelection) {
        multipleSelection.setSelectedItems(extProjects);
      }
    },
    [externalProjects, multipleSelection, selectedProjects],
  );

  return (
    <SearchableList
      tableConfiguration={configuration}
      items={externalProjects}
      loadingItems={loading}
      totalCount={totalCount}
      searchBarTitle={title}
      itemSelection={
        singleSelection
          ? { ...singleSelection, setSelectedItemId: selectProject }
          : multipleSelection
            ? { ...multipleSelection, setSelectedItemIds: selectProjects }
            : undefined
      }
      headerAdditionalFilters={
        filterProjectsByStatus && projectStatusOptions ? (
          <Multiselect
            placeholder={intl.$t({ id: "STATUS" })}
            includeCheckbox
            options={projectStatusOptions}
            onMultipleChange={(values) =>
              setFilter({ ...(filter ?? {}), status: values })
            }
            values={filter?.status}
            getLabel={(option) => option.label}
            getValue={(option) => option.id.toString()}
            className="min-w-60"
            chipSize="small"
          />
        ) : null
      }
      additionalFilters={additionalFilters}
      search={{
        searchText: filter?.search ?? "",
        setSearchText: (search) => setFilter({ ...filter, search }),
      }}
    />
  );
};

export const ExternalProjectsTable: FC<Props> = (props) => {
  return (
    <PaginationProvider
      itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      useQueryString={false}
    >
      <AgaveExternalProjectsProvider sourceSystem={props.sourceSystem}>
        <ExternalProjectsTableWithProviders {...props} />
      </AgaveExternalProjectsProvider>
    </PaginationProvider>
  );
};
