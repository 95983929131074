import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { InvoiceStatus } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`relative`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceItemQuantityInput: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const [quantity, setQuantity] = useState<string>(
    item.invoiceItems?.[0]?.quantity || "0",
  );
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );
  const [invoiced, setInvoiced] = useState<string>(
    item.invoiceItems?.[0]?.quantity || "0",
  );

  const onSave = useCallback(
    async (quantity: string | null) => {
      if (quantity) {
        setInvoiced(quantity);
      }

      if (!invoicedItem) {
        return;
      }
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [
            {
              id: invoicedItem.id,
              quantity: quantity ? quantity.toString() : undefined,
              quantitySoFar: invoicedItem.quantitySoFar || "0",
              releaseItemId: item.id,
            },
          ],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [invoicedItem, updateInvoice, invoice?.id, invoice?.release?.id, item.id],
  );

  const invoiceIsProcessed = useMemo(
    () =>
      invoice?.status === InvoiceStatus.Approved ||
      invoice?.status === InvoiceStatus.Paid,
    [invoice?.status],
  );

  const invoicedSoFar = useMemo(() => {
    return new Decimal(invoicedItem?.quantitySoFar || "0").plus(
      invoiceIsProcessed ? invoicedItem?.quantity || "0" : 0,
    );
  }, [invoiceIsProcessed, invoicedItem?.quantity, invoicedItem?.quantitySoFar]);

  const inputClassName = useMemo(() => {
    const quantity = new Decimal(item.quantityDecimal);
    if (
      quantity.lessThan(invoicedSoFar) ||
      new Decimal(invoiced)
        .plus(invoicedItem?.quantitySoFar ?? 0)
        .greaterThan(item.quantityDecimal)
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [
    invoicedSoFar,
    invoiced,
    invoicedItem?.quantitySoFar,
    item.quantityDecimal,
  ]);

  const isFullyInvoiced = useMemo(() => {
    return (
      Number(invoiced) > 0 &&
      new Decimal(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .equals(Number(invoiced))
    );
  }, [invoiced, invoicedItem?.quantitySoFar, item.quantityDecimal]);

  return (
    <NotNullableRenderer value={!isLumpSumItem(item)}>
      <>
        <If
          isTrue={
            matchedOrderViewState ===
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
            (item.invoiceItems || []).length > 0
          }
        >
          <Container>
            <QuantityPicker
              key={item.id}
              id="invoice-item-quantity-picker"
              quantity={quantity}
              changeQuantity={setQuantity}
              helperText={
                <FormattedMessage
                  id="QUANTITY_SO_FAR"
                  values={{ value: invoicedItem?.quantitySoFar }}
                />
              }
              saveQuantity={onSave}
              className={inputClassName}
              hideErrorIcon
            />
            {isFullyInvoiced && <CheckIcon />}
          </Container>
        </If>
        <If
          isTrue={
            matchedOrderViewState !==
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
            (!readonly && item.invoicedQuantity)
          }
        >
          <NotNullableRenderer
            value={
              !isNaN(Number(item.invoicedQuantity)) &&
              item.invoiceItems.length > 0
            }
          >
            <ReadonlyField>
              <QuantityPicker
                id="invoiced-so-far"
                staticText
                quantity={item.invoiceItems?.[0]?.quantity}
              />
              <FormattedMessage
                id="QUANTITY_SO_FAR"
                tagName={InvoiceSoFarLabel}
                values={{
                  value: new Decimal(
                    item.invoiceItems?.[0]?.quantitySoFar || "0",
                  )
                    .plus(item.invoiceItems?.[0]?.quantity || "0")
                    .toString(),
                }}
              />
            </ReadonlyField>
          </NotNullableRenderer>
        </If>
      </>
    </NotNullableRenderer>
  );
};
