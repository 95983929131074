import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { COLUMN_TYPE } from "@/common/providers/ColumnMapperProvider";
import {
  TOOLTIP_EVENT_NAME,
  TooltipEvent,
} from "@/common/providers/TooltipProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { Identity } from "@/types/Identity";
import Decimal from "decimal.js";
import Core from "handsontable/core";
import { CellProperties } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useTableHelpers } from "../hooks/useTableHelpers";

const readOnlyCellClasses = "bg-gray-100";
const readOnlyCellHideTextClasses = "bg-gray-100 text-gray-100";

export const useRenderHelpers = () => {
  const { getPhysicalColumnIndex } = useTableHelpers();
  const { formatCurrency } = useFormatNumberToCurrency();
  const intl = useIntl();

  const isNotEmpty = useCallback((value: string) => {
    return value !== "" && value !== null && value !== undefined;
  }, []);

  const applyClasses = useCallback(
    (td: HTMLTableCellElement, classes: string) => {
      td.className = td.className.includes(classes)
        ? td.className
        : `${td.className} ${classes}`;
    },
    [],
  );

  const applyTooltip = useCallback((element: HTMLElement, value: string) => {
    element.onmouseover = (event: Event) => {
      window.dispatchEvent(
        new CustomEvent(TOOLTIP_EVENT_NAME, {
          detail: {
            content: value,
            event,
          },
        } as TooltipEvent),
      );
    };

    element.onmouseout = (event: Event) => {
      window.dispatchEvent(
        new CustomEvent(TOOLTIP_EVENT_NAME, {
          detail: {
            content: "",
            event,
          },
        } as TooltipEvent),
      );
    };
  }, []);

  const hideLumpSumItemReadOnlyText = useCallback(
    (
      td: HTMLTableCellElement,
      row: number,
      instance: Core,
      cellProperties: CellProperties,
    ) => {
      const rows = instance?.getData();
      const uomContent =
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      if (cellProperties.disabledForLumpSum) {
        if (isLumpSumUomText(uomContent)) {
          applyClasses(td, readOnlyCellHideTextClasses);
          applyTooltip(td, intl.$t({ id: "LUMP_SUM_ITEM" }));
          return true;
        }
        applyClasses(td, "");
        applyTooltip(td, "");
      }
      return false;
    },
    [applyClasses, applyTooltip, getPhysicalColumnIndex, intl],
  );

  const checkReadOnly = useCallback(
    (
      td: HTMLTableCellElement,
      row: number,
      instance: Core,
      cellProperties: CellProperties,
    ) => {
      hideLumpSumItemReadOnlyText(td, row, instance, cellProperties);
      if (!cellProperties.readOnlyFn) {
        return;
      }
      const physicalRow = instance?.toPhysicalRow(row) || row;
      const sourceRow = instance.getSourceDataAtRow(physicalRow) as Identity;
      const reason = cellProperties.readOnlyFn?.(sourceRow.id);

      td.className = td.className.includes(readOnlyCellClasses)
        ? td.className
        : `${td.className} ${reason ? readOnlyCellClasses : ""}`;

      if (reason) {
        applyTooltip(td, reason);
      }
    },
    [applyTooltip, hideLumpSumItemReadOnlyText],
  );

  const formatPrice = useCallback(
    (row: number, instance: Core, value: string) => {
      const rows = instance?.getData();

      const uom = rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)];
      const quantity =
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Quantity)];

      if (isLumpSumUomText(uom)) {
        return formatCurrency(
          new Decimal(isNotEmpty(quantity) ? quantity : 1).toNumber(),
          {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
          },
        );
      } else {
        return isNotEmpty(value)
          ? formatCurrency(value, {
              maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
            })
          : "";
      }
    },
    [getPhysicalColumnIndex, formatCurrency, isNotEmpty],
  );

  return {
    isNotEmpty,
    checkReadOnly,
    applyTooltip,
    applyClasses,
    formatPrice,
  };
};
