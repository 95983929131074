import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { UomFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { ReleaseReportItem } from "../components/report-item-orders-modal/ReportModalReleaseItem";

type SpendingReportValue = {
  uom: UomFieldsFragment | null | undefined;
  orderedQuantity: number;
  orderedTotal: number;
  invoicedQuantity: number;
  invoicedTotal: number;
};

export const useSpendingReportValuesGroupedByUom = (
  item: ReleaseReportItem,
): SpendingReportValue[] => {
  const { calcExtPrice } = usePriceCalculation();
  const { uoms } = useStartupDataStore();

  return useMemo(
    () =>
      item.releaseItems?.reduce((acc: SpendingReportValue[], releaseItem) => {
        const existingItemIndex = acc.findIndex(
          (item) => item.uom?.id === releaseItem.uom?.id,
        );
        if (existingItemIndex !== -1) {
          acc[existingItemIndex] = {
            ...acc[existingItemIndex],
            orderedQuantity: new Decimal(
              acc[existingItemIndex].orderedQuantity ?? 0,
            )
              .plus(releaseItem.quantityDecimal || 0)
              .toNumber(),
            orderedTotal: new Decimal(acc[existingItemIndex].orderedTotal)
              .plus(
                calcExtPrice(
                  releaseItem.quantityDecimal || 0,
                  releaseItem.unitPrice || 0,
                ),
              )
              .toNumber(),
            invoicedQuantity: new Decimal(
              acc[existingItemIndex].invoicedQuantity,
            )
              .plus(releaseItem.invoicedQuantity || 0)
              .toNumber(),
            invoicedTotal: new Decimal(acc[existingItemIndex].invoicedTotal)
              .plus(
                calcExtPrice(
                  releaseItem.invoicedQuantity || 0,
                  releaseItem.unitPrice || 0,
                ),
              )
              .toNumber(),
          };
        } else {
          acc.push({
            uom: uoms.find((uom) => uom.id === releaseItem.uom?.id),
            orderedQuantity: new Decimal(
              releaseItem.quantityDecimal || 0,
            ).toNumber(),
            orderedTotal: new Decimal(
              calcExtPrice(
                releaseItem.quantityDecimal || 0,
                releaseItem.unitPrice || 0,
              ),
            ).toNumber(),
            invoicedQuantity: new Decimal(
              releaseItem.invoicedQuantity || 0,
            ).toNumber(),
            invoicedTotal: new Decimal(
              calcExtPrice(
                releaseItem.invoicedQuantity || 0,
                releaseItem.unitPrice || 0,
              ),
            ).toNumber(),
          });
        }
        return acc;
      }, []),
    [item.releaseItems, uoms, calcExtPrice],
  );
};
