import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

export const useGridLoadingInfo = () => {
  const { loading: loadingStartupData } = useStartupDataStore();
  const { loading: loadingPrices } = useVendorPrices();
  const { loading: loadingVendors } = useVendors();
  const { loading: loadingMaterials } = useMaterials();
  const { loading: loadingProject } = useProjectStore(
    useShallow((state) => ({
      loading: state.loading,
    })),
  );
  const { config } = useColumnMapper();

  const loadingData = useMemo(() => {
    let loading = loadingStartupData;
    config.forEach((col) => {
      switch (col.columnType) {
        case COLUMN_TYPE.Material:
          loading = loading || loadingMaterials;
          break;
        case COLUMN_TYPE.PrefilledPrice:
          loading = loading || loadingPrices;
          break;
        case COLUMN_TYPE.Vendor:
          loading = loading || loadingVendors;
          break;
        case COLUMN_TYPE.Zone:
        case COLUMN_TYPE.Tag:
        case COLUMN_TYPE.CostCode:
        case COLUMN_TYPE.PhaseCode:
          loading = loading || loadingProject;
          break;
      }
    });
    return loading;
  }, [
    config,
    loadingPrices,
    loadingMaterials,
    loadingVendors,
    loadingStartupData,
    loadingProject,
  ]);

  return {
    loadingData,
  };
};
