import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { APPROVALS_NUMBER } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUserRoles } from "@/common/utils/hooks/useUserRoles";
import { OrgLocationRole } from "@/generated/graphql";
import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { useUpdateOrgSettings } from "../../../org-settings/hooks/useUpdateOrgSettings";

type StyleProps = {
  $disabled?: boolean;
};

const Container = tw.div`grid w-full gap-8 items-star`;
const TopSettings = tw.div`grid w-full pt-10 pl-18 items-start content-start`;
const LineItem = tw.div<StyleProps>`flex flex-row items-center w-fit gap-5 min-h-12
  ${(props) => (props.$disabled ? "pointer-events-none opacity-50" : "")}
`;
const HeaderLabel = tw.div`font-medium`;
const OptionLabel = tw.div``;

const Group = tw.div`grid grid-cols-2`;
const List = tw.div`grid grid-cols-2 w-full bg-gray-100 py-10 pl-18`;
const GrayList = tw.div`grid grid-cols-2 w-full bg-gray-200 py-10 pr-18`;
const HeaderItem = tw.div<StyleProps>`w-full grid justify-center text-left pb-4 mb-4 font-medium border-b border-b-black
  ${(props) => (props.$disabled ? "opacity-50" : "")}
`;
const RowItem = tw.div<StyleProps>`w-full grid justify-center text-left border-b border-b-gray-300 py-3`;

const ButtonContainer = tw.div`w-full grid grid-flow-col justify-end gap-4 col-span-full`;
const Item = tw.div`flex flex-row gap-5 items-center`;
const Delimiter = tw.div`border border-gray-300 border-dashed h-6`;
const ApprovalsContainer = tw.div`flex flex-row gap-2`;
const NumberCircle = tw.div<{
  $selected: boolean;
}>`text-xs w-6 h-6 rounded-full flex items-center justify-center border cursor-pointer
${({ $selected }) =>
  $selected ? "text-black border-black border-2" : "text-black border-blue-500"}
`;
const Approvals = tw.span`lowercase`;

type OrgLocationRoleType = {
  [key in OrgLocationRole]: boolean;
};

type FormType = {
  enabled: boolean;
  standaloneOnly: boolean;
  requireApproval: OrgLocationRoleType;
  requiredApprovals: number;
  canApprove: OrgLocationRoleType;
  requiredApproverRoles: OrgLocationRole[];
  canSubmit: OrgLocationRoleType;
};

const SETTINGS = [
  OrgLocationRole.LocationOwner,
  OrgLocationRole.LocationAccountant,
  OrgLocationRole.LocationAdmin,
  OrgLocationRole.LocationEstimator,
  OrgLocationRole.LocationForeman,
  OrgLocationRole.LocationProjectManager,
  OrgLocationRole.LocationPurchasingAgent,
  OrgLocationRole.LocationSuperintendent,
  OrgLocationRole.LocationProjectEngineer,
];

export const OrderApproval: FC = () => {
  const intl = useIntl();
  const { orgId, settings } = useOrgSettings();
  const { updateOrgSettings, updating } = useUpdateOrgSettings();
  const { setSuccessAlert } = useSnackbar();
  const { userRoles } = useUserRoles();

  const methods = useForm<FormType>({
    defaultValues: {
      enabled: false,
      canApprove: {},
      standaloneOnly: false,
      requiredApprovals: 1,
      requiredApproverRoles:
        settings?.releaseApproval.requiredApproverRoles || [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const resetForm = useCallback(() => {
    methods.reset({
      enabled: settings?.releaseApproval.enabled || false,
      standaloneOnly: settings?.releaseApproval.standaloneOnly || false,
      requireApproval: Object.keys(OrgLocationRole).reduce(
        (acc: OrgLocationRoleType, key: string) => {
          const roleKey = key as keyof typeof OrgLocationRole;
          acc[OrgLocationRole[roleKey]] =
            !settings?.releaseApproval.exemptRoles.includes(
              OrgLocationRole[roleKey],
            ) || false;
          return acc;
        },
        {} as OrgLocationRoleType,
      ),
      canApprove: Object.keys(OrgLocationRole).reduce(
        (acc: OrgLocationRoleType, key: string) => {
          const roleKey = key as keyof typeof OrgLocationRole;
          acc[OrgLocationRole[roleKey]] =
            settings?.releaseApproval.approverRoles.includes(
              OrgLocationRole[roleKey],
            ) || false;
          return acc;
        },
        {} as OrgLocationRoleType,
      ),
      requiredApprovals: settings?.releaseApproval.requiredApprovals || 1,
      requiredApproverRoles:
        settings?.releaseApproval.requiredApproverRoles || [],
      canSubmit: Object.keys(OrgLocationRole).reduce(
        (acc: OrgLocationRoleType, key: string) => {
          const roleKey = key as keyof typeof OrgLocationRole;
          acc[OrgLocationRole[roleKey]] =
            settings?.releaseApproval.submitterRoles.includes(
              OrgLocationRole[roleKey],
            ) || false;
          return acc;
        },
        {} as OrgLocationRoleType,
      ),
    });
  }, [methods, settings]);

  useEffect(() => {
    if (settings) {
      resetForm();
    }
  }, [settings, resetForm]);

  const enabled = methods.watch("enabled");
  const requiredApprovals = methods.watch("requiredApprovals");
  const canApprove = methods.watch("canApprove");
  const requiredApproverRoles = methods.watch("requiredApproverRoles");

  useEffect(() => {
    if (requiredApprovals) {
      methods.trigger("requiredApproverRoles");
    }
  }, [methods, requiredApprovals]);

  const filteredRequiredApproverRoles = requiredApproverRoles.filter(
    (r) => canApprove[r],
  );

  const onSubmit = async (data: FormType) => {
    const exemptRoles = Object.keys(data.requireApproval).filter(
      (role) => !data.requireApproval[role as OrgLocationRole],
    ) as OrgLocationRole[];
    const approverRoles = Object.keys(data.canApprove).filter(
      (role) => data.canApprove[role as OrgLocationRole],
    ) as OrgLocationRole[];
    const submitterRoles = Object.keys(data.canSubmit).filter(
      (role) => data.canSubmit[role as OrgLocationRole],
    ) as OrgLocationRole[];

    const result = await updateOrgSettings({
      id: orgId,
      releaseApproval: {
        enabled: data.enabled,
        standaloneOnly: data.standaloneOnly,
        exemptRoles,
        approverRoles,
        submitterRoles,
        requiredApprovals: data.requiredApprovals,
        requiredApproverRoles:
          data.requiredApprovals > 1 ? filteredRequiredApproverRoles : [],
      },
    });

    if (result) {
      setSuccessAlert(intl.$t({ id: "SETTINGS_SUCCESSFULLY_UPDATED" }));
      methods.reset(data, { keepValues: true });
    }
  };

  const { isDirty } = useFormState({ control: methods.control });

  const filteredRoles = useMemo(
    () => (canApprove ? userRoles.filter((u) => canApprove[u.value]) : []),
    [canApprove, userRoles],
  );

  const isValid = useCallback(() => {
    return filteredRequiredApproverRoles.length <= requiredApprovals
      ? true
      : "";
  }, [filteredRequiredApproverRoles.length, requiredApprovals]);

  return (
    <FormProvider {...methods}>
      <Container>
        <TopSettings>
          <LineItem>
            <Item>
              <SwitchControlled
                name="enabled"
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
              <FormattedMessage
                id="ENABLE_ORDER_APPROVAL"
                tagName={HeaderLabel}
              />
              <If isTrue={enabled}>
                <Delimiter />
                <ApprovalsContainer>
                  <FormattedMessage id="REQUIRE" />
                  {Array.from(Array(APPROVALS_NUMBER).keys()).map((no, key) => {
                    return (
                      <Controller
                        key={key}
                        name="requiredApprovals"
                        render={({ field: { onChange, value } }) => (
                          <NumberCircle
                            $selected={no === value - 1}
                            key={key}
                            onClick={() => {
                              onChange(no + 1);
                            }}
                          >
                            {no + 1}
                          </NumberCircle>
                        )}
                      />
                    );
                  })}
                  <FormattedMessage id="APPROVALS" tagName={Approvals} />
                </ApprovalsContainer>
                <If isTrue={requiredApprovals > 1}>
                  <Delimiter />
                  <FormattedMessage id="INCLUDING_AT_LEAST_ONE" />
                  <InfoTooltip
                    message={intl.$t({ id: "INCLUDING_AT_LEAST_ONE_TOOLTIP" })}
                  />
                  <MultiselectControlled
                    placeholder={intl.$t({ id: "SPECIFY_ROLES" })}
                    label={intl.$t({ id: "NO_REQUIREMENTS" })}
                    options={filteredRoles}
                    name="requiredApproverRoles"
                    getLabel={(option) => option.label}
                    getValue={(option) => option.value}
                    getOptionDisabled={() =>
                      requiredApproverRoles.length >= requiredApprovals
                    }
                    chipSize="small"
                    rules={{ validate: isValid }}
                  />
                </If>
              </If>
            </Item>
          </LineItem>
          <LineItem $disabled={!enabled}>
            <SwitchControlled
              name="standaloneOnly"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
            <FormattedMessage
              id="ALLOW_BUYOUT_ORDERS_WITHOUT_NEEDING_APPROVALS"
              tagName={OptionLabel}
            />
          </LineItem>
        </TopSettings>
        <Group>
          <List>
            <HeaderItem className="justify-start">
              <FormattedMessage id="ROLE" />
            </HeaderItem>
            <HeaderItem>
              <FormattedMessage id="CAN_SUBMIT_ORDERS" />
            </HeaderItem>
            {SETTINGS.map((setting, index) => (
              <Fragment key={index}>
                <RowItem className="justify-start">
                  <FormattedMessage
                    id={`USER_ROLE_${setting}`}
                    tagName={OptionLabel}
                  />
                </RowItem>
                <RowItem>
                  <SwitchControlled
                    name={`canSubmit.${setting}`}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                  />
                </RowItem>
              </Fragment>
            ))}
          </List>
          <GrayList>
            <HeaderItem $disabled={!enabled}>
              <FormattedMessage id="REQUIRES_ORDER_APPROVAL" />
            </HeaderItem>
            <HeaderItem $disabled={!enabled}>
              <FormattedMessage id="CAN_APPROVE_ORDERS" />
            </HeaderItem>
            {SETTINGS.map((setting, index) => (
              <Fragment key={index}>
                <RowItem $disabled={!enabled}>
                  <SwitchControlled
                    name={`requireApproval.${setting}`}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={!enabled}
                  />
                </RowItem>
                <RowItem $disabled={!enabled}>
                  <SwitchControlled
                    name={`canApprove.${setting}`}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={!enabled}
                  />
                </RowItem>
              </Fragment>
            ))}
          </GrayList>
          <List />
          <GrayList />
          <If isTrue={isDirty}>
            <FloatingFooter>
              <ButtonContainer>
                <OutlinedButton wide onClick={resetForm}>
                  <FormattedMessage id="CANCEL" />
                </OutlinedButton>
                <PrimaryButton
                  wide
                  loading={updating}
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={!methods.formState.isValid || updating}
                >
                  <FormattedMessage id="SAVE" />
                </PrimaryButton>
              </ButtonContainer>
            </FloatingFooter>
          </If>
        </Group>
      </Container>
    </FormProvider>
  );
};
