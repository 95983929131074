import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import {
  AddToBuyoutItemInput,
  ManufacturerFieldsFragment,
  UpdateContractorBuyoutItemInput,
} from "@/generated/graphql";
import { useContractorBuyout } from "../../../../providers/ContractorBuyoutProvider";

export const useSpreadsheetBuyoutItems = () => {
  const { buyout } = useContractorBuyout();
  const { spreadsheetData, getRemovedRowIds, rowHasChanges } =
    useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    rowIsEmpty,
    getRowTagIds,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    addMissingTags,
    getCostCodeId,
    getNameParts,
    tableHasColumn,
  } = useTableHelpers();
  const { materials } = useMaterials();
  const { manufacturers } = useManufacturers();

  const addUpdatedItemOptionalFields = (
    item: UpdateContractorBuyoutItemInput,
    row: Record<string, string>,
    newManufacturers: ManufacturerFieldsFragment[],
  ): UpdateContractorBuyoutItemInput => {
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      item.instructions = {
        text: notes,
      };
    }
    if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
      item.manufacturerId = manufacturer?.id;
      item.clearManufacturer = !manufacturer;
    }
    if (
      tableHasColumn(COLUMN_TYPE.Tag) ||
      tableHasColumn(COLUMN_TYPE.PhaseCode)
    ) {
      item.tags = getRowTagIds(row);
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      item.costCodeId = getCostCodeId(row);
      item.clearCostCode = !getCostCodeId(row);
    }
    return item;
  };

  const addNewItemOptionalFields = (
    newItem: AddToBuyoutItemInput,
    row: Record<string, string>,
    newManufacturers: ManufacturerFieldsFragment[],
  ): AddToBuyoutItemInput => {
    const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
      (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
    );
    const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";
    if (tableHasColumn(COLUMN_TYPE.Notes)) {
      newItem.instructions = {
        text: notes,
      };
    }

    if (tableHasColumn(COLUMN_TYPE.Manufacturer)) {
      newItem.manufacturerId = manufacturer?.id;
    }
    if (
      tableHasColumn(COLUMN_TYPE.Tag) ||
      tableHasColumn(COLUMN_TYPE.PhaseCode)
    ) {
      newItem.tags = getRowTagIds(row);
    }
    if (tableHasColumn(COLUMN_TYPE.CostCode)) {
      newItem.costCodeId = getCostCodeId(row);
    }
    return newItem;
  };

  return async () => {
    const newItems: AddToBuyoutItemInput[] = [];
    const itemsToUpdate: UpdateContractorBuyoutItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(buyout?.items ?? []);

    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    await addMissingTags(buyout?.project?.id);
    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      let quantityDecimal =
        getCellValue(row, COLUMN_TYPE.PositiveQuantity) || "0";
      let unitPrice = tableHasColumn(COLUMN_TYPE.PrefilledPrice)
        ? getCellValue(row, COLUMN_TYPE.PrefilledPrice)
        : getCellValue(row, COLUMN_TYPE.UnitPrice);

      if (isLumpSum && Number(unitPrice) > 1 && Number(quantityDecimal) === 1) {
        quantityDecimal = unitPrice;
        unitPrice = "1";
      }

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;

      const existingItem = buyout?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem.material.id === material.id;

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          const item = {
            ...(isLumpSum && { description: rowMaterialText }),
            buyoutItemId: row.id,
            position: index,
            uom,
            requestedUnitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            quantityDecimal,
            tags: getRowTagIds(row),
          } as UpdateContractorBuyoutItemInput;
          itemsToUpdate.push(
            addUpdatedItemOptionalFields(item, row, newManufacturers),
          );
        }
      } else {
        const newItem: AddToBuyoutItemInput = {
          description: getNameParts(rowMaterialText).namePart,
          projectItem: {
            estimateUom: uom,
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          position: index,
          requestedUnitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && {
            description: getNameParts(rowMaterialText).namePart,
          }),
          quantityDecimal,
        };
        newItems.push(addNewItemOptionalFields(newItem, row, newManufacturers));
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    return {
      buyoutId: buyout?.id ?? "",
      version: buyout?.version ?? 0,
      updates: itemsToUpdate,
      addedItems: newItems,
      removedItems: itemsToRemove,
    };
  };
};
