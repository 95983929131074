import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "./BuyoutGroupedProvider";
import { useSpreadsheetBuyoutItems } from "./hooks/useSpreadsheetBuyoutItems";

type ProviderContextType = {
  syncBuyoutItems: () => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncBuyoutItems: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncBuyoutItemsProvider: FC<Props> = ({ children }) => {
  const { resetPreviousData, gotoInvalidRow } = useColumnMapper();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { buyout } = useContractorBuyout();
  const viewState = useTableViewStore((state) => state.viewState);
  const { updateBuyoutItems, loading: updating } = useBuyoutGrouped();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const getSpreadsheetBuyoutItems = useSpreadsheetBuyoutItems();
  const { hasPhaseCodes } = useOrgSettings();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const syncBuyoutItems = async () => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.PrefilledPrice,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    const { updates, addedItems, removedItems } =
      await getSpreadsheetBuyoutItems();

    if (
      updates.length > 0 ||
      addedItems.length > 0 ||
      removedItems.length > 0
    ) {
      const result = await updateBuyoutItems({
        buyoutId: buyout?.id || "",
        version: buyout?.version || 0,
        updates,
        addedItems,
        removedItems,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "BUYOUT_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }
    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncBuyoutItems,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncBuyoutItems = (): ProviderContextType =>
  useContext(ProviderContext);
