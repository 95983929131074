import { isOrgCatalogSku } from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  CreateOrgMaterialInput,
  UpdateOrgMaterialInput,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterials } from "../hooks/useMaterials";

type ProviderContextType = {
  syncMaterials: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncMaterials: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncMaterialsProvider: FC<Props> = ({ children }) => {
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    rowIsEmpty,
    addMissingManufacturers,
    getCellWithAdditionalData,
    confirmRemove,
    getCostCodeId,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { manufacturers } = useManufacturers();
  const { materials, updateMaterials, updating } = useMaterials();
  const viewState = useTableViewStore((state) => state.viewState);
  const [saving, setSaving] = useState(false);
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const syncMaterials = async (trigger?: SpreadsheetSaveType) => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    if (
      !validateRequiredValues([COLUMN_TYPE.MaterialName, COLUMN_TYPE.UOM]) ||
      !validateRowValues([
        COLUMN_TYPE.MaterialName,
        COLUMN_TYPE.CustomPartNumber,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    let newItems: CreateOrgMaterialInput[] = [];
    let itemsToUpdate: UpdateOrgMaterialInput[] = [];
    const itemsToRemove = getRemovedRowIds(materials ?? []);

    setSaving(true);
    const newManufacturers = (await addMissingManufacturers()) || [];
    setSaving(false);

    spreadsheetData.forEach((row) => {
      if (rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.MaterialName,
      );

      const uom = getRowUomCreatableValue(row);
      const externalCode = getCellValue(row, COLUMN_TYPE.ExternalCode);
      const customPartNumber = getCellValue(row, COLUMN_TYPE.CustomPartNumber);
      const manufacturerText = getCellValue(row, COLUMN_TYPE.Manufacturer);
      const manufacturer = [...manufacturers, ...newManufacturers].find(
        (m) => m?.name === manufacturerText,
      );

      const existingItem = materials.find((item) => item.id === row.id);
      const matchingMaterial =
        existingItem &&
        existingItem.material.name === rowMaterialText &&
        existingItem.id === row.id;

      if (matchingMaterial) {
        if (rowHasChanges(row)) {
          itemsToUpdate.push({
            orgMaterialId: row.id,
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
            externalCode,
            clearExternalCode: !externalCode,
            ...(isOrgCatalogSku(existingItem?.material) && {
              orgCatalogSku: {
                id: existingItem?.material.id ?? "",
                name: rowMaterialText,
                defaultUom: uom ?? undefined,
                clearDefaultManufacturer: !manufacturer,
                defaultManufacturerId: manufacturer?.id ?? undefined,
                customPartNumber: customPartNumber || undefined,
              },
            }),
          });
        }
      } else {
        newItems.push({
          newOrgCatalogSKU: {
            name: rowMaterialText,
            defaultUom: uom,
            defaultManufacturerId: manufacturer?.id ?? undefined,
            customPartNumber: customPartNumber || undefined,
          },
          externalCode,
          costCodeId: getCostCodeId(row),
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    newItems = newItems.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.newOrgCatalogSKU?.defaultUom ===
              item.newOrgCatalogSKU?.defaultUom &&
            t.newOrgCatalogSKU?.name === item.newOrgCatalogSKU?.name &&
            t.newOrgCatalogSKU?.customPartNumber ===
              item.newOrgCatalogSKU?.customPartNumber,
        ),
    );

    itemsToUpdate = itemsToUpdate.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.orgMaterialId === item.orgMaterialId),
    );

    const canRemoveItems = await confirmRemove(newItems, itemsToRemove);
    if (!canRemoveItems) {
      return false;
    }

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      const result = await updateMaterials({
        updates: itemsToUpdate,
        addedMaterials: newItems,
        removedMaterials: itemsToRemove,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "MATERIALS_SAVED_SUCCESS" }));
      }
      return !!result;
    } else if (trigger === SpreadsheetSaveType.SaveButton) {
      setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
    }

    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncMaterials,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncMaterials = (): ProviderContextType =>
  useContext(ProviderContext);
