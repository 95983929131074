import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { routes } from "@/config/routes";
import {
  ReleaseReassignmentFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../providers/ReleaseProvider";

const Container = tw.div`
  grid gap-1 px-2 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-cols-auto-auto gap-x-2 gap-y-2 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-3 col-span-3 rounded-3xl text-center
`;

const AdditionalInfo = tw.div`text-xs col-span-3 text-center leading-tight`;

type UpdateDialogProps = {
  visible: boolean;
  callback?: ({
    skip,
    receive,
    version,
    callback,
    skipVendorNotification,
    schedule,
  }: {
    skip?: boolean;
    receive?: boolean;
    version?: number;
    callback?: (result: boolean) => void;
    skipVendorNotification?: boolean;
    schedule?: boolean;
  }) => Promise<boolean | ReleaseReassignmentFieldsFragment[]> | void | boolean;
  setVisible: (visible: boolean) => void;
  status: ReleaseStatus;
  titleKey?: string;
};

export const UpdateReleaseDialog: FC<UpdateDialogProps> = ({
  visible = true,
  callback,
  setVisible,
  status,
  titleKey = "DELIVERY_SUBMIT_TITLE_REQUESTED",
}) => {
  const intl = useIntl();
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const { release } = useRelease();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
      setSaving(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  const displayDialog = useCallback(
    (result: boolean | ReleaseReassignmentFieldsFragment[]) => {
      if (result && release?.id) {
        openDialog({
          content: (
            <SuccessModal
              message={intl.$t({ id: "ORDER_SUCCESSFULLY_UPDATED" })}
            />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigate(
            generatePath(routes.delivery, {
              deliveryId: release.id,
            }),
          );
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
      setSaving(false);
      setVisible(false);
    },
    [intl, navigate, openDialog, release?.id, setVisible],
  );

  const handleConfirm = async () => {
    if (!callback) {
      setVisible(false);
      return;
    }

    setSaving(true);

    const result = await callback({
      skip: true,
      callback: (result) => displayDialog(result),
      skipVendorNotification:
        status !== ReleaseStatus.Received ? skipVendorNotification : true,
    });

    if (typeof result === "boolean" || result) {
      displayDialog(result);
    }
  };

  return (
    <Dialog
      icon={<WarningIcon />}
      title={<FormattedMessage id={titleKey} values={{ br: <br /> }} />}
      saving={saving}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({
        id: "UPDATE_RELEASE",
      })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      buttonsConfiguration={{
        confirm: {
          wide: true,
        },
        cancel: {
          wide: true,
        },
      }}
      loading={false}
      show={visible}
      extraButtonMargin={status !== ReleaseStatus.Received}
      content={
        <Container
          className={status !== ReleaseStatus.Received ? "mt-5" : "mb-3 mt-0"}
        >
          {status !== ReleaseStatus.Received ? (
            <>
              <InfoText>
                <FormattedMessage id="NOTIFY_VENDOR_ABOUT_ORDER_CHANGES" />
                <Switch
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  value={!skipVendorNotification}
                  onChange={() =>
                    setSkipVendorNotification((prevValue) => !prevValue)
                  }
                />
              </InfoText>
              <If isTrue={!skipVendorNotification}>
                <AdditionalInfo className="mt-1">
                  <FormattedMessage id="VENDOR_WILL_BE_NOTIFIED_WO_CONFIRMATION" />
                </AdditionalInfo>
              </If>
            </>
          ) : (
            <AdditionalInfo className="text-sm">
              <FormattedMessage id="VENDOR_WILL_NOT_BE_NOTIFIED" />
            </AdditionalInfo>
          )}
        </Container>
      }
    />
  );
};
