import { getAssetType } from "@/common/components/upload-asset/getAssetType";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { PaymentTerm, usePaymentTerms } from "@/common/hooks/usePaymentTerms";
import { Severity, useSnackbar } from "@/common/providers/SnackbarProvider";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useUser } from "@/common/providers/UserProvider";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { hasProperty } from "@/common/utils/objectUtils";
import { checkQuoteStatus } from "@/common/utils/status-checks/checkQuoteStatus";
import { generateUUID } from "@/common/utils/uuidUtils";
import {
  AssetContext,
  DistributorQuoteCommonFieldsFragment,
  DistributorQuoteDocument,
  DistributorQuoteFieldsFragment,
  DistributorQuoteItemFieldsFragment,
  DistributorQuoteQuery,
  QuoteStatus,
  UpdateQuoteInput,
  useDistributorQuoteQuery,
  useUpdateQuoteMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import { useApolloClient } from "@apollo/client";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { DISTRIBUTOR_BULK_UPDATE_STATUSES } from "../../../common/constants";
import { DistributorQuoteError } from "../utils/DistributorQuoteErrors";
import { isDistributorQuoteItemIncluded } from "../utils/isDistributorQuoteItemIncluded";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "./DistributorQuoteItemsProvider";

type UpdateQuoteInputExtended = Omit<UpdateQuoteInput, "quoteId">;

type ProviderContextType = {
  quote:
    | (DistributorQuoteFieldsFragment & DistributorQuoteCommonFieldsFragment)
    | null
    | undefined;
  paymentTermOptions: PaymentTerm[];
  loading: boolean;
  updateQuote: (input: UpdateQuoteInputExtended) => Promise<boolean>;
  updateQuoteBulk: () => Promise<boolean>;
  validationError: boolean;
  showValidationError: (error?: DistributorQuoteError) => void;
  removeValidations: () => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  updating: boolean;
  inEditQuoteItemGroups: string[];
  updateInEditQuoteItemGroups: (id: string) => void;
  isAlternative: (
    item: DistributorQuoteItemGroupExtendedFieldsFragment,
  ) => boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  quote: null,
  paymentTermOptions: [],
  updateQuote: NoFunctionBooleanPromise,
  loading: false,
  updateQuoteBulk: NoFunctionBooleanPromise,
  validationError: false,
  showValidationError: NoFunction,
  removeValidations: NoFunction,
  expandedItems: [],
  setExpandedItems: NoFunction,
  updating: false,
  inEditQuoteItemGroups: [],
  updateInEditQuoteItemGroups: NoFunction,
  isAlternative: NoFunctionBoolean,
});

export const DistributorQuoteProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { quoteId: id } = useParams();
  const { setContractorCurrency } = useUser();
  const quoteId = id || "";
  const { data, loading, error } = useDistributorQuoteQuery({
    variables: { id: quoteId },
  });
  const { getUomByName } = useUomOptions();
  const intl = useIntl();
  const [updateQuoteMutation, { loading: updating }] = useUpdateQuoteMutation();
  const { setError } = useGlobalError();
  const [bulkUpdate, setBulkUpdate] = useState<UpdateQuoteInput>({
    quoteId,
    updates: [],
    addedItems: [],
    addedAuxiliaryItems: [],
    deletedItems: [],
    deletedAuxiliaryItems: [],
  });
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [validationError, showValidationError] = useState<boolean>(false);
  const { setWarningAlert, removeMessagesByType } = useSnackbar();
  useErrorEffect(error);
  const client = useApolloClient();
  const { manufacturers } = useManufacturers();
  const [inEditQuoteItemGroups, setInEditQuoteItemGroups] = useState<string[]>(
    [],
  );

  const { paymentTermsOptions } = usePaymentTerms();

  useEffect(() => {
    if (data?.quote && !loading) {
      setInEditQuoteItemGroups(
        data.quote.itemGroups
          .filter(
            (i) =>
              i.quoteItems.length === 1 &&
              i.quoteItems[0].description !== i.rfqItem.description,
          )
          .map((item) => item.quoteItems[0].id),
      );
      setContractorCurrency(
        data.quote.rfq.project.location.org.settings?.display?.currency,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getAssetObject = useCallback(
    (url: string) => ({
      url,
      __typename: "Asset" as const,
      type: getAssetType(url),
      context: AssetContext.Instruction,
      pages: null,
      thumbnailUrl: url,
    }),
    [],
  );

  const setUpdateForBulkUpdate = useCallback(
    (input: UpdateQuoteInputExtended) => {
      const newInput: UpdateQuoteInput = {
        ...bulkUpdate,
        updates: mergeChanges(bulkUpdate.updates, input.updates, "quoteItemId"),
        addedItems: input.addedItems,
        deletedItems: [
          ...(bulkUpdate.deletedItems || []),
          ...(input.deletedItems || []),
        ],
        notes: input.notes || bulkUpdate.notes,
        assetUrls: input.assetUrls || bulkUpdate.assetUrls,
        paymentTermDays: input.paymentTermDays || bulkUpdate.paymentTermDays,
        discount: input.discount || bulkUpdate.discount,
        expirationDate: input.expirationDate || bulkUpdate.expirationDate,
        addedAuxiliaryItems: input.addedAuxiliaryItems,
        deletedAuxiliaryItems: [
          ...(bulkUpdate.deletedAuxiliaryItems || []),
          ...(input.deletedAuxiliaryItems || []),
        ],
        updatedAuxiliaryItems: mergeChanges(
          bulkUpdate.updatedAuxiliaryItems,
          input.updatedAuxiliaryItems,
          "auxiliaryItemId",
        ),
        additionalCharges: mergeChanges(
          bulkUpdate.additionalCharges,
          input.additionalCharges,
          "id",
        ),
      };

      setBulkUpdate(newInput);
      client.cache.updateQuery(
        { query: DistributorQuoteDocument, variables: { id: quoteId } },
        (data: DistributorQuoteQuery | null) => {
          if (data?.quote) {
            return {
              ...data,
              quote: {
                ...data?.quote,
                discount: input.discount || data?.quote?.discount,
                additionalCharges: input.additionalCharges
                  ? input.additionalCharges?.map((c) => {
                      return {
                        ...c,
                        id: c.id || "",
                        __typename: "Charge" as const,
                      };
                    }) || []
                  : data?.quote?.additionalCharges || [],
                itemGroups: data?.quote?.itemGroups.map((group) => {
                  const newItems =
                    newInput.addedItems?.filter(
                      (item) => item.rfqItemId === group.rfqItem.id,
                    ) || [];

                  const addedItems: DistributorQuoteItemFieldsFragment[] =
                    newItems.map((addedItem) => ({
                      __typename: "QuoteItem" as const,
                      id: "new" + Math.random(),
                      manufacturer: {
                        __typename: "Manufacturer" as const,
                        id: addedItem.manufacturerId || "",
                        name:
                          manufacturers.find(
                            (m) => m.id === addedItem.manufacturerId,
                          )?.name || "",
                      },
                      status: QuoteStatus.Submitted,
                      assets: addedItem.assetUrls?.map(getAssetObject) ?? [],
                      unitPrice: addedItem.unitPrice || "0",
                      expirationDate: addedItem.expirationDate || null,
                      description: addedItem.description || "",
                      quantityDecimal: addedItem.quantityDecimal || "0",
                      notes: addedItem.notes || "",
                      masterSku: null,
                      orgCatalogSku: null,
                      leadTimeDays: null,
                    }));

                  const quoteItems: DistributorQuoteItemFieldsFragment[] =
                    group.quoteItems
                      .filter(
                        (qi) =>
                          !newInput.deletedItems?.includes(qi.id) && qi.id,
                      )
                      .concat(addedItems);

                  return {
                    ...group,
                    quoteItems: quoteItems.map((item) => {
                      const updatedItem = newInput.updates?.find(
                        (i) => i.quoteItemId === item.id,
                      );

                      const groupContainsRemovedItem = group.quoteItems.some(
                        (qi) =>
                          newInput.updates?.find((i) => i.quoteItemId === qi.id)
                            ?.isIncluded === false,
                      );
                      const groupContainsIncludedItem = group.quoteItems.some(
                        (qi) =>
                          newInput.updates?.find((i) => i.quoteItemId === qi.id)
                            ?.isIncluded === true,
                      );
                      return {
                        ...item,
                        quantityDecimal:
                          updatedItem?.quantityDecimal || item.quantityDecimal,
                        unitPrice: updatedItem?.clearUnitPrice
                          ? null
                          : updatedItem?.unitPrice || item.unitPrice,
                        description:
                          updatedItem?.description || item.description,
                        assets:
                          updatedItem?.assetUrls?.map(getAssetObject) ||
                          item.assets ||
                          [],
                        status: groupContainsRemovedItem
                          ? QuoteStatus.Withdrawn
                          : groupContainsIncludedItem
                            ? QuoteStatus.Submitted
                            : item.status,
                        leadTimeDays:
                          updatedItem?.leadTimeDays || item.leadTimeDays,
                        notes: updatedItem?.notes || item.notes,
                        expirationDate:
                          updatedItem?.expirationDate || item.expirationDate,
                        manufacturer: updatedItem?.manufacturerId
                          ? {
                              __typename: "Manufacturer" as const,
                              id: updatedItem?.manufacturerId,
                              name:
                                manufacturers.find(
                                  (m) => m.id === updatedItem?.manufacturerId,
                                )?.name || "",
                            }
                          : item.manufacturer,
                      };
                    }),
                  };
                }),
                auxiliaryItems: [
                  ...data?.quote?.auxiliaryItems
                    .filter(
                      (item) =>
                        !newInput.deletedAuxiliaryItems?.includes(item.id),
                    )
                    .map((item) => {
                      const updatedItem = newInput.updatedAuxiliaryItems?.find(
                        (i) => i.auxiliaryItemId === item.id,
                      );

                      let uom = item.uom;
                      if (updatedItem?.uom) {
                        const u = getUomByName(updatedItem.uom);
                        if (u) {
                          uom = u;
                        } else {
                          uom = {
                            id: generateUUID(),
                            pluralDescription: updatedItem.uom,
                            alternativeMnemonics: [],
                          };
                        }
                      }
                      return {
                        ...item,
                        quantityDecimal:
                          updatedItem?.quantityDecimal || item.quantityDecimal,
                        unitPrice: updatedItem?.clearUnitPrice
                          ? null
                          : updatedItem?.unitPrice || item.unitPrice,
                        description:
                          updatedItem?.description || item.description,
                        assets:
                          updatedItem?.assetUrls?.map(getAssetObject) ||
                          item.assets ||
                          [],
                        leadTimeDays:
                          updatedItem?.leadTimeDays || item.leadTimeDays,
                        expirationDate:
                          updatedItem?.expirationDate || item.expirationDate,
                        notes: updatedItem?.notes || item.notes,
                        manufacturer: updatedItem?.manufacturerId
                          ? {
                              __typename: "Manufacturer" as const,
                              id: updatedItem?.manufacturerId,
                              name:
                                manufacturers.find(
                                  (m) => m.id === updatedItem?.manufacturerId,
                                )?.name || "",
                            }
                          : item.manufacturer,
                        uom,
                      };
                    }),
                  ...(newInput.addedAuxiliaryItems?.map((aux) => ({
                    __typename: "AuxiliaryQuoteItem" as const,
                    ...aux,
                    id: "new" + Math.random(),
                    unitPrice: aux.unitPrice || "0",
                    manufacturer: null,
                    notes: null,
                    expirationDate: null,
                    quantityDecimal: "0",
                    assets: [],
                    uom: null,
                    leadTimeDays: null,
                  })) || []),
                ],
              },
            };
          }
          return data;
        },
      );
    },
    [
      bulkUpdate,
      client.cache,
      quoteId,
      manufacturers,
      getAssetObject,
      getUomByName,
    ],
  );

  const updateQuote = useCallback(
    async (input: UpdateQuoteInputExtended) => {
      if (!data?.quote) {
        return false;
      }

      if (checkQuoteStatus(data?.quote, DISTRIBUTOR_BULK_UPDATE_STATUSES)) {
        setUpdateForBulkUpdate(input);
        return true;
      }

      if (
        input.additionalCharges?.some(
          (a) => !Number(a.amount) || !a.description,
        )
      ) {
        return false;
      }

      try {
        const { errors } = await updateQuoteMutation({
          variables: {
            input: {
              quoteId,
              ...input,
            },
          },
          optimisticResponse: {
            updateQuote: {
              ...data?.quote,
              additionalCharges:
                input.additionalCharges?.map((c) => ({
                  ...c,
                  id: c.id || "",
                  __typename: "Charge" as const,
                })) || data?.quote?.additionalCharges,
              id: quoteId,
              assets:
                input.assetUrls?.map(getAssetObject) || data?.quote?.assets,
              itemGroups: data?.quote?.itemGroups.map((group) => ({
                ...group,
                quoteItems: group.quoteItems
                  .filter((item) => !input.deletedItems?.includes(item.id))
                  .concat(
                    input.addedItems
                      ?.filter((i) => i.rfqItemId === group.rfqItem.id)
                      .map((addedItem) => ({
                        __typename: "QuoteItem" as const,
                        id: "new" + Math.random(),
                        manufacturer: {
                          __typename: "Manufacturer" as const,
                          id: addedItem.manufacturerId || "",
                          name:
                            manufacturers.find(
                              (m) => m.id === addedItem.manufacturerId,
                            )?.name || "",
                        },
                        status: QuoteStatus.Submitted,
                        assets: addedItem.assetUrls?.map(getAssetObject) || [],
                        unitPrice: addedItem.unitPrice || "0",
                        expirationDate: addedItem.expirationDate || null,
                        description: addedItem.description || "",
                        quantity: addedItem.quantity || 0,
                        notes: addedItem.notes || "",
                        masterSku: null,
                        orgCatalogSku: null,
                        leadTimeDays: null,
                      })) || [],
                  )
                  .map((item) => {
                    const updatedItem = input.updates?.find(
                      (update) => update.quoteItemId === item.id,
                    );
                    return {
                      ...item,
                      quantityDecimal: hasProperty(
                        updatedItem,
                        "quantityDecimal",
                      )
                        ? updatedItem?.quantityDecimal
                        : item.quantityDecimal,
                      unitPrice: hasProperty(updatedItem, "unitPrice")
                        ? updatedItem?.unitPrice
                        : item.unitPrice,
                      leadTimeDays:
                        updatedItem?.leadTimeDays || item.leadTimeDays,
                      notes: updatedItem?.notes || item.notes,
                      expirationDate:
                        updatedItem?.expirationDate || item.expirationDate,
                      status:
                        updatedItem?.isIncluded === false
                          ? QuoteStatus.Withdrawn
                          : updatedItem?.isIncluded === true
                            ? QuoteStatus.Submitted
                            : item.status,
                    };
                  }),
              })),
              auxiliaryItems: data?.quote.auxiliaryItems
                .filter(
                  (item) => !input.deletedAuxiliaryItems?.includes(item.id),
                )
                .concat(
                  input.addedAuxiliaryItems?.map((aux) => ({
                    __typename: "AuxiliaryQuoteItem",
                    ...aux,
                    id: "new" + Math.random(),
                    unitPrice: aux.unitPrice || "0",
                    manufacturer: null,
                    notes: null,
                    expirationDate: null,
                    quantityDecimal: "0",
                    assets: [],
                    uom: aux.uom ? getUomByName(aux.uom) : null,
                    leadTimeDays: null,
                  })) || [],
                )
                .map((item) => {
                  const updatedItem = input.updatedAuxiliaryItems?.find(
                    (i) => i.auxiliaryItemId === item.id,
                  );
                  return {
                    ...item,
                    quantityDecimal: hasProperty(updatedItem, "quantityDecimal")
                      ? updatedItem?.quantityDecimal
                      : item.quantityDecimal,
                    unitPrice: hasProperty(updatedItem, "unitPrice")
                      ? updatedItem?.unitPrice
                      : item.unitPrice,
                  };
                }),
            },
          },
        });
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [
      data?.quote,
      setUpdateForBulkUpdate,
      updateQuoteMutation,
      quoteId,
      getAssetObject,
      setError,
      manufacturers,
      getUomByName,
    ],
  );
  const updateQuoteBulk = async () => {
    try {
      const updates = bulkUpdate.updates?.filter(
        (update) => !update.quoteItemId.includes("new"),
      );
      const addedItems = bulkUpdate.updates
        ?.filter((update) => update.quoteItemId.includes("new"))
        .map((update) => ({
          ...update,
          rfqItemId:
            quote?.itemGroups.find((group) =>
              group.quoteItems.some((qi) => qi.id === update.quoteItemId),
            )?.rfqItem.id || "",
          quoteItemId: undefined,
        }));
      const deletedItems = bulkUpdate.deletedItems?.filter(
        (id) => !id.includes("new"),
      );

      const updatedAuxiliaryItems = bulkUpdate.updatedAuxiliaryItems?.filter(
        (update) => !update.auxiliaryItemId.includes("new"),
      );
      const addedAuxiliaryItems = bulkUpdate.updatedAuxiliaryItems
        ?.filter((update) => update.auxiliaryItemId.includes("new"))
        .map((update) => ({
          ...update,
          auxiliaryItemId: undefined,
          clearUnitPrice: undefined,
        }));
      const deletedAuxiliaryItems = bulkUpdate.deletedAuxiliaryItems?.filter(
        (id) => !id.includes("new"),
      );

      const { errors } = await updateQuoteMutation({
        variables: {
          input: {
            ...bulkUpdate,
            updates,
            addedItems,
            deletedItems,
            addedAuxiliaryItems,
            updatedAuxiliaryItems,
            deletedAuxiliaryItems,
          },
        },
      });
      setError(errors);

      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const showValidation = (error?: DistributorQuoteError) => {
    if (error) {
      let message = "";
      switch (error) {
        case DistributorQuoteError.IncludedQuotesMissingPrice:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_MISSING_PRICE";
          break;

        case DistributorQuoteError.NoManufacturerIncluded:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_NO_MANUFACTURER";
          break;

        case DistributorQuoteError.IncludedQuotesMissingQuantity:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_MISSING_QUANTITY";
          break;

        case DistributorQuoteError.NoUomIncludedForAuxiliaryItem:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_MISSING_UOMS";
          break;

        case DistributorQuoteError.MissingDescription:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_MISSING_DESCRIPTION";
          break;

        case DistributorQuoteError.NoQuoteItemsIncluded:
          message = "DISTRIBUTOR_QUOTE_VALIDATION_ERROR_ALL_ITEMS_EXCLUDED";

        default:
          break;
      }
      setWarningAlert(intl.$t({ id: message }));
    }
    showValidationError(!!error);
  };

  const removeValidations = useCallback(() => {
    removeMessagesByType(Severity.Warning);
  }, [removeMessagesByType]);

  const isItemIncluded = useCallback(isDistributorQuoteItemIncluded, []);

  const quote = useMemo(() => {
    if (data?.quote) {
      return {
        ...data?.quote,
        itemGroups: data?.quote?.itemGroups.map((groupItem) => ({
          ...groupItem,
          quoteItems: groupItem.quoteItems?.filter(
            (item, index) => isItemIncluded(item) || index === 0,
          ),
        })),
      };
    }
  }, [data?.quote, isItemIncluded]);

  const updateInEditQuoteItemGroups = useCallback(
    (id: string) => {
      setInEditQuoteItemGroups((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        } else {
          return [...prev, id];
        }
      });
    },
    [setInEditQuoteItemGroups],
  );

  const isAlternative = useCallback(
    (item: DistributorQuoteItemGroupExtendedFieldsFragment) =>
      (item.quoteItemCount || 0) > 1 || inEditQuoteItemGroups.includes(item.id),
    [inEditQuoteItemGroups],
  );

  return (
    <ProviderContext.Provider
      value={{
        quote,
        paymentTermOptions: paymentTermsOptions,
        loading,
        updateQuote,
        updateQuoteBulk,
        validationError,
        showValidationError: showValidation,
        removeValidations,
        expandedItems,
        setExpandedItems,
        updating,
        updateInEditQuoteItemGroups,
        inEditQuoteItemGroups,
        isAlternative,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorQuote = (): ProviderContextType =>
  useContext(ProviderContext);
