import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { evictCacheById } from "@/common/utils/cacheUtils";
import { readValue, setValue } from "@/common/utils/localStorage";
import {
  BuyoutsBuyoutFieldsFragment,
  QueryBuyoutsFilter,
  namedOperations,
  useArchiveBuyoutMutation,
  useBuyoutsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";

export enum BuyoutsFilterSelection {
  Draft = "DRAFT",
  Requested = "REQUESTED",
  Active = "ACTIVE",
  Depleted = "DEPLETED",
  Cancelled = "CANCELLED",
}

type ProviderContextType = {
  buyouts: BuyoutsBuyoutFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryBuyoutsFilter;
  setFilter: (filter: QueryBuyoutsFilter) => void;
  archiveBuyout: (id: string) => Promise<boolean>;
  isFiltered: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  buyouts: [],
  loading: false,
  error: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  archiveBuyout: () => Promise.resolve(false),
  isFiltered: false,
});

export const BuyoutsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setError } = useGlobalError();
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();

  const [filter, setFilter] = useState<QueryBuyoutsFilter>(
    getFiltersQueryParam(),
  );

  const { paginationArgs, setPageInfo, previousPage, page } = usePagination();
  const {
    data: buyoutsData,
    loading,
    error,
  } = useBuyoutsQuery({
    variables: {
      ...paginationArgs,
      filter,
    },
  });
  const [archiveBuyoutMutation] = useArchiveBuyoutMutation({
    update: (cache, { data: archiveBuyout }) =>
      evictCacheById(
        cache,
        namedOperations.Query.Buyout,
        archiveBuyout?.archiveBuyout.id,
      ),
  });

  useErrorEffect(error);

  useEffect(() => {
    if (buyoutsData?.buyouts?.pageInfo) {
      setPageInfo(buyoutsData.buyouts.pageInfo);
    }
  }, [buyoutsData, setPageInfo]);

  useEffect(() => {
    const localStorageSettings = readValue<QueryBuyoutsFilter>(
      LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER,
    ) as QueryBuyoutsFilter;
    setFilter({
      statuses: localStorageSettings?.statuses,
      deleted: localStorageSettings?.deleted,
      closedProjects: localStorageSettings?.closedProjects,
      poExists: localStorageSettings?.poExists,
      exported: localStorageSettings?.exported,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam]);

  const setFilterAndUpdateQueryString = (filter: QueryBuyoutsFilter) => {
    setFilter(filter);
    setValue(LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER, filter);
    setFiltersQueryParams(filter);
  };

  const archiveBuyout = async (id: string) => {
    try {
      const { data, errors } = await archiveBuyoutMutation({
        variables: { id },
        onCompleted: () => {
          if (page !== 0 && buyoutsData?.buyouts.edges.length === 1) {
            previousPage();
          }
        },
      });
      setError(errors);
      return !!data?.archiveBuyout;
    } catch (error) {
      setError(error);
    }
    return false;
  };

  return (
    <ProviderContext.Provider
      value={{
        buyouts: buyoutsData?.buyouts?.edges?.map((edge) => edge.node) || [],
        isFiltered: !!filter?.projectIDs || !!filter?.statuses?.length,
        loading,
        error: !!error,
        totalCount: buyoutsData?.buyouts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveBuyout,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyouts = (): ProviderContextType =>
  useContext(ProviderContext);
