import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { LUMP_SUM_UOM } from "@/common/const";
import {
  Base,
  MdFixedContainer,
  SmdFixedContainer,
  SmFixedContainer,
  SmWideContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleaseAssignCostCodes } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseAssignCostCodes";
import { ReleaseCostCodeDropdown } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseCostCodeDropdown";
import { ReleaseItemMaterial } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseItemMaterial";
import {
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { GroupByCostCodeToggle } from "../../components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { ReleaseItemDeliveryDate } from "../../release-items-list/components/ReleaseItemDeliveryDate";
import { ReleaseItemExtPriceGroup } from "../../release-items-list/components/ReleaseItemExtPriceGroup";
import { ReleaseItemUnitPrice } from "../../release-items-list/components/ReleaseItemUnitPrice";
import { ReleaseTagsPicker } from "../../release-items-list/components/ReleaseTagsPicker";
import { filterByMaterialNamePriceAndQty } from "../../utils/filters/filterByMaterialNamePriceAndQty";
import { ViewResolution } from "../specify-details/components/issue-resolution/ViewResolution";
import { AddItemIssue } from "../specify-details/components/report-issue/AddItemIssue";
import { ReleaseEmptyFooter } from "./release-footer/ReleaseEmptyFooter";
import { ReleaseExtPriceFooter } from "./release-footer/ReleaseExtPriceFooter";
import { ReleaseInvoicedFooter } from "./release-footer/ReleaseInvoicedFooter";
import { ReleaseReceivedFooter } from "./release-footer/ReleaseReceivedFooter";
import { ReleaseTaxFooter } from "./release-footer/ReleaseTaxFooter";
import { ReleaseTitleColFooter } from "./release-footer/ReleaseTitleColFooter";
import { ReleaseItemInvoicedSoFarSidePanel } from "./ReleaseItemInvoicedSoFarSidePanel";
import { ReleaseItemReceivedSoFarSidePanel } from "./ReleaseItemReceivedSoFarSidePanel";

const RequestedItemsContainer = tw(Base)`col-span-full flex-1`;
const FloatingText = tw.span`absolute w-96 text-center text-xs text-gray-600`;
const Label = tw.span`text-xs`;
const DateWrapper = tw(SmFixedContainer)`2xl:w-24 lg:w-22 md:w-20`;

export const useReleaseDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { calcExtPrice } = usePriceCalculation();
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { settings, hasPhaseCodes } = useOrgSettings();
  const { formatCostType } = useCostTypes();
  const showFooter =
    release?.status &&
    [
      ReleaseStatus.Received,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Requested,
      ReleaseStatus.Scheduled,
    ].includes(release.status);

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const configuration: Array<
    GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterial
            count={count}
            item={item}
            projectId={release?.project?.id}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_RELEASES" />
            <GroupByCostCodeToggle />
          </>
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
        footer: showFooter && <ReleaseTaxFooter />,
      },
      {
        subgroup: (category) => (
          <ReleaseAssignCostCodes
            category={category}
            status={release?.status}
          />
        ),
        item: ({ item }) => (
          <ReleaseCostCodeDropdown
            releaseItemId={item.id}
            readonly={!!item.costCode || !item.isIncluded}
          />
        ),
        hidden: !groupedByCostCode,
        wrapper: SmFixedContainer,
        footer: showFooter && <ReleaseEmptyFooter Wrapper={SmFixedContainer} />,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="COST_TYPE" />,
        item: ({ item }) => <Label>{formatCostType(item.costType)}</Label>,
        position: "center",
        hidden: !settings?.display?.itemCostTypes,
        footer: showFooter && <ReleaseEmptyFooter Wrapper={SmFixedContainer} />,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />,
        item: ({ item }) => {
          return <ReleaseTagsPicker item={item} visibleTags={4} readonly />;
        },
        footer: showFooter && <ReleaseEmptyFooter Wrapper={SmFixedContainer} />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => {
          if (isLumpSumItem(item)) {
            return <FloatingText>{LUMP_SUM_UOM}</FloatingText>;
          }
          return item.isIncluded ? (
            <ValueUnit
              value={item.quantityDecimal}
              uom={item.uom || item.projectItem?.estimateUom}
            />
          ) : (
            <FormattedMessage
              id="NOT_INCLUDED_BY_VENDOR"
              tagName={FloatingText}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
        footer: showFooter ? (
          <ReleaseTitleColFooter />
        ) : (
          <ReleaseEmptyFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        wrapper: MdFixedContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_RECEIVED_SO_FAR" />,
        item: ({ item }) => (
          <ReleaseItemReceivedSoFarSidePanel
            item={item}
            releaseId={release?.id}
          />
        ),
        hidden: !release?.type.requireDeliverySlip,
        footer: showFooter && (
          <ReleaseReceivedFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        wrapper: MdFixedContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_ITEM_INVOICED" />,
        item: ({ item }) => (
          <ReleaseItemInvoicedSoFarSidePanel
            item={item}
            releaseId={release?.id}
          />
        ),
        footer: showFooter && (
          <ReleaseInvoicedFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item, index }) => {
          if (isLumpSumItem(item)) {
            return <NotNullableRenderer />;
          }
          return item.isIncluded ? (
            <ReleaseItemUnitPrice
              item={item}
              release={release}
              index={index}
              readonly
            />
          ) : null;
        },
        header: <FormattedMessage id="UNIT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
        footer: showFooter && (
          <ReleaseEmptyFooter Wrapper={SmdFixedContainer} />
        ),
        sortItemFn: (item1: ExpandedReleaseItem, item2: ExpandedReleaseItem) =>
          new Decimal(item1.unitPrice || 0)
            .minus(item2.unitPrice || 0)
            .toNumber(),
      },
      {
        header: <FormattedMessage id="EXT_PRICE" />,
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <NotNullableRenderer value={item.unitPrice !== null}>
              <Price
                className="text-xs"
                price={calcExtPrice(item.quantityDecimal, item.unitPrice)}
              />
            </NotNullableRenderer>
          </If>
        ),
        subgroup: (category) => (
          <ReleaseItemExtPriceGroup items={category.items} />
        ),
        footer: showFooter && (
          <ReleaseExtPriceFooter Wrapper={SmWideContainer} />
        ),
        hidden: !hasPermissions,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) =>
          item.isIncluded ? (
            item.issues?.[0]?.resolution ? (
              <ViewResolution item={item} />
            ) : !release?.deletedAt ? (
              <AddItemIssue item={item} />
            ) : null
          ) : null,
        position: "center",
        header: <FormattedMessage id="DELIVERY_ISSUES_HEADER" />,
        hidden:
          (showFooter &&
            ![ReleaseStatus.Received, ReleaseStatus.PartiallyReceived].includes(
              release.status,
            )) ||
          !!release?.poLink?.immutable,
        footer: showFooter && <ReleaseEmptyFooter Wrapper={SmFixedContainer} />,
      },
      {
        wrapper: DateWrapper,
        header: (
          <FormattedMessage id={hasEndDate ? "END_DATE" : "DELIVERY_DATE"} />
        ),
        position: "center",
        item: ({ item }) => <ReleaseItemDeliveryDate item={item} readonly />,
        footer: showFooter && <ReleaseEmptyFooter Wrapper={DateWrapper} />,
      },
    ];
  }, [
    calcExtPrice,
    formatCostType,
    groupedByCostCode,
    hasPermissions,
    release,
    showFooter,
    settings?.display?.itemCostTypes,
    hasPhaseCodes,
    hasEndDate,
  ]);

  return configuration;
};
