import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { readValue, setValue } from "@/common/utils/localStorage";
import {
  CreateDeliverySlipInput,
  DeliverySlipPartialFieldsFragment,
  DeliverySlipStatsDocument,
  DeliverySlipsDocument,
  QueryDeliverySlipsFilter,
  useCreateDeliverySlipMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useArchiveDeliverySlip } from "../hooks/useArchiveDeliverySlip";
import { useDeliverySlipsWithPagination } from "../hooks/useDeliverySlipsWithPagination";

type ProviderContextType = {
  deliverySlips: DeliverySlipPartialFieldsFragment[];
  isFiltered: boolean;
  filter?: QueryDeliverySlipsFilter | undefined;
  setFilter: (filter: QueryDeliverySlipsFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  archiveDeliverySlip: (id: string) => Promise<boolean>;
  createDeliverySlip: (
    input: CreateDeliverySlipInput,
  ) => Promise<DeliverySlipPartialFieldsFragment | null | undefined>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  deliverySlips: [],
  isFiltered: false,
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  archiveDeliverySlip: NoFunctionBooleanPromise,
  createDeliverySlip: NoFunctionUndefinedPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
});

export const DeliverySlipsProvider: FC<{
  children: React.ReactNode;
  filter?: QueryDeliverySlipsFilter;
}> = ({ children }) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { setError } = useGlobalError();
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  const [filter, setFilter] = useState<QueryDeliverySlipsFilter | undefined>(
    getFiltersQueryParam(),
  );

  const { deliverySlips, loading, error, totalCount, pagination } =
    useDeliverySlipsWithPagination(filter);

  useEffect(() => {
    const localStorageSettings = readValue<QueryDeliverySlipsFilter>(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIPS_LIST_FILTER,
    ) as QueryDeliverySlipsFilter;
    setFilter({
      archived: localStorageSettings?.archived,
      closedProjects: localStorageSettings?.closedProjects,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam]);

  const { archiveDeliverySlip } = useArchiveDeliverySlip({
    filter,
    pagination,
  });

  const [createDeliverySlipMutation] = useCreateDeliverySlipMutation();
  const createDeliverySlip = async (input: CreateDeliverySlipInput) => {
    try {
      const { data, errors } = await createDeliverySlipMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: DeliverySlipsDocument,
            variables: { ...pagination, filter },
          },
          {
            query: DeliverySlipStatsDocument,
          },
        ],
      });

      setError(errors);
      return data?.createDeliverySlip;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const setFilterAndUpdateQueryString = (
    filter: QueryDeliverySlipsFilter | undefined,
  ) => {
    setFiltersQueryParams(filter);
    setFilter(filter);
    setValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIPS_LIST_FILTER, filter);
  };

  const isFiltered = useMemo(
    () =>
      !!filter?.search ||
      !!filter?.projectIDs?.length ||
      !!filter?.sellerOrgLocationIds?.length ||
      !!filter?.statuses?.length ||
      !!filter?.minCreatedAt ||
      !!filter?.maxCreatedAt ||
      !!filter?.minFulfillmentDate ||
      !!filter?.maxFulfillmentDate ||
      !!filter?.closedProjects,
    [filter],
  );

  return (
    <ProviderContext.Provider
      value={{
        deliverySlips: deliverySlips ?? [],
        isFiltered,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveDeliverySlip,
        createDeliverySlip,
        exportEnabled,
        setExportEnabled,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlips = (): ProviderContextType =>
  useContext(ProviderContext);
