import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useSyncReleaseItems } from "./useSyncReleaseItems";

type SubmitOptions = {
  callback?: () => void;
  validationKey: string;
};

export const useSubmitChanges = () => {
  const [loading, setLoading] = useState(false);
  const { hasChanges } = useReleaseStore();
  const { submitUpdate } = useReleaseUpdate();
  const intl = useIntl();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();
  const viewState = useTableViewStore((state) => state.viewState);
  const { syncReleaseItems } = useSyncReleaseItems();

  const submitChanges = useCallback(
    async ({ callback, validationKey }: SubmitOptions) => {
      setLoading(true);
      const isValid = true;
      if (hasChanges) {
        if (!isValid) {
          setWarningAlert(
            intl.$t({
              id: validationKey,
            }),
          );
          setLoading(false);
        }
        if (
          viewState === TableViewState.spreadsheet &&
          !(await syncReleaseItems())
        ) {
          setLoading(false);
          return;
        }
        submitUpdate(undefined, {
          callback: (result) => {
            setLoading(false);
            if (result) {
              if (viewState === TableViewState.normal) {
                setSuccessAlert(
                  intl.$t({
                    id: "RELEASE_ITEMS_SAVED_SUCCESS",
                  }),
                );
              }
              callback?.();
            } else {
              setWarningAlert(
                intl.$t({
                  id: validationKey,
                }),
              );
            }
          },
        });
        return;
      }
      setLoading(false);
      callback?.();
    },
    [
      hasChanges,
      intl,
      setSuccessAlert,
      setWarningAlert,
      submitUpdate,
      syncReleaseItems,
      viewState,
    ],
  );

  return { submitChanges, submitting: loading };
};
