import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { QueryRfQsFilter } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useRfqs } from "../providers/RfqsProvider";

enum AdditionalFilter {
  ReceivedQuotes = "RECEIVED_QUOTES",
  ClosedProjects = "CLOSED_PROJECTS",
  Deleted = "DELETED",
}

export const RfqsAdditionalFilters = () => {
  const { filter, setFilter } = useRfqs();
  const intl = useIntl();

  const options = useMemo<Option<QueryRfQsFilter>[]>(() => {
    return [
      {
        id: AdditionalFilter.ReceivedQuotes,
        title: intl.$t({ id: "RECEIVED_QUOTES" }),
        filter: (value) => ({
          quotesReceived: value,
        }),
        value: (currentFilter) => currentFilter?.quotesReceived,
      },
      {
        id: AdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "QUOTES_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: AdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          deleted: value,
        }),
        value: (currentFilter) => currentFilter?.deleted,
      },
    ];
  }, [intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        quotesReceived: null,
        deleted: false,
        closedProjects: false,
      }}
      options={options}
      setFilter={setFilter}
    />
  );
};
