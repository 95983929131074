import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgRolesWrapper } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { PhoneInput } from "@/common/components/phone-input/PhoneInput";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { strongify } from "@/common/utils/htmlUtils";
import {
  AdminUsersUserFieldsFragment,
  EnterpriseRole,
  LocationRoleInput,
  OrgRole,
  SystemRole,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FormControl, TextField } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../../common/utils/users/getUserName";
import { useAdminUsers } from "../../hooks/useAdminUsers";
import { useEnterpriseUsers } from "../../hooks/useEnterpriseUsers";
import { LocationRoles } from "./LocationRoles";

type Props = {
  onClose: () => void;
  title: string;
  user?: AdminUsersUserFieldsFragment;
};

const Container = tw.div`flex flex-col gap-4`;
const OrgAdminContainer = tw.div`w-full flex flex-row items-center`;
const EnterpriseAdminContainer = tw(
  OrgAdminContainer,
)`pb-3 border-b-2 border-dotted mt-3`;
const FormControlStyled = tw(FormControl)`w-full`;
const Label = tw.div`flex-1 font-bold`;

export const UserForm: FC<Props> = ({ onClose, title, user }) => {
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [cellPhone, setCellPhone] = useState(user?.cellPhone || "");
  const [isAdmin, setIsAdmin] = useState(
    user?.orgRoles.includes(OrgRole.OrgAdmin) ||
      user?.enterpriseRoles.includes(EnterpriseRole.EnterpriseAdmin) ||
      false,
  );
  const [isEnterpriseAdmin, setIsEnterpriseAdmin] = useState(
    user?.enterpriseRoles.includes(EnterpriseRole.EnterpriseAdmin) || false,
  );
  const [isAccountant, setIsAccountant] = useState(
    [OrgRole.OrgAccountant, OrgRole.OrgAdmin].some((role) =>
      user?.orgRoles.includes(role),
    ) ||
      user?.enterpriseRoles.includes(EnterpriseRole.EnterpriseAdmin) ||
      false,
  );
  const [isOrgRestrictedAdmin, setIsOrgRestrictedAdmin] = useState(
    user?.orgRoles.includes(OrgRole.OrgAdmin) ||
      user?.enterpriseRoles.includes(EnterpriseRole.EnterpriseAdmin)
      ? true
      : user?.orgRoles.includes(OrgRole.OrgRestrictedAdmin) || false,
  );
  const [canSeePrices, setCanSeePrices] = useState(
    !user?.orgRoles.includes(OrgRole.OrgRestrictedMember) || false,
  );
  const [locationRoles, setLocationRoles] = useState<
    Partial<LocationRoleInput>[]
  >(
    user?.locationRoles.map((locationRole) => ({
      orgLocationId: locationRole.orgLocID,
      role: locationRole.role,
    })) || [{}],
  );
  const intl = useIntl();
  const { createUser, updateUser, loading } = useAdminUsers();
  const { viewer } = useUser();
  const { setSuccessAlert } = useSnackbar();
  const { fetchUsers, users } = useEnterpriseUsers();
  const { openDialog } = useDialog();

  useEffect(() => {
    if (viewer?.enterpriseRoles.includes(EnterpriseRole.EnterpriseAdmin)) {
      fetchUsers();
    }
  }, [fetchUsers, viewer?.enterpriseRoles]);

  const saveUser = useCallback(async () => {
    let result = null;
    const orgRoles = [
      ...(isAdmin ? [OrgRole.OrgAdmin] : []),
      ...(isAccountant ? [OrgRole.OrgAccountant] : []),
      ...(isOrgRestrictedAdmin ? [OrgRole.OrgRestrictedAdmin] : []),
      ...(!canSeePrices ? [OrgRole.OrgRestrictedMember] : []),
      ...(!isAdmin && !isAccountant && canSeePrices ? [OrgRole.OrgMember] : []),
    ];
    const viewerIsEnterpriseAdmin = viewer?.enterpriseRoles.includes(
      EnterpriseRole.EnterpriseAdmin,
    );
    const enterpriseRoles = viewerIsEnterpriseAdmin
      ? isEnterpriseAdmin
        ? [EnterpriseRole.EnterpriseAdmin]
        : []
      : null;

    if (user) {
      result = await updateUser({
        id: user.id,
        cellPhone,
        email,
        firstName,
        lastName,
        locationRoles: locationRoles as LocationRoleInput[],
        orgRoles,
        enterpriseRoles,
      });
    } else {
      result = await createUser({
        firstName,
        lastName,
        cellPhone,
        email,
        locationRoles: locationRoles as LocationRoleInput[],
        orgId: viewer?.org.id || "",
        orgRoles,
        enterpriseRoles,
        systemRoles: [SystemRole.FmUser],
      });
    }
    if (!result) {
      return;
    }
    setSuccessAlert(
      intl.$t(
        { id: user ? "USER_UPDATE_SUCCESS" : "USER_CREATE_SUCCESS" },
        { name: strongify(getUserName({ firstName, lastName })) },
      ),
    );
    onClose();
  }, [
    canSeePrices,
    cellPhone,
    createUser,
    email,
    firstName,
    intl,
    isAccountant,
    isAdmin,
    isEnterpriseAdmin,
    isOrgRestrictedAdmin,
    lastName,
    locationRoles,
    onClose,
    setSuccessAlert,
    updateUser,
    user,
    viewer?.enterpriseRoles,
    viewer?.org.id,
  ]);

  const loadUserDetails = useCallback(() => {
    const user = users?.find((user) => user.email === email);
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setCellPhone(user.cellPhone);
      setIsEnterpriseAdmin(user.isEnterpriseAdmin);
      setIsAdmin(user.isEnterpriseAdmin);
    }
  }, [email, users]);

  const validateAndSave = useCallback(() => {
    if (user && user.orgs.length > 1) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "CHANGE" }),
        icon: <WarningIcon />,
        title: <FormattedMessage id="MODIFY_USER_TITLE" />,
        text: (
          <FormattedMessage
            id="MODIFY_USER_TEXT"
            values={{
              orgs: user.orgs.map((org) => org.name).join(", "),
            }}
          />
        ),
        handleConfirm: () => {
          saveUser();
        },
        handleCancel() {},
      });
    } else {
      saveUser();
    }
  }, [intl, openDialog, saveUser, user]);

  return (
    <OverlayPanel
      title={title}
      onSave={validateAndSave}
      onCancel={onClose}
      saving={loading}
      disableSave={
        firstName.length === 0 ||
        lastName.length === 0 ||
        email.length === 0 ||
        cellPhone.length === 0 ||
        locationRoles.some((role) => !role.orgLocationId || !role.role)
      }
    >
      <Container>
        <FormControlStyled fullWidth>
          <TextField
            size="small"
            label={intl.$t({ id: "USER_FIRST_NAME" })}
            required
            value={firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFirstName(event.target.value)
            }
          />
        </FormControlStyled>
        <FormControlStyled fullWidth>
          <TextField
            size="small"
            label={intl.$t({ id: "USER_LAST_NAME" })}
            required
            value={lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLastName(event.target.value)
            }
          />
        </FormControlStyled>
        <FormControlStyled fullWidth>
          <TextField
            size="small"
            label={intl.$t({ id: "USER_FORM_EMAIL" })}
            required
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value.trim())
            }
            onBlur={loadUserDetails}
          />
        </FormControlStyled>
        <FormControlStyled fullWidth>
          <PhoneInput
            size="small"
            label={intl.$t({ id: "USER_CELL_PHONE" })}
            required
            value={cellPhone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCellPhone(event.target.value)
            }
            countryCode={
              viewer?.org.hqAddress?.country || DEFAULT_ADDRESS_COUNTRY
            }
          />
        </FormControlStyled>
        <OrgRolesWrapper enterpriseRoles={[EnterpriseRole.EnterpriseAdmin]}>
          <EnterpriseAdminContainer>
            <Label>
              <FormattedMessage id="USER_FORM_ENTERPRISE_ADMINISTRATOR" />
            </Label>
            <InnerLabeledSwitch
              initialValue={isEnterpriseAdmin}
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
              toggle={(checked) => {
                if (isEnterpriseAdmin) {
                  setIsAdmin(true);
                  setCanSeePrices(true);
                  setIsAccountant(true);
                }
                if (checked) {
                  setCanSeePrices(true);
                  setIsAccountant(true);
                  setIsAdmin(true);
                  setIsOrgRestrictedAdmin(true);
                }
                setIsEnterpriseAdmin((value) => !value);
              }}
              width={60}
            />
          </EnterpriseAdminContainer>
        </OrgRolesWrapper>
        <OrgAdminContainer>
          <Label>
            <FormattedMessage id="USER_FORM_ORGANIZATION_ADMINISTRATOR" />
          </Label>
          <InnerLabeledSwitch
            initialValue={isAdmin}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            toggle={(checked) => {
              if (checked) {
                setIsAccountant(true);
                setCanSeePrices(true);
                setIsOrgRestrictedAdmin(true);
              }
              setIsAdmin((value) => !value);
            }}
            disabled={isEnterpriseAdmin}
            width={60}
          />
        </OrgAdminContainer>
        <OrgAdminContainer>
          <Label>
            <FormattedMessage id="USER_FORM_ORGANIZATION_ACCOUNTANT" />
          </Label>
          <Tooltip
            id="accountant-tooltip"
            element={
              <LinkLike>
                <InfoOutlined />
              </LinkLike>
            }
          >
            <FormattedMessage id="ORG_ACCOUNTANT_TOOLTIP" />
          </Tooltip>
          <InnerLabeledSwitch
            initialValue={isAccountant}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            toggle={() => {
              setIsAccountant((value) => !value);
            }}
            disabled={isAdmin}
            width={60}
          />
        </OrgAdminContainer>
        <OrgAdminContainer>
          <Label>
            <FormattedMessage id="USER_FORM_CAN_MANAGE_VENDORS_AND_ITEM_DATABASE" />
          </Label>
          <InnerLabeledSwitch
            initialValue={isOrgRestrictedAdmin}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            toggle={() => {
              setIsOrgRestrictedAdmin((value) => !value);
            }}
            disabled={isAdmin}
            width={60}
          />
        </OrgAdminContainer>
        <OrgAdminContainer>
          <Label>
            <FormattedMessage id="USER_CAN_SEE_PRICES" />
          </Label>
          <InnerLabeledSwitch
            initialValue={canSeePrices}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
            toggle={() => setCanSeePrices((value) => !value)}
            disabled={isAdmin || isAccountant}
            width={60}
          />
        </OrgAdminContainer>
        <LocationRoles
          locationRoles={locationRoles}
          setLocationRoles={setLocationRoles}
        />
      </Container>
    </OverlayPanel>
  );
};
