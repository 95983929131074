import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ExclamationCircleRed } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import { generateUUID } from "@/common/utils/uuidUtils";
import {
  AlternativeFulfillmentInput,
  DistributorReleaseItemFieldsFragment,
  TransactionKind,
} from "@/generated/graphql";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  DUPLICATE_RELEASE_ITEM_ID_SUFFIX,
  useDistributorRelease,
} from "../providers/DistributorReleaseProvider";
import { DistributorReleaseDatePicker } from "./DistributorReleaseDatePicker";

const DATE_FORMAT = "MM/dd/yyyy";

const LinkLikeStyled = tw(LinkLike)<{ $sameAsRelease: boolean }>`
  text-xs
  ${({ $sameAsRelease }) => ($sameAsRelease ? "text-blue-500" : "text-orange-500")}
`;
const DatePickerContainer = tw.div`relative`;
const DateGroup = tw.div`relative flex items-center gap-1`;
const Exclamation = tw(
  ExclamationCircleRed,
)`absolute bg-orange-500 w-4 h-4 -left-5 -top-[3px]`;
const DifferentDate = tw.div`text-orange-500 relative flex items-center font-semibold`;
const CancelButton = tw(LinkLike)`text-orange-500 absolute -right-6 w-5 h-5`;
const DateValue = tw.div`text-2xs font-normal`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly?: boolean;
};

export const DistributorReleaseDeliveryDate: FC<Props> = ({
  item,
  readonly,
}) => {
  const intl = useIntl();
  const { release, updateVendorReleaseItem, updatedItem, updateVendorRelease } =
    useDistributorRelease();
  const { openDialog } = useDialog();
  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const date = useMemo<Date | null>(
    () => new Date(item.deliveryDate || release?.time || ""),
    [item, release],
  );

  const onSave = useCallback(
    async (time: AlternativeFulfillmentInput) => {
      if (
        time.quantityDecimal &&
        time.quantityDecimal !== item.quantityDecimal &&
        time.time !== release?.time
      ) {
        await updateVendorRelease({
          addedItems: [
            {
              sourceReleaseItemId: `${item.id}${DUPLICATE_RELEASE_ITEM_ID_SUFFIX}${generateUUID()}`,
              deliveryDate: time.time,
              quantity: time.quantityDecimal,
              position: item.position,
              uomID: item.uom?.id,
              unitPrice: item.unitPrice,
            },
          ],
          updates: [
            {
              releaseItemId: item.id,
              quantityDecimal: new Decimal(item.quantityDecimal)
                .minus(time.quantityDecimal ?? 0)
                .toString(),
            },
          ],
        });
      } else {
        await updateVendorReleaseItem({
          releaseItemId: item.id,
          deliveryDate: time.time,
        });
      }
    },
    [item, updateVendorReleaseItem, updateVendorRelease, release?.time],
  );

  const clearDeliveryDate = () => {
    if (!release) {
      return;
    }

    if (item.id?.includes(DUPLICATE_RELEASE_ITEM_ID_SUFFIX)) {
      const parentItem = release.items.find((relItem) =>
        item.id.includes(relItem.id),
      );
      if (!parentItem) {
        return;
      }
      updateVendorRelease({
        removedItems: [item.id],
        updates: [
          {
            releaseItemId: parentItem.id,
            quantityDecimal: new Decimal(item.quantityDecimal)
              .plus(Number(parentItem?.quantityDecimal))
              .toString(),
          },
        ],
      });
    } else {
      updateVendorReleaseItem({
        releaseItemId: item.id,
        deliveryDate: undefined,
        clearDeliveryDate: true,
      });
    }
  };

  const openReleaseDateDialog = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "SPECIFY_DELIVERY_DATE" }),
      titleClassName: "text-base text-left font-medium text-blue-800",
      content: (
        <DistributorReleaseDatePicker
          release={release}
          onSave={onSave}
          item={item}
          includeReleaseOptions={false}
          includeBackorderQuantity={true}
        />
      ),
    });
  }, [openDialog, intl, release, onSave, item]);

  const sameAsRelease = useMemo(
    () => !date || release?.time === date.getTime(),
    [release, date],
  );

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  if (!release || !date) {
    return null;
  }

  return readonly ? (
    <DateValue>
      {hasEndDate ? (
        <NotNullableRenderer value={item.deliveryDate}>
          {item.deliveryDate &&
            format(new Date(item.deliveryDate), DATE_FORMAT)}
        </NotNullableRenderer>
      ) : item.deliveryDate ? (
        <DifferentDate>
          <Exclamation />
          {format(new Date(item.deliveryDate), DATE_FORMAT)}
        </DifferentDate>
      ) : (
        format(new Date(date), DATE_FORMAT)
      )}
    </DateValue>
  ) : (
    <DatePickerContainer>
      <DateGroup>
        <LinkLikeStyled
          onClick={openReleaseDateDialog}
          $sameAsRelease={sameAsRelease}
        >
          {hasEndDate ? (
            item.deliveryDate ? (
              format(new Date(item.deliveryDate), DATE_FORMAT)
            ) : (
              <FormattedMessage id="SPECIFY_DATE" />
            )
          ) : (
            format(new Date(date), DATE_FORMAT)
          )}
        </LinkLikeStyled>
        <If isTrue={!sameAsRelease}>
          <CancelButton onClick={clearDeliveryDate}>
            <Close className="h-5 w-5" />
          </CancelButton>
        </If>
      </DateGroup>
    </DatePickerContainer>
  );
};
