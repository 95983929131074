import {
  OrderTypeFieldsFragment,
  ReleaseFieldsFragment,
  TaxCodeFieldsFragment,
  TaxMode,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";

type Entity = Pick<
  ReleaseFieldsFragment,
  "taxRate" | "customTaxAmount" | "subtotal"
> & {
  taxCode?: Pick<TaxCodeFieldsFragment, "rate"> | undefined | null;
} & {
  type?: Pick<OrderTypeFieldsFragment, "taxMode"> | undefined;
} & {
  chargesAmount?: string | undefined | null;
};

export const getTotalTaxAmount = (entity: Entity) => {
  if (entity.type?.taxMode === TaxMode.Column) {
    if (entity.taxCode) {
      return new Decimal(entity.subtotal || 0)
        .plus(entity.chargesAmount || 0)
        .mul(entity.taxCode.rate || 0)
        .toString();
    }
  } else {
    if (entity.customTaxAmount) {
      return entity.customTaxAmount;
    }
    if (entity.taxRate) {
      return new Decimal(entity.subtotal || 0)
        .plus(entity.chargesAmount || 0)
        .mul(entity.taxRate)
        .toString();
    }
  }

  return "0";
};

export const getIsNativeSalesTax = (
  orderType: Pick<OrderTypeFieldsFragment, "taxMode"> | undefined,
) => {
  return orderType?.taxMode === TaxMode.Column;
};

export const getTaxRate = (
  entity: Pick<
    UpdateContractorReleaseInput,
    "taxRate" | "customTaxAmount" | "taxCodeId"
  >,
) => {
  if (entity.taxCodeId || entity.customTaxAmount) {
    return undefined;
  }

  return entity.taxRate || "0";
};
