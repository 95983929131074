import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { Check, Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  index?: number;
  readonly?: boolean;
  setEditMode: (value: boolean) => void;
};
const IconButtonContainer = tw.div`grid gap-2 grid-cols-3`;

export const MaterialItemActions: FC<Props> = ({
  item,
  readonly = false,
  setEditMode,
}: Props) => {
  const { commitUpdate, removeMaterial } = useMaterialList();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const onCancel = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  const onSave = useCallback(async () => {
    await commitUpdate(item.id);
    setEditMode(false);
  }, [commitUpdate, item, setEditMode]);

  const onDelete = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "CONFIRM_DELETE_MATERIAL_TITLE" }),
      text: intl.$t(
        { id: "CONFIRM_DELETE_MATERIAL_DESCRIPTION" },
        { ...item.referenceCounts },
      ),
      handleConfirm: () => {
        removeMaterial(item.id);
      },
    });
  }, [intl, item.id, item.referenceCounts, openDialog, removeMaterial]);

  const icons = useMemo(() => {
    if (!readonly) {
      return [
        <LinkLike key={0} onClick={onSave} className="col-start-2">
          <Check />
        </LinkLike>,
        <LinkLike key={1} onClick={onCancel} className="col-start-3">
          <Close />
        </LinkLike>,
      ];
    } else {
      return [
        <LinkLike key={0} onClick={onEdit} className="col-start-2">
          <EditOutlined />
        </LinkLike>,
        <LinkLike key={1} onClick={onDelete} className="col-start-3">
          <DeleteOutline />
        </LinkLike>,
      ];
    }
  }, [readonly, onSave, onCancel, onEdit, onDelete]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
