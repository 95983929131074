import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import {
  OrgRole,
  PoNumberingMode,
  SystemRole,
  UpdateOrgInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import {
  Container,
  FloatingFooterStyled,
} from "../../common/Organization.styles";
import { OrderNotificationsSelector } from "./order-notifications-management/OrderNotificationsSelector";
import { PoNumberingSelector } from "./po-management/PoNumberingSelector";

const SettingsContainer = tw.div`grid w-fit`;
const ItemContainer = tw.div<{ $disabled?: boolean | null }>`
  grid grid-flow-col gap-x-25 w-full items-center justify-between border-b border-gray-200 py-4 
  ${({ $disabled }) => ($disabled ? "opacity-50" : "")}
`;
const SwitchContainer = tw.div`flex items-center space-x-2`;
const Label = tw.div``;

export const OrgSettingsForm = () => {
  const intl = useIntl();
  const { updating, updateOrgSettings } = useUpdateOrgSettings();
  const { setSuccessAlert } = useSnackbar();
  const { viewer } = useStartupDataStore(
    useShallow((state) => ({
      viewer: state.viewer,
    })),
  );
  const { hasRoles } = useRoles({ roles: [OrgRole.OrgRestrictedAdmin] });
  const isFMAdmin = useMemo(() => {
    return viewer?.systemRoles.includes(SystemRole.FmAdmin);
  }, [viewer]);
  const { connectedSourceSystem, connectedIntegrationType } = useOrgSettings();
  const { handleSubmit, formState, reset, watch, setValue } =
    useFormContext<UpdateOrgInput>();
  const itemsCostTypes = watch("settings.display.itemCostTypes");
  const poNumbering = watch("settings.releases.poNumbering");

  useEffect(() => {
    if (itemsCostTypes) {
      setValue("settings.display.enableManufacturers", false);
    }
  }, [itemsCostTypes, setValue]);

  const onSaveHandler = useCallback(
    async (values: UpdateOrgInput) => {
      let result = null;
      if (values.id) {
        result = await updateOrgSettings({
          id: values.id,
          display: {
            enableManufacturers:
              values.settings?.display?.enableManufacturers ?? false,
            itemCostTypes: values.settings?.display?.itemCostTypes ?? false,
          },
          releases: {
            poNumbering: values.settings?.releases?.poNumbering,
            notifications: values.settings?.releases?.notifications,
            useSourceSystemPOs: values.settings?.releases?.useSourceSystemPOs,
          },
        });
      }

      if (!result) {
        return;
      }
      setSuccessAlert(intl.$t({ id: "ORG_SETTINGS_UPDATE_SUCCESS" }));
    },
    [setSuccessAlert, intl, updateOrgSettings],
  );

  const connectedSystem = useMemo(() => {
    if (connectedSourceSystem) {
      return intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` });
    }
    if (connectedIntegrationType) {
      return intl.$t({ id: `INTEGRATION_${connectedIntegrationType}` });
    }
    return "";
  }, [connectedIntegrationType, connectedSourceSystem, intl]);

  return (
    <Container>
      <SettingsContainer>
        <If isTrue={!hasRoles}>
          <If isTrue={itemsCostTypes}>
            <Tooltip
              id="ENABLE_MANUFACTURERS_ASSIGNMENT_TOOLTIP"
              element={
                <ItemContainer $disabled={itemsCostTypes}>
                  <Label>
                    <FormattedMessage id="ENABLE_MANUFACTURERS_ASSIGNMENT" />
                  </Label>
                  <SwitchContainer>
                    <InfoTooltip
                      message={intl.$t({
                        id: "ENABLE_MANUFACTURERS_ASSIGNMENT_TOOLTIP",
                      })}
                    />
                    <SwitchControlled
                      name="settings.display.enableManufacturers"
                      width={60}
                      offLabel={intl.$t({ id: "NO" })}
                      onLabel={intl.$t({ id: "YES" })}
                      disabled
                    />
                  </SwitchContainer>
                </ItemContainer>
              }
            >
              <Label>
                <FormattedMessage id="MANUFACTURER_DISABLED_IF_COST_TYPES_ENABLED" />
              </Label>
            </Tooltip>
          </If>
          <If isTrue={!itemsCostTypes}>
            <ItemContainer $disabled={itemsCostTypes}>
              <Label>
                <FormattedMessage id="ENABLE_MANUFACTURERS_ASSIGNMENT" />
              </Label>
              <SwitchContainer>
                <InfoTooltip
                  message={intl.$t({
                    id: "ENABLE_MANUFACTURERS_ASSIGNMENT_TOOLTIP",
                  })}
                />
                <SwitchControlled
                  name="settings.display.enableManufacturers"
                  width={60}
                  offLabel={intl.$t({ id: "NO" })}
                  onLabel={intl.$t({ id: "YES" })}
                />
              </SwitchContainer>
            </ItemContainer>
          </If>
        </If>
        <ItemContainer>
          <Label>
            <FormattedMessage id="PO_MANAGEMENT" />
          </Label>
          <PoNumberingSelector />
        </ItemContainer>
        <If isTrue={poNumbering !== PoNumberingMode.Never}>
          <SourceSystemWrapper anyIntegrationType anySourceSystem>
            <ItemContainer>
              <Label>
                <FormattedMessage
                  id="USE_PO_IN_SOURCE_SYSTEM"
                  values={{ sourceSystem: connectedSystem }}
                />
              </Label>
              <SwitchContainer>
                <InfoTooltip
                  message={intl.$t(
                    {
                      id: "USE_SOURCE_SYSTEM_PO_TOOLTIP",
                    },
                    { sourceSystem: connectedSystem },
                  )}
                />
                <SwitchControlled
                  name="settings.releases.useSourceSystemPOs"
                  width={60}
                  offLabel={intl.$t({ id: "NO" })}
                  onLabel={intl.$t({ id: "YES" })}
                />
              </SwitchContainer>
            </ItemContainer>
          </SourceSystemWrapper>
        </If>
        <ItemContainer>
          <Label>
            <FormattedMessage id="ORDER_NOTIFICATION" />
          </Label>
          <OrderNotificationsSelector />
        </ItemContainer>
        <If isTrue={isFMAdmin}>
          <ItemContainer>
            <Label>
              <FormattedMessage id="ENABLE_COST_TYPES_SUPPORT" />
            </Label>
            <SwitchContainer>
              <InfoTooltip
                message={intl.$t({
                  id: "ENABLE_COST_TYPES_SUPPORT_TOOLTIP",
                })}
              />
              <SwitchControlled
                name="settings.display.itemCostTypes"
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
              />
            </SwitchContainer>
          </ItemContainer>
        </If>
      </SettingsContainer>
      <FloatingFooterStyled>
        <If isTrue={formState.isDirty}>
          <OutlinedButton wide onClick={() => reset()}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButton>
        </If>
        <PrimaryButton
          onClick={handleSubmit(onSaveHandler)}
          loading={updating}
          wide
        >
          <FormattedMessage id="SAVE" />
        </PrimaryButton>
      </FloatingFooterStyled>
    </Container>
  );
};
