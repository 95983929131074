import { SearchOutlined } from "@mui/icons-material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { TextField } from "../textfield/TextField";
import { SelectCustomView } from "./SelectCustomView";

type SearchInputProps = {
  value?: string | null;
  onChange?: (value: string) => void;
  placeHolder?: string;
  updateOnChange?: boolean;
  info?: React.ReactNode;
  className?: string;
  applyHeaderAccent?: boolean;
  clear?: () => void;
};

const SearchInputContainer = tw.div`w-full flex items-center bg-transparent`;
const InputContainer = tw.div`relative w-full rounded-md bg-white`;
const InfoContainer = tw.div`ml-3`;

const SearchIcon = tw(
  SearchOutlined,
)`text-gray-600 cursor-pointer focus:text-black hover:text-black`;

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeHolder,
  updateOnChange,
  info = null,
  className,
  applyHeaderAccent,
  clear,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = () => {
    if (onChange) {
      onChange(searchText.trim());
    }
  };

  const showCustomView = useMemo(
    () => applyHeaderAccent && !isFocused && value,
    [applyHeaderAccent, isFocused, value],
  );

  useEffect(() => {
    setSearchText(value || "");
  }, [value]);

  return (
    <SearchInputContainer className={className}>
      <InputContainer>
        <TextField
          inputRef={ref}
          value={searchText}
          onClickEnter={handleKeyDown}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
            if (updateOnChange && onChange) {
              onChange(event.target.value);
            }
          }}
          onBlur={(event) => {
            setIsFocused(false);
            if (onChange) {
              onChange(event.currentTarget.value.trim());
            }
          }}
          onFocus={() => setIsFocused(true)}
          fullWidth
          label={placeHolder}
          InputProps={{
            endAdornment: (
              <SearchIcon onClick={() => onChange && onChange(searchText)} />
            ),
            className: showCustomView ? "text-transparent" : "",
          }}
        />
        {showCustomView ? (
          <SelectCustomView
            text={searchText}
            clear={
              clear
                ? () => {
                    clear();
                    ref.current?.focus();
                  }
                : undefined
            }
            onClick={() => {
              ref.current?.focus();
            }}
          />
        ) : null}
      </InputContainer>
      <If isTrue={info}>
        <InfoContainer>{info}</InfoContainer>
      </If>
    </SearchInputContainer>
  );
};
