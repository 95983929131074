import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { routes } from "@/config/routes";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { SubmitReleaseButton } from "@/contractor/pages/home/release/pages/specify-details/footer-actions/SubmitReleaseButton";
import { useReleaseItemsToSubmit } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseItemsToSubmit";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { ReceiveReleaseDialog } from "../../../receive-release-dialog/ReceiveReleaseDialog";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";
import { SubmitOrderFromQuoteDialog } from "./SubmitOrderFromQuoteDialog";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateOrderFromQuoteFooter: FC = () => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { release } = useRelease();
  const { quoteDocument } = useQuoteDocument();
  const {
    syncCreateReleaseFromQuote,
    syncUpdateReleaseFromQuote,
    validateSpreadsheet,
    loading,
    vendors,
    createLumpSumRelease,
    updateLumpSumRelease,
    itemized,
  } = useOrderFromQuote();
  const items = useReleaseItemsToSubmit();

  const [isSaving, setIsSaving] = useState(false);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);

  const { reset, watch, handleSubmit } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const createAndSubmitOrder = useCallback(
    async (
      skipVendorNotification: boolean,
      skipConfirmation: boolean,
      retroactive: boolean,
    ) => {
      if (release) {
        const result = await syncUpdateReleaseFromQuote();
        if (result) {
          reset();
        }
        return result;
      }

      const result = itemized
        ? await syncCreateReleaseFromQuote({
            skipVendorNotification,
            skipConfirmation,
            retroactive,
          })
        : await createLumpSumRelease({
            skipVendorNotification,
            skipConfirmation,
            retroactive,
          });

      if (result) {
        reset();
      }

      return result;
    },
    [
      itemized,
      reset,
      syncCreateReleaseFromQuote,
      createLumpSumRelease,
      release,
      syncUpdateReleaseFromQuote,
    ],
  );

  const createAndEditOrder = useCallback(async () => {
    const result = await syncCreateReleaseFromQuote({});
    if (result) {
      reset();
    }
    return result;
  }, [reset, syncCreateReleaseFromQuote]);

  const orderDate = watch("orderDate");

  const retroactiveRelease = useMemo(() => {
    return orderDate && orderDate?.getTime() < new Date().getTime();
  }, [orderDate]);

  const handleSaveReleaseFromQuote = useCallback(async () => {
    await handleSubmit(async () => {
      setIsSaving(true);
      if (deliveryId) {
        if (itemized) {
          await syncUpdateReleaseFromQuote();
        } else {
          await updateLumpSumRelease();
        }
      } else {
        const result = itemized
          ? await syncCreateReleaseFromQuote({
              status: ReleaseStatus.Draft,
            })
          : await createLumpSumRelease({
              status: ReleaseStatus.Draft,
            });

        if (result) {
          navigate(
            generatePath(routes.editDeliveryFromQuote, {
              quoteDocumentId: quoteDocument?.id,
              deliveryId: result.id,
            }),
          );
        }
      }
      setIsSaving(false);
    })();
  }, [
    handleSubmit,
    deliveryId,
    syncUpdateReleaseFromQuote,
    syncCreateReleaseFromQuote,
    navigate,
    quoteDocument?.id,
    itemized,
    createLumpSumRelease,
    updateLumpSumRelease,
  ]);

  const selectedVendorId = watch("vendorId");
  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === selectedVendorId),
    [vendors, selectedVendorId],
  );

  const handleCreateReleaseFromQuote = useCallback(async () => {
    await handleSubmit(() => {
      if (itemized ? validateSpreadsheet() : true) {
        setVisibleConfirmDialog(true);
      }
    })();
  }, [itemized, handleSubmit, validateSpreadsheet]);

  const onCancel = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, {
          deliveryId: release.id,
        }),
      );
    } else {
      reset();
      navigate(routes.deliveries);
    }
  }, [navigate, release, reset]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OutlinedButton className="py-0" onClick={onCancel}>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <OutlinedButton
          className="py-0"
          onClick={handleSaveReleaseFromQuote}
          loading={isSaving && loading}
          disabled={!isSaving && loading}
        >
          <FormattedMessage id="SAVE" />
        </OutlinedButton>
        {release ? (
          <SubmitReleaseButton release={release} items={items} />
        ) : (
          <PrimaryButton
            className="py-0"
            onClick={handleCreateReleaseFromQuote}
          >
            <FormattedMessage
              id={
                selectedVendor && selectedVendor.contacts.length === 0
                  ? "SCHEDULE_ORDER"
                  : "SUBMIT_RELEASE"
              }
            />
          </PrimaryButton>
        )}
      </ButtonContainer>
      <SubmitOrderFromQuoteDialog
        visible={visibleConfirmDialog}
        setVisible={setVisibleConfirmDialog}
        onSubmit={createAndSubmitOrder}
        onEdit={createAndEditOrder}
        retroactiveRelease={retroactiveRelease}
      />
      <ReceiveReleaseDialog />
    </FloatingFooterStyled>
  );
};
