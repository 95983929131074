import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  AddOrgLocationInput,
  AddOrgPreferredVendorInput,
  AddVendorOrgInput,
  namedOperations,
  OrgPreferredVendorsDocument,
  UpdateOrgLocationInput,
  UpdateOrgPreferredVendorInput,
  useAddVendorLocationMutation,
  useAddVendorOrgMutation,
  useCreateVendorMutation,
  useRemoveVendorMutation,
  useUpdateVendorLocationMutation,
  useUpdateVendorMutation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useVendorMutations = () => {
  const { setError } = useGlobalError();

  const [createVendorMutation, { loading: createVendorLoader }] =
    useCreateVendorMutation({
      update: (cache) =>
        cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
    });
  const [updateVendorMutation, { loading: updateVendorLoader }] =
    useUpdateVendorMutation({
      update: (cache) =>
        cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
    });
  const [removeVendorMutation] = useRemoveVendorMutation({
    update: (cache) =>
      cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
  });
  const [addVendorOrgMutation] = useAddVendorOrgMutation({
    update: (cache) =>
      cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
  });
  const [addVendorLocationMutation] = useAddVendorLocationMutation({
    update: (cache) =>
      cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
  });
  const [updateVendorLocationMutation] = useUpdateVendorLocationMutation({
    update: (cache) =>
      cleanQuery(cache, namedOperations.Query.AdminOrgPreferredVendors),
  });

  const loading = useMemo(
    () => createVendorLoader || updateVendorLoader,
    [createVendorLoader, updateVendorLoader],
  );

  const createVendorOrg = useCallback(
    async (vendor: AddVendorOrgInput) => {
      try {
        const { data, errors } = await addVendorOrgMutation({
          variables: { input: vendor },
        });
        setError(errors);
        return data?.addVendorOrg;
      } catch (errors) {
        setError(errors);
      }
    },
    [addVendorOrgMutation, setError],
  );

  const createVendor = useCallback(
    async (vendor: AddOrgPreferredVendorInput) => {
      try {
        const { data, errors } = await createVendorMutation({
          variables: { input: vendor },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        setError(errors);
        return data?.addOrgPreferredVendor.find(
          (o) => o.sellerOrgLocation.id === vendor.orgLocationID,
        );
      } catch (errors) {
        setError(errors);
      }
    },
    [createVendorMutation, setError],
  );

  const updateVendor = useCallback(
    async (vendor: UpdateOrgPreferredVendorInput) => {
      try {
        const { data, errors } = await updateVendorMutation({
          variables: { input: vendor },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        setError(errors);
        return data?.updateOrgPreferredVendor;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateVendorMutation, setError],
  );

  const removeVendor = useCallback(
    async (id: string) => {
      try {
        const { errors } = await removeVendorMutation({
          variables: { id },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [removeVendorMutation, setError],
  );

  const addVendorLocation = useCallback(
    async (vendorLocation: AddOrgLocationInput) => {
      try {
        const { data, errors } = await addVendorLocationMutation({
          variables: {
            input: { ...vendorLocation, name: vendorLocation.name || null },
          },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
          awaitRefetchQueries: true,
        });
        setError(errors);
        return data?.addVendorLocation;
      } catch (errors) {
        setError(errors);
      }
    },
    [addVendorLocationMutation, setError],
  );

  const updateVendorLocation = useCallback(
    async (updatedVendorLocation: UpdateOrgLocationInput) => {
      try {
        const { data, errors } = await updateVendorLocationMutation({
          variables: {
            input: updatedVendorLocation,
          },
          refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        });
        setError(errors);
        return data?.updateVendorLocation;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateVendorLocationMutation, setError],
  );

  return {
    loading,
    createVendorOrg,
    createVendor,
    updateVendor,
    removeVendor,
    addVendorLocation,
    updateVendorLocation,
  };
};
