import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo } from "react";
import {
  LOCAL_STORAGE_KEYS,
  SIGNATURE_HEADER_NAME,
  SIGNATURE_PREFIX,
} from "../const";
import { readValue, setValue } from "../utils/localStorage";
import { useQueryParams } from "./useQueryParams";

export const useAuthentication = () => {
  const { getAccessTokenSilently, logout: auth0Logout } = useAuth0();
  const { queryParams } = useQueryParams();

  const logout = useCallback(
    async (returnUrl: string | undefined = undefined) => {
      setValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID, null);
      await auth0Logout({
        logoutParams: { returnTo: returnUrl || window.location.origin },
      });
    },
    [auth0Logout],
  );

  const getAuthenticationHeader = useCallback(
    async ({ force } = { force: false }) => {
      if (queryParams.get(SIGNATURE_HEADER_NAME)) {
        const result = {} as Record<string, string>;
        [...queryParams.entries()]
          .filter((entry) => entry[0].startsWith(SIGNATURE_PREFIX))
          .forEach((entry) => (result[entry[0]] = entry[1]));
        return result;
      }
      let token = null;
      try {
        const orgId = readValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID);
        token = await getAccessTokenSilently({
          cacheMode: force ? "off" : "on",
          authorizationParams: { orgId: orgId || undefined },
        });
      } catch (e) {
        console.error(e);
        await logout();
      }
      if (token) {
        return { Authorization: `Bearer ${token}` };
      }
      return {};
    },
    [getAccessTokenSilently, logout, queryParams],
  );

  return useMemo(() => {
    return {
      getAuthenticationHeader,
      logout,
    };
  }, [getAuthenticationHeader, logout]);
};
