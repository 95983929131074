import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { dateDiffInDays } from "../../scanned-invoices/utils/dateDiff";
import {
  invoiceCreateReleaseFormDefaultValues,
  InvoiceCreateReleaseFormValues,
} from "../components/matched-order/components/InvoiceVerificationForm";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useInvoiceImportExternalPO } from "./useInvoiceImportExternalPO";

const EDITABLE_ORDER_VIEW_STATES = [
  MatchedOrderViewState.CREATE_ORDER,
  MatchedOrderViewState.EDIT_ORDER,
  MatchedOrderViewState.IMPORT_ORDER,
];

export const useInvoiceOrderEdit = () => {
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { reset, getValues } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { locations } = useUserLocations();
  const { projects } = useProjectListOptions();
  const { warehouses } = useWarehouses();
  const { vendors, findOrderTypeByLocationId } = useVendors();
  const {
    importedPoNumber,
    importedProjectId,
    importedOrderTypeId,
    importedSellerOrgLocationId,
    importedLocationId,
    importedTaxType,
    importedTaxAmount,
    importedCustomTaxAmount,
    importedTaxCodeId,
    importedPoDate,
    importedAdditionalCharges,
  } = useInvoiceImportExternalPO();
  const { defaultOrderType } = useOrderTypeOptions();
  const intl = useIntl();

  const isOrderInEditMode = useMemo(
    () =>
      EDITABLE_ORDER_VIEW_STATES.includes(
        matchedOrderViewState ?? MatchedOrderViewState.DEFAULT,
      ),
    [matchedOrderViewState],
  );

  useEffect(() => {
    if (isOrderInEditMode) {
      const projectId =
        getValues("projectId") ||
        invoice?.release?.project?.id ||
        importedProjectId ||
        invoice?.predictedProject?.id ||
        (projects.length === 1 ? projects[0].id : undefined);

      const vendorId =
        getValues("vendorId") ||
        invoice?.release?.sellerOrgLocation?.id ||
        importedSellerOrgLocationId ||
        invoice?.predictedSellerOrgLocation?.id ||
        (vendors.length === 1 ? vendors[0].sellerOrgLocation.id : undefined);

      const fulfillmentLocationId =
        getValues("fulfillmentLocationId") ||
        invoice?.release?.warehouse?.id ||
        (warehouses.length === 1 ? warehouses[0].id : undefined) ||
        projectId;

      const orderTypeId =
        getValues("orderTypeId") ||
        invoice?.release?.type.id ||
        importedOrderTypeId ||
        (vendorId ? findOrderTypeByLocationId(vendorId) : undefined) ||
        defaultOrderType?.id;

      const poNumber = includePoNumbering
        ? (getValues("poNumber") ??
          (invoice?.release?.poNumber ||
            importedPoNumber ||
            invoice?.poNumber ||
            ""))
        : undefined;

      const orderDate =
        getValues("orderDate") ||
        getUTCDate(
          invoice?.release?.time || importedPoDate || invoice?.issueDate,
        );

      const taxCodeId =
        getValues("taxCodeId") ?? importedTaxCodeId ?? undefined;

      const customTaxAmount =
        getValues("customTaxAmount") ||
        (invoice?.release?.customTaxAmount &&
        invoice?.release.customTaxAmount !== "0"
          ? invoice?.release.customTaxAmount
          : undefined) ||
        importedTaxAmount ||
        importedCustomTaxAmount ||
        (invoice?.taxAmount !== "0" ? invoice?.taxAmount : undefined) ||
        undefined;

      const additionalCharges =
        getValues("additionalCharges") ??
        (invoice?.release ? invoice?.release.additionalCharges : undefined) ??
        importedAdditionalCharges ??
        (invoice?.chargesAmount && Number(invoice.chargesAmount) > 0
          ? [
              {
                id: null,
                amount: invoice?.chargesAmount,
                description: intl.$t({ id: "ADDITIONAL_CHARGES" }),
              },
            ]
          : undefined) ??
        invoice?.charges ??
        [];

      const subtotal =
        getValues("subtotal") ||
        (invoice?.release?.subtotal
          ? Number(invoice?.release.subtotal)
          : undefined) ||
        Number(invoice?.subtotal);

      const total = invoice?.total ?? "0";

      const paymentTerm =
        invoice?.dueDate && invoice?.issueDate
          ? dateDiffInDays(invoice.dueDate, invoice.issueDate)?.toString()
          : "";

      const taxType = getValues("taxType") ?? importedTaxType ?? undefined;

      reset({
        ...getValues(),
        projectId,
        vendorId,
        fulfillmentLocationId,
        poNumber,
        orderTypeId,
        orderDate,
        customTaxAmount,
        taxRate: undefined,
        additionalCharges,
        subtotal,
        total,
        paymentTerm,
        taxType,
        taxCodeId,
      });
    } else {
      reset(invoiceCreateReleaseFormDefaultValues);
    }
  }, [
    isOrderInEditMode,
    invoice,
    getValues,
    includePoNumbering,
    reset,
    locations,
    projects,
    warehouses,
    vendors,
    defaultOrderType,
    findOrderTypeByLocationId,
    intl,
    importedLocationId,
    importedProjectId,
    importedSellerOrgLocationId,
    importedOrderTypeId,
    importedTaxType,
    importedTaxAmount,
    importedTaxCodeId,
    importedPoNumber,
    importedPoDate,
    importedCustomTaxAmount,
    importedAdditionalCharges,
  ]);
};
