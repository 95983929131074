import { useTaxCodeSummaries } from "@/common/components/sales-tax-input/hooks/useTaxCodeSummaries";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { SalesTaxInputProps } from "../types/SalesTaxInputProps";
import { useTaxCalculation } from "./useTaxCalculation";
import { useTaxType } from "./useTaxType";

type Props = {
  params: Pick<
    SalesTaxInputProps,
    "fallbackTaxCodes" | "salesTaxInput" | "fallbackOrderTypes"
  >;
  options: {
    taxCode?: string | null;
    canEdit?: boolean;
  };
};

export const useFormattedSalesTax = ({ params, options }: Props) => {
  const { fallbackTaxCodes, fallbackOrderTypes, salesTaxInput } = params;
  const { taxCode, canEdit } = options;
  const intl = useIntl();
  const { taxCodes: allTaxCodes } = useTaxCodeSummaries();
  const taxCodes = useMemo(
    () => (allTaxCodes.length ? allTaxCodes : fallbackTaxCodes || []),
    [allTaxCodes, fallbackTaxCodes],
  );
  const { getTaxAmount } = useTaxCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();

  const subtotalWithCharges = useMemo(
    () =>
      new Decimal(salesTaxInput.subtotal || 0).add(
        salesTaxInput.chargesAmount || 0,
      ),
    [salesTaxInput.subtotal, salesTaxInput.chargesAmount],
  );

  const calculatedTaxAmount = useMemo(() => {
    return subtotalWithCharges.mul(salesTaxInput.taxRate || 0).toNumber();
  }, [subtotalWithCharges, salesTaxInput.taxRate]);

  const { isNativeSalesTax } = useTaxType(
    salesTaxInput.orderTypeId,
    fallbackOrderTypes,
    salesTaxInput.taxType,
  );

  const formattedTaxAmount = useMemo(() => {
    let taxAmount: number | undefined | string = undefined;
    if (isNativeSalesTax) {
      taxAmount = getTaxAmount(
        salesTaxInput.taxRate,
        salesTaxInput.customTaxAmount,
        salesTaxInput.subtotal,
      );
    } else {
      taxAmount = salesTaxInput.customTaxAmount ?? calculatedTaxAmount;
    }
    return `${formatCurrency(taxAmount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
  }, [
    calculatedTaxAmount,
    formatCurrency,
    getTaxAmount,
    isNativeSalesTax,
    salesTaxInput.customTaxAmount,
    salesTaxInput.subtotal,
    salesTaxInput.taxRate,
  ]);

  const formattedNativeSalesTax = useMemo(() => {
    const code = taxCodes.find(
      (code) => code.id === taxCode || code.id === salesTaxInput.taxCodeId,
    );
    if (!code) {
      return;
    }
    const amount = new Decimal(salesTaxInput.subtotal || 0)
      .add(salesTaxInput.chargesAmount || 0)
      .mul(code.rate || 0)
      .toNumber();

    return `${intl.formatNumber(new Decimal(code?.rate || "0").mul(100).toNumber())}% (${formatCurrency(amount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })})`;
  }, [
    taxCodes,
    salesTaxInput.subtotal,
    salesTaxInput.chargesAmount,
    salesTaxInput.taxCodeId,
    intl,
    formatCurrency,
    taxCode,
  ]);

  const formattedSalesTax = useMemo(() => {
    const formattedRate = new Decimal(salesTaxInput.taxRate || 0)
      .mul(100)
      .toString();
    if (canEdit) {
      return formattedRate;
    }

    if (taxCode || salesTaxInput.taxCodeId) {
      return formattedNativeSalesTax;
    }

    if (salesTaxInput.customTaxAmount) {
      return formattedTaxAmount;
    }

    return `${intl.formatNumber(Number(formattedRate))}% (${formattedTaxAmount})`;
  }, [
    salesTaxInput.taxRate,
    salesTaxInput.taxCodeId,
    salesTaxInput.customTaxAmount,
    canEdit,
    taxCode,
    intl,
    formattedTaxAmount,
    formattedNativeSalesTax,
  ]);

  return {
    formattedNativeSalesTax,
    formattedSalesTax,
    formattedTaxAmount,
    calculatedTaxAmount,
  };
};
