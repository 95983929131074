import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { ProjectCostCodeFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useContractorBuyout } from "../../buyout/providers/ContractorBuyoutProvider";
import { useRelease } from "../../release/providers/ReleaseProvider";
import { useProjectStore } from "../store/useProjectStore";

type Options = {
  includeUnspecifiedCostCode?: boolean;
  selectedCostCodeId?: string | null;
  zoneId?: string | null;
};

type ProjectCostCode = ProjectCostCodeFieldsFragment & {
  isExcluded?: boolean;
  formatted: string;
};

export const useProjectCostCodes = (options?: Options) => {
  const { projectStoreCostCodes, restrictCostCodes, loading, allowance } =
    useProjectStore(
      useShallow((state) => ({
        projectStoreCostCodes: state.costCodes,
        restrictCostCodes: state.restrictCostCodes,
        loading: state.loading,
        allowance: state.allowance,
      })),
    );

  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { costCodes, formatCostCode } = useCostCodes();
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();
  const { orderTypes } = useOrderTypes();
  const intl = useIntl();

  const projectCostCodes = useMemo(() => {
    if (restrictCostCodes) {
      return (
        projectStoreCostCodes?.map((cc) => ({
          ...cc,
          formatted: formatCostCode(cc),
        })) || []
      );
    }

    return costCodes || [];
  }, [costCodes, projectStoreCostCodes, restrictCostCodes, formatCostCode]);

  const allCostCodes = useMemo((): ProjectCostCode[] => {
    if (!options?.selectedCostCodeId) {
      return projectCostCodes;
    }
    if (
      !projectCostCodes?.find(
        (costCode) => costCode.id === options?.selectedCostCodeId,
      )
    ) {
      const existingCostCode = costCodes.find(
        (costCode) => costCode.id === options.selectedCostCodeId,
      );
      if (existingCostCode) {
        return [
          ...projectCostCodes,
          ...(existingCostCode
            ? [
                {
                  ...existingCostCode,
                  isExcluded: true,
                  formatted: formatCostCode(existingCostCode),
                },
              ]
            : []),
          ...(options?.includeUnspecifiedCostCode
            ? [
                {
                  ...unassignedCostCode,
                  formatted: unassignedCostCode.description,
                },
              ]
            : []),
        ];
      }
    }

    return [
      ...projectCostCodes,
      ...(options?.includeUnspecifiedCostCode
        ? [
            {
              ...unassignedCostCode,
              formatted: unassignedCostCode.description,
            },
          ]
        : []),
    ];
  }, [
    costCodes,
    formatCostCode,
    options?.includeUnspecifiedCostCode,
    options?.selectedCostCodeId,
    projectCostCodes,
    unassignedCostCode,
  ]);

  const costCodeIsAllowed = useCallback(
    (costCodeId: string) => {
      return projectCostCodes.some(
        (projectCostCode) => projectCostCode.id === costCodeId,
      );
    },
    [projectCostCodes],
  );

  const budgetIsPerZone = useMemo(() => {
    return (
      allowance.costCodes.reduce((acc, costCode) => {
        if (costCode.zone?.id) {
          acc.push(costCode.zone?.id);
        }
        return acc;
      }, [] as string[]).length > 0
    );
  }, [allowance]);

  const zoneBudgetAllowance = useCallback(
    (costCodeId: string, zoneId?: string) => {
      if (!zoneId) {
        return allowance.costCodes.find(
          (costCode) => costCode.costCode.id === costCodeId,
        );
      }
      return allowance.costCodes.find(
        (costCode) =>
          costCode.costCode.id === costCodeId && costCode.zone?.id === zoneId,
      );
    },
    [allowance],
  );

  const zoneHasBudgetAllowance = useCallback(
    (costCodeId: string, zoneId?: string) => {
      const allowance = zoneBudgetAllowance(costCodeId, zoneId);
      return allowance?.amount !== "0";
    },
    [zoneBudgetAllowance],
  );

  const allowanceHasCostType = useCallback(
    (costCodeId: string, zoneId?: string) => {
      const costCode = zoneBudgetAllowance(costCodeId, zoneId);
      return !!costCode?.costTypes?.length;
    },
    [zoneBudgetAllowance],
  );

  const projectUsingCostTypes = useMemo(() => {
    return allowance.costCodes.some(
      (costCode) => costCode.costTypes && costCode.costTypes?.length > 0,
    );
  }, [allowance]);

  const costTypeMatchesOrderType = useCallback(
    (costCodeId: string, zoneId?: string) => {
      if (!buyout && !release) {
        return true;
      }

      const orderType = orderTypes.find(
        (type) =>
          type.id === buyout?.releaseType.id || type.id === release?.type.id,
      );
      if (!orderType) {
        return true;
      }

      const costCode = zoneBudgetAllowance(costCodeId, zoneId);
      return costCode?.costTypes?.some(
        (costType) => costType.id === orderType.costType?.id,
      );
    },
    [zoneBudgetAllowance, buyout, release, orderTypes],
  );

  const costCodeIsNotSupported = useCallback(
    (costCodeText?: null | string, zoneId?: string): string => {
      if (!restrictCostCodes || !costCodeText) {
        return "";
      }

      const costCode = projectCostCodes.find(
        (costCode) =>
          costCode.formatted === costCodeText || costCode.id === costCodeText,
      );
      if (!costCode) {
        return intl.$t({ id: "MISSING_COST_CODE_ERROR" });
      }

      if (!costCodeIsAllowed(costCode.id)) {
        return intl.$t({ id: "MISSING_COST_CODE_ERROR" });
      }

      if (!budgetIsPerZone) {
        return "";
      }

      if (!zoneId && zoneBudgetAllowance(costCode.id, zoneId)?.amount === "0") {
        return "";
      }

      if (!zoneHasBudgetAllowance(costCode.id, zoneId)) {
        return intl.$t({ id: "MISSING_ZONE_ERROR" });
      }

      if (!projectUsingCostTypes) {
        return "";
      }

      if (
        !allowanceHasCostType(costCode.id, zoneId) ||
        !costTypeMatchesOrderType(costCode.id, zoneId)
      ) {
        return intl.$t({ id: "COST_TYPE_DOES_NOT_MATCH_ITEM_COST_TYPE" });
      }

      return "";
    },
    [
      restrictCostCodes,
      intl,
      zoneHasBudgetAllowance,
      allowanceHasCostType,
      costTypeMatchesOrderType,
      budgetIsPerZone,
      projectCostCodes,
      costCodeIsAllowed,
      zoneBudgetAllowance,
      projectUsingCostTypes,
    ],
  );

  const isNotSupportedCostCode = useMemo(() => {
    return !!costCodeIsNotSupported(
      options?.selectedCostCodeId,
      options?.zoneId || undefined,
    );
  }, [costCodeIsNotSupported, options]);

  return {
    projectCostCodes,
    isNotSupportedCostCode,
    costCodes: allCostCodes,
    formatCostCode,
    loading,
    costCodeIsNotSupported,
  };
};
