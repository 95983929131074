import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUser } from "@/common/providers/UserProvider";
import {
  AdditionalChargesFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, ReactNode, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesContainer,
  AdditionalChargesItemContainer,
  ItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../additional-charges/AdditionalCharges.styles";
import { useOrderTypeOptions } from "../order-type-picker/hooks/useOrderTypeOptions";
import {
  OrgRolesWrapper,
  Permission,
} from "../org-roles-wrapper/OrgRolesWrapper";
import { ReleaseTermsAndNotes } from "../release-terms-and-notes/ReleaseTermsAndNotes";
import { SalesTax } from "../sales-tax-input/SalesTax";
import { SalesTaxInput } from "../sales-tax-input/types/SalesTaxInputType";
import { CustomPaymentTerms } from "../terms-and-discounts/CustomPaymentTerms";
import { ReleaseAdditionalCharges } from "./ReleaseAdditionalCharges";
import { ReleaseWithAdditionalChargesAndTaxes } from "./ReleaseAdditionalChargesAndTaxesTypes";

const SubTotalItemContainer = tw(
  AdditionalChargesItemContainer,
)`text-base pr-10`;
const Sub = tw.span`font-normal`;
const Item = tw.div`font-medium text-sm`;
const TotalItem = tw.div`-mr-1`;
const Total = tw.div`flex flex-row items-center gap-1 justify-end`;
const NoPriceContainer = tw.div`flex justify-end items-center`;
const LinkLikeStyled = tw(LinkLike)`pl-1`;
const PartialItems = tw.div`text-xs font-normal text-gray-600`;

export type ReleaseAdditionalChargesAndTaxesProps = {
  release?: ReleaseWithAdditionalChargesAndTaxes | null;
  releaseInput?: SalesTaxInput;
  showError?: boolean;
  total?: string | null;
  customPaymentTerm?: string | null;
  additionalItems?: React.ReactNode;
  editableByContractor?: boolean;
  updateRelease?: (
    input:
      | Omit<UpdateVendorReleaseInput, "addedItems">
      | UpdateContractorReleaseInput,
  ) =>
    | Promise<UpdateVendorReleaseFieldsFragment | boolean | undefined | null>
    | (UpdateVendorReleaseFieldsFragment | boolean | undefined | null);
  includeNotesPanel?: boolean;
  includePaymentTerms?: boolean;
  includeAdditionalCharges?: boolean;
  includeSubtotal?: boolean;
  includeInvoicedTotals?: boolean;
  editablePaymentTerms?: boolean;
  editableAdditionalCharges?: boolean;
  readonly?: boolean;
  additionalTaxes?: ReactNode;
  additionalCharges?: AdditionalChargesFieldsFragment[];
  classes?: {
    total?: string;
    root?: string;
    tax?: string;
  };
  hideTotalBorder?: boolean;
  totalDivider?: ReactNode;
  totalTooltip?: ReactNode;
  itemsCount?: {
    partial: number;
    total: number;
  };
  taxExempt?: {
    isProjectTaxExempt?: boolean;
    isVendorTaxExempt?: boolean;
    vendorName?: string;
  };
};

export const ReleaseAdditionalChargesAndTaxes: FC<
  ReleaseAdditionalChargesAndTaxesProps
> = ({
  release,
  releaseInput,
  total,
  showError,
  customPaymentTerm,
  additionalItems = null,
  editableByContractor = false,
  includeNotesPanel = true,
  includePaymentTerms = false,
  editablePaymentTerms = false,
  editableAdditionalCharges = true,
  includeAdditionalCharges = true,
  includeSubtotal = true,
  includeInvoicedTotals = false,
  readonly = false,
  additionalTaxes,
  additionalCharges,
  updateRelease,
  classes,
  hideTotalBorder,
  totalDivider,
  itemsCount,
  taxExempt,
  totalTooltip,
}) => {
  const { isContractor } = useUser();
  const { getOrderType } = useOrderTypeOptions();

  const [paymentTerm, setPaymentTerm] = useState(
    release?.paymentTerm !== undefined ||
      (customPaymentTerm !== undefined && customPaymentTerm !== null)
      ? Number(release?.paymentTerm ?? customPaymentTerm).toString()
      : "30",
  );

  const isPartialAmount = useMemo(
    () => itemsCount?.partial !== itemsCount?.total,
    [itemsCount?.partial, itemsCount?.total],
  );

  const isOrderScheduledOrInEditByVendor = useMemo(
    () =>
      release?.status !== ReleaseStatus.Draft &&
      release?.status !== ReleaseStatus.AwaitingApproval &&
      release?.status !== ReleaseStatus.Rejected &&
      (!isContractor || release?.status !== ReleaseStatus.Requested),
    [release?.status, isContractor],
  );

  const canEdit = useMemo(
    () => !readonly && (!isContractor || editableByContractor),
    [readonly, isContractor, editableByContractor],
  );

  const receivedSoFar = useMemo(() => {
    return release?.items?.reduce((acc, item) => {
      return new Decimal(acc)
        .add(
          new Decimal(item.receivedQuantityDecimal || 0).mul(
            item.unitPrice || 0,
          ),
        )
        .toNumber();
    }, 0);
  }, [release]);

  const invoicedQuantity = useMemo(() => {
    const invoiceSubtotal = release?.invoices?.reduce((acc, item) => {
      return acc.add(item.total || 0);
    }, new Decimal(0));

    return invoiceSubtotal?.toNumber() || 0;
  }, [release]);

  const salesTaxInput: SalesTaxInput = useMemo(() => {
    const charges =
      releaseInput && releaseInput.chargesAmount
        ? releaseInput.chargesAmount
        : release?.additionalCharges?.reduce((acc, charge) => {
            return new Decimal(acc).add(charge.amount);
          }, new Decimal(0)) || new Decimal(0);
    const subtotal = new Decimal(
      releaseInput ? releaseInput.subtotal || 0 : release?.subtotal || 0,
    ).toString();
    return {
      taxRate: releaseInput ? releaseInput.taxRate : release?.taxRate,
      customTaxAmount: releaseInput
        ? releaseInput.customTaxAmount
        : release?.customTaxAmount,
      orderTypeId: releaseInput ? releaseInput.orderTypeId : release?.type?.id,
      taxAmount: releaseInput ? releaseInput.taxAmount : release?.taxAmount,
      subtotal: subtotal,
      id: release?.id,
      version: release?.version,
      taxCodeId: releaseInput ? releaseInput.taxCodeId : release?.taxCode?.id,
      taxType: releaseInput ? releaseInput.taxType : release?.taxType,
      chargesAmount: charges.toString(),
    };
  }, [
    release?.additionalCharges,
    release?.customTaxAmount,
    release?.id,
    release?.subtotal,
    release?.taxAmount,
    release?.taxCode?.id,
    release?.taxRate,
    release?.taxType,
    release?.type?.id,
    release?.version,
    releaseInput,
  ]);

  const orderType = useMemo(
    () => (release ? release.type : getOrderType(salesTaxInput.orderTypeId)),
    [getOrderType, release, salesTaxInput.orderTypeId],
  );

  return (
    <OrgRolesWrapper
      permissions={isContractor ? [Permission.canViewPrices] : []}
    >
      <AdditionalChargesContainer className={classes?.root}>
        <If isTrue={includePaymentTerms}>
          <ItemContainer className="h-10">
            <FormattedMessage id="PAYMENT_TERMS" tagName={Item} />
            <CustomPaymentTerms
              hideLabel
              paymentTerm={paymentTerm}
              updateItem={(paymentTerm) => {
                setPaymentTerm(paymentTerm);
                updateRelease?.({
                  releaseId: release?.id || "",
                  version: release?.version,
                  paymentTerm: Number(paymentTerm),
                });
              }}
              selectInputProps="text-right pr-0 h-4 text-sm"
              staticText={!editablePaymentTerms || !canEdit}
            />
          </ItemContainer>
        </If>
        <If isTrue={includeSubtotal}>
          <SubTotalItemContainer>
            <FormattedMessage id="SUBTOTAL" tagName={Item} />
            <Price
              testId="order-subtotal"
              price={salesTaxInput.subtotal}
              maximumFractionDigits={2}
              className="text-sm font-normal"
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled onClick={() => null} forwardEvent={false}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_SUBTOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
            />
          </SubTotalItemContainer>
        </If>
        <If
          isTrue={
            includeAdditionalCharges &&
            (release?.type.includeAdditionalCharges ||
              orderType?.includeAdditionalCharges)
          }
        >
          <ReleaseAdditionalCharges
            release={release}
            readonly={!canEdit || !editableAdditionalCharges}
            showError={showError}
            updateRelease={updateRelease}
            additionalCharges={additionalCharges}
          />
        </If>
        {additionalItems}
        <SalesTax
          canEdit={canEdit}
          className={classes?.tax}
          salesTaxInput={salesTaxInput}
          taxExempt={taxExempt}
          update={async ({ id, ...input }) => {
            await updateRelease?.({
              ...(input as UpdateContractorReleaseInput),
              ...(id && { releaseId: id }),
            });
          }}
          fallbackOrderTypes={orderType ? [orderType] : []}
          fallbackTaxCodes={release?.taxCode ? [release.taxCode] : []}
        />
        {totalDivider}
        <TotalItemOuter className={classes?.total}>
          <TotalItemContainer
            $highlightTotal={!additionalTaxes && !hideTotalBorder}
            $hasAdditionalTaxes={!!additionalTaxes}
          >
            <TotalItem>
              <Total>
                <FormattedMessage
                  id="TOTAL_ORDER"
                  values={{ sub: (...chunks) => <Sub>{chunks}</Sub> }}
                />
                {totalTooltip}
              </Total>
              <If isTrue={isPartialAmount}>
                <FormattedMessage
                  id="ITEMS_OUT_OF"
                  values={{
                    partial: itemsCount?.partial,
                    total: itemsCount?.total,
                  }}
                  tagName={PartialItems}
                />
              </If>
            </TotalItem>
            <Price
              price={total}
              maximumFractionDigits={2}
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled forwardEvent={false} onClick={() => null}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_TOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
              className={!additionalTaxes ? "font-bold" : "font-normal"}
              testId="total-price"
            />
          </TotalItemContainer>
        </TotalItemOuter>
        <If isTrue={includeInvoicedTotals}>
          <ItemContainer>
            <FormattedMessage id="ORDER_RECEIVED_SO_FAR" tagName={Item} />
            <Price
              testId="order-receivedSoFar"
              price={receivedSoFar}
              maximumFractionDigits={2}
              className="font-normal"
            />
          </ItemContainer>
          <ItemContainer className="mb-2">
            <FormattedMessage id="ORDER_ITEM_INVOICED" tagName={Item} />
            <Price
              testId="order-itemInvoiced"
              price={invoicedQuantity}
              maximumFractionDigits={2}
              className="font-normal"
            />
          </ItemContainer>
        </If>
        <If
          isTrue={
            includeNotesPanel && (isOrderScheduledOrInEditByVendor || canEdit)
          }
        >
          <ReleaseTermsAndNotes
            release={release}
            updateRelease={updateRelease}
          />
        </If>
        {additionalTaxes}
      </AdditionalChargesContainer>
    </OrgRolesWrapper>
  );
};
