import { If } from "@/common/components/if/If";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { FC, useMemo, useState } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { routes } from "@/config/routes";
import {
  AssetContext,
  AssetFieldsFragment,
  AssetType,
} from "@/generated/graphql";
import { ErrorBoundary } from "@sentry/react";
import { isSafari } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { PrimaryButton } from "../button/PrimaryButton";
import { Image } from "../image/Image";
import { AssetDownloadButton } from "./AssetDownloadButton";
import { AssetPageCounter } from "./AssetPageCounter";
import { NativePdfViewer } from "./NativePdfViewer";
import { PDF_VIEWER_MODE, PdfRenderingSwitcher } from "./PdfRenderingSwitcher";
import { PdfViewer } from "./PdfViewer";

const Container = tw.div``;
const PdfContainer = tw.div`grid grid-cols-[24px_1fr_24px] w-full `;
const PdfSide = tw.div`flex flex-1 items-center`;
const PdfSideButton = tw.div<{
  $active: boolean;
  $right?: boolean;
}>`flex items-center text-center justify-center h-20
  ${({ $active }: { $active: boolean }) =>
    $active ? "cursor-pointer bg-orange-500" : ""}
    ${({ $right }: { $right?: boolean }) =>
      $right ? "rounded-r-xl" : "rounded-l-xl"}
`;
const PdfView = tw.div`group rounded-3xl flex place-items-center self-stretch overflow-auto relative bg-white h-[65vh]`;
const ImageContainer = tw.div`grid px-5 mb-4`;
const ImageView = tw.div`grid w-full bg-white overflow-hidden rounded-t-3xl cursor-move`;
const PdfFooter = tw.div`flex flex-row justify-between items-center px-4 mt-2`;
const Controls = tw.div`grid px-5`;
const PdfActions = tw.div`flex items-center px-3 gap-2`;

type AssetWithParent =
  | (AssetFieldsFragment & {
      parentId?: string;
    })
  | undefined
  | null;

type Props = {
  asset: AssetWithParent;
  pageRange?: { start: number; end: number };
  downloadButton?: React.ReactNode;
  customFooter?: React.ReactNode;
  className?: string;
};

export const AssetViewer: FC<Props> = ({
  asset,
  pageRange,
  downloadButton,
  customFooter,
  className = "",
}) => {
  const [activePdfPage, setActivePdfPage] = useState(pageRange?.start || 1);
  const [pdfView, setPdfView] = useState(PDF_VIEWER_MODE.NATIVE);
  const navigate = useNavigate();
  const { url, pages, type, context, parentId } = asset ?? {};

  const pageStart = useMemo(() => {
    if (pageRange) {
      return activePdfPage - pageRange?.start + 1;
    }
    return activePdfPage;
  }, [pageRange, activePdfPage]);

  const pageCount = useMemo(() => {
    if (pageRange) {
      return pageRange?.end - pageRange?.start + 1;
    }
    return pages?.length ?? 1;
  }, [pages?.length, pageRange]);

  const hasPreviousPage = useMemo(
    () =>
      activePdfPage > (pageRange?.start === undefined ? 1 : pageRange?.start),
    [activePdfPage, pageRange],
  );

  const hasNextPage = useMemo(
    () => activePdfPage < (pageRange?.end ?? pages?.length ?? 0),
    [activePdfPage, pages?.length, pageRange?.end],
  );

  return (
    <Container className={className}>
      <If isTrue={type === AssetType.Document}>
        <PdfContainer>
          <PdfSide>
            <PdfSideButton
              onClick={
                hasPreviousPage
                  ? () => setActivePdfPage(activePdfPage - 1)
                  : undefined
              }
              $active={hasPreviousPage}
            >
              <If isTrue={hasPreviousPage}>
                <ChevronLeft />
              </If>
            </PdfSideButton>
          </PdfSide>
          <PdfView>
            <If isTrue={pdfView === PDF_VIEWER_MODE.PDF_JS}>
              <ErrorBoundary
                fallback={
                  <p>
                    <FormattedMessage id="FAILED_TO_LOAD_PDF" />
                  </p>
                }
              >
                <PdfViewer url={url ?? ""} page={activePdfPage} />
              </ErrorBoundary>
            </If>
            <If isTrue={pdfView === PDF_VIEWER_MODE.NATIVE}>
              <NativePdfViewer
                url={pages?.[activePdfPage - 1]?.url ?? url ?? ""}
              />
            </If>
          </PdfView>
          <PdfSide>
            <PdfSideButton
              onClick={
                hasNextPage
                  ? () => setActivePdfPage(activePdfPage + 1)
                  : undefined
              }
              $active={hasNextPage}
              $right
            >
              <If isTrue={hasNextPage}>
                <ChevronRight />
              </If>
            </PdfSideButton>
          </PdfSide>
        </PdfContainer>
        <Controls>
          <PdfActions>
            <If isTrue={downloadButton}>{downloadButton}</If>
            <If isTrue={!downloadButton && url}>
              <AssetDownloadButton url={url} type={type} />
            </If>
            <If isTrue={!isSafari}>
              <PdfRenderingSwitcher pdfView={pdfView} setPdfView={setPdfView} />
            </If>
          </PdfActions>
          <PdfFooter>
            <If isTrue={(pages ?? []).length > 0}>
              <AssetPageCounter
                activePage={activePdfPage}
                setActivePage={setActivePdfPage}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                pageStart={pageStart}
                pageCount={pageCount}
              />
            </If>
            {customFooter}
            {context === AssetContext.DeliverySlip && parentId && (
              <PrimaryButton
                $small
                className="h-6"
                onClick={() =>
                  navigate(
                    generatePath(routes.deliverySlipReceiveOrder, {
                      deliverySlipId: parentId,
                    }),
                  )
                }
              >
                <FormattedMessage id="OPEN_PACKING_SLIP" />
              </PrimaryButton>
            )}
          </PdfFooter>
        </Controls>
      </If>
      <If isTrue={type === AssetType.Image}>
        <ImageContainer>
          <ImageView>
            <Image src={url} withControls width="auto" />
          </ImageView>
        </ImageContainer>
        <Controls>
          <PdfActions>
            <If isTrue={downloadButton}>{downloadButton}</If>
            <If isTrue={!downloadButton && url}>
              <AssetDownloadButton url={url} type={type} />
            </If>
            {context === AssetContext.DeliverySlip && parentId && (
              <PrimaryButton
                $small
                className="h-6"
                onClick={() =>
                  navigate(
                    generatePath(routes.deliverySlipReceiveOrder, {
                      deliverySlipId: parentId,
                    }),
                  )
                }
              >
                <FormattedMessage id="OPEN_PACKING_SLIP" />
              </PrimaryButton>
            )}
          </PdfActions>
          <PdfFooter>{customFooter}</PdfFooter>
        </Controls>
      </If>
    </Container>
  );
};
