import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AddToRfqItemInput, UpdateRfqItemInput } from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRfq } from "../hooks/useRfq";

type ProviderContextType = {
  syncRfqItems: () => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncRfqItems: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncRfqItemsProvider: FC<Props> = ({ children }) => {
  const { manufacturers } = useManufacturers();
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    rowIsEmpty,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    getNameParts,
    getCostCodeId,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { rfq, updateRfq, isUpdating } = useRfq();
  const { materials } = useMaterials();
  const viewState = useTableViewStore((state) => state.viewState);
  const [saving, setSaving] = useState(false);
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { hasPhaseCodes } = useOrgSettings();
  useEffect(() => {
    setSaving(isUpdating);
  }, [isUpdating]);

  const syncRfqItems = async () => {
    if (viewState !== TableViewState.spreadsheet) {
      return true;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }
    const newItems: AddToRfqItemInput[] = [];
    const itemsToUpdate: UpdateRfqItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(rfq?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      const uom = getRowUomCreatableValue(row);
      const quantityDecimal = getCellValue(
        row,
        COLUMN_TYPE.PositiveQuantity,
      ).toString();
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";

      const existingItem = rfq?.items.find((pi) => pi.id === row.id);

      const matchingMaterials =
        existingItem && existingItem.projectItem.material.id === material.id;

      const costCodeId = getCostCodeId(row);

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          itemsToUpdate.push({
            rfqItemId: row.id,
            quantityDecimal,
            instructions: {
              text: notes,
            },
            uom,
            clearManufacturer: !manufacturer,
            manufacturerId: manufacturer?.id,
            description: rowMaterialText,
            position: index,
            costCodeId,
            clearCostCode: !costCodeId,
          });
        }
      } else {
        newItems.push({
          description: getNameParts(rowMaterialText).namePart,
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          instructions: {
            text: notes,
          },
          manufacturerId: manufacturer?.id,
          quantityDecimal,
          position: index,
          costCodeId,
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      const result = await updateRfq({
        rfqId: rfq?.id ?? "",
        updates: itemsToUpdate,
        addedItems: newItems,
        removedItems: itemsToRemove,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "RFQ_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }

    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncRfqItems,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncRfqItems = (): ProviderContextType =>
  useContext(ProviderContext);
