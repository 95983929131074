import { If } from "@/common/components/if/If";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { routes } from "@/config/routes";
import { BuyoutStatus } from "@/generated/graphql";
import { ChevronRight } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import { QuotedBuyoutRelatedBuyout } from "../providers/BuyoutQuotedProvider";

const Item = tw.div`${({ $opaque }: { $opaque: boolean }) =>
  `inline-block grid grid-flow-col text-sm items-center font-medium text-center
    ${$opaque ? "opacity-30" : "opacity-100"}
  `}`;
const BuyoutCircle = tw.div`
  ${({ $filled }: { $filled: boolean }) => `
    flex w-[20px] h-[20px] items-center justify-center border-blue-500 border-[1.5px] rounded-full
    text-xs mr-2
    ${$filled ? "bg-blue-500 text-white" : "bg-white text-blue-500"}
  `}`;

const BuyoutNumber = tw.span`ml-1`;

type Props = {
  relatedBuyout: QuotedBuyoutRelatedBuyout;
  index: number;
  currentBuyoutQueueIndex: number;
  totalBuyouts: number;
};

export const BuyoutQuotedBreadcrumbItem = ({
  relatedBuyout,
  index,
  totalBuyouts,
  currentBuyoutQueueIndex,
}: Props) => {
  const isActiveBuyout = useMemo(
    () => relatedBuyout.status === BuyoutStatus.Active,
    [relatedBuyout.status],
  );

  return (
    <Link to={generatePath(routes.buyout, { id: relatedBuyout.id })}>
      <Item $opaque={!isActiveBuyout && index !== currentBuyoutQueueIndex}>
        <BuyoutCircle $filled={isActiveBuyout}>{index + 1}</BuyoutCircle>
        <If isTrue={!!relatedBuyout.sellerOrgLocation?.org?.photoUrl}>
          <OrgLogo
            logoImageUrl={relatedBuyout.sellerOrgLocation?.org?.photoUrl}
            name={relatedBuyout.sellerOrgLocation?.org?.name}
            width={24}
          />
        </If>
        <FormattedMessage
          id="BUYOUT"
          tagName={BuyoutNumber}
          values={{ number: relatedBuyout.clientIdentifier }}
        />
        <If isTrue={index !== totalBuyouts - 1}>
          <ChevronRight />
        </If>
      </Item>
    </Link>
  );
};
