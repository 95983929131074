import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  AssetContext,
  AuthorizationStatus,
  InvoiceType,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useReceiptCreation } from "../receipts/providers/ReceiptCreationProvider";
import { ReceiptsSequenceProvider } from "../receipts/providers/ReceiptsSequenceProvider";
import {
  ReceiptCreateReleaseFormValues,
  ReceiptVerificationForm,
} from "./components/ReceiptVerificationForm";
import { ReceiptBreadcrumbs } from "./components/breadcrumbs/ReceiptBreadcrumbs";
import { ReceiptFooter } from "./components/footer/ReceiptFooter";
import { RecordOrderForm } from "./components/order/RecordOrderForm";
import { useReceiptCreateReleaseSpreadsheetConfig } from "./components/order/itemized/create/ReceiptCreateRelease.config";
import { useReceiptUpdateReleaseSpreadsheetConfig } from "./components/order/itemized/update/InvoiceUpdateRelease.config";
import { ReceiptMatchedHeader } from "./components/order/receipt-release-items/ReceiptMatchedHeader";
import { ReceiptReleaseItems } from "./components/order/receipt-release-items/ReceiptReleaseItems";
import { ReceiptCreateReleaseProvider } from "./providers/ReceiptCreateReleaseProvider";
import { ReceiptProvider, useReceipt } from "./providers/ReceiptProvider";
import { ReceiptUpdateReleaseProvider } from "./providers/ReceiptUpdateReleaseProvider";
import { UploadReceiptProvider } from "./providers/UploadReceiptProvider";
import { ReceiptFooterState } from "./types/ReceiptFooterState";

const Container = tw.div`relative mt-5 xl:-mx-10`;
const MatchedOrderContainer = tw.div`bg-gray-100 rounded-3xl pt-4 h-full overflow-y-scroll`;

const ReceiptRecordOrderWithProvider: FC = () => {
  const { receipt, updateReceipt, footerState } = useReceipt();
  const { release } = useRelease();
  const { locationId } = useReceiptCreation();
  const { setGlobalVendorId } = useVendorPrices();
  const [initialized, setInitialized] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { reset, watch, setValue, trigger } =
    useFormContext<ReceiptCreateReleaseFormValues>();

  useEffect(() => {
    if (!initialized || (receipt && release)) {
      reset({
        businessLocationId:
          (receipt?.project && !receipt.project.deletedAt
            ? receipt?.project?.location.id
            : undefined) ??
          receipt?.predictedProject?.location?.id ??
          locationId ??
          "",
        projectId:
          release?.project?.id ??
          (receipt?.project && !receipt.project.deletedAt
            ? receipt?.project?.id
            : undefined) ??
          receipt?.predictedProject?.id ??
          "",
        vendorId:
          release?.sellerOrgLocation?.id ??
          receipt?.predictedSellerOrgLocation?.id ??
          "",
        issueDate: defaultReleaseDate(getUTCDate(receipt?.issueDate)),
        phaseCodeId:
          release?.items.length === 1 &&
          release?.items[0].tags.filter((t) => t.hasMapping).length
            ? release?.items[0].tags.filter((t) => t.hasMapping)[0].id
            : "",
        costCodeId:
          release?.items.length === 1 ? release?.items[0].costCode?.id : "",
        customTaxAmount: receipt?.taxAmount || undefined,
        subtotal: release?.subtotal
          ? Number(release.subtotal)
          : (Number(receipt?.subtotal) ?? undefined),
        orderTypeId: release?.type.id,
      });
      setInitialized(true);
    }
  }, [initialized, locationId, receipt, release, reset]);

  const vendorId = watch("vendorId");
  const projectId = watch("projectId");
  useSetCurrentProjectId(projectId);

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  useEffect(() => {
    if (receipt?.issueDate) {
      setValue("issueDate", defaultReleaseDate(getUTCDate(receipt?.issueDate)));
    }

    if (receipt?.taxAmount) {
      setValue(
        "customTaxAmount",
        release?.customTaxAmount && release?.customTaxAmount !== "0"
          ? release?.customTaxAmount
          : receipt.taxAmount,
      );
    }
    if (receipt?.subtotal) {
      setValue(
        "subtotal",
        release?.subtotal
          ? Number(release.subtotal)
          : (Number(receipt?.subtotal) ?? undefined),
      );
    }

    if (release?.items.length === 1) {
      setValue("costCodeId", release?.items[0].costCode?.id || "");
    }
  }, [receipt, release, setValue, trigger]);

  const hasPermissionOrArchived = useMemo(() => {
    return (
      receipt?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!receipt.archivedAt
    );
  }, [receipt]);

  const readonly = useMemo(
    () => !receipt || INVOICE_READONLY_STATUSES.includes(receipt?.status),
    [receipt],
  );

  const receiptPoNumberReadonly = useMemo(
    () =>
      receipt?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!receipt.archivedAt,
    [receipt],
  );

  const toggleEdit = useCallback(
    () => setIsEditMode((oldValue) => !oldValue),
    [],
  );

  return (
    <Container>
      <ReceiptBreadcrumbs />
      <ResizableColumns
        hasFooter={!readonly}
        tallFooter={footerState === ReceiptFooterState.KICKBACK}
      >
        {!readonly || isEditMode ? (
          <RecordOrderForm />
        ) : (
          <MatchedOrderContainer>
            <ReceiptMatchedHeader />
            <ReceiptReleaseItems toggleEdit={toggleEdit} />
          </MatchedOrderContainer>
        )}
        <InvoiceDetails
          invoice={receipt}
          updateInvoice={updateReceipt}
          pricesReadonly={hasPermissionOrArchived}
          invoiceReadonly={hasPermissionOrArchived}
          invoicePoNumberReadonly={receiptPoNumberReadonly}
          releaseHasMultipleInvoices={
            (receipt?.release?.invoices || []).length > 1
          }
          type={InvoiceType.Receipt}
        />
      </ResizableColumns>
      <ReceiptFooter isEditMode={isEditMode} />
    </Container>
  );
};

const ReceiptRecordOrderWithReleaseProvider: FC = () => {
  const { receipt } = useReceipt();

  if (!receipt) {
    return <Loader loading />;
  }

  return (
    <ReleaseProvider id={receipt?.release?.id}>
      <UploadAssetProvider
        context={AssetContext.Invoice}
        projectId={receipt.predictedProject?.id}
      >
        <UploadReceiptProvider>
          <SpreadsheetWrapper />
        </UploadReceiptProvider>
      </UploadAssetProvider>
    </ReleaseProvider>
  );
};

const SpreadsheetWrapper = () => {
  const { receipt } = useReceipt();

  const receiptCreateReleaseSpreadsheetConfig =
    useReceiptCreateReleaseSpreadsheetConfig();
  const receiptUpdateReleaseSpreadsheetConfig =
    useReceiptUpdateReleaseSpreadsheetConfig();
  if (!receipt?.release?.id) {
    return (
      <ColumnMapperProvider config={receiptCreateReleaseSpreadsheetConfig}>
        <ReceiptCreateReleaseProvider>
          <ReceiptRecordOrderWithProvider />
        </ReceiptCreateReleaseProvider>
      </ColumnMapperProvider>
    );
  }
  return (
    <ColumnMapperProvider config={receiptUpdateReleaseSpreadsheetConfig}>
      <ReceiptUpdateReleaseProvider>
        <ReceiptRecordOrderWithProvider />
      </ReceiptUpdateReleaseProvider>
    </ColumnMapperProvider>
  );
};

export const ReceiptRecordOrder = () => {
  return (
    <WarehousesProvider>
      <ReceiptProvider>
        <ReceiptsSequenceProvider>
          <ReceiptVerificationForm>
            <ReceiptRecordOrderWithReleaseProvider />
          </ReceiptVerificationForm>
        </ReceiptsSequenceProvider>
      </ReceiptProvider>
    </WarehousesProvider>
  );
};
