import { Dialog } from "@/common/components/dialog/Dialog";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { StaticDatePicker } from "@/common/components/picker/components/StaticDatePicker";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { Close } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../../project/providers/EstimatedItemsProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const Title = tw.div`text-lg font-medium text-blue-800`;
const Container = tw.div`grid`;
const Divider = tw.div`w-full border-b border-gray-200 border-dashed`;
const ClearDates = tw(
  LinkLike,
)`grid w-fit place-self-end font-medium text-base mt-6`;

type ReleaseItemDeliveryDateDialogProps = {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  item: ReleaseItemFieldsFragment;
};

export const ReleaseItemDeliveryDateDialog: FC<
  ReleaseItemDeliveryDateDialogProps
> = ({ visible = true, setVisible, item }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { release } = useRelease();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const [date, setDate] = useState<Date | null>(
    new Date(item.deliveryDate || release?.time || ""),
  );
  const { updateRelease } = useReleaseUpdate();

  useEffect(() => {
    setDate(new Date(item.deliveryDate || release?.time || ""));
  }, [item, release]);

  const updateNewItem = useCallback(
    (date: Date | null) => {
      setNewProjectEstimatedItem([
        {
          key: "deliveryDate",
          value: date?.getTime(),
        },
      ]);
    },
    [setNewProjectEstimatedItem],
  );

  const handleConfirm = useCallback(async () => {
    if (release) {
      if (item.id) {
        await updateRelease({
          releaseId: release?.id,
          version: release?.version,
          updates: [
            {
              releaseItemId: item.id,
              deliveryDate: date?.getTime() || release.time,
            },
          ],
        });
      } else {
        updateNewItem(date);
      }
    }
    setVisible?.(false);
  }, [release, item, updateRelease, updateNewItem, setVisible, date]);

  const handleCancel = useCallback(() => {
    if (!release?.id) {
      navigate(-1);
    } else {
      setVisible?.(false);
    }
  }, [release, navigate, setVisible]);

  const clearDate = useCallback(() => {
    if (release) {
      if (item.id) {
        updateRelease({
          releaseId: release?.id,
          version: release?.version,
          updates: [
            {
              releaseItemId: item.id,
              deliveryDate: undefined,
              clearDeliveryDate: true,
            },
          ],
        });
      } else {
        updateNewItem(null);
      }
      setVisible?.(false);
    }
  }, [release, item, updateRelease, updateNewItem, setVisible]);

  return (
    <Dialog
      cancelButtonText={intl.$t({ id: "CANCEL" })}
      confirmButtonText={intl.$t({ id: "CONFIRM" })}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      show={visible}
      buttonsConfiguration={{
        cancel: { wide: true },
        confirm: { wide: true },
      }}
      content={
        <Container>
          <Title>
            <FormattedMessage id="SPECIFY_DELIVERY_DATE" />
          </Title>
          <StaticDatePicker
            value={date}
            onChange={(newDate) => setDate(newDate)}
            className="-mx-6"
          />
          <Divider />
          <ClearDates onClick={clearDate}>
            <Close className="text-lg" />
            <FormattedMessage id="CLEAR_DATE" />
          </ClearDates>
        </Container>
      }
    />
  );
};
