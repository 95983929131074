import { If } from "@/common/components/if/If";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SpecifyCostCodeExpandable } from "@/common/components/specify-cost-code/SpecifyCostCodeExpandable";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseItems } from "../../components/delivery-slip-release-items/DeliverySlipReleaseItems";
import { useDeliverySlipEditReleaseSpreadsheetConfig } from "./DeliverySlipEditRelease.config";
import { DeliverySlipEditReleaseHeader } from "./DeliverySlipEditReleaseHeader";
import { DeliverySlipReceiveFooter } from "./DeliverySlipReceiveFooter";
import { DeliverySlipReceiveHeader } from "./DeliverySlipReceiveHeader";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col px-2.5 pb-4  mb-20`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipReceiveOrderDetails: FC = () => {
  const { release, loading } = useRelease();
  const { spreadsheetData } = useColumnMapper();
  const { calcTableTotal } = useTableHelpers();
  const { setGlobalVendorId } = useVendorPrices();
  const { updating, updateReleaseForm: form } = useDeliverySlipRelease();
  const { packingSlipReceiveViewState } = useDeliverySlipVerification();
  const spreadsheetViewColumns = useDeliverySlipEditReleaseSpreadsheetConfig();
  const { getTaxAmount } = useTaxCalculation();

  const setGlobalProjectId = useProjectStore(
    (state) => state.setCurrentProjectId,
  );
  const taxRate = form.watch("taxRate");
  const customTaxAmount = form.watch("customTaxAmount");
  const subtotal = form.watch("subtotal");
  const additionalCharges = form.watch("additionalCharges");
  const orderTypeId = form.watch("orderTypeId");
  const taxCodeId = form.watch("taxCodeId");
  const taxType = form.watch("taxType");
  const vendorId = form.watch("vendorId");
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);

  const projectId = form.watch("projectId");
  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
    if (projectId) {
      setGlobalProjectId(projectId);
    }
  }, [setGlobalVendorId, vendorId, setGlobalProjectId, projectId]);

  const releaseItems = useMemo(
    () =>
      release?.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => ({
          ...item,
          phaseCode: item.tags[0]?.name,
        })) ?? [],
    [release?.items],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        form.setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        form.setValue(
          "customTaxAmount",
          values.customTaxAmount || release?.taxAmount || "0",
        );
        form.setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        form.setValue("customTaxAmount", undefined);
        form.setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        form.setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        form.setValue("taxType", values.taxType);
      }

      return true;
    },
    [customTaxAmount, form, release?.taxAmount],
  );

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== subtotal) {
        form.setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, form, subtotal],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const { calcTotalPrice, calcAdditionalChargesPrice } = usePriceCalculation();
  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
    ],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(additionalCharges),
    }),
    [
      additionalCharges,
      calcAdditionalChargesPrice,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <Container>
      <InnerContainer>
        <If
          isTrue={
            packingSlipReceiveViewState ===
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipEditReleaseHeader />
          <SpreadSheetView>
            <SpreadSheetTable
              items={releaseItems}
              columns={spreadsheetViewColumns}
              saving={loading || updating}
              height="450px"
              rowNumber={17}
              onChanges={updateSubtotal}
            />
          </SpreadSheetView>
          <SpecifyCostCodeExpandable costCode={release?.costCode} />
          <Footer>
            <ReleaseAdditionalChargesAndTaxes
              total={`${total}`}
              releaseInput={releaseInput}
              customPaymentTerm={form?.watch("paymentTerm")}
              editableByContractor
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              includePaymentTerms
              taxExempt={{
                isProjectTaxExempt: release?.project?.taxExempt,
                isVendorTaxExempt: release?.preferredVendor?.taxExempt,
                vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
              }}
              additionalCharges={
                form?.watch(
                  "additionalCharges",
                ) as AdditionalChargesFieldsFragment[]
              }
            />
          </Footer>
        </If>
        <If
          isTrue={
            packingSlipReceiveViewState !==
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipReceiveHeader />
          <DeliverySlipReleaseItems />
        </If>
      </InnerContainer>
      <DeliverySlipReceiveFooter />
    </Container>
  );
};
