import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT } from "@/common/const";
import Decimal from "decimal.js";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

const buyoutTotals = (
  doc: jsPDF,
  subtotal: Decimal,
  salesTax: string | null | undefined,
  total: Decimal,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  formatCurrency: FormatCurrencyType,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y - 4,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "SUBTOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        formatCurrency(subtotal.toString()),
      ],
      [
        {
          content: `${intl.$t({ id: "SALES_TAX" })}:`,
          styles: { fontStyle: "bold" },
        },
        salesTax || "-",
      ],
      [
        {
          content: `${intl.$t({ id: "TOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        formatCurrency(total.toString()),
      ],
    ],
  });
};

export const footer = (
  doc: jsPDF,
  subtotal: Decimal,
  salesTax: string | null | undefined,
  total: Decimal,
  intl: IntlShape,
  formatCurrency: FormatCurrencyType,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const topSpacing = (doc as any).lastAutoTable.finalY + 30;
  autoTable(doc, {
    startY: topSpacing,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    body: [["", "", "", "", { content: "", styles: { minCellHeight: 40 } }]],
    didDrawCell: (data) => {
      if (
        data.row.index === 0 &&
        data.row.section === "body" &&
        data.column.index === 4
      ) {
        buyoutTotals(
          doc,
          subtotal,
          salesTax,
          total,
          intl,
          data.cell.x,
          data.cell.y,
          data.cell.width,
          formatCurrency,
        );
      }
    },
  });
};
