import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { useRenderHelpers } from "./useRenderHelpers";

export const useCostTypeRenderer = () => {
  const { checkReadOnly, applyClasses } = useRenderHelpers();
  const { release } = useRelease();
  const { rowIsEmpty } = useTableHelpers();
  const { formatCostType } = useCostTypes();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rows = instance?.getData();

      if (!value && !rowIsEmpty(rows[row])) {
        textRenderer(
          instance,
          td,
          row,
          col,
          prop,
          formatCostType(release?.type?.costType?.id),
          cellProperties,
        );
        applyClasses(td, "htCenter htMiddle text-gray-500");
      } else {
        textRenderer(instance, td, row, col, prop, value, cellProperties);
      }

      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      checkReadOnly,
      release?.type?.costType?.id,
      rowIsEmpty,
      applyClasses,
      formatCostType,
    ],
  );

  return renderer;
};
