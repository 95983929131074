import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  FileSourceSelector,
  FileSourceType,
  PASTE_TABLE,
} from "@/common/components/file-source-selector/FileSourceSelector";
import { If } from "@/common/components/if/If";
import { InfoText } from "@/common/components/info-text/InfoText";
import { Loader } from "@/common/components/loader/Loader";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Select } from "@/common/components/select/components/single/Select";
import { Switch } from "@/common/components/switch/Switch";
import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { TextField } from "@/common/components/textfield/TextField";
import { FileTableCopyPaste } from "@/common/components/upload-file-mapper/FileTableCopyPaste";
import {
  CSV_EXTENSIONS,
  CSV_MIME_TYPE,
  FileUploadArea,
  XLS_EXTENSIONS,
  XLS_MIME_TYPE,
  getFileType,
} from "@/common/components/upload/FileUploadArea";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  CustomFileFormat,
  CustomIntegrationInput,
  FieldMappingTargetProperty,
  FileType,
  IntegrationType,
  ProjectExtendedFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { LocalOfferOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useExternalEstimates } from "../../hooks/useExternalEstimates";
import { useProjectTags } from "../../hooks/useProjectTags";
import { useProjectZones } from "../../hooks/useProjectZones";
import { FM_COLUMNS, MaterialFileMapper } from "./MaterialFileMapper";
import {
  MaterialFileUploadProvider,
  useMaterialFileUpload,
} from "./MaterialFileUploadProvider";

type MaterialFileUploadSelectionProps = {
  onClose: () => void;
  project: ProjectExtendedFieldsFragment;
};

const FormControlStyled = tw(FormControl)`
  flex flex-row w-full mb-2 justify-between
`;

const UploadAreaContainer = tw.div`
  grid h-full content-between gap-2
`;

const OverlayPanelContainer = tw.div`
  grid gap-2 content-start h-full w-full
`;

const FileFormatContainer = tw.div`
  mt-4 pb-8
`;

const StyledCheckbox = tw(Checkbox)`
  ml-1 text-blue-800 mb-2 pb-1
`;
const Label = tw.div`mr-2`;

const NEW_ZONE_ID = "new-zone";

const MaterialFileUploadSelectionWithProvider: FC<
  MaterialFileUploadSelectionProps
> = ({ onClose, project }) => {
  const intl = useIntl();
  const { id } = useParams();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { addZone } = useProjectZones();
  const {
    uploadFile,
    isUploading,
    setFilePreview,
    setFieldPreviewType,
    fieldPreviews,
    loadingPreview,
    previewError,
    assignedColumns,
    customFileFormats,
    createCustomFileFormat,
    deleteCustomFileFormat,
  } = useMaterialFileUpload();

  const [tags, setTags] = useState<string[]>([]);
  const [clearAllEstimatedItems, setClearAllEstimatedItems] = useState(false);
  const [importZones, setImportZones] = useState(false);
  const [importManufacturers, setImportManufacturers] = useState(false);
  const [sourceType, setSourceType] = useState<FileSourceType>("");
  const [selectedZone, setSelectedZone] = useState<string | null>(null);
  const [newZoneName, setNewZoneName] = useState("");
  const [file, setFile] = useState<File>();
  const [loadingError, setLoadingError] = useState<unknown>();
  const [formatName, setFormatName] = useState("");
  const [saveFormat, setSaveFormat] = useState(false);
  const [mappingMode, setMappingMode] = useState(false);
  const [selectedEstimateExternalId, setSelectedEstimateExternalId] = useState<
    string | null
  >(null);
  const { tags: projectTags } = useProjectTags();
  const { zones } = useProjectZones();

  const { loadingEstimates, foundationJobMaterialListOptions, importEstimate } =
    useExternalEstimates(project.id, sourceType);

  const onSourceChanged = (source: FileSourceType) => {
    setSourceType(source);
    setSaveFormat(false);
    if (source !== SourceSystem.Foundation) {
      setSelectedEstimateExternalId(null);
    }
  };

  const updateFile = (files: File[]) => {
    const file = files[0];
    setFile(file);
    if (customFileTypeSelected || copyPasteTable) {
      setFilePreview(file);
      setFieldPreviewType(getFileType(file));
      setSourceType(file.name);
    }
  };

  const uploadedFileSelected = useMemo(() => {
    return sourceType === file?.name;
  }, [sourceType, file]);

  const customFileTypeSelected = useMemo(() => {
    return sourceType === IntegrationType.Custom;
  }, [sourceType]);

  const myCustomTypeSelected = useMemo(() => {
    return customFileFormats.find(
      (format: CustomFileFormat) => format.id === sourceType,
    );
  }, [customFileFormats, sourceType]);

  const integrationTypeSelected = useMemo(() => {
    return Object.values(IntegrationType).includes(
      sourceType as IntegrationType,
    );
  }, [sourceType]);

  const copyPasteTable = useMemo(() => {
    return sourceType === PASTE_TABLE;
  }, [sourceType]);

  const pastedDataSelected = useMemo(() => {
    return sourceType === intl.$t({ id: "PASTED_TABLE_DATA" });
  }, [sourceType, intl]);

  const disableSave = useMemo(() => {
    if (sourceType === SourceSystem.Foundation) {
      return !selectedEstimateExternalId;
    }

    if (!file) {
      return true;
    }

    if (saveFormat && !formatName) {
      return true;
    }

    const assignedColumnNames = assignedColumns
      .filter((col) => col.assigned)
      .map((col) => col.assigned);
    const duplicateColumnNames = assignedColumnNames.filter(
      (name, index) => assignedColumnNames.indexOf(name) !== index,
    );
    if (duplicateColumnNames.length > 0) {
      return true;
    }

    if (uploadedFileSelected) {
      return !assignedColumns
        .filter(
          (col) =>
            importZones ||
            (!importZones && col.value !== FieldMappingTargetProperty.Zone),
        )
        .every(
          (c) =>
            c.assigned !== "" ||
            FM_COLUMNS.find((col) => col.value === c.value)?.required === false,
        );
    }
  }, [
    file,
    uploadedFileSelected,
    assignedColumns,
    formatName,
    saveFormat,
    importZones,
    sourceType,
    selectedEstimateExternalId,
  ]);

  const removeOption = (option: { value: string }) => {
    openDialog({
      confirmButtonText: intl.$t({ id: "REMOVE" }),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "REMOVE_CUSTOM_OPTION" }),
      text: intl.$t({ id: "REMOVE_CUSTOM_OPTION_TEXT" }),
      handleConfirm: async () => {
        await deleteCustomFileFormat(option.value);
      },
    });
  };

  const createZone = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "CONFIRM" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "ADD_NEW_ZONE" }, { zone: newZoneName }),
      handleConfirm: async () => {
        const zones = await addZone({ name: newZoneName, projectId: id || "" });
        if ((zones || [])?.length > 0) {
          const newZone = zones?.find((z) => z.name === newZoneName);
          if (newZone) {
            setSelectedZone(newZone.id);
            setNewZoneName(newZone.name);
          }
        }
      },
      handleCancel: () => setNewZoneName(""),
    });
  }, [addZone, id, intl, newZoneName, openDialog]);

  const setZone = useCallback(
    (value: string | null) => {
      if (value !== NEW_ZONE_ID) {
        setSelectedZone(value);
        setNewZoneName(zones.find((z) => z.id === value)?.name || "");
      } else {
        createZone();
      }
    },
    [createZone, zones],
  );

  const importExternalEstimates = async () => {
    try {
      if (selectedEstimateExternalId) {
        const result = await importEstimate({
          projectId: project.id,
          sourceSystem: SourceSystem.Foundation,
          estimateExternalId: selectedEstimateExternalId,
          clearAllEstimatedItems,
          tags,
          zoneId: selectedZone,
        });

        if (result?.success) {
          setSuccessAlert(intl.$t({ id: "MATERIAL_LIST_IMPORT_SUCCESS" }));
        } else {
          return;
        }

        onClose();
      }
    } catch (error) {
      setLoadingError(error);
    }
  };

  const uploadMaterials = async () => {
    if (file) {
      const mappings = assignedColumns
        .filter((col) => col.assigned)
        .map((col) => ({
          index: fieldPreviews.findIndex((f) => col.assigned === f.name),
          name: col.assigned,
          property: col.value,
        }));

      const customInput =
        uploadedFileSelected || myCustomTypeSelected
          ? ({
              fileFormat: {
                fileType: getFileType(file),
                mappings: myCustomTypeSelected?.mappings || mappings,
              },
            } as CustomIntegrationInput)
          : undefined;

      try {
        setLoadingError(undefined);
        const result = await uploadFile({
          projectId: id || "",
          fileFormat:
            uploadedFileSelected || myCustomTypeSelected
              ? IntegrationType.Custom
              : (sourceType as IntegrationType),
          file,
          custom: customInput,
          clearAllEstimatedItems,
          importZones,
          importManufacturers,
          tags,
          zoneId: importZones ? undefined : selectedZone || undefined,
        });

        if (result?.success) {
          if (!result.hasErrors) {
            setSuccessAlert(intl.$t({ id: "MATERIAL_FILE_UPLOAD_SUCCESS" }));
          }
        } else {
          return;
        }

        if (saveFormat) {
          await createCustomFileFormat({
            name: formatName,
            fileType: getFileType(file),
            mappings,
          });
        }

        onClose();
      } catch (error) {
        setLoadingError(error);
      }
    }
  };

  const handleSave = async () => {
    if (sourceType === SourceSystem.Foundation) {
      await importExternalEstimates();
    } else {
      await uploadMaterials();
    }
  };

  return (
    <OverlayPanel
      title={intl.$t({
        id:
          sourceType === SourceSystem.Foundation
            ? "IMPORT_MATERIAL_LIST"
            : "UPLOAD_MATERIAL_LIST",
      })}
      onSave={handleSave}
      saving={isUploading}
      disableSave={disableSave}
      onCancel={onClose}
      saveLabel={intl.$t({
        id: sourceType === SourceSystem.Foundation ? "IMPORT" : "SAVE",
      })}
    >
      <OverlayPanelContainer>
        <InfoText
          type="primary"
          title="YOUR_MATERIAL_LIST"
          body="YOUR_MATERIAL_LIST_DESCRIPTION"
        />
        <FormControlStyled>
          <FileSourceSelector
            value={sourceType}
            onChange={onSourceChanged}
            selectedFiles={file ? [file.name] : []}
            disabled={mappingMode}
            customFileFormats={customFileFormats}
            onRemoveCallback={removeOption}
          />
        </FormControlStyled>
        <If isTrue={sourceType === SourceSystem.Foundation}>
          <FormControlStyled>
            <Select
              placeholder={intl.$t({
                id:
                  foundationJobMaterialListOptions.length === 0
                    ? "NO_MATERIAL_LIST_FOR_JOB"
                    : "SELECT_JOB_MATERIAL_LIST",
              })}
              className="w-full"
              options={foundationJobMaterialListOptions}
              getLabel={(option) =>
                `${option.sourceId}${
                  option.description ? ` - ${option.description}` : ""
                }`
              }
              getValue={(option) => option.externalId}
              value={selectedEstimateExternalId}
              onChange={setSelectedEstimateExternalId}
              loading={loadingEstimates}
            />
          </FormControlStyled>
        </If>
        <If
          isTrue={
            sourceType !== SourceSystem.Foundation || selectedEstimateExternalId
          }
        >
          <FormControlStyled>
            <TagsSelector
              creatable
              options={projectTags}
              selectedTags={tags}
              setSelectedTags={setTags}
              placeholder={intl.$t({
                id: "TAGS",
              })}
              popup={<LocalOfferOutlined fontSize="small" />}
              project={project}
            />
          </FormControlStyled>
          <FormControlStyled>
            <Label>
              <FormattedMessage id="UPLOAD_ESTIMATE_CLEAR_EXISTING_PROJECT_ITEMS" />
            </Label>
            <Switch
              value={clearAllEstimatedItems}
              onChange={() =>
                setClearAllEstimatedItems(!clearAllEstimatedItems)
              }
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </FormControlStyled>
        </If>
        <If isTrue={sourceType !== SourceSystem.Foundation}>
          <FormControlStyled>
            <Label>
              <FormattedMessage id="UPLOAD_ESTIMATE_CLEAR_INCLUDE_ZONES" />
            </Label>
            <Switch
              value={importZones}
              onChange={() => setImportZones(!importZones)}
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </FormControlStyled>
          <FormControlStyled>
            <Label>
              <FormattedMessage id="UPLOAD_ESTIMATE_IMPORT_MANUFACTURERS" />
            </Label>
            <Switch
              value={importManufacturers}
              onChange={() => setImportManufacturers(!importManufacturers)}
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </FormControlStyled>
        </If>
        <If
          isTrue={
            !importZones &&
            (sourceType !== SourceSystem.Foundation ||
              selectedEstimateExternalId)
          }
        >
          <FormControlStyled>
            <Select
              placeholder={intl.$t({
                id: "ZONE",
              })}
              className="w-full"
              options={zones}
              value={selectedZone}
              onChange={setZone}
              inputValue={newZoneName}
              handleInputChange={setNewZoneName}
              getLabel={(option) => option.name}
              getValue={(option) => option.id}
              creatable
              creatableFn={(value) => {
                return {
                  id: NEW_ZONE_ID,
                  name: value,
                  inUse: false,
                };
              }}
            />
          </FormControlStyled>
        </If>
        <If
          isTrue={
            (integrationTypeSelected ||
              customFileTypeSelected ||
              myCustomTypeSelected) &&
            sourceType !== SourceSystem.Foundation
          }
        >
          <UploadAreaContainer>
            <FileUploadArea
              onChange={updateFile}
              error={loadingError}
              accept={
                myCustomTypeSelected
                  ? myCustomTypeSelected.fileType === FileType.Csv
                    ? {
                        [CSV_MIME_TYPE]: CSV_EXTENSIONS,
                      }
                    : {
                        [XLS_MIME_TYPE]: XLS_EXTENSIONS,
                      }
                  : undefined
              }
            />
          </UploadAreaContainer>
        </If>

        <If isTrue={uploadedFileSelected}>
          <Loader loading={loadingPreview}>
            <If isTrue={!previewError}>
              <InfoText type="primary" title="MAP_UPLOADED_FILES" />
              <MaterialFileMapper
                mappingMode={mappingMode}
                setMappingMode={setMappingMode}
                fieldPreviews={fieldPreviews}
              />
              <If isTrue={!pastedDataSelected}>
                <FileFormatContainer>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          value={saveFormat}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setSaveFormat(e.target.checked);
                          }}
                        />
                      }
                      label={intl.$t({ id: "SAVE_FORMAT" })}
                    />
                    <If isTrue={saveFormat}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          label={intl.$t({ id: "FILE_FORMAT_NAME" })}
                          value={formatName}
                          disabled={!saveFormat}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => setFormatName(event.target.value)}
                        />
                      </FormControl>
                    </If>
                  </FormGroup>
                </FileFormatContainer>
              </If>
            </If>
          </Loader>
          <If isTrue={previewError}>
            <InfoText
              type="error"
              title="UPLOAD_MATERIAL_LIST_ERROR"
              body="CUSTOM_FILE_UPLOAD_ERROR"
            />
          </If>
        </If>
        <If isTrue={copyPasteTable}>
          <FileTableCopyPaste
            handleChange={updateFile}
            instructions="COPY_PASTE_TABLE_DESCRIPTION"
          />
        </If>
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

export const MaterialFileUploadSelection: FC<
  MaterialFileUploadSelectionProps
> = (props) => (
  <MaterialFileUploadProvider>
    <MaterialFileUploadSelectionWithProvider {...props} />
  </MaterialFileUploadProvider>
);
