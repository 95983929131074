import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { UsersProvider } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ReleaseSequenceProvider } from "../deliveries/providers/ReleaseSequenceProvider";
import { DeliverySlipsHeader } from "./components/list/DeliverySlipsHeader";
import { DeliverySlipsList } from "./components/list/DeliverySlipsList";
import { DeliverySlipSequenceProvider } from "./providers/DeliverySlipSequenceProvider";
import {
  DeliverySlipsProvider,
  useDeliverySlips,
} from "./providers/DeliverySlipsProvider";

const DeliverySlipsWithProvider = () => {
  const intl = useIntl();
  const { deliverySlips, loading, totalCount, isFiltered } = useDeliverySlips();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "DELIVERIES" })}</title>
      </Helmet>
      <DeliverySlipsHeader />
      <DeliverySlipsList
        deliverySlips={deliverySlips}
        loading={loading}
        totalCount={totalCount}
        isFiltered={isFiltered}
      />
    </>
  );
};
export const DeliverySlips = () => (
  <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <ReleaseSequenceProvider>
      <DeliverySlipSequenceProvider>
        <DeliverySlipsProvider>
          <UsersProvider>
            <DeliverySlipsWithProvider />
          </UsersProvider>
        </DeliverySlipsProvider>
      </DeliverySlipSequenceProvider>
    </ReleaseSequenceProvider>
  </PaginationProvider>
);
