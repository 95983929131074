import { GridSortDirection } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { DeliverySlipStatusChip } from "@/common/components/statuses/DeliverySlipStatusChip";
import { TypeIndicator } from "@/common/components/type-indicator/TypeIndicator";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  LogoContainer,
  MdContainer,
  MdFixedContainer,
  SmFixedContainer,
  XsFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useUsers } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import {
  DeliverySlipPartialFieldsFragment,
  QueryDeliverySlipsSortBy,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";
import { DeliverySlipActions } from "./DeliverySlipActions";
import { DeliverySlipOrderAndBuyout } from "./DeliverySlipOrderAndBuyout";
import { DeliverySlipsAllItemsCheckbox } from "./DeliverySlipsAllItemsCheckbox";
import { DeliverySlipsCheckbox } from "./DeliverySlipsCheckbox";

const ItemContainer = tw.div`text-sm`;
const StatusContainer = tw.div`w-24 basis-24`;
const ProjectWrapper = tw(MdContainer)`col-span-2`;

export const useDeliverySlipsConfiguration = () => {
  const { users } = useUsers();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { exportEnabled: includeCheckbox } = useDeliverySlips();

  const configuration: Array<GridCol<DeliverySlipPartialFieldsFragment>> =
    useMemo(
      () => [
        {
          wrapper: XxsFixedContainer,
          position: "center",
          item: ({ item }) => <DeliverySlipsCheckbox item={item} />,
          hidden: !includeCheckbox,
          header: <DeliverySlipsAllItemsCheckbox />,
        },
        {
          wrapper: MdFixedContainer,
          header: <FormattedMessage id="SCANNED_ON" />,
          position: includeCheckbox ? "start" : "center",
          item: ({ item }) => (
            <NotNullableRenderer value={item.createdAt}>
              <ItemContainer>
                <DateView date={item.createdAt} options={SHORT_DATE_OPTION} />
              </ItemContainer>
            </NotNullableRenderer>
          ),
          sortKey: QueryDeliverySlipsSortBy.CreatedAt,
          defaultSort: GridSortDirection.Descending,
        },
        {
          wrapper: MdFixedContainer,
          header: <FormattedMessage id="ORDERED_DATE" />,
          position: "center",
          item: ({ item }) => (
            <NotNullableRenderer value={item.release?.time}>
              <ItemContainer>
                <DateView
                  date={item.release?.time}
                  options={SHORT_DATE_OPTION}
                />
              </ItemContainer>
            </NotNullableRenderer>
          ),
          sortKey: QueryDeliverySlipsSortBy.FulfillmentDate,
          defaultSort: GridSortDirection.Descending,
        },
        {
          wrapper: MdFixedContainer,
          header: <FormattedMessage id="SUBMITTED_BY" />,
          position: "center",
          item: ({ item }) => (
            <ItemContainer>
              {getUserName(users.find((user) => user.id === item.createdBy.id))}
            </ItemContainer>
          ),
        },
        {
          wrapper: ProjectWrapper,
          header: <FormattedMessage id="PROJECT" />,
          item: ({ item }) => (
            <ProjectNameListDetails
              project={item.release?.project || item.project}
            />
          ),
        },
        {
          wrapper: LogoContainer,
          header: <FormattedMessage id="VENDOR" />,
          item: ({ item, index }) => (
            <VendorLogo
              logoImageUrl={item.release?.sellerOrgLocation?.org.photoUrl}
              address={item.release?.sellerOrgLocation?.address}
              orgName={item.release?.sellerOrgLocation?.org.name}
              index={index}
            />
          ),
          position: "center",
        },
        {
          wrapper: SmFixedContainer,
          header: <FormattedMessage id="ORDER" />,
          item: ({ item }) => (
            <NotNullableRenderer value={item.release}>
              <DeliverySlipOrderAndBuyout deliverySlip={item} />
            </NotNullableRenderer>
          ),
        },
        {
          wrapper: XsFixedContainer,
          item: ({ item }) => <TypeIndicator orderType={item?.release?.type} />,
        },
        {
          wrapper: SmFixedContainer,
          header: <FormattedMessage id="PO_HASH" />,
          item: ({ item }) => (
            <NotNullableRenderer value={!!item.release?.poNumber}>
              <ItemContainer>{item.release?.poNumber}</ItemContainer>
            </NotNullableRenderer>
          ),
          hidden: !includePoNumbering,
        },
        {
          wrapper: StatusContainer,
          header: <FormattedMessage id="STATUS" />,
          position: "center",
          item: ({ item }) => <DeliverySlipStatusChip status={item.status} />,
        },
        {
          wrapper: XsFixedContainer,
          header: "",
          position: "start",
          item: ({ item }) => <DeliverySlipActions item={item} />,
        },
      ],
      [includeCheckbox, includePoNumbering, users],
    );

  return configuration;
};
