import { WAREHOUSE_FIELDS } from "@/common/fragments/warehouseFragment";
import { QUOTE_DOCUMENT_SUMMARY_FIELDS } from "@/contractor/pages/home/common/quote-document/fragments/quoteDocumentSummary";
import { PROJECT_COST_CODE_FIELDS } from "@/contractor/pages/home/project/fragments/projectCostCodeFragment";
import { PO_LINK_FIELDS } from "@/contractor/pages/home/release/components/connections/fragments/poLinkFragment";
import { SITE_CONTACT_FIELDS } from "@/contractor/pages/home/release/pages/specify-details/components/site-contact-picker/fragments/siteContactFragment";
import { NOTE_DOCUMENT_SUMMARY_FIELDS } from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-note/note-document/fragments/noteDocumentSummary";
import { RELEASE_GROUP_FIELDS } from "@/contractor/pages/home/releases/pages/deliveries/fragments/releaseGroupFragment";
import { gql } from "@apollo/client";
import { ORDER_TYPE_SUMMARY_FIELDS } from "../components/order-type-picker/fragments/orderTypeSummaryFragment";
import { ADDITIONAL_CHARGES_FIELDS } from "./additionalChargesFragment";
import { ADDRESS_FIELDS } from "./addressFragment";
import { ASSET_FIELDS } from "./assetFragment";
import { ESTIMATED_ITEM_FIELDS } from "./estimatedItemFragment";
import { INSTRUCTION_FIELDS } from "./instructionFragment";
import { RELEASE_APPROVAL_FIELDS } from "./releaseApprovalFragment";
import { RELEASE_APPROVAL_PROGRESS_FIELDS } from "./releaseApprovalProgress";
import { RELEASE_DELIVERY_SLIP_FIELDS } from "./releaseDeliverySlipFragment";
import { RELEASE_ISSUES_FIELDS } from "./releaseIssuesFragment";
import { RELEASE_ITEM_FIELDS } from "./releaseItemFragment";
import { RELEASE_REASSIGNMENT_FIELDS } from "./releaseReassignmentFragment";
import { VENDOR_CONTACT_FIELDS } from "./vendorContactFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const RELEASE_FIELDS = gql`
  ${RELEASE_ITEM_FIELDS}
  ${RELEASE_ISSUES_FIELDS}
  ${ADDRESS_FIELDS}
  ${ZONE_FIELDS}
  ${INSTRUCTION_FIELDS}
  ${ADDITIONAL_CHARGES_FIELDS}
  ${WAREHOUSE_FIELDS}
  ${PO_LINK_FIELDS}
  ${RELEASE_REASSIGNMENT_FIELDS}
  ${ESTIMATED_ITEM_FIELDS}
  ${RELEASE_APPROVAL_FIELDS}
  ${RELEASE_APPROVAL_PROGRESS_FIELDS}
  ${RELEASE_DELIVERY_SLIP_FIELDS}
  ${ASSET_FIELDS}
  ${QUOTE_DOCUMENT_SUMMARY_FIELDS}
  ${NOTE_DOCUMENT_SUMMARY_FIELDS}
  ${ORDER_TYPE_SUMMARY_FIELDS}
  ${VENDOR_CONTACT_FIELDS}
  ${SITE_CONTACT_FIELDS}
  ${PROJECT_COST_CODE_FIELDS}
  ${RELEASE_GROUP_FIELDS}
  fragment ReleaseFields on Release {
    id
    sequenceNumber
    deletedAt
    createdAt
    syncVersion
    total(allowIncomplete: true)
    receiptPostedAt
    poExists
    receiptPostedBy {
      id
      firstName
      lastName
    }
    buyout {
      id
      clientIdentifier
      createdAt
      poLink {
        id
      }
    }
    preferredVendor {
      id
      externalCode
      contacts {
        id
        name
        cellPhone
        email
        receivesOrderNotifications
      }
      taxExempt
    }
    sellerOrgLocation {
      id
      address {
        ...AddressFields
      }
      org {
        id
        name
        photoUrl
      }
      phone
    }
    project {
      id
      name
      jobNumber
      taxExempt
      address {
        ...AddressFields
      }
      location {
        id
      }
    }
    poNumber
    createdBy {
      id
      firstName
      lastName
    }
    submittedAt
    submittedBy {
      id
      firstName
      lastName
      photoUrl
    }
    rejectedAt
    rejectedBy {
      id
      firstName
      lastName
      photoUrl
    }
    rejectionReason
    permissions {
      edit
      submit
      submitDirectly
      approve
      archive
      cancel
    }
    siteContact {
      ...SiteContactFields
    }
    status
    time
    items {
      ...ReleaseItemFields
    }
    issues {
      ...ReleaseIssuesFields
    }
    includeServices
    deliverySlips {
      ...ReleaseDeliverySlipFields
    }
    deliveryPhotos {
      ...AssetFields
    }
    instructions {
      ...InstructionFields
    }
    taxRate
    taxAmount
    customTaxAmount
    taxCode {
      id
      code
      description
      rate
    }
    taxType
    subtotal(allowIncomplete: true)
    notes
    description
    assets {
      ...AssetFields
    }
    paymentTerm
    additionalCharges {
      ...AdditionalChargesFields
    }
    hasMissingCostCodes
    hasMissingExternalCodes
    warehouse {
      ...WarehouseFields
    }
    deliveryAddress {
      ...AddressFields
    }
    quoteDocument {
      ...QuoteDocumentSummaryFields
    }
    noteDocument {
      ...NoteDocumentSummaryFields
    }
    version
    exportedAt
    exportedVersion
    poLink {
      ...POLinkFields
    }
    vendorReassignmentReleases {
      ...ReleaseReassignmentFields
    }
    invoices {
      id
      status
      number
      vendorDuplicate
      orgDuplicate
      total
      documents {
        id
        asset {
          ...AssetFields
        }
      }
      subtotal
      chargesAmount
      taxAmount
    }
    receipts {
      id
      number
      createdAt
      prePaid
      createdBy {
        id
        firstName
        lastName
      }
      documents {
        id
        asset {
          ...AssetFields
        }
      }
    }
    requiresInventoryReceipt
    approvals {
      ...ReleaseApprovalFields
    }
    approvalProgress {
      ...ReleaseApprovalProgressFields
    }
    type {
      ...OrderTypeSummaryFields
      costType {
        id
        description
      }
    }
    vendorContacts {
      ...VendorContactFields
    }
    orgLocation {
      id
      phone
      address {
        ...AddressFields
      }
      org {
        id
        photoUrl
        name
      }
    }
    costCode {
      ...ProjectCostCodeFields
    }
    groups {
      ...ReleaseGroupFields
    }
  }
`;
