import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { ReleaseListActions } from "@/contractor/pages/home/release/components/connections/components/buttons/ReleaseListActions";
import { ReleaseStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { DeliveriesAdditionalFilters } from "./filters/DeliveriesAdditionalFilters";
import { DeliveriesDateRangePickerActionBar } from "./filters/DeliveriesDateRangePickerActionBar";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid lg:grid-flow-col justify-between gap-3 flex-wrap bg-transparent lg:bg-gray-100 pt-4 pb-4 top-[77px] xl:top-[69px]
`;
const Filters = tw.div`grid grid-flow-col gap-2 items-center`;

enum ReleaseStatusType {
  Draft = "DRAFT",
  Reserved = "RESERVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Scheduled = "SCHEDULED",
  Received = "RECEIVED",
  PartiallyReceived = "PARTIALLY_RECEIVED",
  Canceled = "CANCELED",
}

export const DeliveriesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useDeliveries();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const [useItemsDeliveryDate, setUseItemsDeliveryDate] = useState(
    filter?.useItemsDeliveryDate ?? false,
  );

  useEffect(() => {
    setUseItemsDeliveryDate(filter?.useItemsDeliveryDate ?? false);
  }, [filter?.useItemsDeliveryDate]);

  const options = useMemo(() => {
    return Object.entries(ReleaseStatusType).map(([, value]) => {
      return {
        value,
        name: intl.$t({ id: `RELEASE_STATUS_FILTER_${value}` }),
      };
    });
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: ReleaseStatus[] | undefined) => {
      setFilter({
        ...filter,
        statuses: values,
      });
    },
    [filter, setFilter],
  );

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.exported != null) +
      Number(filter?.hasReceipt != null) +
      Number(filter?.isBackorder != null) +
      Number(filter?.deleted !== false) +
      Number(filter?.typeIds != null) +
      Number(filter?.retroactive != null) +
      Number(filter?.closedProjects !== false) +
      Number(filter?.createdBy != null) +
      Number(filter?.poExists != null) +
      Number(filter?.hasInvoices != null) +
      Number(filter?.groupIds != null)
    );
  }, [
    filter?.exported,
    filter?.hasReceipt,
    filter?.isBackorder,
    filter?.deleted,
    filter?.typeIds,
    filter?.retroactive,
    filter?.closedProjects,
    filter?.createdBy,
    filter?.poExists,
    filter?.hasInvoices,
    filter?.groupIds,
  ]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minTime != null || filter?.maxTime != null) +
      Number(filter?.useItemsDeliveryDate === true)
    );
  }, [filter?.minTime, filter?.maxTime, filter?.useItemsDeliveryDate]);

  const onCloseDateRangePicker = useCallback(
    (onClose: () => void) => {
      onClose();
      setUseItemsDeliveryDate(filter?.useItemsDeliveryDate ?? false);
    },
    [filter],
  );

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          info={
            <Tooltip
              id="subtotal-price"
              element={
                <LinkLike onClick={() => null} forwardEvent={false}>
                  <InfoOutlined />
                </LinkLike>
              }
            >
              <FormattedMessage
                id="ORDERS_SEARCH_INFO"
                values={{ br: <br /> }}
              />
            </Tooltip>
          }
          applyHeaderAccent
          clear={() => setFilter({ ...filter, search: undefined })}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(value) =>
            setFilter({
              ...filter,
              projectIds: value ? ([value] as string[]) : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              projectIds: undefined,
            })
          }
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
          withCustomView
          clear={() =>
            setFilter({
              ...filter,
              sellerOrgLocationIds: undefined,
            })
          }
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={setFilterHandler}
          applyHeaderAccent
        />
        <ListHeaderFilterButton
          isDefault={appliedFilters === 0}
          filter={<DeliveriesAdditionalFilters />}
          appliedFilters={appliedFilters}
        />
        <ListDatesButton
          isDefault={appliedDateFilters === 0}
          appliedFilters={appliedDateFilters}
          hideDates
          dates={[
            {
              startDate: filter?.minTime,
              endDate: filter?.maxTime,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minTime: undefined,
              maxTime: undefined,
              useItemsDeliveryDate: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "DELIVERY_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={() => onCloseDateRangePicker(onClose)}
                      selectedValue={
                        filter?.maxTime || filter?.minTime
                          ? {
                              startDate: filter?.minTime
                                ? new Date(filter.minTime)
                                : new Date(),
                              endDate: filter?.maxTime
                                ? new Date(filter.maxTime)
                                : new Date(),
                            }
                          : undefined
                      }
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minTime: dateRange.startDate?.getTime(),
                          maxTime: dateRange.endDate?.getTime(),
                          useItemsDeliveryDate,
                        })
                      }
                      customActionBar={() => (
                        <DeliveriesDateRangePickerActionBar
                          value={useItemsDeliveryDate}
                          onChange={setUseItemsDeliveryDate}
                        />
                      )}
                      onClear={() =>
                        setFilter({
                          ...filter,
                          minTime: undefined,
                          maxTime: undefined,
                          useItemsDeliveryDate: false,
                        })
                      }
                    />
                  ),
                  viewState: "deliveryDate",
                },
              ]}
              activeTab="deliveryDate"
            />
          )}
        </ListDatesButton>
      </Filters>
      <ReleaseListActions />
    </HeaderContainerStyled>
  );
};
