import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterItemInputContainer,
  FooterProps,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { FC } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = FooterProps & {
  includeTax?: boolean;
};

export const BuyoutEmptyFooter: FC<Props> = ({ Wrapper, includeTax }) => {
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { isActive } = useContractorBuyout();

  if (!canViewPrices) {
    return null;
  }
  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <If isTrue={includeTax}>
          <FooterItemInputContainer />
        </If>
        <If isTrue={isActive}>
          <FooterHeader />
        </If>
        <FooterCell />
        <FooterCell />
        <FooterDashedLine />
        <FooterTotal />
      </Wrapper>
    </FooterCol>
  );
};
