import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  OrgLocationFieldsFragment,
  ProjectAllowanceFieldsFragment,
  ProjectCostCodeFieldsFragment,
  ProjectStoreDocument,
  TagExtendedFieldsFragment,
  ZoneExtendedFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  currentProjectId: string | null | undefined;
  zones: ZoneExtendedFieldsFragment[];
  tags: TagExtendedFieldsFragment[];
  phaseCodes: TagExtendedFieldsFragment[];
  costCodes: ProjectCostCodeFieldsFragment[];
  restrictCostCodes: boolean;
  allowance: ProjectAllowanceFieldsFragment;
  location: OrgLocationFieldsFragment | null;
  loading: boolean;
  error: Error | null;
  setCurrentProjectId: (projectId: string | null | undefined) => void;
  updateStoreData: (data: Partial<State>) => void;
  refetch: () => void;
};

const sortByName = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name);

const sortByDescription = (
  a: { description: string },
  b: { description: string },
) => a.description.localeCompare(b.description);

export const useProjectStore = create<State>()(
  devtools((set, get) => ({
    currentProjectId: null,
    tags: [],
    phaseCodes: [],
    zones: [],
    costCodes: [],
    location: null,
    restrictCostCodes: false,
    allowance: {
      amount: "",
      costCodes: [],
      tags: [],
    },
    projectsMap: {},
    loading: false,
    error: null,
    setCurrentProjectId: async (projectId: string | null | undefined) => {
      if (!projectId || projectId === get().currentProjectId) {
        return;
      }
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }

      set({ loading: true, currentProjectId: projectId });
      try {
        const result = await client.query({
          query: ProjectStoreDocument,
          variables: { id: projectId },
          fetchPolicy: "network-only",
        });

        if (!result.data?.project) {
          return;
        }

        const project = result.data.project;
        set({
          zones: project.zones.toSorted(sortByName),
          tags: project.tags.toSorted(sortByName),
          phaseCodes: project.tags
            .filter((t: TagExtendedFieldsFragment) => t.hasMapping)
            .toSorted(sortByName),
          costCodes: (project.costCodes || []).toSorted(sortByDescription),
          restrictCostCodes: project.restrictCostCodes,
          location: project.location,
          allowance: project.allowance,
          loading: false,
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
    updateStoreData: (data: Partial<State>) => {
      set((state) => ({
        ...state,
        ...data,
        tags: (data.tags || state.tags).toSorted(sortByName),
        zones: (data.zones || state.zones).toSorted(sortByName),
        costCodes: (data.costCodes || state.costCodes).toSorted(
          sortByDescription,
        ),
      }));
    },
    refetch: () => {
      set({ loading: true, currentProjectId: undefined });
      get().setCurrentProjectId(get().currentProjectId);
    },
  })),
);
