import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { ReportItemType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { ProjectReportVendorFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import tw from "tailwind-styled-components";
import { SpendingReportValueType } from "../SpendingReportListItem.configuration";
import { CurrencyDisplay } from "./CurrencyDisplay";
import {
  HeaderType,
  ReportItemOrderedInvoicedModal,
  ReportType,
} from "./report-item-orders-modal/ReportItemOrderedInvoicedModal";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item: ProjectReportVendorFieldsFragment;
  costCodeId?: string | undefined;
  valueType: SpendingReportValueType;
};

export const SpendingReportVendorValue: FC<Props> = ({
  item,
  costCodeId,
  valueType,
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <NotNullableRenderer value={item.ordered || item.invoiced}>
        <LinkLikeStyled
          onClick={() => {
            setModalOpened(true);
          }}
        >
          <CurrencyDisplay
            value={
              valueType === SpendingReportValueType.ORDERED
                ? item.ordered
                : item.invoiced
            }
          />
        </LinkLikeStyled>
      </NotNullableRenderer>
      <If isTrue={modalOpened}>
        <ProjectOrderedQuantityProvider
          item={{
            costCodeId,
            ordered: item.ordered,
            invoiced: item.invoiced,
            vendor: {
              id: item.vendor?.id,
              name: item.vendor?.name,
            },
          }}
          type={ReportItemType.CostCode}
        >
          <ReportItemOrderedInvoicedModal
            opened={modalOpened}
            close={() => setModalOpened(false)}
            reportType={ReportType.Vendor}
            headerType={HeaderType.Total}
            itemCostCodeId={costCodeId}
          />
        </ProjectOrderedQuantityProvider>
      </If>
    </>
  );
};
