import { BuyoutAdditionalChargesAndTaxes } from "@/common/components/buyout-additional-chatrges-and-taxes/BuyoutAdditionalChargesAndTaxes";
import { Instructions } from "@/common/components/instructions/Instructions";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { BuyoutList } from "../../BuyoutList";
import { BuyoutHeader } from "../../header/BuyoutHeader";
import { DistributorBuyoutItemsProvider } from "../../providers/DistributorBuyoutItemsProvider";
import { useDistributorBuyout } from "../../providers/DistributorBuyoutProvider";
import { DistributorBuyoutInlineNotesAndTerms } from "../distributor-buyout-notes-and-terms/DistributorBuyoutInlineNotesAndTerms";
import { NonQuotedBuyoutFooter } from "./components/NonQuotedBuyoutFooter";

const Container = tw.div`pb-15`;

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutNonQuoted: FC<Props> = ({ buyout }) => {
  const { updateVendorBuyout } = useDistributorBuyout();
  const total = useMemo(
    () =>
      new Decimal(buyout.amount)
        .plus(
          buyout.additionalCharges.reduce(
            (acc, charge) => acc.plus(charge.amount || 0),
            new Decimal(0),
          ),
        )
        .toString(),
    [buyout.amount, buyout.additionalCharges],
  );
  return (
    <DistributorBuyoutItemsProvider items={buyout.items}>
      <Container>
        <BuyoutHeader buyout={buyout} />
        <BuyoutList buyout={buyout} />
        <Instructions
          instruction={buyout.instructions}
          projectId={buyout.project.id}
        />
        <QuoteDocumentPanel quoteDocument={buyout.quoteDocument} readonly />
        <DistributorBuyoutInlineNotesAndTerms buyout={buyout} />
        <BuyoutAdditionalChargesAndTaxes
          buyout={buyout}
          total={total}
          updateBuyout={updateVendorBuyout}
          readonlySalesTax
          includePaymentTerms
          includeAdditionalChargesAllowance
          readonly={!checkBuyoutStatus(buyout, [BuyoutStatus.Requested])}
        />
        <NonQuotedBuyoutFooter buyout={buyout} />
      </Container>
    </DistributorBuyoutItemsProvider>
  );
};
