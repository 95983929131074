import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { useCostTypes } from "@/contractor/pages/admin/cost-structure/pages/cost-types/hooks/useCostTypes";
import { CostTypeFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";

type Props = {
  costTypes: CostTypeFieldsFragment[];
  onChange: (value: string | null) => void;
  readonly?: boolean;
  costType?: string | null | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  inputProps?: string;
};

export const CostTypeSelector: FC<Props> = ({
  costTypes,
  onChange,
  costType = null,
  readonly = false,
  disableClearable,
  className,
  disabled,
  inputProps,
  placeholder,
}) => {
  const [selectedCostType, setSelectedCostType] = useState<string | null>(
    costType,
  );
  const { formatCostType } = useCostTypes();

  useEffect(() => {
    if (readonly) {
      setSelectedCostType(costType);
    }
  }, [costType, readonly]);

  const handleOnChange = useCallback(
    (value: string | null) => {
      setSelectedCostType(value);
      onChange(value);
    },
    [onChange],
  );

  return (
    <StyledSelect
      placeholder={placeholder}
      className={readonly ? `text-center ${className}` : className}
      options={costTypes}
      value={selectedCostType}
      staticText={readonly}
      onChange={handleOnChange}
      getLabel={(option: CostTypeFieldsFragment) => formatCostType(option)}
      getValue={(option: CostTypeFieldsFragment) => option.id}
      inputProps={{ className: inputProps ?? "py-2 text-sm " }}
      InputLabelProps={{ className: "text-sm" }}
      disableClearable={disableClearable}
      disabled={disabled}
      size="xs"
      sx={{
        ".MuiInputLabel-shrink": {
          display: "none",
        },
        legend: {
          width: 0,
        },
      }}
    />
  );
};
