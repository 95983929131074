import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { ReportItemType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectReportCostCodeFieldsFragment,
  ProjectReportCostCodeVendorFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { SpendingReportValueType } from "../../SpendingReportListItem.configuration";
import { CurrencyDisplay } from "../CurrencyDisplay";
import {
  HeaderType,
  ReportItemOrderedInvoicedModal,
  ReportType,
} from "../report-item-orders-modal/ReportItemOrderedInvoicedModal";
import { CostCodeTotalContainer, ValueWithTax } from "./CostCodeTotal.Styles";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item:
    | ProjectReportCostCodeVendorFieldsFragment
    | ProjectReportCostCodeFieldsFragment
    | null
    | undefined;
  zoneId?: string | undefined;
  reportType: ReportType;
  valueType: SpendingReportValueType;
};

export const CostCodeOrderedInvoiced: FC<Props> = ({
  item,
  zoneId,
  reportType,
  valueType,
}) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const [modalOpened, setModalOpened] = useState(false);

  if (!item) {
    return null;
  }
  return (
    <>
      <NotNullableRenderer value={item.ordered || item.invoiced}>
        <LinkLikeStyled
          onClick={() => {
            setModalOpened(true);
          }}
        >
          <CostCodeTotalContainer>
            <CurrencyDisplay
              value={
                valueType === SpendingReportValueType.ORDERED
                  ? item?.orderedTotal
                  : item?.invoicedTotal
              }
            />
            <If
              isTrue={
                valueType === SpendingReportValueType.ORDERED
                  ? item?.orderedTotal &&
                    item?.ordered &&
                    item?.orderedTotal !== item?.ordered
                  : item?.invoicedTotal &&
                    item.invoiced &&
                    item?.invoicedTotal !== item.invoiced
              }
            >
              <FormattedMessage
                id="VALUE_TAX_AND_FEES"
                tagName={ValueWithTax}
                values={{
                  value: formatCurrency(
                    valueType === SpendingReportValueType.ORDERED
                      ? new Decimal(item?.orderedTotal ?? 0).minus(
                          item?.ordered ?? 0,
                        )
                      : new Decimal(item?.invoicedTotal ?? 0).minus(
                          item?.invoiced ?? 0,
                        ),
                  ),
                }}
              />
            </If>
          </CostCodeTotalContainer>
        </LinkLikeStyled>
      </NotNullableRenderer>
      <If isTrue={modalOpened}>
        <ProjectOrderedQuantityProvider
          item={{
            zoneId,
            costCodeId: item.costCode?.id,
            ordered: item.ordered,
            orderedTotal: item.orderedTotal,
            invoiced: item.invoiced,
            invoicedTotal: item.invoicedTotal,
          }}
          type={ReportItemType.CostCode}
        >
          <ReportItemOrderedInvoicedModal
            opened={modalOpened}
            close={() => setModalOpened(false)}
            reportType={reportType}
            headerType={HeaderType.CostCode}
          />
        </ProjectOrderedQuantityProvider>
      </If>
    </>
  );
};
