import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

type Props = {
  item: ExpandedReleaseItem;
};

const Container = tw.div`flex`;

export const InvoiceReleaseItemCheckbox: FC<Props> = ({ item }) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { release } = useRelease();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();

  const checked = useMemo(
    () => (item.invoiceItems || [])?.length > 0,
    [item.invoiceItems],
  );

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? [
            {
              id: item.id,
              releaseItemId: item.id,
              quantity: item.quantityDecimal ? item.quantityDecimal : "",
              unitPrice: item.unitPrice ? item.unitPrice.toString() : "0",
            },
          ]
        : undefined;
      const removedInvoicedReleaseItems =
        !newChecked && item.invoiceItems?.[0].id
          ? [item.invoiceItems?.[0].id]
          : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      invoice?.id,
      invoice?.release?.id,
      item.id,
      item.invoiceItems,
      item.quantityDecimal,
      item.unitPrice,
      updateInvoice,
      refetchInvoiceValidation,
    ],
  );

  if (hasOrderItemPoItemReferences(item, release)) {
    return null;
  }

  return (
    <Container>
      <SelectionCheckbox
        itemId={item.id}
        items={item.invoiceItems}
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
