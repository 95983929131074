import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  ReleaseStatus,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CategoryLike } from "../../../../../../../common/hooks/useToggleCategory";
import { BidPriceTotalForItem } from "../../list-items/BidPriceTotalForItem";
import { BuyoutCategoryPrice } from "../../list-items/BuyoutCategoryPrice";
import { BuyoutInclusionWrapper } from "../../non-quoted/components/BuyoutInclusionWrapper";
import { BuyoutItemCheckbox } from "../../non-quoted/components/BuyoutItemCheckbox";
import { BuyoutItemGroupCheckbox } from "../../non-quoted/components/BuyoutItemGroupCheckbox";
import { BuyoutItemMaterialView } from "../../non-quoted/components/BuyoutItemMaterialView";
import { BuyoutReleaseAmounts } from "../../non-quoted/components/BuyoutReleaseAmounts";
import { BuyoutReleaseAmountsCount } from "../../non-quoted/components/BuyoutReleaseAmountsCount";
import { BuyoutTagsPicker } from "../../non-quoted/components/BuyoutTagsPicker";
import { GroupBuyoutByCostCodeToggle } from "../GroupBuyoutByCostCodeToggle";
import { GroupQuotedBuyoutByCostCodeToggle } from "../GroupQuotedBuyoutByCostCodeToggle";
import { BuyoutEmptyFooter } from "../footer/BuyoutEmptyFooter";
import { BuyoutOrderedTotalFooter } from "../footer/BuyoutOrderedTotalFooter";
import { BuyoutPaymentFooter } from "../footer/BuyoutPaymentFooter";
import { BuyoutReceivedTotalFooter } from "../footer/BuyoutReceivedTotalFooter";
import { BuyoutTitleColFooter } from "../footer/BuyoutTitleColFooter";
import { BuyoutTotalFooter } from "../footer/BuyoutTotalFooter";
import { BuyoutRfqItemContainer } from "./BuyoutListItemsConfiguration";

export const useBuyoutListNonDraftedConfiguration = ({
  buyout,
}: {
  buyout: BuyoutFieldsFragment | null;
}) => {
  const intl = useIntl();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { hasPhaseCodes } = useOrgSettings();
  const isNotDraft = useMemo(
    () => !checkBuyoutStatus(buyout, [BuyoutStatus.Draft]),
    [buyout],
  );
  const configuration: Array<GridCol<BuyoutItemFieldsFragment, CategoryLike>> =
    useMemo(
      () => [
        {
          wrapper: Base,
          position: "center",
          item: ({ item }) => <BuyoutItemCheckbox item={item} />,
          group: (category) => <BuyoutItemGroupCheckbox group={category} />,
          subgroup: (category) => <BuyoutItemGroupCheckbox group={category} />,
          hidden: !checkBuyoutStatus(buyout, [BuyoutStatus.Active]),
        },
        {
          wrapper: BuyoutRfqItemContainer,
          header: (
            <>
              <FormattedMessage id="ITEM_DESCRIPTION" />
              {buyout?.quote ? (
                <GroupQuotedBuyoutByCostCodeToggle />
              ) : (
                <GroupBuyoutByCostCodeToggle />
              )}
            </>
          ),
          item: ({ item, count, readonly }) => (
            <BuyoutItemMaterialView
              item={item}
              count={count}
              readonly={readonly}
            />
          ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
            />
          ),
          includesCounter: true,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutPaymentFooter includeTax />
            </If>
          ),
        },
        {
          wrapper: MdFixedContainer,
          header: (
            <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />
          ),
          item: ({ item }) => {
            return (
              <BuyoutTagsPicker
                item={item}
                readonly={
                  checkBuyoutStatus(buyout, [
                    BuyoutStatus.Draft,
                    BuyoutStatus.Requested,
                  ]) && !buyout?.quote
                }
              />
            );
          },
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutEmptyFooter Wrapper={MdFixedContainer} includeTax />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="BUYOUT_QUANTITY" />,
          wrapper: MdFixedContainer,
          position: "center",
          item: ({ item }) => (
            <ValueUnit
              value={item.quantityDecimal}
              uom={item.projectItem.estimateUom}
              mnemonicFirst={false}
              classNames={{ footer: "truncate" }}
            />
          ),
          group: (category) => (
            <ProductCategoryCount itemsLength={category.items.length} />
          ),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutEmptyFooter Wrapper={MdFixedContainer} includeTax />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="PRICE" />,
          wrapper: SmFixedContainer,
          position: "center",
          item: ({ item }) => (
            <BuyoutInclusionWrapper item={item}>
              <ValueCurrency
                value={item.unitPrice || 0}
                perUom={item.projectItem.estimateUom}
                dynamicDigits
                mnemonicFirst={false}
              />
            </BuyoutInclusionWrapper>
          ),
          hidden: !canViewPrices,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutTitleColFooter
                includeTax
                includeLabels={checkBuyoutStatus(buyout, [
                  BuyoutStatus.Draft,
                  BuyoutStatus.Requested,
                ])}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="TOTAL" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutInclusionWrapper item={item} includeMessage>
              <BidPriceTotalForItem item={item} />
            </BuyoutInclusionWrapper>
          ),
          group: (category) => <BuyoutCategoryPrice items={category.items} />,
          hidden: !canViewPrices,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutTotalFooter
                Wrapper={MdFixedContainer}
                includeTaxes={checkBuyoutStatus(buyout, [
                  BuyoutStatus.Draft,
                  BuyoutStatus.Requested,
                ])}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="ORDERED_SO_FAR" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutReleaseAmounts
              title={intl.$t({ id: "ORDERED_SO_FAR" })}
              item={item}
              amount={item.releasedAmount}
              releaseStatuses={[
                ReleaseStatus.Requested,
                ReleaseStatus.Scheduled,
                ReleaseStatus.Received,
                ReleaseStatus.PartiallyReceived,
              ]}
            />
          ),
          group: (category) => (
            <BuyoutReleaseAmountsCount
              items={category.items}
              releaseStatuses={[
                ReleaseStatus.Requested,
                ReleaseStatus.Scheduled,
                ReleaseStatus.Received,
                ReleaseStatus.PartiallyReceived,
              ]}
            />
          ),
          hidden: checkBuyoutStatus(buyout, [
            BuyoutStatus.Draft,
            BuyoutStatus.Requested,
          ]),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutOrderedTotalFooter Wrapper={MdFixedContainer} />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="RECEIVED_SO_FAR" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutReleaseAmounts
              title={intl.$t({ id: "RECEIVED_SO_FAR" })}
              item={item}
              amount={item.completedAmount}
              releaseStatuses={[ReleaseStatus.Received]}
            />
          ),
          group: (category) => (
            <BuyoutReleaseAmountsCount
              items={category.items}
              releaseStatuses={[ReleaseStatus.Received]}
            />
          ),
          hidden: checkBuyoutStatus(buyout, [
            BuyoutStatus.Draft,
            BuyoutStatus.Requested,
          ]),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutReceivedTotalFooter Wrapper={MdFixedContainer} />
            </If>
          ),
        },
      ],
      [buyout, isNotDraft, hasPhaseCodes, canViewPrices, intl],
    );

  return configuration;
};
