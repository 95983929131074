import { Address } from "@/common/components/address/Address";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { routes } from "@/config/routes";
import { StyledTextField } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ConnectionError,
  ExportErrorList,
  ValidationType,
} from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { useUnlinkPo } from "@/contractor/pages/home/release/components/connections/hooks/useUnlinkPo";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import {
  ArrowForward,
  Check,
  CheckCircle,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { Error } from "./ConnectionOptions.styles";

const ProjectAndVendorContainer = tw.div`grid grid-cols-[1.5fr_2fr] flex-row gap-5`;
const ProjectContainer = tw.div`flex flex-col justify-start border-r border-gray-400 border-dashed pr-2`;
const ProjectNumber = tw.div`flex flex-row gap-1 items-center`;
const ProjectName = tw.div``;
const VendorContainer = tw.div`flex flex-row gap-2`;
const CheckCircleStyled = tw(CheckCircle)`text-green-800`;
const CheckStyled = tw(Check)`text-blue-800`;
const Details = tw.div`flex flex-col`;
const Container = tw.div`flex flex-col gap-3 text-sm`;
const CardStyled = tw.div<{
  $hasError: boolean;
  $hasMissingPO: boolean;
}>`rounded-3xl p-4 shadow-md
    ${({ $hasError }) => $hasError && "border border-red-500 bg-gray-100"}
    ${({ $hasMissingPO }) => $hasMissingPO && "bg-gray-100"}
`;
const IconContainer = tw.div`border border-dashed border-gray-500 rounded-full`;
const Arrow = tw(ArrowForward)`transition-all duration-500 animate-slide`;

type Props = PropsWithChildren<{
  buyout: BuyoutFieldsFragment;
  poNumber: string;
  updatePoNumber: (poNumber: string) => void;
  includedValidations: ConnectionError[];
  editablePoNumber?: boolean;
  linking?: boolean;
  loading?: boolean;
  error?: string;
}>;

export const PoBuyoutDetails: FC<Props> = ({
  buyout,
  poNumber,
  updatePoNumber,
  includedValidations,
  editablePoNumber = true,
  linking,
  loading,
  error,
  children,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { unlinkPo } = useUnlinkPo();
  const { connectedSourceSystem } = useOrgSettings();

  const errorsAndWarnings = useMemo(
    () => includedValidations.filter((v) => v.condition),
    [includedValidations],
  );

  const errors = useMemo(
    () =>
      includedValidations.filter(
        (v) => v.condition && v.validationType !== ValidationType.Warning,
      ),
    [includedValidations],
  );

  const onPoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!buyout.poLink) {
        updatePoNumber(value);
      } else {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "DISCONNECT_PO" }),
          icon: <WarningIcon />,
          closeOnConfirm: true,
          title: intl.$t({ id: "CHANGE_PO_NUMBER" }),
          text: intl.$t(
            { id: "DISCONNECT_PO_CHANGE_TEXT" },
            {
              integration: intl.$t({
                id: `INTEGRATION_${connectedSourceSystem}`,
              }),
            },
          ),
          handleConfirm: async () => {
            await unlinkPo(buyout.poLink?.id || "", buyout.id);
            updatePoNumber(value);
          },
        });
      }
    },
    [
      buyout.poLink,
      buyout.id,
      updatePoNumber,
      openDialog,
      intl,
      connectedSourceSystem,
      unlinkPo,
    ],
  );

  if (linking && errors.length > 0) {
    return null;
  }

  return (
    <CardStyled $hasError={errors.length > 0} $hasMissingPO={!poNumber}>
      <Container>
        <ProjectAndVendorContainer>
          <ProjectContainer>
            <ProjectNumber>
              {loading ? (
                <Loader loading small className="w-5" />
              ) : linking ? (
                <IconContainer>
                  <Arrow />
                </IconContainer>
              ) : (
                <If isTrue={!errors.length}>
                  {buyout.poLink?.syncedAt ? (
                    <CheckCircleStyled />
                  ) : (
                    <CheckStyled />
                  )}
                </If>
              )}
              <LinkLike
                to={generatePath(routes.buyout, { id: buyout.id })}
                target="_blank"
              >
                <FormattedMessage
                  id="BUYOUT"
                  values={{ number: buyout?.clientIdentifier }}
                />
              </LinkLike>
            </ProjectNumber>
            <ProjectName>{buyout.project?.name}</ProjectName>
          </ProjectContainer>
          <VendorContainer>
            <OrgLogo
              logoImageUrl={buyout.sellerOrgLocation?.org.photoUrl}
              name={buyout.sellerOrgLocation?.org.name || ""}
              width={40}
            />
            <Details>
              {buyout.sellerOrgLocation?.org.name || ""}
              <Address address={buyout.sellerOrgLocation?.address} />
            </Details>
          </VendorContainer>
        </ProjectAndVendorContainer>
        <If isTrue={error}>
          <Error>
            <ErrorOutlineOutlined />
            {error}
          </Error>
        </If>
        <PoNumberingSettingsCheck>
          <StyledTextField
            label={intl.$t({ id: "PO_NUMBER" })}
            className="mb-auto"
            value={poNumber}
            placeholder={""}
            onChange={onPoChange}
            size="small"
            required
            shrink
            disabled={!editablePoNumber || linking}
          />
        </PoNumberingSettingsCheck>
        {children}
        <ExportErrorList includedValidations={errorsAndWarnings} />
      </Container>
    </CardStyled>
  );
};
