import { SubmittedByFilter } from "@/contractor/pages/home/receipts/pages/receipts/components/header/SubmittedByFilter";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  values: string[] | null | undefined;
  setFilter: (createdByIds: string[] | undefined) => void;
};

const Container = tw.div`grid grid-cols-[1fr_1.5fr] justify-between items-center gap-4 mt-2`;

export const RequestedByFilter = ({ values, setFilter }: Props) => {
  return (
    <Container>
      <FormattedMessage id="REQUESTED_BY" />
      <SubmittedByFilter values={values} setFilter={setFilter} />
    </Container>
  );
};
