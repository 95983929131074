import { InfoOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalPOUtils } from "../hooks/useExternalPOUtils";
import { useImportExternalPos } from "../hooks/useImportExternalPos";

const Container = tw.div`flex flex-col border-y border-dashed border-gray-500 py-2 mb-4`;
const Item = tw.div`pl-2 items-center flex flex-row gap-1 text-xs font-medium`;
const Error = tw.div`flex flex-row items-center font-semibold text-2xs text-red-500 ml-1.5`;

enum ImportExternalPoValidationType {
  MissingProject,
  MissingVendor,
  MissingItems,
}

const ImportExternalPoValidationTranslation = {
  [ImportExternalPoValidationType.MissingProject]:
    "EXTERNAL_PO_MISSING_PROJECT",
  [ImportExternalPoValidationType.MissingVendor]: "EXTERNAL_PO_MISSING_VENDOR",
  [ImportExternalPoValidationType.MissingItems]: "EXTERNAL_PO_MISSING_ITEMS",
};

export const ImportExternalPoValidation: FC = () => {
  const {
    variables: { release },
    externalPo,
  } = useImportExternalPos();
  const { getIncludedItemsCategory, getImportableItems } = useExternalPOUtils();

  const validations = useMemo(() => {
    const validations: ImportExternalPoValidationType[] = [];
    const includedItemsCategory = getIncludedItemsCategory(externalPo);
    const importableItems = getImportableItems(externalPo);

    if (release?.poLink?.immutable) {
      return validations;
    }

    if (
      (includedItemsCategory.length === 0 ||
        includedItemsCategory.every((item) => item.items.length === 0)) &&
      importableItems.length === 0
    ) {
      validations.push(ImportExternalPoValidationType.MissingItems);
    }

    if (!externalPo?.project?.project) {
      validations.push(ImportExternalPoValidationType.MissingProject);
    }

    if ((externalPo?.vendor?.orgPreferredVendors ?? []).length === 0) {
      validations.push(ImportExternalPoValidationType.MissingVendor);
    }

    return validations;
  }, [
    externalPo,
    getImportableItems,
    getIncludedItemsCategory,
    release?.poLink?.immutable,
  ]);

  if (validations.length === 0) {
    return null;
  }

  return (
    <Container>
      <FormattedMessage
        id="ERROR_COUNT_IMPORT"
        values={{ count: validations.length }}
        tagName={Error}
      />
      {validations.map((validation) => (
        <Item key={validation}>
          <InfoOutlined className="text-red-500" />
          <FormattedMessage
            id={ImportExternalPoValidationTranslation[validation]}
          />
        </Item>
      ))}
    </Container>
  );
};
