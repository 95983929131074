import { Group } from "@/common/components/groups/common/types/types";
import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { useInvoiceFolders } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-folder-picker/hooks/useInvoiceFolders";
import { useReceipts } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsProvider";
import { useReceiptSequence } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsSequenceProvider";
import { InvoiceType, UpdateInvoicesInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";

type Props = {
  removing?: boolean;
  onClose: () => void;
  existingFolders?: Group[];
};

export const ReceiptFolderSelector: FC<Props> = ({
  removing,
  onClose,
  existingFolders = [],
}) => {
  const { folders, createFolder } = useInvoiceFolders(InvoiceType.Receipt);
  const { sequenceIds } = useReceiptSequence();
  const { updateReceipts } = useReceipts();
  const filteredFolders = useMemo(
    () => (removing ? existingFolders : folders),
    [folders, removing, existingFolders],
  );

  const updateEntity = useCallback(
    (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      updateReceipts({
        invoiceIds: sequenceIds,
        ...input,
      });
    },
    [updateReceipts, sequenceIds],
  );

  return (
    <GroupSelector
      createGroup={createFolder}
      groups={filteredFolders}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
