import { MAX_VENDOR_NUMBER } from "@/common/const";
import { FC, useMemo } from "react";
import { SelectCustomItemView } from "../select/components/single/components/SelectCustomItemView";
import { Select } from "../select/components/single/Select";
import { useVendorOptions } from "../vendor-picker/useVendorOptions";
import { VendorPickerCustomRender } from "../vendor-picker/VendorPickerCustomRender";

type Props = {
  onChange: (newValue: string | null) => void;
  value?: string | null;
  withCustomView?: boolean;
  clear?: () => void;
};

const ALL_VENDORS = "-1";

export const VendorsFilterSelector: FC<Props> = ({
  onChange,
  value,
  withCustomView = false,
  clear,
}) => {
  const { vendorOptions, loading, shouldShowVendorCode } = useVendorOptions({
    includeAllOption: true,
  });

  const selectedOption = useMemo(
    () =>
      value === ALL_VENDORS
        ? undefined
        : vendorOptions.find((vendor) => vendor.value === value),
    [vendorOptions, value],
  );

  return (
    <Select
      options={vendorOptions || []}
      value={value || ALL_VENDORS}
      onChange={(value) => onChange(value === ALL_VENDORS ? null : value)}
      customRender={(item) =>
        VendorPickerCustomRender(item, { shouldShowVendorCode })
      }
      getLabel={(option) => option.name}
      getValue={(option) => option.value as string}
      inputProps={{
        className: `${!value && "text-gray-600"} px-2 py-[2.5px]`,
      }}
      selectedOptionCustomView={
        withCustomView && selectedOption ? (
          <SelectCustomItemView text={selectedOption.name} clear={clear} />
        ) : null
      }
      loading={loading}
      limitResults={MAX_VENDOR_NUMBER}
    />
  );
};
