import { getTaxRate } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useLumpSumReleaseItems } from "@/contractor/pages/home/release/hooks/useLumpSumReleaseItems";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import {
  ReceiptDocument,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useReceiptSequence } from "../../receipts/providers/ReceiptsSequenceProvider";
import { useReceiptCreateReleaseSpreadsheetConfig } from "../components/order/itemized/create/ReceiptCreateRelease.config";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useReceipt } from "./ReceiptProvider";

type ProviderContextType = {
  syncCreateReleaseFromReceipt: (
    values: ReceiptCreateReleaseFormValues,
    options: { markAsApproved: boolean },
  ) => Promise<boolean>;
  loading: boolean;
  saving: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  createRelease: (
    values: ReceiptCreateReleaseFormValues,
    options: { markAsApproved: boolean },
  ) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromReceipt: NoFunctionBooleanPromise,
  loading: false,
  saving: false,
  spreadsheetViewColumns: [],
  createRelease: NoFunctionBooleanPromise,
});

export const ReceiptCreateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { receipt, updateReceipt } = useReceipt();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { gotoInvalidRow } = useColumnMapper();
  const { sequenceActive, navigateToNextSequence } = useReceiptSequence();
  const { getLumpSumReleaseItem } = useLumpSumReleaseItems();

  const { getSyncedRelease } = useSyncReleaseItems();
  const [saving, setSaving] = useState(false);
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { hasPhaseCodes } = useOrgSettings();
  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();
  const syncCreateReleaseFromReceipt = async (
    values: ReceiptCreateReleaseFormValues,
    { markAsApproved }: { markAsApproved: boolean },
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UnitPrice,
      ]) ||
      !validateRowValues(
        [
          COLUMN_TYPE.Quantity,
          COLUMN_TYPE.UnitPrice,
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    setSaving(true);
    const { addedItems } = await getSyncedRelease();

    if (addedItems.length > 0) {
      try {
        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              invoiceId: receipt?.id ?? "",
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId,
              items: addedItems,
              taxRate: getTaxRate(values),
              customTaxAmount: values.customTaxAmount || undefined,
              retroactive: true,
              time: values.issueDate?.getTime(),
              typeId: values.orderTypeId || undefined,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
              paymentTerm: values.paymentTerm
                ? Number(values.paymentTerm)
                : undefined,
              skipInvoiceApproval: !markAsApproved,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: ReceiptDocument,
              variables: { id: receipt?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        let receiptUpdated = false;
        if (!errors && receipt?.id) {
          setSystemAlert(
            intl.$t(
              {
                id: markAsApproved
                  ? "ORDER_WAS_SUCCESSFULLY_CREATED_WITH_APPROVED_RECEIPT"
                  : "ORDER_WAS_SUCCESSFULLY_CREATED_WITH_UNAPPROVED_RECEIPT",
              },
              {
                orderNumber: data?.createStandaloneRelease?.sequenceNumber,
                receiptNumber: receipt.number,
              },
            ),
            { type: SystemAlertType.MANUAL, clear: true },
          );
          await updateReceipt({
            id: receipt?.id,
            issueDate: values.issueDate?.getTime(),
          });
        }
        if (!errors && receiptUpdated && sequenceActive) {
          navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
        }
        setSaving(false);
        return !errors && receiptUpdated;
      } catch (error) {
        setError(error);
        setSaving(false);
        return false;
      }
    }

    return true;
  };

  const createRelease = async (
    values: ReceiptCreateReleaseFormValues,
    { markAsApproved }: { markAsApproved: boolean },
  ) => {
    try {
      setSaving(true);
      const items = await getLumpSumReleaseItem(values);
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: receipt?.id ?? "",
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            time: values.issueDate?.getTime(),
            items,
            taxRate: getTaxRate(values),
            customTaxAmount: values.customTaxAmount || undefined,
            assignDefaultCostCodes: false,
            retroactive: true,
            typeId: values.orderTypeId || undefined,
            taxCodeId: values.taxCodeId,
            taxType: values.taxType,
            skipInvoiceApproval: !markAsApproved,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);
      let receiptUpdated = false;
      if (!errors && receipt?.id) {
        setSystemAlert(
          intl.$t(
            {
              id: markAsApproved
                ? "ORDER_WAS_SUCCESSFULLY_CREATED_WITH_APPROVED_RECEIPT"
                : "ORDER_WAS_SUCCESSFULLY_CREATED_WITH_UNAPPROVED_RECEIPT",
            },
            {
              orderNumber: data?.createStandaloneRelease?.sequenceNumber,
              receiptNumber: receipt.number,
            },
          ),
          { type: SystemAlertType.MANUAL, clear: true },
        );
        await updateReceipt({
          id: receipt?.id,
          issueDate: values.issueDate?.getTime(),
        });
      }
      if (!errors && receiptUpdated && sequenceActive) {
        navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
      }

      setSaving(false);
      return !errors && receiptUpdated;
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromReceipt,
        loading: creatingRelease,
        saving,
        spreadsheetViewColumns: useReceiptCreateReleaseSpreadsheetConfig(),
        createRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptCreateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
