import { COST_CODE_FIELDS } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/fragments/costCodeFragment";
import { gql } from "@apollo/client";
import { PROJECT_MATERIAL_FIELDS } from "../../contractor/pages/admin/org-items/pages/materials/fragments/projectMaterialFragment";
import { INVOICED_RELEASE_ITEM_FIELDS } from "./InvoicedReleaseItemFragment";
import { ASSET_FIELDS } from "./assetFragment";
import { ESTIMATED_ITEM_FIELDS } from "./estimatedItemFragment";
import { INSTRUCTION_FIELDS } from "./instructionFragment";
import { MANUFACTURER_FIELDS } from "./manufacturerFragment";
import { RELEASE_ISSUES_FIELDS } from "./releaseIssuesFragment";
import { TAG_EXTENDED_FIELDS } from "./tagFragmentExtended";
import { UOM_FIELDS } from "./uomFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const RELEASE_ITEM_FIELDS = gql`
  ${ZONE_FIELDS}
  ${TAG_EXTENDED_FIELDS}
  ${RELEASE_ISSUES_FIELDS}
  ${MANUFACTURER_FIELDS}
  ${UOM_FIELDS}
  ${ESTIMATED_ITEM_FIELDS}
  ${PROJECT_MATERIAL_FIELDS}
  ${INSTRUCTION_FIELDS}
  ${ASSET_FIELDS}
  ${COST_CODE_FIELDS}
  ${INVOICED_RELEASE_ITEM_FIELDS}
  fragment ReleaseItemFields on ReleaseItem {
    id
    tags {
      ...TagExtendedFields
    }
    zone {
      ...ZoneFields
    }
    name
    quantityDecimal
    receivedQuantityDecimal
    notes
    assets {
      ...AssetFields
    }
    unitPrice
    alternativeFulfillmentTime
    priceEstimated
    pricePrenegotiated
    isIncluded
    instructions {
      ...InstructionFields
    }
    alternativeFulfillmentRelease {
      id
      sequenceNumber
    }
    deliveryDate
    backorderFromRelease {
      id
      sequenceNumber
    }
    sellerOrgLocation {
      id
      address {
        id
        city
        state
      }
      org {
        id
        name
      }
    }
    redelivery {
      id
      issue {
        id
        releaseItem {
          id
          release {
            id
            time
            sequenceNumber
          }
        }
      }
    }
    buyoutItem {
      id
      quantityDecimal
      description
      unitPrice
      isIncluded
      releaseItems(
        status: [REQUESTED, SCHEDULED, RECEIVED, PARTIALLY_RECEIVED]
      ) {
        id
        release {
          id
        }
        quantityDecimal
        zone {
          ...ZoneFields
        }
        uom {
          ...UOMFields
        }
      }
    }
    projectItem {
      id
      material {
        ...ProjectMaterialFields
      }
      estimatedItems {
        ...EstimatedItemFields
      }
      estimateUom {
        ...UOMFields
      }
    }
    costCode {
      ...CostCodeFields
    }
    costType {
      id
      code
      description
    }
    issues {
      ...ReleaseIssuesFields
    }
    manufacturer {
      ...ManufacturerFields
    }
    uom {
      ...UOMFields
    }
    position
    invoicedQuantity
    invoiceItems(invoiceId: $invoiceId, invoiceStatuses: $invoiceStatuses) {
      ...InvoicedReleaseItemFields
    }
    poItemLink {
      id
    }
  }
`;
