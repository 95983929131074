import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUnspecifiedZone } from "@/common/hooks/useUnspecifiedZone";
import { getZonesByReleaseItems } from "@/common/utils/cost-codes-and-zones/getZonesByReleaseItems";
import { useReleaseItemsGrouping } from "@/common/utils/hooks/useReleaseItemsGrouping";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type ZoneCategory = CategoryState<
  CategoryState<DistributorReleaseItemFieldsFragment>
>;

type ProviderContextType = {
  zones: ZoneCategory[];
  toggleZone: (name: string) => void;
  toggleCostCode: (costCodeId: string, zoneId: string) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;
};

type Props = {
  items: DistributorReleaseItemFieldsFragment[];
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  zones: [],
  toggleZone: NoFunction,
  toggleCostCode: NoFunction,
  groupedByCostCode: false,
  setGroupedByCostCode: NoFunction,
});

export const DistributorReleaseItemsZoneProvider: FC<Props> = ({
  children,
  items,
}) => {
  const [zones, setZones] = useState<ZoneCategory[]>([]);
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { toggleCategory } = useToggleCategory(zones, setZones);
  const [groupedByCostCode, setGroupedByCostCode] = useState(false);
  const { getProjectCodes, getFilteredProjectItems } = useReleaseItemsGrouping({
    items,
    forceUsePhaseCodes: items.some((releaseItem) =>
      releaseItem.tags.some((tag) => tag.hasMapping),
    ),
  });

  useEffect(() => {
    if (!groupedByCostCode) {
      setZones([
        {
          id: unassignedZone.id,
          name: unassignedZone.name,
          isOpened: true,
          items: [
            {
              id: unassignedCostCode.id,
              name: unassignedCostCode.description,
              isOpened: true,
              items: items.sort(
                (a, b) => (a.position || 0) - (b.position || 0),
              ),
            },
          ],
        },
      ]);
      return;
    }
    const zoneGroups = getZonesByReleaseItems(items, unassignedZone);
    const zoneCategories: ZoneCategory[] = zoneGroups.map((zone) => {
      const newZone = {
        id: zone.id,
        name: zone.name,
        isOpened: true,
      };
      const projectCostCodes = getProjectCodes(zone, false);
      const mappedCost: CategoryState<DistributorReleaseItemFieldsFragment>[] =
        projectCostCodes.map((costCode) => {
          const newCostCode = {
            id: costCode.id,
            name: costCode.description,
            isOpened: true,
          };
          const projectItems = getFilteredProjectItems(
            zone,
            costCode,
          ) as DistributorReleaseItemFieldsFragment[];
          return {
            ...newCostCode,
            items: projectItems,
          };
        });
      return {
        ...newZone,
        items: mappedCost,
      };
    });
    setZones(zoneCategories);
  }, [
    items,
    unassignedZone,
    unassignedCostCode,
    groupedByCostCode,
    getProjectCodes,
    getFilteredProjectItems,
  ]);

  const toggleCostCode = useCallback(
    (costCodeId: string, zoneId: string) => {
      const zoneIndex = zones.findIndex((z) => z.id === zoneId);
      const zone = zones[zoneIndex];
      const costCodeIndex = zone.items.findIndex((c) => c.id === costCodeId);
      const costCode = zone.items[costCodeIndex];
      setZones([
        ...zones.slice(0, zoneIndex),
        {
          ...zone,
          items: [
            ...zone.items.slice(0, costCodeIndex),
            { ...costCode, isOpened: !costCode.isOpened },
            ...zone.items.slice(costCodeIndex + 1),
          ],
        },
        ...zones.slice(zoneIndex + 1),
      ]);
    },
    [zones],
  );

  return (
    <ProviderContext.Provider
      value={{
        zones,
        toggleZone: toggleCategory,
        toggleCostCode,
        groupedByCostCode,
        setGroupedByCostCode,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorReleaseItemsZoneProvider = (): ProviderContextType =>
  useContext(ProviderContext);
