import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useVendors } from "../vendors/hooks/useVendors";
import { vendorLabelFormatter } from "./VendorPickerCustomRender";

export const ALL_VENDORS_LIST_ID = "-1";

type Props = {
  includeAllOption?: boolean;
};

export const useVendorOptions = (props?: Props) => {
  const { vendors, loading, shouldShowVendorCode, getVendorCode } =
    useVendors();
  const intl = useIntl();
  const vendorOptions = useMemo(() => {
    const opts = [
      ...(props?.includeAllOption
        ? [
            {
              value: ALL_VENDORS_LIST_ID,
              name: intl.$t({ id: "ALL_VENDORS" }),
            },
          ]
        : []),
    ] as {
      value: string | undefined;
      name: string;
    }[];

    vendors.forEach((vendor) => {
      opts.push({
        ...vendor,
        value: vendor.sellerOrgLocation.id,
        name: vendorLabelFormatter(vendor.sellerOrgLocation, vendor.contacts, {
          vendorCode: getVendorCode(vendor),
        }),
      });
    });
    return opts;
  }, [getVendorCode, intl, props?.includeAllOption, vendors]);

  return { vendorOptions, loading, shouldShowVendorCode };
};
