import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { If } from "@/common/components/if/If";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { BuyoutStatusChip } from "@/common/components/statuses/BuyoutStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BuyoutMessagesButton } from "@/contractor/pages/home/buyout/components/common/buyout-messages/BuyoutMessagesButton";
import { ExportBuyoutButton } from "@/contractor/pages/home/buyouts/components/connections/components/buttons/ExportBuyoutButton";
import { useBuyoutSequence } from "@/contractor/pages/home/buyouts/hooks/useBuyoutSequence";
import {
  BuyoutFieldsFragment,
  BuyoutStatus,
  MessageContextKind,
} from "@/generated/graphql";
import { ListAlt } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { BuyoutSequenceStepper } from "../../BuyoutSequenceStepper";
import { BuyoutItemListFilters } from "../../common/filters/BuyoutItemListFilters";
import { BuyoutPrintButton } from "../components/BuyoutPrintButton";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { SubmitBuyoutDialog } from "../providers/submit-buyout-dialog/SubmitBuyoutDialog";
import { useBuyoutMutations } from "../providers/useBuyoutMutations";
import { BuyoutNonQuotedHeaderInfo } from "./BuyoutNonQuotedHeaderInfo";

const StyledSelect = tw(
  Select,
)`[&>div]:p-0 [&>div]:pr-7 [&_fieldset]:border-none [&_svg]:right-0
`;

type Props = {
  buyout: BuyoutFieldsFragment;
  readonly: boolean;
};

const BuyoutNoWrapper = tw.div`pr-2`;

const Headline = tw.div`
  text-lg flex items-center
`;

const BUYOUT_TRANSITION_STATUSES = [
  BuyoutStatus.Requested,
  BuyoutStatus.Active,
];

export const BuyoutNonQuotedHeader: FC<Props> = ({ buyout, readonly }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const viewState = useTableViewStore((state) => state.viewState);
  const { connectedSourceSystem } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { sequenceActive } = useBuyoutSequence();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const { submitBuyout, submitting } = useBuyoutMutations();
  const { isEditable } = useBuyoutGrouped();

  const actions = useMemo(() => {
    const bActions = [];
    bActions.push(
      ...[
        ...([BuyoutStatus.Active].includes(buyout.status) &&
        viewState === TableViewState.normal
          ? [
              <ListHeaderFilterButton
                key="filters"
                filter={<BuyoutItemListFilters />}
                classes={{
                  button: "hover:border-white flex-row-reverse px-0 py-0",
                  buttonText: "text-xs",
                }}
                hideLabel
              />,
            ]
          : []),

        <If key="buyoutMessages" isTrue={buyout.status !== BuyoutStatus.Draft}>
          <QuestionBox
            key="delivery-history"
            onClick={() =>
              navigate(generatePath(routes.buyoutDeliveries, { id: buyout.id }))
            }
          >
            <Tooltip id="delivery-history" element={<ListAlt />}>
              <FormattedMessage id="DELIVERY_HISTORY" />
            </Tooltip>
          </QuestionBox>
          <BuyoutPrintButton buyout={buyout} key="print" />
          <BuyoutMessagesButton
            key="messages"
            buyout={buyout}
            org={buyout.sellerOrgLocation.org}
            text={
              <QuestionBox>
                <Tooltip
                  id="buyout-messages"
                  element={
                    <MessageButton
                      id={buyout.id}
                      kind={MessageContextKind.Buyout}
                    />
                  }
                >
                  <FormattedMessage id="MESSENGER" />
                </Tooltip>
              </QuestionBox>
            }
          />
        </If>,
      ],
    );

    if (
      (includePoNumbering || buyout.poLink || buyout.poExists) &&
      connectedSourceSystem
    ) {
      bActions.push(<ExportBuyoutButton buyout={buyout} key="export-buyout" />);
    }
    if (sequenceActive) {
      bActions.push(<BuyoutSequenceStepper />);
    }

    return bActions;
  }, [
    buyout,
    viewState,
    includePoNumbering,
    connectedSourceSystem,
    navigate,
    sequenceActive,
  ]);

  const handleSubmit = useCallback(
    async (skipConfirmation: boolean, skipVendorNotification: boolean) => {
      const result = await submitBuyout({
        buyoutId: buyout.id,
        skipConfirmation,
        skipVendorNotification,
      });
      if (result) {
        setSubmitDialogVisible(false);
      }
    },
    [buyout.id, submitBuyout],
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier })}
        </title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            text: intl.$t({ id: "BUYOUTS" }),
            link: routes.buyouts,
            id: "buyouts",
          },
          {
            id: "buyoutNo",
            text: (
              <Headline>
                <BuyoutNoWrapper>
                  <FormattedMessage
                    id="BUYOUT"
                    values={{ number: buyout.clientIdentifier }}
                  />
                </BuyoutNoWrapper>
              </Headline>
            ),
          },
        ]}
        appendItems={[
          <>
            {buyout.status === BuyoutStatus.Requested &&
            !buyout.quote &&
            !isEditable ? (
              <StyledSelect
                id="buyout-status-select"
                key="status-select"
                value={buyout?.status || BuyoutStatus.Requested}
                onChange={() => setSubmitDialogVisible(true)}
              >
                {BUYOUT_TRANSITION_STATUSES.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={
                      option === buyout?.status
                        ? { display: "none" }
                        : undefined
                    }
                  >
                    <BuyoutStatusChip status={option} type="breadcrumb" />
                  </MenuItem>
                ))}
              </StyledSelect>
            ) : (
              <BuyoutStatusChip
                key="status"
                status={buyout.status}
                type="breadcrumb"
              />
            )}
          </>,
        ]}
        classes={{
          text: "flex items-center print:font-bold print:text-3xl print:flex-1 print:flex",
        }}
        actions={actions}
      />
      <BuyoutNonQuotedHeaderInfo buyout={buyout} readonly={readonly} />
      <SubmitBuyoutDialog
        visible={submitDialogVisible}
        setVisible={setSubmitDialogVisible}
        callback={handleSubmit}
        saving={submitting}
        forceSkipVendorConfirmation
      />
    </>
  );
};
