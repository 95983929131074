import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import {
  getIsNativeSalesTax,
  getTaxRate,
} from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { RELEASE_DRAFT_STATUSES } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { RELEASE } from "@/common/queries/release";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  namedOperations,
  UomsDocument,
  UpdateContractorReleaseInput,
  useSubmitReleaseMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useLumpSumInvoiceReleaseItems } from "../hooks/useLumpSumInvoiceReleaseItems";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type ProviderContextType = {
  syncUpdateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean>;
  saving: boolean;
  updateRelease: (input: UpdateContractorReleaseInput) => Promise<boolean>;
  itemized: boolean;
  setItemized: (itemized: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  syncUpdateReleaseFromInvoice: NoFunctionBooleanPromise,
  saving: false,
  updateRelease: NoFunctionBooleanPromise,
  itemized: false,
  setItemized: NoFunction,
});

export const InvoiceUpdateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [itemized, setItemized] = useState(true);
  const { getOrderType } = useOrderTypeOptions();
  const { invoice } = useInvoiceVerification();

  const [submitReleaseMutation] = useSubmitReleaseMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Releases),
  });
  const { resetPreviousData, gotoInvalidRow } = useColumnMapper();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const [saving, setSaving] = useState(false);
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { getInvoiceLumpSumReleaseItem } = useLumpSumInvoiceReleaseItems();
  const { getSyncedRelease } = useSyncReleaseItems();
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(itemized ? TableViewState.spreadsheet : TableViewState.normal);
  }, [itemized, setViewState]);

  const syncUpdateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      itemized &&
      (!validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
        !validateRowValues(
          [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
          undefined,
          { minPrice: undefined },
        ))
    ) {
      gotoInvalidRow();
      return false;
    }

    const { addedItems, updates, removedItems } = await getSyncedRelease({
      addItemsProps: { invoiceId: invoice?.id },
    });

    let itemsToRemove: string[] = [];
    let newItems;
    if (itemized) {
      newItems = addedItems;
      itemsToRemove = removedItems;
    } else {
      newItems = [...(await getInvoiceLumpSumReleaseItem(values))];
      itemsToRemove = release?.items.map((item) => item.id) ?? [];
    }

    if (release) {
      try {
        const isNativeSalesTax = getIsNativeSalesTax(
          getOrderType(values.orderTypeId),
        );
        const { data, errors } = await updateReleaseMutation({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              poNumber: values.poNumber,
              addedItems: newItems,
              updates,
              removedItems: itemsToRemove,
              taxRate: getTaxRate(values),
              customTaxAmount: isNativeSalesTax
                ? undefined
                : values.customTaxAmount || undefined,
              taxCodeId: isNativeSalesTax ? values.taxCodeId : undefined,
              taxType: isNativeSalesTax ? values.taxType : undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges?.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              assignDefaultCostCodes: false,
              prefillPrices: false,
              requestedTime: defaultReleaseDate(values.orderDate)?.getTime(),
              typeId: values.orderTypeId,
              paymentTerm: Number(values.paymentTerm),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });

        if (
          data?.updateContractorRelease.status &&
          RELEASE_DRAFT_STATUSES.includes(
            data?.updateContractorRelease.status,
          ) &&
          data.updateContractorRelease.items.length > 0
        ) {
          await submitReleaseMutation({
            variables: {
              input: {
                releaseId: data?.updateContractorRelease.id,
                version: data?.updateContractorRelease.version,
                retroactive: true,
              },
            },
          });
        }
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    setSaving(true);
    try {
      const { errors } = await updateReleaseMutation({
        variables: {
          input,
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncUpdateReleaseFromInvoice,
        updateRelease,
        saving: saving || updating,
        itemized,
        setItemized,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceUpdateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
