import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { CheckCircleOutline } from "@mui/icons-material";
import Decimal from "decimal.js";
import { useCallback, useMemo, useState } from "react";
import { PiWarningCircleBold } from "react-icons/pi";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`relative`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;
const WarningIcon = tw(
  PiWarningCircleBold,
)`text-red-500 absolute right-1 top-1/2 transform -translate-y-1/2 text-lg`;

export const InvoiceExtPriceInput = ({
  item,
}: {
  item: ExpandedReleaseItem;
}) => {
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { invoiceValidation } = useInvoiceValidation();
  const { calcExtPrice } = usePriceCalculation();
  const { formatCurrency } = useFormatNumberToCurrency();
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );

  const [invoiced, setInvoiced] = useState<string>(
    item.invoiceItems?.[0]?.quantity || "0",
  );

  const poItemsExceedingRemainingAmount = useMemo(() => {
    const isExceedingRemainingAmount =
      invoiceValidation?.itemsExceedingRemainingAmount.find(
        (releaseItem) => releaseItem.id === item.id,
      );
    return isExceedingRemainingAmount?.poItem?.remainingAmount;
  }, [invoiceValidation, item.id]);

  const onSave = useCallback(
    async (quantity: string | null) => {
      if (quantity) {
        setInvoiced(quantity);
      }

      if (!invoicedItem) {
        return;
      }
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [
            {
              id: invoicedItem.id,
              quantity: quantity ? quantity.toString() : undefined,
              quantitySoFar: invoicedItem.quantitySoFar || "0",
              releaseItemId: item.id,
            },
          ],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [invoicedItem, updateInvoice, invoice?.id, invoice?.release?.id, item.id],
  );

  const inputClassName = useMemo(() => {
    if (!isLumpSumItem(item)) {
      return "text-blue-800 bg-white relative";
    }

    if (
      new Decimal(invoiced)
        .plus(invoicedItem?.quantitySoFar ?? 0)
        .greaterThan(item.quantityDecimal)
    ) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [invoiced, invoicedItem?.quantitySoFar, item]);

  const isFullyInvoiced = useMemo(() => {
    return (
      isLumpSumItem(item) &&
      Number(invoiced) > 0 &&
      new Decimal(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .equals(Number(invoiced))
    );
  }, [invoiced, invoicedItem?.quantitySoFar, item]);

  const quantitySoFar = useMemo(() => {
    return new Decimal(item.invoiceItems?.[0]?.quantitySoFar || "0").plus(
      item.invoiceItems?.[0]?.quantity || "0",
    );
  }, [item.invoiceItems]);

  return (
    <If isTrue={(item.invoiceItems || []).length > 0}>
      <ReadonlyField>
        {matchedOrderViewState ===
          MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
        isLumpSumItem(item) ? (
          <ReadonlyField>
            <Container>
              <PricePicker
                value={invoiced}
                onBlur={async (extPrice) => {
                  await onSave(extPrice);
                }}
                className={inputClassName}
                helperText={
                  <FormattedMessage
                    id="QUANTITY_SO_FAR"
                    values={{
                      value: formatCurrency(invoicedItem?.quantitySoFar),
                    }}
                  />
                }
              />
              {isFullyInvoiced && <CheckIcon />}
            </Container>
          </ReadonlyField>
        ) : (
          <>
            <Tooltip
              hideTooltip={!quantitySoFar.greaterThan(item.quantityDecimal)}
              element={
                <Price
                  price={calcExtPrice(
                    item.invoiceItems?.[0]?.quantity,
                    item.unitPrice,
                  )}
                  className={
                    quantitySoFar.greaterThan(item.quantityDecimal)
                      ? "text-red-500"
                      : ""
                  }
                />
              }
              id="invoiced-ordered-quantity"
            >
              <FormattedMessage id="INVOICED_AMOUNT_GREATER_THAN_ORDERED_AMOUNT" />
            </Tooltip>
            <FormattedMessage
              id="QUANTITY_SO_FAR"
              tagName={InvoiceSoFarLabel}
              values={{
                value: isLumpSumItem(item)
                  ? item.invoiceItems?.[0]?.quantitySoFar || "0"
                  : formatCurrency(
                      calcExtPrice(
                        item.invoiceItems?.[0]?.quantitySoFar,
                        item.unitPrice,
                      ),
                    ),
              }}
            />
          </>
        )}
      </ReadonlyField>
      {poItemsExceedingRemainingAmount != undefined && (
        <Tooltip
          id="po-items-exceeding-remaining-amount-tooltip"
          element={<WarningIcon />}
        >
          <FormattedMessage
            id="EXPORT_PO_ITEMS_REMAINING_AMOUNT"
            values={{
              remainingAmount: formatCurrency(poItemsExceedingRemainingAmount),
            }}
          />
        </Tooltip>
      )}
    </If>
  );
};
