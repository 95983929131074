import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { VendorListItem } from "@/common/components/vendor-list/VendorListItem";
import {
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

export const ReleaseVendorContactSelector: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { updateRelease, changes } = useReleaseUpdate();
  const { inputErrors } = useReleaseActions();

  const update = async (vendorContacts: string[]) => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
        vendorContactIds: vendorContacts,
      };
      await updateRelease(input);
    }
  };

  return (
    <Multiselect
      testId="vendor-contacts-selector"
      placeholder={intl.$t({
        id: "VENDOR_CONTACT",
      })}
      options={
        release?.preferredVendor?.contacts?.filter(
          (contact) => contact.receivesOrderNotifications,
        ) || []
      }
      className="min-w-32 xl:min-w-48"
      values={release?.vendorContacts.map((vc) => vc.id) || []}
      onMultipleChange={(values: string[] | null) => update(values || [])}
      required={
        release?.status === ReleaseStatus.Draft
          ? false
          : !!changes?.vendorContactIds &&
            changes.vendorContactIds.length === 0 &&
            (
              release?.preferredVendor?.contacts?.filter(
                (contact) => contact.receivesOrderNotifications,
              ) ?? []
            ).length > 0
      }
      error={inputErrors.includes(ReleaseErrorType.VENDOR_CONTACT)}
      getLabel={(o) => o.name}
      getValue={(option) => option.id}
      noOptionsText={intl.$t({ id: "NO_VENDORS_CONTACTS_FOUND" })}
      customRender={(contact) => <VendorListItem contact={contact} />}
      limitTags={1}
      chipSize="small"
      includeCheckbox
      noWrap
    />
  );
};
