import { Dialog } from "@/common/components/dialog/Dialog";
import { Select } from "@/common/components/select/components/single/Select";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useProjectZones } from "../../../project/hooks/useProjectZones";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const Container = tw.div`
  grid gap-y-4 mt-5
`;

type AssignZoneDialogProps = {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  item?: ReleaseItemFieldsFragment;
};

export const AssignZoneDialog: FC<AssignZoneDialogProps> = ({
  visible = true,
  setVisible,
  item,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { release } = useRelease();
  const [selectedZone, setSelectedZone] = useState(item?.zone?.id || "");
  const { zones: projectZones } = useProjectZones();

  const zones = useMemo(
    () =>
      (projectZones || [])
        .map((zone) => zone)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [projectZones],
  );

  useEffect(() => {
    setSelectedZone(item?.zone?.id || "");
  }, [item]);

  const { updateRelease } = useReleaseUpdate();

  const assignZone = useCallback(async () => {
    if (release && item && selectedZone) {
      await updateRelease({
        releaseId: release.id,
        version: release.version,
        updates: [
          {
            releaseItemId: item.id,
            zoneId: selectedZone,
          },
        ],
      });
    }
  }, [release, item, selectedZone, updateRelease]);

  const handleConfirm = async () => {
    setVisible?.(false);
    await assignZone();
  };

  const handleCancel = () => {
    if (!release?.id) {
      navigate(-1);
    } else {
      setVisible?.(false);
    }
  };

  return (
    <Dialog
      title={<FormattedMessage id="ASSIGN_ZONE" />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SELECT" })}
      handleConfirm={handleConfirm}
      classes={{
        paper: "mx-2 w-full max-w-96",
      }}
      handleCancel={handleCancel}
      show={visible}
      content={
        <Container>
          <Select
            placeholder={intl.$t({ id: "ZONE" })}
            className="w-full"
            options={zones}
            value={selectedZone}
            onChange={(zone) => setSelectedZone(zone as string)}
            getLabel={(option) => option.name}
            getValue={(option) => option.id}
            required
          />
        </Container>
      }
    />
  );
};
