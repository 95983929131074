import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  UsersProvider,
  useUsers,
} from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AdditionalFiltersOverlay } from "../additional-filters-overlay/AdditionalFiltersOverlay";
import { Multiselect } from "../select/components/multiple/Multiselect";

const CustomFilterContainer = tw.div`
  grid grid-cols-2 space-between items-center mb-2
`;

enum KickbackFilter {
  NO_FILTER = "no-filter",
  ANYONE = "anyone-filter",
}

type KickbackFilters = Pick<
  QueryInvoicesFilter,
  "kickbackResolved" | "kickbackByUserIds" | "kickbackToUserIds"
>;

type Props = {
  filter: KickbackFilters | undefined;
  setFilter: (filter: KickbackFilters) => void;
};

const KickbackFiltersWithProviders: FC<Props> = ({ filter, setFilter }) => {
  const intl = useIntl();
  const { viewer } = useUser();
  const { users, loading } = useUsers();

  const filteredUsers = useMemo(
    () => users.filter((u) => u.id !== viewer?.id),
    [users, viewer],
  );

  const kickbackOptions = useMemo(() => {
    const options = [
      {
        label: intl.$t({ id: "NO_FILTER" }),
        id: KickbackFilter.NO_FILTER as string,
      },
      { label: intl.$t({ id: "ANYONE" }), id: KickbackFilter.ANYONE as string },
    ];
    if (viewer) {
      options.push({ label: intl.$t({ id: "MYSELF" }), id: viewer.id });
    }
    options.push(
      ...filteredUsers.map((user) => ({
        id: user.id,
        label: getUserName(user),
      })),
    );
    return options;
  }, [intl, viewer, filteredUsers]);

  const kickbackByFilter = useMemo(() => {
    if (filter?.kickbackByUserIds === undefined) {
      return [KickbackFilter.NO_FILTER];
    }
    if (
      Array.isArray(filter?.kickbackByUserIds) &&
      filter.kickbackByUserIds.length === 0
    ) {
      return [KickbackFilter.ANYONE];
    }
    return filter.kickbackByUserIds;
  }, [filter?.kickbackByUserIds]);

  const kickbackToFilter = useMemo(() => {
    if (filter?.kickbackToUserIds === undefined) {
      return [KickbackFilter.NO_FILTER];
    }
    if (
      Array.isArray(filter?.kickbackToUserIds) &&
      filter.kickbackToUserIds.length === 0
    ) {
      return [KickbackFilter.ANYONE];
    }
    return filter.kickbackToUserIds;
  }, [filter?.kickbackToUserIds]);

  const handleKickbackByChange = useCallback(
    (kickbackBy: string[] | null) => {
      if (
        (filter?.kickbackByUserIds === undefined ||
          !!filter?.kickbackByUserIds?.length) &&
        kickbackBy?.includes(KickbackFilter.ANYONE)
      ) {
        setFilter({
          ...filter,
          kickbackByUserIds: [],
        });
        return;
      }
      if (
        !kickbackBy ||
        !kickbackBy.length ||
        (filter?.kickbackByUserIds !== undefined &&
          kickbackBy.includes(KickbackFilter.NO_FILTER))
      ) {
        setFilter({
          ...filter,
          kickbackByUserIds: undefined,
        });
        return;
      }
      const kickbackByUserIds = kickbackBy.filter(
        (option) =>
          option !== KickbackFilter.ANYONE &&
          option !== KickbackFilter.NO_FILTER,
      );
      setFilter({
        ...filter,
        kickbackByUserIds,
      });
    },
    [filter, setFilter],
  );

  const handleKickbackToChange = useCallback(
    (kickbackTo: string[] | null) => {
      if (
        (filter?.kickbackToUserIds === undefined ||
          !!filter?.kickbackToUserIds?.length) &&
        kickbackTo?.includes(KickbackFilter.ANYONE)
      ) {
        setFilter({
          ...filter,
          kickbackToUserIds: [],
        });
        return;
      }
      if (
        !kickbackTo ||
        !kickbackTo.length ||
        (filter?.kickbackToUserIds !== undefined &&
          kickbackTo.includes(KickbackFilter.NO_FILTER))
      ) {
        setFilter({
          ...filter,
          kickbackToUserIds: undefined,
        });
        return;
      }
      const kickbackToUserIds = kickbackTo.filter(
        (option) =>
          option !== KickbackFilter.ANYONE &&
          option !== KickbackFilter.NO_FILTER,
      );
      setFilter({
        ...filter,
        kickbackToUserIds,
      });
    },
    [filter, setFilter],
  );

  return (
    <>
      <CustomFilterContainer>
        <AdditionalFiltersOverlay.Name>
          <FormattedMessage id="FILTER_KICKBACK_RESOLVED" />
        </AdditionalFiltersOverlay.Name>
        <AdditionalFiltersOverlay.Configurations>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.kickbackResolved === true,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                kickbackResolved: true,
              })
            }
          >
            <FormattedMessage id="YES" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.kickbackResolved === false,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                kickbackResolved: false,
              })
            }
          >
            <FormattedMessage id="NO" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.kickbackResolved == null,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                kickbackResolved: undefined,
              })
            }
            $isDefault
          >
            <FormattedMessage id="NO_FILTER" />
          </AdditionalFiltersOverlay.Configuration>
        </AdditionalFiltersOverlay.Configurations>
      </CustomFilterContainer>
      <CustomFilterContainer>
        <FormattedMessage id="KICKBACK_BY" />
        <Multiselect
          options={kickbackOptions}
          getLabel={(option) => option.label}
          getValue={(option) => option.id}
          loading={loading}
          values={kickbackByFilter}
          onMultipleChange={handleKickbackByChange}
          includeCheckbox
          limitTags={1}
          chipSize="small"
          disableCloseOnSelect
        />
      </CustomFilterContainer>
      <CustomFilterContainer>
        <FormattedMessage id="KICKBACK_TO" />
        <Multiselect
          options={kickbackOptions}
          getLabel={(option) => option.label}
          getValue={(option) => option.id}
          loading={loading}
          values={kickbackToFilter}
          onMultipleChange={handleKickbackToChange}
          includeCheckbox
          limitTags={1}
          chipSize="small"
          disableCloseOnSelect
        />
      </CustomFilterContainer>
    </>
  );
};

export const KickbackFilters: FC<Props> = (props) => (
  <UsersProvider>
    <KickbackFiltersWithProviders {...props} />
  </UsersProvider>
);
