import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Tune } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

interface ListHeaderFilterButtonProps {
  filter: React.ReactNode;
  isDefault?: boolean;
  hideLabel?: boolean;
  classes?: {
    button?: string;
    buttonText?: string;
  };
  appliedFilters?: number;
}

const Container = tw.div``;
const FiltersButton = tw.span``;
const ButtonContent = tw.div<{
  $isDefault: boolean;
}>`flex gap-2 items-center px-2 py-1.5 rounded-xl border border-white hover:border-gray-400 relative
  ${({ $isDefault }) => ($isDefault ? "bg-white text-blue-500" : "bg-blue-500 font-normal text-white")}
`;
const FilterIndicator = tw.div`bg-red-500 -top-3 -right-3 w-6 h-6 rounded-full border-white border-2 absolute text-white flex items-center justify-center text-xs`;

export const ListHeaderFilterButton = ({
  filter,
  isDefault = true,
  classes,
  hideLabel,
  appliedFilters = 0,
}: ListHeaderFilterButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <IconButtonBorderless
        autoScale
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ButtonContent className={classes?.button} $isDefault={isDefault}>
          <Tooltip id="filter-button" element={<Tune />}>
            <FormattedMessage id="FILTERS" />
          </Tooltip>
          <FiltersButton className={classes?.buttonText}>
            <If isTrue={!hideLabel}>
              <FormattedMessage id="FILTERS" />
            </If>
          </FiltersButton>
          <If isTrue={!!appliedFilters}>
            <FilterIndicator>{appliedFilters}</FilterIndicator>
          </If>
        </ButtonContent>
      </IconButtonBorderless>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {filter}
      </Popover>
    </Container>
  );
};
