import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ExclamationCircleRed } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import { TransactionKind } from "@/generated/graphql";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../../project/providers/EstimatedItemsProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { ReleaseItemDeliveryDateDialog } from "./ReleaseItemDeliveryDateDialog";

const DATE_FORMAT = "MM/dd/yyyy";
const READ_ONLY_DATE_FORMAT = "MMM dd, yyyy";

const LinkLikeStyled = tw(LinkLike)<{ $sameAsRelease: boolean }>`
  ${({ $sameAsRelease }) => ($sameAsRelease ? "text-blue-500" : "text-orange-500")}
`;

const Container = tw.div`pl-6 truncate py-2`;
const DatePickerContainer = tw.div`relative`;
const DifferentDate = tw.div`text-orange-500 relative flex items-center font-semibold`;
const Exclamation = tw(
  ExclamationCircleRed,
)`absolute bg-orange-500 w-4 h-4 -left-5 -top-[3px]`;
const DateGroup = tw.div`relative flex items-center gap-1`;
const CancelButton = tw(LinkLike)`text-orange-500 absolute -right-7 w-6 h-6`;
const DateValue = tw.div`text-2xs font-normal`;

type Props = {
  item: ExpandedReleaseItem;
  readonly: boolean;
};

export const ReleaseItemDeliveryDate: FC<Props> = ({ item, readonly }) => {
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const { newProjectEstimatedItem, setNewProjectEstimatedItem } =
    useEstimatedItems();
  const [deliveryDateDialogOpen, setDeliveryDateDialogOpen] = useState(false);

  const updateNewItem = (date?: Date | null) => {
    setNewProjectEstimatedItem([
      {
        key: "deliveryDate",
        value: date?.getTime(),
      },
    ]);
  };

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const date = useMemo<Date | null>(() => {
    const timestamp = item.id
      ? (item.deliveryDate ?? release?.time)
      : (newProjectEstimatedItem.deliveryDate ?? release?.time);
    return timestamp ? new Date(timestamp) : null;
  }, [item, release, newProjectEstimatedItem]);

  const handleOpen = () => {
    setDeliveryDateDialogOpen(true);
  };

  const clearDeliveryDate = () => {
    if (!release) {
      return;
    }

    if (item.id) {
      updateRelease({
        releaseId: release.id,
        version: release.version,
        updates: [
          {
            releaseItemId: item.id,
            deliveryDate: undefined,
            clearDeliveryDate: true,
          },
        ],
      });
    } else {
      updateNewItem(undefined);
    }
  };

  const sameAsRelease = useMemo(
    () => !date || release?.time === date.getTime(),
    [release, date],
  );

  if (!release || !date) {
    return null;
  }

  return readonly ? (
    <Container>
      <DateValue>
        {hasEndDate ? (
          <NotNullableRenderer value={item.deliveryDate}>
            {item.deliveryDate &&
              format(new Date(item.deliveryDate), READ_ONLY_DATE_FORMAT)}
          </NotNullableRenderer>
        ) : item.deliveryDate ? (
          <DifferentDate>
            <Exclamation />
            {format(new Date(item.deliveryDate), READ_ONLY_DATE_FORMAT)}
          </DifferentDate>
        ) : (
          format(date, READ_ONLY_DATE_FORMAT)
        )}
      </DateValue>
    </Container>
  ) : (
    <DatePickerContainer>
      <DateGroup>
        <LinkLikeStyled onClick={handleOpen} $sameAsRelease={sameAsRelease}>
          {hasEndDate ? (
            item.deliveryDate ? (
              format(new Date(item.deliveryDate), DATE_FORMAT)
            ) : newProjectEstimatedItem.deliveryDate ? (
              format(
                new Date(newProjectEstimatedItem.deliveryDate),
                DATE_FORMAT,
              )
            ) : (
              <FormattedMessage id="SPECIFY_DATE" />
            )
          ) : (
            format(date, DATE_FORMAT)
          )}
        </LinkLikeStyled>
        <If isTrue={!sameAsRelease}>
          <CancelButton onClick={clearDeliveryDate}>
            <Close className="h-6 w-6" />
          </CancelButton>
        </If>
      </DateGroup>
      {deliveryDateDialogOpen ? (
        <ReleaseItemDeliveryDateDialog
          visible={deliveryDateDialogOpen}
          setVisible={setDeliveryDateDialogOpen}
          item={item}
        />
      ) : null}
    </DatePickerContainer>
  );
};
