import { GridTableConfiguration } from "@/common/components/grid-table/GridTable";
import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { Price } from "@/common/components/price/Price";
import {
  MdContainer,
  SmContainer,
  SmFixedContainer,
  XlContainer,
} from "@/common/layout/ResponsiveClasses";
import { DateView } from "@/common/utils/dates/DateView";
import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FormattedMessage } from "react-intl";
import { Header, ReadonlyField } from "./items/ExternalPO.styles";
import { ExternalPOLinks } from "./items/ExternalPOLinks";
import { ExternalPONumber } from "./items/ExternalPONumber";
import { ExternalPOProjects } from "./items/ExternalPOProjects";
import { ExternalPOVendors } from "./items/ExternalPOVendors";

export const useExternalPOsTableConfiguration = (): GridTableConfiguration<
  ExternalPoBaseFieldsFragment & Identity,
  undefined
> => {
  const { selectedBaseExternalPO, setSelectedBaseExternalPO } =
    useImportExternalPos();

  return {
    columns: [
      {
        header: <FormattedMessage id="PO_NUMBER_SYMBOL" tagName={Header} />,
        wrapper: SmFixedContainer,
        position: "start",
        item: ({ item, count }) => (
          <ExternalPONumber
            item={item}
            selectedBaseExternalPO={selectedBaseExternalPO}
            count={count}
          />
        ),
      },
      {
        header: <FormattedMessage id="DATE" />,
        wrapper: SmFixedContainer,
        position: "start",
        item: ({ item }) => (
          <ReadonlyField>
            <DateView date={item.date} />
          </ReadonlyField>
        ),
      },
      {
        header: <FormattedMessage id="PROJECT_IN_ERP" />,
        wrapper: MdContainer,
        position: "start",
        item: ({ item }) => <ExternalPOProjects item={item} />,
      },
      {
        header: <FormattedMessage id="VENDOR" />,
        wrapper: XlContainer,
        position: "start",
        item: ({ item }) => <ExternalPOVendors item={item} />,
      },
      {
        header: <FormattedMessage id="FM_ORDER" />,
        wrapper: MdContainer,
        position: "start",
        item: ({ item }) => <ExternalPOLinks item={item} />,
      },
      {
        header: <FormattedMessage id="TOTAL" />,
        wrapper: SmContainer,
        position: "end",
        item: ({ item }) => (
          <ReadonlyField
            className={
              !(item.project && item.project?.project) ||
              !(item.vendor && item.vendor.orgPreferredVendors.length > 0)
                ? "opacity-50"
                : ""
            }
          >
            <Price price={item.amount} />
          </ReadonlyField>
        ),
      },
    ],
    classNames: {
      header: "top-[70px] lg:top-[72px] xl:top-[80px]",
      itemFn: (item: ExternalPoBaseFieldsFragment) => {
        if (
          !(item.project && item.project?.project) ||
          !(item.vendor && item.vendor.orgPreferredVendors.length > 0)
        ) {
          return { className: "bg-gray-100 hover:bg-gray-100" };
        }
        if (item.externalId === selectedBaseExternalPO?.externalId) {
          return { className: "bg-blue-100" };
        }
        return { className: "" };
      },
    },
    toggle: {
      item: (item) => {
        setSelectedBaseExternalPO(
          item.externalId === selectedBaseExternalPO?.externalId
            ? undefined
            : item,
        );
      },
    },
  };
};
