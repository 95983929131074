import { If } from "@/common/components/if/If";

import { QUERYSTRING } from "@/common/const";
import { routes } from "@/config/routes";
import { OrderSelectorFilters } from "@/contractor/pages/home/common/order-selector-filters/OrderSelectorFilters";
import { SubmitReleaseDialog } from "@/contractor/pages/home/release/pages/specify-details/footer-actions/SubmitReleaseDialog";
import { ReleaseActionsProvider } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { ReleaseProvider } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import tw from "tailwind-styled-components";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipOrderItems } from "./DeliverySlipOrderItems";

const Container = tw.div`flex flex-col flex-1 items-start overflow-scroll pb-5 px-2.5`;
const InnerContainer = tw.div`flex flex-col w-full h-full mt-2`;

export const DeliverySlipOrderSelectorView: FC = () => {
  const {
    deliverySlip,
    updateDeliverySlip,
    selectedRelease,
    setSelectedRelease,
  } = useDeliverySlipVerification();
  const navigate = useNavigate();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const intl = useIntl();

  const assignOrder = useCallback(
    async (params?: { requestedDate: Date | null }) => {
      if (
        selectedRelease &&
        selectedRelease.status === ReleaseStatus.Draft &&
        selectedRelease.poNumber &&
        !selectedRelease.time
      ) {
        if (!submitDialogVisible) {
          setSubmitDialogVisible(true);
          return false;
        }
        if (params && !params.requestedDate) {
          return false;
        }
      }
      await updateDeliverySlip({
        id: deliverySlip?.id ?? "",
        releaseId: selectedRelease?.id ?? "",
      });
      navigate({
        pathname: generatePath(routes.deliverySlipReceiveOrder, {
          deliverySlipId: deliverySlip?.id,
        }),
        search: createSearchParams({
          [QUERYSTRING.SPREADSHEET_VIEW]:
            selectedRelease?.poNumber &&
            (selectedRelease?.status === ReleaseStatus.Draft ||
              selectedRelease?.status === ReleaseStatus.Reserved)
              ? "true"
              : "false",
        }).toString(),
      });
      return true;
    },
    [
      deliverySlip?.id,
      navigate,
      selectedRelease,
      submitDialogVisible,
      updateDeliverySlip,
    ],
  );

  return (
    <Container>
      <OrderSelectorFilters
        initialRelease={selectedRelease}
        onOrderChange={setSelectedRelease}
        allowDraftOrders
      />
      <If isTrue={selectedRelease}>
        <ReleaseProvider id={selectedRelease?.id ?? ""}>
          <InnerContainer>
            <DeliverySlipOrderItems assignOrder={assignOrder} />
          </InnerContainer>
        </ReleaseProvider>
      </If>
      {selectedRelease && (
        <ReleaseUpdateProvider release={selectedRelease} items={[]}>
          <ReleaseActionsProvider>
            <SubmitReleaseDialog
              visible={submitDialogVisible}
              setVisible={setSubmitDialogVisible}
              params={{
                includeVendorConfirmation: false,
                title: intl.$t({ id: "WOULD_YOU_LIKE_TO_RECEIVE_DRAFT_ORDER" }),
                confirmButtonText: intl.$t({ id: "CONFIRM" }),
                dateLabelText: intl.$t({ id: "RELEASE_FULFILLMENT_DATE" }),
                release: {
                  id: selectedRelease.id,
                  version: selectedRelease.version,
                },
                customCallback: assignOrder,
              }}
            />
          </ReleaseActionsProvider>
        </ReleaseUpdateProvider>
      )}
    </Container>
  );
};
