import { If } from "@/common/components/if/If";
import { useTaxType } from "@/common/components/sales-tax-input/hooks/useTaxType";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";
import { useRelease } from "../../../providers/ReleaseProvider";

const TitleNormal = tw.div`text-right`;
const Title = tw.div`font-medium`;
const InfoIcon = tw(InfoOutlined)`text-blue-500 mr-4 text-md`;

export const ReleaseTitleColFooter = () => {
  const { noPrices } = useReleaseItemsZoneProvider();
  const { release } = useRelease();
  const { useTaxKey } = useTaxType(
    release?.type.id,
    undefined,
    release?.taxType,
  );

  return (
    <FooterCol className="min-w-[150px]">
      <FooterHeader />
      <FooterCell>
        <FormattedMessage id="SUBTOTAL" tagName={TitleNormal} />:
      </FooterCell>
      <FooterCell>
        <TitleNormal>
          <FormattedMessage id="ADDITIONAL_CHARGES" />:
        </TitleNormal>
      </FooterCell>
      <FooterCell>
        <TitleNormal>
          <FormattedMessage id={useTaxKey} />:
        </TitleNormal>
      </FooterCell>
      <FooterDashedLine />
      <FooterTotal>
        <If isTrue={noPrices}>
          <Tooltip id="no-prices-tooltip" element={<InfoIcon />}>
            <FormattedMessage id="TO_SEE_VALUES_SPECIFY_PRICES" />
          </Tooltip>
        </If>
        <FooterCellCol className="items-end">
          <Title>
            <FormattedMessage id="TOTAL" />:
          </Title>
          <FormattedMessage
            id="INCL_TAXES_AND_FEES"
            tagName={FooterHeaderText}
          />
        </FooterCellCol>
      </FooterTotal>
    </FooterCol>
  );
};
