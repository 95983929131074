import { getTotalTaxAmount } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { extPriceCalculation } from "../hooks/usePriceCalculation";
import { UpdateReleaseNomenclature } from "./useReleaseStore";

export const getReleaseSubtotal = (items: ReleaseFieldsFragment["items"]) =>
  items.reduce(
    (acc, item) =>
      acc.add(extPriceCalculation(item.quantityDecimal, item.unitPrice)),
    new Decimal(0),
  );

const getReleaseSubtotalWithCharges = (release: ReleaseFieldsFragment) =>
  getReleaseSubtotal(release.items).plus(
    release.additionalCharges.reduce(
      (acc, charge) => acc.add(new Decimal(charge.amount)),
      new Decimal(0),
    ),
  );

export const getReleaseTotal = (release: ReleaseFieldsFragment) => {
  const subtotalWithCharges = getReleaseSubtotalWithCharges(release);
  const taxAmount = getTotalTaxAmount({
    ...release,
    subtotal: subtotalWithCharges.toString(),
  });
  return subtotalWithCharges.plus(taxAmount);
};

export const applyInputChanges = (
  release: ReleaseFieldsFragment,
  input: UpdateContractorReleaseInput,
  nomenclature: UpdateReleaseNomenclature = {
    taxCodes: [],
    orderTypes: [],
  },
): ReleaseFieldsFragment => {
  const { taxCodes, orderTypes } = nomenclature;
  const orderType =
    orderTypes.find((type) => type.id === input.typeId) || release.type;
  const taxCode =
    taxCodes.find((code) => code.id === input.taxCodeId) || release.taxCode;

  return {
    ...release,
    type: orderType || release.type,
    paymentTerm: input.paymentTerm ?? release.paymentTerm,
    taxType: input.taxType || release.taxType,
    taxCode: taxCode,
    taxRate: input.customTaxAmount
      ? Number(getReleaseSubtotalWithCharges(release))
        ? new Decimal(input.customTaxAmount)
            .div(getReleaseSubtotalWithCharges(release))
            .toString()
        : "0"
      : input.taxRate || release.taxRate,
    customTaxAmount: Object.hasOwn(input, "customTaxAmount")
      ? input.customTaxAmount
      : input.clearCustomTaxAmount
        ? null
        : release.customTaxAmount,
    taxAmount:
      input.customTaxAmount || input.taxRate || taxCode
        ? new Decimal(taxCode ? taxCode.rate : input.taxRate || 0)
            .mul(getReleaseSubtotalWithCharges(release))
            .toString()
        : release.taxAmount,
    additionalCharges:
      input.additionalCharges?.map((charge, index) => ({
        ...charge,
        id: charge.id || index.toString(),
        amount: charge.amount.toString(),
      })) || release.additionalCharges,
    items: release.items
      .filter((item) => !input.removedItems?.includes(item.id))
      .map((item) => {
        const updatedItem = input.updates?.find(
          (update) => update.releaseItemId === item.id,
        );
        if (!updatedItem) {
          return item;
        }
        return {
          ...item,
          unitPrice: updatedItem.unitPrice || item.unitPrice,
          quantityDecimal: updatedItem.quantityDecimal || item.quantityDecimal,
        };
      }),
  };
};
