import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { mergeChanges } from "@/common/utils/mergeChanges";
import {
  AddVendorReleaseItemInput,
  DistributorReleaseDocument,
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
  UpdateVendorReleaseFieldsFragment,
  UpdateVendorReleaseInput,
  UpdateVendorReleaseItemInput,
  useDistributorReleaseQuery,
  useUpdateVendorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useDistributorReleaseUpdates } from "./useDistributorReleaseUpdates";

export enum DistributorReleaseErrorType {
  "MANUFACTURER",
  "UOM",
  "PRICE",
  "ADDITIONAL_PRICE",
}

export const DUPLICATE_RELEASE_ITEM_ID_SUFFIX = "-duplicate-";

export type DistributorUpdateVendorReleaseInput = Omit<
  UpdateVendorReleaseInput,
  "releaseId" | "version"
>;

type ProviderContextType = {
  release: DistributorReleaseFieldsFragment | undefined | null;
  updating: boolean;
  updatedItem: (
    item: DistributorReleaseItemFieldsFragment,
  ) => UpdateVendorReleaseItemInput | null | undefined;
  changes: UpdateVendorReleaseInput | undefined;
  confirmAndUpdateRelease: () => Promise<boolean>;
  updateVendorRelease: (
    input: DistributorUpdateVendorReleaseInput,
  ) => Promise<UpdateVendorReleaseFieldsFragment | null | undefined | boolean>;
  updateVendorReleaseItem: (
    input: UpdateVendorReleaseItemInput,
  ) => Promise<void>;
  removeReleaseItem: (id: string) => Promise<void>;
  addReleaseItem: (input: AddVendorReleaseItemInput) => Promise<void>;
  inputErrors: DistributorReleaseErrorType[];
  setInputError: (fieldName: DistributorReleaseErrorType) => void;
  removeInputError: (fieldName: DistributorReleaseErrorType) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  release: undefined,
  updating: false,
  changes: undefined,
  updatedItem: () => null,
  confirmAndUpdateRelease: NoFunctionBooleanPromise,
  updateVendorRelease: NoFunctionUndefinedPromise,
  updateVendorReleaseItem: NoFunctionPromise,
  removeReleaseItem: NoFunctionPromise,
  addReleaseItem: NoFunctionPromise,
  inputErrors: [],
  setInputError: NoFunction,
  removeInputError: NoFunction,
  expandedItems: [],
  setExpandedItems: NoFunction,
});

export const DistributorReleaseProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { deliveryId: id } = useParams();
  const { setContractorCurrency } = useUser();
  const releaseId = id || "";
  const { getUomByName } = useUomOptions();
  const { setError } = useGlobalError();
  const [inputErrors, setInputErrors] = useState<DistributorReleaseErrorType[]>(
    [],
  );
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const { data, error, refetch } = useDistributorReleaseQuery({
    variables: { id: releaseId },
  });
  const [updateRelease, { loading: updating }] =
    useUpdateVendorReleaseMutation();
  const [changes, setChanges] = useState<
    UpdateVendorReleaseInput | undefined
  >();
  const [release, setRelease] =
    useState<DistributorReleaseFieldsFragment | null>(null);
  const updateReleaseCache = useDistributorReleaseUpdates();

  useEffect(() => {
    if (data?.release) {
      const releaseWithRequested = {
        ...data.release,
        requestedTime: data.release.time,
        items: data.release.items.map((item) => ({
          ...item,
          requestedQuantity:
            data.release?.status === ReleaseStatus.Requested
              ? Number(item.quantityDecimal)
              : 0,
        })),
      };

      setRelease(releaseWithRequested);
      setContractorCurrency(
        data.release.orgLocation?.org.settings?.display?.currency,
      );
    }
  }, [data?.release, setContractorCurrency]);

  const accumulateChanges = useCallback(
    (
      input:
        | Omit<UpdateVendorReleaseInput, "releaseId" | "version">
        | undefined
        | null,
    ) => {
      const newChanges = {
        ...changes,
        ...input,
        releaseId,
        version: data?.release?.version || 0,
        updates: mergeChanges(
          changes?.updates,
          input?.updates,
          "releaseItemId",
        ),
        addedItems: mergeChanges(
          changes?.addedItems,
          input?.addedItems,
          "sourceReleaseItemId",
        ),
      };

      setChanges(newChanges);
      return newChanges;
    },
    [changes, releaseId, data?.release?.version],
  );

  const update = useCallback(
    async (input?: UpdateVendorReleaseInput) => {
      const realUpdates = input?.updates?.filter(
        (update) => !update.releaseItemId.includes("duplicate"),
      );
      const fakeUpdates = input?.updates?.filter((update) =>
        update.releaseItemId.includes("duplicate"),
      );
      if (fakeUpdates && input) {
        input.addedItems = [
          ...(input.addedItems?.filter(
            (item) => !input.removedItems?.includes(item.sourceReleaseItemId),
          ) ?? []),
          ...fakeUpdates.map((update) => {
            const existingNewItem = changes?.addedItems?.find(
              (item) => item.sourceReleaseItemId === update.releaseItemId,
            );

            return {
              sourceReleaseItemId: update.releaseItemId,
              deliveryDate: update.clearDeliveryDate
                ? null
                : (update.deliveryDate ?? existingNewItem?.deliveryDate),
              quantity: update.quantityDecimal ?? existingNewItem?.quantity,
              isIncluded: update.isIncluded ?? existingNewItem?.isIncluded,
              notes: update.notes ?? existingNewItem?.notes,
              unitPrice: update.unitPrice ?? existingNewItem?.unitPrice,
              uom: getUomByName(update.uom ?? "")?.id,
            } as AddVendorReleaseItemInput;
          }),
        ];
      }
      await accumulateChanges({
        ...input,
        updates: realUpdates,
      });
      return true;
    },
    [accumulateChanges, getUomByName, changes],
  );

  const updateVendorRelease = useCallback(
    async (input: DistributorUpdateVendorReleaseInput) => {
      if (release) {
        const updatedRelease = updateReleaseCache(release, input);
        if (!updatedRelease) {
          return;
        }
        const subtotal = updatedRelease.items.reduce((acc: number, item) => {
          return Number(
            new Decimal(acc).add(
              new Decimal(item.unitPrice || 0).mul(item.quantityDecimal),
            ),
          );
        }, 0);
        const charges = updatedRelease.additionalCharges.reduce(
          (acc, charge) => {
            return Number(
              new Decimal(acc).add(new Decimal(charge.amount || 0)),
            );
          },
          0,
        );
        const taxAmount = updatedRelease.taxRate
          ? new Decimal(subtotal)
              .add(charges)
              .mul(updatedRelease.taxRate || 0)
              .toString()
          : "";
        const customTaxAmount =
          input.customTaxAmount ?? updatedRelease.customTaxAmount;
        setRelease((rel) => ({
          ...rel,
          ...updatedRelease,
          taxAmount: taxAmount.toString(),
          subtotal: subtotal.toString(),
          total: new Decimal(subtotal)
            .add(charges)
            .add(customTaxAmount || taxAmount || 0)
            .toString(),
        }));
      }

      return await update({
        releaseId,
        version: data?.release?.version || 0,
        ...input,
      });
    },
    [update, release, data?.release?.version, releaseId, updateReleaseCache],
  );

  const updateVendorReleaseItem = useCallback(
    async (input: UpdateVendorReleaseItemInput): Promise<void> => {
      await updateVendorRelease({
        updates: [input],
      });
    },
    [updateVendorRelease],
  );

  const removeReleaseItem = useCallback(
    async (id: string): Promise<void> => {
      await updateVendorRelease({
        removedItems: [id],
      });
    },
    [updateVendorRelease],
  );

  const addReleaseItem = useCallback(
    async (input: AddVendorReleaseItemInput): Promise<void> => {
      await updateVendorRelease({
        addedItems: [input],
      });
    },
    [updateVendorRelease],
  );

  const submitUpdate = useCallback(
    async (mergedChanges: UpdateVendorReleaseInput | undefined | null) => {
      try {
        const { data: updateResponse, errors } = await updateRelease({
          variables: {
            input: {
              ...(mergedChanges ?? {}),
              addedItems: mergedChanges?.addedItems?.map((item) => ({
                ...item,
                sourceReleaseItemId: item.sourceReleaseItemId.split(
                  DUPLICATE_RELEASE_ITEM_ID_SUFFIX,
                )[0],
              })),
              removedItems: mergedChanges?.removedItems?.filter(
                (item) => !item.includes(DUPLICATE_RELEASE_ITEM_ID_SUFFIX),
              ),
              releaseId: release?.id ?? "",
              version: release?.version ?? 0,
              confirm: true,
            },
          },
          refetchQueries: [
            {
              query: DistributorReleaseDocument,
              variables: { id: releaseId },
            },
          ],
        });
        setChanges(undefined);
        setError(errors);
        return updateResponse;
      } catch (errors) {
        setError(errors);
        return null;
      }
    },
    [updateRelease, release?.id, release?.version, releaseId, setError],
  );

  const confirmAndUpdateRelease = useCallback(async () => {
    if (data?.release?.version) {
      const result = await submitUpdate(changes);
      if (result) {
        refetch();
      }
      return !!result;
    }
    return false;
  }, [submitUpdate, changes, data?.release?.version, refetch]);

  const setInputError = useCallback(
    (fieldName: DistributorReleaseErrorType) => {
      setInputErrors((errors) =>
        errors.includes(fieldName) ? errors : [fieldName, ...errors],
      );
    },
    [setInputErrors],
  );

  const removeInputError = useCallback(
    (fieldName: DistributorReleaseErrorType) => {
      setInputErrors((errors) =>
        errors.includes(fieldName)
          ? errors.filter((e) => e !== fieldName)
          : errors,
      );
    },
    [setInputErrors],
  );

  const updatedItem = useCallback(
    (item: DistributorReleaseItemFieldsFragment) => {
      return changes?.updates?.find((i) => i.releaseItemId === item.id);
    },
    [changes?.updates],
  );

  useErrorEffect(error);
  return (
    <ProviderContext.Provider
      value={{
        release,
        updateVendorRelease,
        updatedItem,
        updateVendorReleaseItem,
        removeReleaseItem,
        addReleaseItem,
        updating,
        changes,
        confirmAndUpdateRelease,
        inputErrors,
        setInputError,
        removeInputError,
        expandedItems,
        setExpandedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorRelease = (): ProviderContextType =>
  useContext(ProviderContext);
