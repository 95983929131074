import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SpecifyCostCodeExpandable } from "@/common/components/specify-cost-code/SpecifyCostCodeExpandable";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useInvoiceUpdateRelease } from "../../../../providers/InvoiceUpdateReleaseProvider";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";
import { ReleaseItemType } from "./InvoiceUpdateRelease";
import { useInvoiceUpdateReleaseSpreadsheetConfig } from "./InvoiceUpdateRelease.config";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

type Props = {
  releaseItems: ReleaseItemType[];
  additionalCharges?: AdditionalChargesFieldsFragment[];
  total: number;
  updateSubtotal: (data: Record<string, string>[]) => void;
  handleUpdateRelease: (values: UpdateContractorReleaseInput) => boolean;
  form: UseFormReturn<InvoiceCreateReleaseFormValues, unknown, undefined>;
};

export const InvoiceUpdateReleaseItemizedView: FC<Props> = ({
  releaseItems,
  additionalCharges,
  total,
  updateSubtotal,
  handleUpdateRelease,
  form: { watch },
}) => {
  const { release } = useRelease();
  const { saving } = useInvoiceUpdateRelease();
  const spreadsheetViewColumns = useInvoiceUpdateReleaseSpreadsheetConfig();

  const subtotal = watch("subtotal");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const orderTypeId = watch("orderTypeId");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
    }),
    [
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <>
      <SpreadSheetView>
        <SpreadSheetTable
          items={releaseItems}
          columns={spreadsheetViewColumns}
          saving={saving}
          height="550px"
          rowNumber={17}
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <SpecifyCostCodeExpandable costCode={release?.costCode} />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          customPaymentTerm={watch("paymentTerm")}
          releaseInput={releaseInput}
          editableByContractor
          editableAdditionalCharges
          editablePaymentTerms
          includePaymentTerms
          includeAdditionalCharges
          release={release}
          updateRelease={(values) =>
            handleUpdateRelease({
              ...values,
              releaseId: release?.id ?? "",
              version: release?.version ?? -1,
            })
          }
          additionalCharges={additionalCharges}
        />
      </Footer>
    </>
  );
};
