import { BulkZoneSelector } from "@/common/components/zone-selector/BulkZoneSelector";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateContractorReleaseItemInput } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const ReleaseBulkZoneSelector: FC<Props> = ({ setLoading }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds, updateRelease } =
    useReleaseUpdate();
  const { release } = useRelease();

  const updateZone = useCallback(
    async (zone: string | null) => {
      const itemsToUpdate: UpdateContractorReleaseItemInput[] =
        selectedReleaseItemIds.map((item) => ({
          releaseItemId: item,
          zoneId: zone,
        }));

      await updateRelease({
        releaseId: release?.id ?? "",
        version: release?.version,
        updates: itemsToUpdate,
      });

      setSelectedReleaseItemIds([]);
    },
    [
      release?.id,
      release?.version,
      selectedReleaseItemIds,
      setSelectedReleaseItemIds,
      updateRelease,
    ],
  );

  return (
    <BulkZoneSelector
      setLoading={setLoading}
      count={selectedReleaseItemIds.length}
      update={updateZone}
    />
  );
};
