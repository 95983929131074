import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../providers/ReleaseProvider";

const VendorTooltip = tw.div`text-center`;

const VendorImageCont = tw.div`-ml-2 -my-2 grid h-12 w-10 place-items-center bg-white`;

type VendorLogoProps = {
  sellerOrgLocationId?: string;
};

export const VendorSplitLogo: FC<VendorLogoProps> = ({
  sellerOrgLocationId,
}) => {
  const { vendors } = useVendors();
  const { release } = useRelease();

  const vendor = useMemo(() => {
    return vendors?.find((v) => v.sellerOrgLocation.id === sellerOrgLocationId);
  }, [vendors, sellerOrgLocationId]);

  if (!vendor || release?.sellerOrgLocation?.id === sellerOrgLocationId) {
    return null;
  }

  return (
    <VendorImageCont>
      <VendorLogo
        orgName={vendor.sellerOrgLocation.org.name}
        logoImageUrl={vendor.sellerOrgLocation.org.photoUrl}
        imageWidth={28}
        tooltip={
          <VendorTooltip>
            <FormattedMessage
              id="CREATE_ORDER_BY"
              values={{
                vendorName: vendor.sellerOrgLocation.org.name,
                br: <br />,
              }}
            />
          </VendorTooltip>
        }
      />
    </VendorImageCont>
  );
};
