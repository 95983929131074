import { ConnectionStatusAdornment } from "@/common/components/connection-status-adornment/ConnectionStatusAdornment";
import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { MAX_VENDOR_NUMBER } from "@/common/const";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { SearchOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../hooks/useDeliverySlipImportExternalPO";
import { DeliverySlipReleaseFormValues } from "../delivery-slip-form/DeliverySlipVerificationForm";

const Container = tw.div`w-full grid grid-cols-3 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;
const SearchIcon = tw(
  SearchOutlined,
)`text-blue-500 cursor-pointer focus:text-black hover:text-black -mr-1`;

export const DeliverySlipCreateReleaseHeader: FC = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const {
    vendors,
    loading: loadingVendors,
    shouldShowVendorCode,
    getVendorCode,
  } = useVendors();
  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();
  const { setOptions, setVariables } = useDeliverySlipImportExternalPO();
  const poNumber = watch("poNumber");

  useEffect(() => {
    if (poNumber) {
      setOptions({ forceFetchExternalPO: true });
      setVariables({ poNumber });
    }
  }, [poNumber, setOptions, setVariables]);

  const {
    loadingExternalPo,
    externalPo,
    importedItems,
    openModal,
    importedPoIsImmutable,
  } = useDeliverySlipImportExternalPO();
  const { defaultOrderType } = useOrderTypeOptions();
  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!externalPo && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!externalPo });
  }, [externalPo, poNumber, externalPOsQueryForm, openModal]);

  return (
    <Container>
      <SelectControlled
        name="projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        rules={{
          required: true,
        }}
        disabled={!!importedItems}
      />
      <SelectControlled
        name="vendorId"
        options={vendors}
        getValue={(o) => o.sellerOrgLocation.id}
        getLabel={(o) =>
          vendorLabelFormatter(
            o.sellerOrgLocation,
            o.contacts.filter((c) => c.receivesOrderNotifications),
            {
              vendorCode: getVendorCode(o),
            },
          )
        }
        placeholder={intl.$t({ id: "VENDOR" })}
        loading={loadingVendors}
        rules={{
          required: true,
        }}
        customRender={(item) =>
          VendorPickerCustomRender(item, {
            filter: (c) => c.receivesOrderNotifications,
            shouldShowVendorCode,
          })
        }
        disabled={!!importedItems && importedPoIsImmutable}
        limitResults={MAX_VENDOR_NUMBER}
      />
      <OrderTypePickerControlled
        name="orderTypeId"
        disabled={!!importedItems}
      />
      <PoNumberingSettingsCheck>
        <TextFieldControlled
          name="poNumber"
          label={intl.$t({ id: "PO_NUMBER" })}
          className="bg-white"
          InputProps={{
            startAdornment: (
              <ConnectionStatusAdornment
                connectedSourceSystem={connectedSourceSystem}
                loading={loadingExternalPo}
                isActive={!!externalPo}
                onClick={() => openModal({ skipPoList: !!externalPo })}
                enabledTooltip="PO_FOUND_IN_SOURCE_SYSTEM"
                disabledTooltip="PO_NOT_FOUND_IN_SOURCE_SYSTEM"
              />
            ),
            endAdornment: connectedSourceSystem ? (
              <SearchIcon onClick={onPoNumberSearchIconClick} />
            ) : null,
          }}
          disabled={!!importedItems}
        />
      </PoNumberingSettingsCheck>
      <DatePickerControlled
        name="orderDate"
        label={intl.$t({ id: "FULFILLMENT_DATE" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
      />
    </Container>
  );
};
