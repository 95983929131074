import { If } from "@/common/components/if/If";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectPhaseCodes } from "@/contractor/pages/home/project/hooks/useProjectPhaseCodes";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { DashedDivider } from "@/contractor/pages/home/receipts/pages/receipt-record-order/components/order/itemized/DashedDivider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceCreateReleaseFormValues } from "../InvoiceVerificationForm";

const Container = tw.div`flex flex-col h-full pb-7 self-stretch`;
const InnerContainer = tw.div`flex flex-row items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-5`;

type Props = {
  form: UseFormReturn<InvoiceCreateReleaseFormValues, unknown, undefined>;
};

export const InvoiceUpdateReleaseNonItemizedView: FC<Props> = ({
  form: { watch, setValue },
}) => {
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  const { phaseCodes } = useProjectPhaseCodes();

  const subtotal = watch("subtotal");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const projectId = watch("projectId");
  const orderTypeId = watch("orderTypeId");
  const taxCodeId = watch("taxCodeId");
  const taxType = watch("taxType");
  const chargesAmount = watch("additionalCharges");

  const { costCodes, loading } = useProjectCostCodes();
  const { getTaxAmount } = useTaxCalculation();
  const taxAmount = getTaxAmount(taxRate, customTaxAmount, subtotal);

  useSetCurrentProjectId(projectId);
  const { calcTotalPrice, calcAdditionalChargesPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      calcTotalPrice({
        subtotal,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges: chargesAmount,
      }),
    [
      calcTotalPrice,
      subtotal,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      chargesAmount,
    ],
  );

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || undefined);
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  const releaseInput = useMemo(
    () => ({
      customTaxAmount,
      taxRate,
      orderTypeId,
      taxAmount,
      subtotal,
      taxCodeId,
      taxType,
      chargesAmount: calcAdditionalChargesPrice(chargesAmount),
    }),
    [
      calcAdditionalChargesPrice,
      chargesAmount,
      customTaxAmount,
      orderTypeId,
      subtotal,
      taxAmount,
      taxCodeId,
      taxRate,
      taxType,
    ],
  );

  return (
    <Container>
      <InnerContainer>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={costCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                disableClearable={false}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                disableClearable={false}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="subtotal"
              label={intl.$t({ id: "SUBTOTAL" })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ReleaseAdditionalChargesAndTaxes
            classes={{
              root: "mr-5",
              total: "-mr-7",
            }}
            total={`${total}`}
            releaseInput={releaseInput}
            editableByContractor
            includeSubtotal={false}
            updateRelease={(values) =>
              handleUpdateRelease({ ...values, version: -1 })
            }
            hideTotalBorder
            totalDivider={<DashedDivider />}
            additionalCharges={
              watch("additionalCharges") as AdditionalChargesFieldsFragment[]
            }
          />
        </Item>
      </InnerContainer>
    </Container>
  );
};
