import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import {
  AuthorizationStatus,
  ReleaseFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { AddRejectReason } from "./reject-reason/AddRejectReason";

type ButtonType = {
  label: string;
  onClick: () => void;
};

type Props = {
  release: ReleaseFieldsFragment;
};

export const CancelReleaseButton: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { toggle } = useGlobalDrawer();
  const { archiveRelease, archiving, cancelRelease, canceling, rejecting } =
    useReleaseActions();
  const { navigateToNextSequence } = useReleaseSequence();

  const cancelReleaseCallback = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "SEND" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "CANCEL_RELEASE" }),
      content: intl.$t({ id: "CANCEL_DELIVERY_QUESTION" }),
      handleConfirm: async () => {
        const result = await cancelRelease({
          releaseId: release.id,
          version: release.version,
        });
        if (result) {
          setSuccessAlert(intl.$t({ id: "CANCELED_RELEASE" }));
          navigateToNextSequence({ navigateToReleases: true });
        }
      },
    });
  }, [
    release,
    cancelRelease,
    intl,
    navigateToNextSequence,
    openDialog,
    setSuccessAlert,
  ]);

  const cancelButton = useMemo<ButtonType | undefined>(() => {
    switch (release.status) {
      case ReleaseStatus.Draft:
      case ReleaseStatus.Canceled:
      case ReleaseStatus.Rejected:
        return {
          label: release.status === ReleaseStatus.Draft ? "CANCEL" : "DELETE",
          onClick: () => {
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              icon: <WarningIcon />,
              title: intl.$t({ id: "DELETE_ORDER_DETAILS" }),
              handleConfirm: async () => {
                const result = await archiveRelease(release.id);
                if (result) {
                  setSuccessAlert(intl.$t({ id: "DELETE_ORDER" }));
                  navigateToNextSequence({ navigateToReleases: true });
                }
              },
            });
          },
        };
      case ReleaseStatus.AwaitingApproval:
        if (release.permissions?.cancel === AuthorizationStatus.Authorized) {
          return {
            label: "CANCEL_RELEASE",
            onClick: cancelReleaseCallback,
          };
        }
        if (release.approvalProgress.canReject) {
          return {
            label: "REJECT",
            onClick: () => {
              toggle("reject-reason", true);
            },
          };
        }
        break;
      case ReleaseStatus.Requested:
      case ReleaseStatus.Scheduled:
        if (
          release.permissions?.submitDirectly === AuthorizationStatus.Authorized
        ) {
          return {
            label: "CANCEL_RELEASE",
            onClick: cancelReleaseCallback,
          };
        }
    }
  }, [
    intl,
    openDialog,
    release,
    setSuccessAlert,
    archiveRelease,
    toggle,
    cancelReleaseCallback,
    navigateToNextSequence,
  ]);

  if (!cancelButton) {
    return null;
  }

  return (
    <>
      <OutlinedButton
        onClick={cancelButton.onClick}
        loading={archiving || canceling || rejecting}
        testId="archive-release"
      >
        <FormattedMessage id={cancelButton.label} />
      </OutlinedButton>
      <AddRejectReason release={release} />
    </>
  );
};
