import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { getTaxRate } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { LUMP_SUM_UOM } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectZones } from "@/contractor/pages/home/project/hooks/useProjectZones";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  AddToReleaseItemInput,
  InvoiceDocument,
  InvoiceItemFieldsFragment,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useGetOrCreateLumpSumMaterial } from "../../../../../../../common/components/import-external-po/hooks/useGetOrCreateLumpSumMaterial";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "../components/matched-order/components/invoice-create-release/InvoiceCreateRelease.config";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceImportExternalPO } from "../hooks/useInvoiceImportExternalPO";
import { useLumpSumInvoiceReleaseItems } from "../hooks/useLumpSumInvoiceReleaseItems";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type CreateReleaseOptions = {
  isChangeOrder?: boolean;
};

type ProviderContextType = {
  invoiceItems: InvoiceItemFieldsFragment[];
  setInvoiceItems: (items: InvoiceItemFieldsFragment[]) => void;
  syncCreateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean | undefined>;
  loading: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  createRelease: (values: InvoiceCreateReleaseFormValues) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  invoiceItems: [],
  setInvoiceItems: NoFunction,
  syncCreateReleaseFromInvoice: NoFunctionBooleanPromise,
  loading: false,
  spreadsheetViewColumns: [],
  createRelease: NoFunctionBooleanPromise,
});

export const InvoiceCreateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { connectedSourceSystem, settings, hasPhaseCodes } = useOrgSettings();
  const { invoice, setFooterState } = useInvoiceVerification();
  const { getOrCreateLumpSumMaterial } = useGetOrCreateLumpSumMaterial();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    importedPoExternalId,
    importedPoItemized,
    importedItems,
    importedDescription,
  } = useInvoiceImportExternalPO();
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const {
    getCellValue,
    getCostCodeId,
    rowIsEmpty,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const { getSyncedRelease } = useSyncReleaseItems();
  const { calcExtPrice } = usePriceCalculation();
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const { getInvoiceLumpSumReleaseItem } = useLumpSumInvoiceReleaseItems();
  const { zones } = useProjectZones();
  const [saving, setSaving] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemFieldsFragment[]>(
    (invoice?.items ?? []).map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
        extPrice: calcExtPrice(item?.quantityDecimal, item?.unitPrice),
      };
    }),
  );

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();

  const getLumpSumItems = async () => {
    const newItems: AddToReleaseItemInput[] = [];
    const material = await getOrCreateLumpSumMaterial();

    spreadsheetData.forEach((row) => {
      const costCodeText = getCellWithAdditionalData(row, COLUMN_TYPE.CostCode);

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const zone = zones.find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const unitPrice = "1";

      newItems.push({
        invoiceId: invoice?.id ?? "",
        projectItem: {
          estimateUom: LUMP_SUM_UOM,
          orgCatalogSkuId: material.material.id,
        },
        costCodeId: getCostCodeId(row),
        name: costCodeText,
        zoneId: zone?.id,
        quantityDecimal: quantityDecimal.toString(),
        unitPrice: String(unitPrice),
      });
    });

    return newItems;
  };

  const createReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
    newItems: AddToReleaseItemInput[],
    options?: CreateReleaseOptions,
  ) => {
    try {
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: invoice?.id ?? "",
            projectId: values.projectId ?? "",
            warehouseId:
              values.fulfillmentLocationId === values.projectId
                ? undefined
                : values.fulfillmentLocationId,
            sellerOrgLocationId: values.vendorId,
            poNumber: values.poNumber,
            time: defaultReleaseDate(values.orderDate)?.getTime(),
            items: newItems,
            paymentTerm: values.paymentTerm
              ? Number(values.paymentTerm)
              : undefined,
            taxRate: getTaxRate(values),
            customTaxAmount: values.customTaxAmount || undefined,
            additionalCharges: values.additionalCharges?.filter(
              (charge) => charge.description && Number(charge.amount) > 0,
            ),
            retroactive: true,
            mapping:
              importedPoExternalId &&
              connectedSourceSystem &&
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                ? {
                    externalId: importedPoExternalId,
                    sourceSystem: connectedSourceSystem,
                  }
                : undefined,
            typeId: values.orderTypeId || undefined,
            assignPOItemZones:
              !!importedPoExternalId &&
              importedPoItemized === true &&
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                ? true
                : undefined,
            taxCodeId: values.taxCodeId,
            taxType: values.taxType,
            description:
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
              importedDescription
                ? importedDescription
                : null,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          ...(invoice?.id
            ? [
                {
                  query: InvoiceDocument,
                  variables: { id: invoice.id },
                },
              ]
            : []),
        ],
      });
      setError(errors);
      if (!errors) {
        resetPreviousData();
      }
      if (data) {
        setSystemAlert(
          intl.$t(
            {
              id:
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? options?.isChangeOrder
                    ? "INVOICE_WAS_SUCCESSFULLY_IMPORTED_CHANGE_ORDER"
                    : "INVOICE_WAS_SUCCESSFULLY_IMPORTED"
                  : "ORDER_WAS_SUCCESSFULLY_CREATED",
            },
            {
              integration: connectedSourceSystem
                ? intl.$t({
                    id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                  })
                : "",
              poNumber: invoice?.poNumber,
              orderNumber: data.createStandaloneRelease?.sequenceNumber,
            },
          ),
          {
            type:
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                ? SystemAlertType.AUTOMATIC
                : SystemAlertType.MANUAL,
            clear: true,
          },
        );
      }
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const syncCreateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        ...(importedPoItemized
          ? [
              COLUMN_TYPE.Material,
              COLUMN_TYPE.UOM,
              COLUMN_TYPE.Quantity,
              invoice?.isDeliverySlip
                ? COLUMN_TYPE.PrefilledPrice
                : COLUMN_TYPE.UnitPrice,
            ]
          : [COLUMN_TYPE.CostCode]),
        COLUMN_TYPE.UnitPrice,
      ]) ||
      !validateRowValues(
        [
          ...(importedPoItemized
            ? [
                COLUMN_TYPE.Quantity,
                invoice?.isDeliverySlip
                  ? COLUMN_TYPE.PrefilledPrice
                  : COLUMN_TYPE.UnitPrice,
                COLUMN_TYPE.UOM,
                ...(hasPhaseCodes
                  ? [COLUMN_TYPE.PhaseCode]
                  : [COLUMN_TYPE.CostCode]),
              ]
            : [COLUMN_TYPE.UnitPrice]),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    setSaving(true);
    const { addedItems } = await getSyncedRelease({
      importedItems: importedItems ?? [],
      addItemsProps: { invoiceId: invoice?.id },
    });
    const newItems = importedPoItemized ? addedItems : await getLumpSumItems();
    setSaving(false);

    const options =
      matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
      newItems.find((i) => !i.poItemExternalId)
        ? {
            isChangeOrder: !!settings?.integrations.sourceSystems.find(
              (s) => s.connected,
            )?.autoPostPOs,
          }
        : undefined;
    if (await createReleaseFromInvoice(values, newItems, options)) {
      if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
        setMatchedOrderViewState(MatchedOrderViewState.EDIT_INVOICE_COVERAGES);
      } else {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
      }
      setFooterState(InvoiceFooterState.DEFAULT);
    }
  };

  const createRelease = async (values: InvoiceCreateReleaseFormValues) => {
    try {
      setSaving(true);
      const items = await getInvoiceLumpSumReleaseItem(values);
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: invoice?.id ?? "",
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            time: values.orderDate?.getTime() ?? undefined,
            items,
            taxRate: getTaxRate(values),
            customTaxAmount: values.customTaxAmount || undefined,
            assignDefaultCostCodes: false,
            retroactive: true,
            typeId: values.orderTypeId || undefined,
            poNumber: values.poNumber,
            taxCodeId: values.taxCodeId,
            taxType: values.taxType,
            additionalCharges: values.additionalCharges,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
          ...(invoice?.id
            ? [
                {
                  query: InvoiceDocument,
                  variables: { id: invoice.id },
                },
              ]
            : []),
        ],
      });
      if (data) {
        setSystemAlert(
          intl.$t(
            {
              id: "ORDER_WAS_SUCCESSFULLY_CREATED",
            },
            {
              orderNumber: data.createStandaloneRelease?.sequenceNumber,
            },
          ),
          { type: SystemAlertType.MANUAL, clear: true },
        );
      }
      setError(errors);
      setSaving(false);
      return !errors;
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        invoiceItems,
        setInvoiceItems,
        syncCreateReleaseFromInvoice,
        createRelease,
        loading: saving || creatingRelease,
        spreadsheetViewColumns: useInvoiceCreateReleaseSpreadsheetConfig(),
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceCreateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
