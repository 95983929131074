import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { ResizableColumns } from "@/common/components/resizable-columns/ResizableColumns";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { ReleaseSequenceProvider } from "../../../../deliveries/providers/ReleaseSequenceProvider";
import { useDeliverySlipImportExternalPO } from "../../../hooks/useDeliverySlipImportExternalPO";
import { DeliverySlipReleaseProvider } from "../../../providers/DeliverySlipReleaseProvider";
import { DeliverySlipSequenceProvider } from "../../../providers/DeliverySlipSequenceProvider";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipBreadcrumbs } from "../../breadcrumbs/DeliverySlipBreadcrumbs";
import { DeliverySlipDetails } from "../components/delivery-slip-details/DeliverySlipDetails";
import { useDeliverySlipReleaseSpreadsheetConfig } from "../components/delivery-slip-edit-release/DeliverySlipRelease.config";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-form/DeliverySlipVerificationForm";
import { DeliverySlipImportExternalPOWizard } from "../delivery-slip-import-external-po/DeliverySlipImportExternalPOWizard";
import { DeliverySlipMatchFooter } from "./components/DeliverySlipMatchFooter";
import { DeliverySlipMatchOrderDetails } from "./components/DeliverySlipMatchOrderDetails";

const Container = tw.div`
  mt-5 xl:-mx-10
`;

const DeliverySlipVerificationWithProvider: FC = () => {
  const {
    deliverySlip,
    setPackingSlipMatchViewState,
    setSelectedRelease,
    selectedRelease,
  } = useDeliverySlipVerification();
  const { setGlobalVendorId } = useVendorPrices();
  const { importModalOpened } = useDeliverySlipImportExternalPO();

  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();
  const vendorId = watch("vendorId");
  const poNumber = watch("poNumber");

  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  useEffect(() => {
    if (deliverySlip) {
      setValue("projectId", deliverySlip.project?.id || "");
      setValue("vendorId", deliverySlip.predictedSellerOrgLocation?.id || "");
    }

    if (deliverySlip?.poNumber && !poNumber) {
      setValue("poNumber", deliverySlip?.poNumber);
    }
    if (deliverySlip?.fulfillmentDate) {
      setValue(
        "orderDate",
        defaultReleaseDate(getUTCDate(deliverySlip.fulfillmentDate)),
      );
    }
    if (deliverySlip?.predictedRelease && !selectedRelease) {
      setPackingSlipMatchViewState(PackingSlipMatchViewState.MATCH_ORDER);
      setSelectedRelease(deliverySlip.predictedRelease);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverySlip]);

  return (
    <Container>
      <DeliverySlipBreadcrumbs />
      <ResizableColumns hasFooter>
        <DeliverySlipMatchOrderDetails />
        <DeliverySlipDetails />
      </ResizableColumns>
      <If isTrue={deliverySlip}>
        <DeliverySlipMatchFooter />
      </If>
      <If isTrue={importModalOpened}>
        <DeliverySlipImportExternalPOWizard />
      </If>
    </Container>
  );
};

export const DeliverySlipMatchOrder: FC = () => {
  const { deliverySlip } = useDeliverySlipVerification();
  const deliverySlipReleaseSpreadsheetConfig =
    useDeliverySlipReleaseSpreadsheetConfig();

  if (!deliverySlip) {
    return <Loader loading />;
  }

  return (
    <ColumnMapperProvider config={deliverySlipReleaseSpreadsheetConfig}>
      <ReleaseSequenceProvider>
        <DeliverySlipSequenceProvider>
          <DeliverySlipReleaseProvider>
            <DeliverySlipVerificationWithProvider />
          </DeliverySlipReleaseProvider>
        </DeliverySlipSequenceProvider>
      </ReleaseSequenceProvider>
    </ColumnMapperProvider>
  );
};
