import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";
import { useBuyoutGrouped } from "../components/non-quoted/providers/BuyoutGroupedProvider";
import { footer } from "./print/footer";
import { header } from "./print/header";
import { materials } from "./print/materials";
import { termsAndConditions } from "./print/termsAndConditions";

type CostCodesCategory = CategoryState<BuyoutItemFieldsFragment>;

export const usePrintView = (
  buyout: BuyoutFieldsFragment | null | undefined,
  costCodes: CostCodesCategory[],
  groupedByCostCode: boolean,
) => {
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  const { formatCostCode } = useCostCodes();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { subtotal, salesTax, total } = useBuyoutGrouped();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  const savePDF = () => {
    if (buyout) {
      const doc = new jsPDF("landscape")
        .setFont(PDF_FONT)
        .setLineHeightFactor(1.5);
      doc
        .setFont(PDF_FONT, "", "bold")
        .setFontSize(16)
        .text(
          intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier }),
          PDF_LEFT_SPACING,
          20,
        );
      header(doc, buyout, intl);
      if (
        buyout.paymentTermDays ||
        buyout.notes ||
        buyout.additionalCharges.length
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const topSpacing = (doc as any).lastAutoTable.finalY + 10;
        doc
          .setFontSize(10)
          .setFont(PDF_FONT, "", "bold")
          .text(
            intl.$t({ id: "NOTES_AND_TERMS" }),
            PDF_LEFT_SPACING,
            topSpacing,
          );
        termsAndConditions(doc, buyout, intl, formatCurrency);
        doc.addPage();
      }
      materials(
        doc,
        intl,
        buyout,
        hasPhaseCodes,
        costCodes,
        groupedByCostCode,
        formatCostCode,
        formatCurrency,
        canViewPrices,
      );
      if (canViewPrices) {
        footer(doc, subtotal, salesTax, total, intl, formatCurrency);
      }
      doc.save(`Buyout #${buyout.clientIdentifier}.pdf`);
    }
  };

  return {
    savePDF,
  };
};
