import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { TaxCodeSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useTaxCodeSummaries } from "../../../hooks/useTaxCodeSummaries";
import { getTaxCodeLabel } from "../utils/getTaxCodeLabel";

type Props = {
  onChange: (taxAuthorityId: string | null) => void;
  value: string | null;
  fallbackTaxCodes?: TaxCodeSummaryFieldsFragment[];
};

export const NativeSalesTaxPicker: FC<Props> = ({
  onChange,
  value,
  fallbackTaxCodes,
}) => {
  const { taxCodes, loading } = useTaxCodeSummaries();
  const intl = useIntl();
  const options = useMemo(
    () => (taxCodes.length ? taxCodes : fallbackTaxCodes || []),
    [taxCodes, fallbackTaxCodes],
  );

  return (
    <StyledSelect
      options={options}
      loading={loading}
      value={value}
      getLabel={(option) => getTaxCodeLabel(option)}
      getValue={(option) => option.id}
      onChange={onChange}
      label={intl.$t({ id: "TAX_CODE" })}
      disableClearable
      shrink
      staticText={taxCodes.length === 0}
    />
  );
};
