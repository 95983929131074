import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`flex`;

type Props = {
  costCode: CategoryState<ExpandedReleaseItem>;
};

export const InvoiceReleaseItemCostCodeCheckbox: FC<Props> = ({ costCode }) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { release } = useRelease();

  const checked = useMemo(
    () => costCode.items.every((item) => (item.invoiceItems || [])?.length > 0),
    [costCode.items],
  );
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? costCode.items
            .filter((item) => (item.invoiceItems || []).length === 0)
            .filter((item) => !hasOrderItemPoItemReferences(item, release))
            .map((item) => ({
              id: item.id,
              releaseItemId: item.id,
              quantity: item.quantityDecimal || "0",
              unitPrice: item.unitPrice ? item.unitPrice.toString() : "0",
            }))
        : undefined;
      const removedInvoicedReleaseItems = !newChecked
        ? (costCode.items
            .map((item) => item.invoiceItems?.[0].id)
            .filter((id) => id) as string[])
        : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      costCode.items,
      hasOrderItemPoItemReferences,
      invoice?.id,
      invoice?.release?.id,
      release,
      updateInvoice,
      refetchInvoiceValidation,
    ],
  );

  const hasSelectableItems = useMemo(
    () =>
      !costCode.items.some((item) =>
        hasOrderItemPoItemReferences(item, release),
      ),
    [costCode.items, hasOrderItemPoItemReferences, release],
  );

  if (!hasSelectableItems) {
    return null;
  }

  return (
    <Container>
      <SelectionCheckbox
        testId={`invoice-cost-code-${costCode.name.toLowerCase().replaceAll(/\s+/g, "-")}`}
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
