import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Multiselect } from "../select/components/multiple/Multiselect";

type Props<T> = {
  onChange: (values: T[] | undefined) => void;
  selectedOrderTypes: T[] | undefined | null;
  className?: string;
};

const OrderTypesFilterContainer = tw.div`w-full flex justify-between items-center gap-4`;

export const OrderTypesFilter = <T,>({
  selectedOrderTypes,
  onChange,
  className,
}: Props<T>) => {
  const { orderTypes } = useOrderTypes();
  const intl = useIntl();

  const orderTypesOptions = useMemo(() => {
    return orderTypes.map((type) => ({
      name: type.name,
      value: type.id,
    }));
  }, [orderTypes]);

  const filterValues = useMemo(() => {
    if (selectedOrderTypes?.length) {
      return selectedOrderTypes as string[];
    }
    return null;
  }, [selectedOrderTypes]);

  return (
    <OrderTypesFilterContainer className={className}>
      <FormattedMessage id="SELECT_ORDER_TYPES" />

      <Multiselect
        options={orderTypesOptions}
        getLabel={(o) => o.name}
        getValue={(o) => o.value as string}
        values={filterValues}
        placeholder={
          filterValues?.length ? "" : intl.$t({ id: "ALL_ORDER_TYPES" })
        }
        onMultipleChange={(values: string[] | null) => {
          onChange(values?.length ? (values as T[]) : undefined);
        }}
        chipSize="small"
        includeCheckbox
        limitTags={1}
        disableCloseOnSelect
        className="min-w-45"
      />
    </OrderTypesFilterContainer>
  );
};
