import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { Price } from "@/common/components/price/Price";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  APPROVED_INVOICES_STATUS,
  DIALOG_AUTO_CLOSE_TIMER,
} from "@/common/const";
import usePreventUnsavedChanges from "@/common/hooks/usePreventUnsavedChanges";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { AuthorizationStatus, InvoiceStatus } from "@/generated/graphql";
import { ChevronRight } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { Form, FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  InvoiceKickbackForm,
  VENDOR_CONTACTS_FAKE_ID,
} from "../../../../../../../../common/components/invoices/invoice-issue/kickback/InvoiceKickbackForm";
import { useArchiveInvoice } from "../../../scanned-invoices/hooks/useArchiveInvoice";
import { useInvoiceSequence } from "../../../scanned-invoices/providers/InvoiceSequenceProvider";
import { useInvoiceValidation } from "../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useApproveInvoice } from "../../hooks/useApproveInvoice";
import { useAssignOrderToInvoice } from "../../hooks/useAssignOrderToInvoice";
import { useInvoiceImportExternalPO } from "../../hooks/useInvoiceImportExternalPO";
import { useInvoiceIssues } from "../../hooks/useInvoiceIssues";
import { useInvoiceKickbacks } from "../../hooks/useInvoiceKickbacks";
import { useInvoicePermissions } from "../../hooks/useInvoicePermissions";
import { useInvoiceCreateRelease } from "../../providers/InvoiceCreateReleaseProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../matched-order/components/InvoiceVerificationForm";

interface InvoiceKickbackFormValues {
  description: string;
  assigneeIds: string[];
  assetUrls: string[];
  issue: string;
}

const defaultValues: InvoiceKickbackFormValues = {
  description: "",
  assigneeIds: [],
  assetUrls: [],
  issue: "",
};

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const ButtonSub = tw.div`text-2xs`;
const FooterBreadcrumbs = tw.div`flex flex-row gap-2 items-center mr-4`;
const FooterBreadcrumb = tw.div<{
  $selected?: boolean;
}>`flex font-medium text-xs items-center gap-2
  ${({ $selected }) => (!$selected ? "text-gray-600" : "")}
`;
const Count = tw.div<{ $selected?: boolean }>`
  rounded-full border border-gray-600 p-2 text-2xs font-medium h-6 flex items-center
  ${({ $selected }) => ($selected ? "border-black text-black" : "text-gray-600")}
`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;
const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm`;
const SuccessTitle = tw.div`font-medium text-lg`;
const TooltipContent = tw.div`text-center`;

export const InvoiceFooter: FC = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const {
    invoice,
    approving,
    footerState,
    setFooterState,
    submitUpdates,
    hasChanges,
    resetUpdates,
  } = useInvoiceVerification();
  const { matchedOrderViewState, setMatchedOrderViewState, selectedRelease } =
    useInvoiceMatchedOrder();
  const { syncCreateReleaseFromInvoice, loading, createRelease } =
    useInvoiceCreateRelease();
  const { syncUpdateReleaseFromInvoice, saving } = useInvoiceUpdateRelease();
  const { createInvoiceKickback } = useInvoiceKickbacks();
  const { createInvoiceIssue } = useInvoiceIssues();
  const { setSuccessAlert } = useSnackbar();
  const { archiveInvoice } = useArchiveInvoice();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { navigateToNextSequence } = useInvoiceSequence();
  const { settings } = useOrgSettings();
  const { refetch: refetchRelease } = useRelease();
  const methods = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { approve } = useApproveInvoice();
  const { assignOrderToInvoice, isAssigningOrderToInvoice } =
    useAssignOrderToInvoice();

  const { handleSubmit, getValues, trigger } =
    useFormContext<InvoiceCreateReleaseFormValues>();
  const { fetchInvoicePermissions, permissions } = useInvoicePermissions();
  const { importedPoItemized } = useInvoiceImportExternalPO();

  const assigneeIds = methods.watch("assigneeIds");

  useEffect(() => {
    if (invoice?.release?.project?.id) {
      fetchInvoicePermissions(
        invoice?.id,
        invoice?.release?.project?.id ?? getValues().projectId,
      );
    }
  }, [
    fetchInvoicePermissions,
    getValues,
    invoice?.id,
    invoice?.release?.project?.id,
  ]);

  const sendIssueToVendor = useCallback(async () => {
    const { description } = methods.getValues();
    if (
      await createInvoiceIssue({
        invoiceId: invoice?.id ?? "",
        description,
        assetUrls: methods.getValues().assetUrls,
      })
    ) {
      methods.reset();
      openDialog({
        content: (
          <SuccessModal message={intl.$t({ id: "ISSUE_REPORTED_TO_VENDOR" })} />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(InvoiceFooterState.DEFAULT);
      navigateToNextSequence();
    }
  }, [
    methods,
    createInvoiceIssue,
    invoice?.id,
    openDialog,
    intl,
    setFooterState,
    navigateToNextSequence,
  ]);

  const kickback = useCallback(async () => {
    const { description, assigneeIds } = methods.getValues();

    const result = await createInvoiceKickback({
      invoiceId: invoice?.id ?? "",
      description,
      assigneeIds,
      assetUrls: methods.getValues().assetUrls,
    });
    if (result) {
      methods.reset();
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="KICKBACK_SENT_TO_TITLE"
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="KICKBACK_SENT_TO"
                  values={{
                    kickbackTo: result.kickbacks[
                      result.kickbacks.length - 1
                    ].assignees
                      .map(getUserName)
                      .join(", "),
                  }}
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(InvoiceFooterState.DEFAULT);
      navigateToNextSequence();
    }
  }, [
    methods,
    createInvoiceKickback,
    invoice?.id,
    openDialog,
    setFooterState,
    navigateToNextSequence,
  ]);

  const handleKickbackClick = async () => {
    if (assigneeIds.includes(VENDOR_CONTACTS_FAKE_ID)) {
      await sendIssueToVendor();
      return;
    }
    await kickback();
  };

  const handleCreateReleaseFromInvoice = useCallback(async () => {
    await handleSubmit(async (values: InvoiceCreateReleaseFormValues) => {
      if (
        importedPoItemized ||
        matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
      ) {
        const sync = await syncCreateReleaseFromInvoice(values);
        if (!sync) {
          return null;
        }
      } else {
        const release = await createRelease(values);
        if (!release) {
          return null;
        }
      }
      if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
        setMatchedOrderViewState(MatchedOrderViewState.EDIT_INVOICE_COVERAGES);
      } else {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
      }
      setFooterState(InvoiceFooterState.DEFAULT);
    })();
  }, [
    handleSubmit,
    importedPoItemized,
    matchedOrderViewState,
    invoice,
    setFooterState,
    syncCreateReleaseFromInvoice,
    createRelease,
    setMatchedOrderViewState,
  ]);

  const recordOrder = useCallback(async () => {
    trigger();
    if (!getValues().projectId || !invoice?.id) {
      return;
    }
    const { data } = await fetchInvoicePermissions(
      invoice?.id,
      getValues().projectId,
    );
    if (!data) {
      return;
    }
    handleCreateReleaseFromInvoice();
  }, [
    fetchInvoicePermissions,
    getValues,
    handleCreateReleaseFromInvoice,
    invoice?.id,
    trigger,
  ]);

  const handleUpdateReleaseFromInvoice = useCallback(async () => {
    await handleSubmit(async (values: InvoiceCreateReleaseFormValues) => {
      const sync = await syncUpdateReleaseFromInvoice(values);
      if (!sync) {
        return null;
      }
      if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
        setMatchedOrderViewState(MatchedOrderViewState.EDIT_INVOICE_COVERAGES);
      } else {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
      }
      setFooterState(InvoiceFooterState.DEFAULT);
    })();
  }, [
    handleSubmit,
    syncUpdateReleaseFromInvoice,
    invoice,
    setFooterState,
    setMatchedOrderViewState,
  ]);

  const isApprovedOrPaid = useMemo(() => {
    if (!invoice) {
      return false;
    }
    return INVOICE_READONLY_STATUSES.includes(invoice.status);
  }, [invoice]);

  const saveChanges = useCallback(async () => {
    if (await submitUpdates({ refetchQueries: !!invoice?.release?.id })) {
      setSuccessAlert(intl.$t({ id: "CHANGES_SAVED_SUCCESSFULLY" }));
      refetchInvoiceValidation();
      if (isApprovedOrPaid) {
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setFooterState(InvoiceFooterState.DEFAULT);
      }
    }
  }, [
    submitUpdates,
    invoice?.release?.id,
    setSuccessAlert,
    intl,
    isApprovedOrPaid,
    setMatchedOrderViewState,
    setFooterState,
    refetchInvoiceValidation,
  ]);

  const back = useCallback(() => {
    resetUpdates();
    if (invoice?.release?.id) {
      refetchRelease();
    }
    setFooterState(InvoiceFooterState.DEFAULT);
    setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
  }, [
    resetUpdates,
    invoice?.release?.id,
    setFooterState,
    setMatchedOrderViewState,
    refetchRelease,
  ]);

  usePreventUnsavedChanges({
    hasChanges,
    onSave: async () => {
      await saveChanges();
      return true;
    },
  });

  const showSaveChangesDialog = useCallback(() => {
    if (!hasChanges) {
      return;
    }

    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "SAVE_CHANGES" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "INVOICE_UNSAVED_CHANGES" }),
      text: intl.$t({ id: "SAVE_COVERAGE_CHANGES_WARNING" }),
      handleConfirm: saveChanges,
    });
  }, [openDialog, saveChanges, hasChanges, intl]);

  const defaultFooterState = useMemo(
    () =>
      [
        InvoiceFooterState.DEFAULT,
        InvoiceFooterState.EDIT_INVOICE_COVERAGES,
      ].includes(footerState),
    [footerState],
  );

  // TODO remove archivedAt when we add unarchive feature - right now we hide the footer entirely if archived
  if (invoice?.archivedAt || (invoice?.hasRelease && !invoice.release)) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Form>
        <FloatingFooterStyled>
          <If isTrue={footerState === InvoiceFooterState.KICKBACK}>
            <InvoiceKickbackForm
              onClose={() => setFooterState(InvoiceFooterState.DEFAULT)}
              invoiceId={invoice?.id}
              releaseCreatedBy={invoice?.release?.createdBy?.id}
              hideDetails
            />
          </If>
          <ButtonContainer>
            <If
              isTrue={
                footerState === InvoiceFooterState.MATCH_ORDER ||
                footerState === InvoiceFooterState.CREATE_ORDER ||
                footerState === InvoiceFooterState.IMPORT_ORDER ||
                matchedOrderViewState === MatchedOrderViewState.FULFILLED
              }
            >
              <FooterBreadcrumbs>
                <FooterBreadcrumb
                  $selected={
                    footerState === InvoiceFooterState.MATCH_ORDER ||
                    footerState === InvoiceFooterState.IMPORT_ORDER ||
                    footerState === InvoiceFooterState.CREATE_ORDER
                  }
                >
                  <Count
                    $selected={
                      footerState === InvoiceFooterState.MATCH_ORDER ||
                      footerState === InvoiceFooterState.IMPORT_ORDER ||
                      footerState === InvoiceFooterState.CREATE_ORDER
                    }
                  >
                    1
                  </Count>
                  <FormattedMessage
                    id={
                      footerState === InvoiceFooterState.MATCH_ORDER
                        ? "SELECT_ORDER"
                        : "CREATE_ORDER"
                    }
                  />
                </FooterBreadcrumb>
                <ChevronRight color="disabled" />
                <FooterBreadcrumb
                  $selected={
                    matchedOrderViewState === MatchedOrderViewState.FULFILLED
                  }
                >
                  <Count
                    $selected={
                      matchedOrderViewState === MatchedOrderViewState.FULFILLED
                    }
                  >
                    2
                  </Count>
                  <FormattedMessage id="VERIFY_INVOICE" />
                </FooterBreadcrumb>
              </FooterBreadcrumbs>
            </If>
            <If
              isTrue={
                footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES &&
                isApprovedOrPaid
              }
            >
              <OutlinedButton onClick={back}>
                <FormattedMessage id="BACK" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                footerState === InvoiceFooterState.MATCH_ORDER ||
                footerState === InvoiceFooterState.IMPORT_ORDER ||
                footerState === InvoiceFooterState.CREATE_ORDER
              }
            >
              <ButtonContainer>
                <OutlinedButton
                  onClick={() => {
                    setFooterState(InvoiceFooterState.DEFAULT);
                    setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
                  }}
                >
                  <FormattedMessage id="CLOSE" />
                </OutlinedButton>
                <If
                  isTrue={
                    footerState === InvoiceFooterState.CREATE_ORDER ||
                    footerState === InvoiceFooterState.IMPORT_ORDER
                  }
                >
                  <PrimaryButton
                    className="py-0"
                    onClick={recordOrder}
                    loading={loading}
                    testId="record-order"
                  >
                    <FormattedMessage
                      id={
                        footerState === InvoiceFooterState.IMPORT_ORDER
                          ? "IMPORT_ORDER"
                          : "RECORD_ORDER"
                      }
                    />
                    <If
                      isTrue={footerState === InvoiceFooterState.CREATE_ORDER}
                    >
                      <ButtonSub>
                        <FormattedMessage id="WITHOUT_VENDOR_CONFIRMATION" />
                      </ButtonSub>
                    </If>
                  </PrimaryButton>
                </If>
                <If isTrue={footerState === InvoiceFooterState.MATCH_ORDER}>
                  <PrimaryButton
                    onClick={assignOrderToInvoice}
                    disabled={!selectedRelease}
                    loading={isAssigningOrderToInvoice}
                  >
                    <FormattedMessage id="CONFIRM" />
                  </PrimaryButton>
                </If>
              </ButtonContainer>
            </If>
            <If isTrue={footerState === InvoiceFooterState.EDIT_ORDER}>
              <ButtonContainer>
                <OutlinedButton
                  onClick={() => {
                    if (
                      invoice &&
                      !INVOICE_READONLY_STATUSES.includes(invoice?.status)
                    ) {
                      setMatchedOrderViewState(
                        MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
                      );
                    } else {
                      setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
                    }
                    setFooterState(InvoiceFooterState.DEFAULT);
                  }}
                >
                  <FormattedMessage id="CLOSE" />
                </OutlinedButton>
                <PrimaryButton
                  className="py-0"
                  onClick={handleUpdateReleaseFromInvoice}
                  loading={saving}
                >
                  <FormattedMessage id="SAVE_ORDER" />
                </PrimaryButton>
              </ButtonContainer>
            </If>
            <If isTrue={!invoice?.archivedAt}>
              <If isTrue={footerState === InvoiceFooterState.KICKBACK}>
                <OutlinedButton
                  onClick={() => setFooterState(InvoiceFooterState.DEFAULT)}
                >
                  <FormattedMessage id="BACK" />
                </OutlinedButton>
                <PrimaryButton
                  className="py-0"
                  onClick={handleKickbackClick}
                  disabled={assigneeIds.length === 0}
                >
                  <FormattedMessage id="INVOICE_KICKBACK" />
                </PrimaryButton>
              </If>
              <If isTrue={defaultFooterState && invoice?.permissions.archive}>
                <If
                  isTrue={
                    !(
                      footerState ===
                        InvoiceFooterState.EDIT_INVOICE_COVERAGES &&
                      isApprovedOrPaid
                    )
                  }
                >
                  <PageBackButton />
                </If>
                <OutlinedButton
                  onClick={() => {
                    openDialog({
                      cancelButtonText: intl.$t({ id: "CANCEL" }),
                      confirmButtonText: intl.$t({ id: "PROCEED" }),
                      icon: <WarningIcon />,
                      title: intl.$t({ id: "DELETE_INVOICE_DETAILS" }),
                      text: intl.$t({ id: "DELETE_INVOICE_DETAILS_TEXT" }),
                      handleConfirm: async () => {
                        await archiveInvoice(invoice?.id ?? "");
                        navigateToNextSequence({ navigateToInvoices: true });
                      },
                    });
                  }}
                >
                  <FormattedMessage id="DELETE" />
                </OutlinedButton>
              </If>
              <If
                isTrue={
                  defaultFooterState && invoice?.status !== InvoiceStatus.Paid
                }
              >
                <OutlinedButton
                  className="py-0"
                  onClick={() => {
                    showSaveChangesDialog();
                    setFooterState(InvoiceFooterState.KICKBACK);
                  }}
                >
                  <FormattedMessage id="INVOICE_KICKBACK" />
                </OutlinedButton>
              </If>
              <If
                isTrue={
                  defaultFooterState && invoice?.status !== InvoiceStatus.Paid
                }
              >
                <If
                  isTrue={
                    invoice?.permissions.edit ===
                      AuthorizationStatus.Authorized && invoice.release
                  }
                >
                  <If
                    isTrue={
                      footerState === InvoiceFooterState.DEFAULT ||
                      footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES
                    }
                  >
                    <OutlinedButton
                      onClick={saveChanges}
                      disabled={!hasChanges}
                    >
                      <FormattedMessage
                        id={
                          invoice &&
                          APPROVED_INVOICES_STATUS.includes(invoice.status)
                            ? "SAVE_CHANGES"
                            : "SAVE_PROGRESS"
                        }
                      />
                    </OutlinedButton>
                  </If>
                </If>
                <If
                  isTrue={
                    invoice?.release &&
                    invoice.status !== InvoiceStatus.Approved
                  }
                >
                  <Tooltip
                    id="asset"
                    hideTooltip={
                      permissions?.approve === AuthorizationStatus.Authorized
                    }
                    element={
                      <PrimaryButton
                        onClick={() => approve()}
                        testId="approve-invoice"
                        loading={approving}
                        disabled={
                          permissions?.approve !==
                          AuthorizationStatus.Authorized
                        }
                      >
                        <FormattedMessage id="INVOICE_APPROVE_INVOICE" />
                      </PrimaryButton>
                    }
                  >
                    <If
                      isTrue={
                        permissions?.approve !== AuthorizationStatus.Authorized
                      }
                    >
                      <TooltipContent>
                        <FormattedMessage
                          id="ROLES_APPROVE_INVOICE"
                          tagName={"div"}
                        />
                        {settings?.invoices.approval?.roles.map((role) => (
                          <div key={role.role}>
                            - {intl.$t({ id: `USER_ROLE_${role.role}` })}
                            <If isTrue={role.maxAmount}>
                              {" "}
                              <FormattedMessage
                                id="UP_TO_PRICE_AMOUNT"
                                values={{
                                  price: <Price price={role.maxAmount} />,
                                }}
                              />
                            </If>
                          </div>
                        ))}
                      </TooltipContent>
                    </If>
                  </Tooltip>
                </If>
              </If>
              <If
                isTrue={
                  invoice?.status === InvoiceStatus.Paid &&
                  footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES
                }
              >
                <PrimaryButton onClick={saveChanges} disabled={!hasChanges}>
                  <FormattedMessage id={"SAVE_CHANGES"} />
                </PrimaryButton>
              </If>
            </If>
          </ButtonContainer>
        </FloatingFooterStyled>
      </Form>
    </FormProvider>
  );
};
