import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useDeliverySlipVerification } from "@/contractor/pages/home/releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import { DeliverySlipItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddSlipItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { deliverySlip } = useDeliverySlipVerification();
  const { release } = useRelease();
  const { findMaterialByName, appendRows, rowIsEmpty } = useTableHelpers();
  const { formatCostCode } = useCostCodes();
  const { showModal } = useAddItemsModal();
  const { getPrefilledValue } = useTableHelpers();
  const missingItems = useMemo(
    () =>
      deliverySlip?.items.filter(
        (item) =>
          !deliverySlip?.releaseItemHints.some(
            (hint) => hint.deliverySlipItem?.id === item.id,
          ),
      ) || [],
    [deliverySlip?.items, deliverySlip?.releaseItemHints],
  );

  const addAllItems = useCallback(
    (items?: DeliverySlipItemFieldsFragment[]) => {
      const newItems =
        (items || deliverySlip?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          let prefilledPrice = "";
          if (material) {
            prefilledPrice = getPrefilledValue({
              material: item.description ?? "",
              uom: item.uom ?? "",
            }).value;
          }
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.UOM]: item.uom || "UT",
            [COLUMN_TYPE.Quantity]: item.orderedQuantity || "",
            [COLUMN_TYPE.ReceivedQuantity]: item.receivedQuantity || "0",
            [COLUMN_TYPE.CostCode]: formatCostCode(
              release?.costCode ?? material?.costCode ?? "",
            ),
            [COLUMN_TYPE.PrefilledPrice]: prefilledPrice || "",
          };
        }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      appendRows,
      deliverySlip?.items,
      release?.costCode,
      findMaterialByName,
      formatCostCode,
      handsonInstance,
      getPrefilledValue,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(missingItems);
  }, [addAllItems, missingItems]);

  const addSlipItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = deliverySlip?.items.length === missingItems.length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      deliverySlip?.items.length || 0,
      missingItems.length,
    );
  }, [
    handsonInstance,
    rowIsEmpty,
    showModal,
    addAllItems,
    addMissingItems,
    deliverySlip?.items.length,
    missingItems,
  ]);

  return {
    addSlipItems,
  };
};
