import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useMessagesPanel } from "@/common/components/messages/providers/MessagesPanelProvider";
import { RELEASE_MESSAGE_DISABLE_STATUSES } from "@/common/const";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import { useReleaseActions } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAddDeliverySlipStore } from "@/contractor/pages/home/releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import {
  AuthorizationStatus,
  BuyoutReleaseFieldsFragment,
  MessageContextKind,
  ReleaseStatus,
} from "@/generated/graphql";
import {
  Check,
  EventBusy,
  ForumOutlined,
  Info,
  MoreVert,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { RiFileEditLine } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBuyoutReleases } from "../../../providers/BuyoutReleasesProvider";

const Container = tw.div`flex justify-end`;
const RiFileEditLineStyled = tw(RiFileEditLine)`w-[24px] h-[24px]`;
const ListItemIconStyled = tw(ListItemIcon)`text-blue-500`;

type Props = {
  release: BuyoutReleaseFieldsFragment;
};

export const ReleaseActions: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { cancelRelease, archiveRelease } = useReleaseActions();
  const { buyout } = useBuyoutReleases();
  const { setIsOpened, setData } = useMessagesPanel();
  const { navigateToNextSequence } = useReleaseSequence();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((ev?: { stopPropagation?: () => void }) => {
    ev?.stopPropagation?.();
    setAnchorEl(null);
  }, []);

  const handleCancelRelease = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "CANCEL_DELIVERY_QUESTION" }),
      text: intl.$t({ id: "CANCEL_DELIVERY_TEXT" }),
      handleConfirm: async () => {
        await cancelRelease({
          releaseId: release.id,
          version: release.version,
        });
        navigateToNextSequence();
      },
    });
    handleClose();
  }, [
    cancelRelease,
    handleClose,
    intl,
    openDialog,
    navigateToNextSequence,
    release.id,
    release.version,
  ]);

  const handleDeleteRelease = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "DELETE_ORDER_DETAILS" }),
      handleConfirm: async () => {
        await archiveRelease(release.id);
        navigateToNextSequence();
      },
    });
    handleClose();
  }, [
    archiveRelease,
    navigateToNextSequence,
    handleClose,
    intl,
    openDialog,
    release.id,
  ]);

  const showMessagesPanel = useCallback(
    (ev: React.MouseEvent) => {
      if (buyout) {
        ev.stopPropagation();
        setData({
          title: "MESSENGER",
          id: release.id,
          kind: MessageContextKind.Release,
          context: { release, buyout },
          isContractor: true,
          org: buyout.sellerOrgLocation.org,
          messageTabName: <FormattedMessage id="MESSAGE_VENDOR" />,
          noMessage: "RELEASE_NO_MESSAGES",
        });
        setIsOpened(true);
        handleClose();
      }
    },
    [buyout, handleClose, release, setData, setIsOpened],
  );

  if (!buyout) {
    return null;
  }

  return (
    <Container>
      <LinkLike onClick={handleClick}>
        <MoreVert />
      </LinkLike>
      <Menu
        id={`release-action-menu-${release.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            navigate(generatePath(routes.delivery, { deliveryId: release.id }))
          }
        >
          <ListItemIconStyled>
            <Info />
          </ListItemIconStyled>
          <ListItemText>
            <FormattedMessage id="DELIVERY_DETAILS" />
          </ListItemText>
        </MenuItem>
        <If isTrue={checkReleaseStatus(release, [ReleaseStatus.Scheduled])}>
          <MenuItem
            onClick={() => {
              setSlipRelease(release);
              setAddSlipVisible(true);
            }}
          >
            <ListItemIconStyled>
              <Check />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="CONFIRM_DELIVERY" />
            </ListItemText>
          </MenuItem>
        </If>
        <If
          isTrue={checkReleaseStatus(release, [
            ReleaseStatus.Scheduled,
            ReleaseStatus.Requested,
          ])}
        >
          <MenuItem
            onClick={() =>
              navigate(
                generatePath(routes.specifyDeliveryDetails, {
                  deliveryId: release.id,
                }),
              )
            }
          >
            <ListItemIconStyled>
              <RiFileEditLineStyled />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="MAKE_CHANGES" />
            </ListItemText>
          </MenuItem>
        </If>
        <If
          isTrue={checkReleaseStatus(release, [
            ReleaseStatus.AwaitingApproval,
            ReleaseStatus.Rejected,
          ])}
        >
          <MenuItem
            onClick={() =>
              navigate(
                generatePath(routes.specifyDeliveryDetails, {
                  deliveryId: release.id,
                }),
              )
            }
          >
            <ListItemIconStyled>
              <RiFileEditLineStyled />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage
                id={
                  release.status === ReleaseStatus.Rejected
                    ? "MAKE_CHANGES"
                    : "REVIEW"
                }
              />
            </ListItemText>
          </MenuItem>
        </If>
        <If
          isTrue={checkReleaseStatus(release, [
            ReleaseStatus.Requested,
            ReleaseStatus.Scheduled,
          ])}
        >
          <MenuItem onClick={handleCancelRelease}>
            <ListItemIconStyled>
              <EventBusy />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="CANCEL_DELIVERY" />
            </ListItemText>
          </MenuItem>
        </If>
        <If
          isTrue={
            checkReleaseStatus(release, [
              ReleaseStatus.Draft,
              ReleaseStatus.Canceled,
            ]) &&
            !release.deletedAt &&
            release.permissions.archive === AuthorizationStatus.Authorized
          }
        >
          <MenuItem onClick={handleDeleteRelease}>
            <ListItemIconStyled>
              <EventBusy />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="DELETE_ORDER" />
            </ListItemText>
          </MenuItem>
        </If>
        <If
          isTrue={
            !checkReleaseStatus(release, RELEASE_MESSAGE_DISABLE_STATUSES)
          }
        >
          <MenuItem onClick={showMessagesPanel}>
            <ListItemIconStyled>
              <ForumOutlined />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="MESSENGER" />
            </ListItemText>
          </MenuItem>
        </If>
      </Menu>
    </Container>
  );
};
