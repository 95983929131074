import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { useMemo } from "react";

export const useInvoiceTotals = (
  release: ExpandedRelease | null | undefined,
) => {
  const { calcExtPrice } = usePriceCalculation();

  const invoiceSubtotal = useMemo(
    () =>
      release?.items.reduce(
        (acc, item) =>
          acc.plus(
            calcExtPrice(
              item.invoiceItems?.[0]?.quantity || 0,
              item.unitPrice || 0,
            ),
          ),
        new Decimal(0),
      ) || new Decimal(0),
    [calcExtPrice, release?.items],
  );

  return {
    invoiceSubtotal,
  };
};
