import { If } from "@/common/components/if/If";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  RfqQuotesAuxiliaryQuoteItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useBidsPrices } from "../../../../providers/BidsPricesProvider";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";
import { ExpirationRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/ExpirationRow";
import { ManufacturerRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/ManufacturerRow";
import { PricePositionRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/PricePositionRow";
import { UnitPriceRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/UnitPriceRow";
import { ItemDetailsRow } from "./ItemDetailsRow";

type Props = {
  item: RfqQuotesAuxiliaryQuoteItemFieldsFragment;
  quoteItem?: RfqQuotesQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
};

type ContainerProps = {
  $selected: boolean;
};

const Container = tw.div<ContainerProps>`
  grid relative w-full transition z-10 content-start h-fit
  rounded-3xl overflow-hidden border cursor-pointer
  ${(p) =>
    p.$selected
      ? `border-2 border-green-600 p-[7px] bg-green-600 bg-opacity-10`
      : `border-gray-400 bg-white p-2`}
`;

const Separator = tw.div`
  border-b border-gray-400 border-dashed h-0.5 mx-4
`;

export const AuxItemCard: FC<Props> = ({ item, quote }) => {
  const {
    selectedAuxiliaryQuoteItems,
    setSelectedAuxiliaryQuoteItems,
    multiQuote,
    setSelectedQuotes,
    selectedQuotes,
  } = useRfqQuotes();
  const { hasManufacturersSetting } = useManufacturersSettings();

  const { selectEntireQuoteByBestPrice } = useBidsPrices();
  const manufacturerName = useMemo(
    () => item?.manufacturer?.name,
    [item?.manufacturer?.name],
  );

  const isSelected = useMemo(() => {
    return selectedAuxiliaryQuoteItems.some(
      (aux) => aux.itemId === item?.id && aux.quoteId === quote.id,
    );
  }, [item?.id, quote?.id, selectedAuxiliaryQuoteItems]);

  const selectAuxItem = useCallback(() => {
    if (item) {
      if (isSelected) {
        setSelectedAuxiliaryQuoteItems(
          selectedAuxiliaryQuoteItems.filter(
            (selected) => selected.itemId !== item.id,
          ),
        );
      } else if (multiQuote) {
        setSelectedAuxiliaryQuoteItems([
          ...selectedAuxiliaryQuoteItems,
          {
            itemId: item.id,
            quoteId: quote?.id || "",
          },
        ]);
      } else {
        const sameQuoteSelected =
          selectedQuotes.length &&
          selectedQuotes.every((qi) => qi.quoteId === quote?.id);

        const entireQuote = selectEntireQuoteByBestPrice(quote?.id);
        setSelectedAuxiliaryQuoteItems([
          ...(sameQuoteSelected
            ? [
                ...selectedAuxiliaryQuoteItems,
                {
                  itemId: item.id,
                  quoteId: quote?.id || "",
                },
              ]
            : entireQuote.auxQuoteItems),
        ]);

        setSelectedQuotes(
          sameQuoteSelected ? selectedQuotes : entireQuote.quoteItems,
        );
      }
    }
  }, [
    item,
    isSelected,
    quote?.id,
    selectedAuxiliaryQuoteItems,
    setSelectedAuxiliaryQuoteItems,
    multiQuote,
    selectEntireQuoteByBestPrice,
    setSelectedQuotes,
    selectedQuotes,
  ]);

  const totalPrice = useMemo(() => {
    return (
      new Decimal(item?.unitPrice ?? 0)
        .mul(item?.quantityDecimal ?? 0)
        .toNumber() || 0
    );
  }, [item?.quantityDecimal, item?.unitPrice]);

  return (
    <Container
      $selected={isSelected}
      onClick={selectAuxItem}
      style={{
        maxWidth: `${MAX_COLUMN_WIDTH}px`,
      }}
    >
      <ItemDetailsRow name={item?.description} />
      <If isTrue={hasManufacturersSetting}>
        <ManufacturerRow
          manufacturerName={manufacturerName}
          notes={item?.notes}
          itemId={item?.id}
          assets={item?.assets}
        />
      </If>
      <Separator />
      <UnitPriceRow
        quantityDiff={0}
        quantityDecimal={item?.quantityDecimal}
        unit={item.uom?.mnemonic || ""}
        unitPrice={Number(item?.unitPrice)}
      />
      <If isTrue={item?.expirationDate}>
        <Separator />
        <ExpirationRow expirationDate={item?.expirationDate} />
      </If>
      <PricePositionRow
        price={totalPrice}
        isSelected={isSelected}
        leadTimeDays={item?.leadTimeDays}
      />
    </Container>
  );
};
