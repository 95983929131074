import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { evictCacheById } from "@/common/utils/cacheUtils";
import { readValue, setValue } from "@/common/utils/localStorage";
import {
  InvoiceEmailsDocument,
  namedOperations,
  OrgRole,
  QueryInvoiceEmailsFilter,
  useArchiveInvoiceEmailMutation,
  useInvoiceEmailsQuery,
} from "@/generated/graphql";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInvoicesStore } from "../store/useInvoicesStore";

export const useInvoiceEmails = (
  {
    skipQuery,
  }:
    | {
        skipQuery?: boolean;
      }
    | undefined = { skipQuery: false },
) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage } = usePagination();
  const [archiveInvoiceEmailMutation] = useArchiveInvoiceEmailMutation();
  const { setError } = useGlobalError();
  const { filter, setFilter } = useInvoicesStore(
    useShallow((state) => ({
      filter: state.filter,
      setFilter: state.setFilter,
    })),
  );
  const { hasRoles: canSeeInbox } = useRoles({
    roles: [OrgRole.OrgAdmin, OrgRole.OrgAccountant],
  });

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryInvoiceEmailsFilter,
  ) => {
    setFiltersQueryParams(updatedFilter);
    setFilter(updatedFilter);
    setValue(LOCAL_STORAGE_KEYS.INVOICE_EMAILS_LIST_FILTER, updatedFilter);
  };

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoiceEmailsFilter>(
      LOCAL_STORAGE_KEYS.INVOICE_EMAILS_LIST_FILTER,
    ) as QueryInvoiceEmailsFilter;

    setFilter({
      statuses: localStorageSettings?.statuses,
      archived: localStorageSettings?.archived,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, setFilter]);

  const { data, loading, error, refetch } = useInvoiceEmailsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    skip: skipQuery,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.invoiceEmails) {
      setPageInfo(data.invoiceEmails.pageInfo);
    }
  }, [data, setPageInfo]);

  const archiveInvoiceEmail = async (id: string) => {
    try {
      const { errors } = await archiveInvoiceEmailMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: InvoiceEmailsDocument,
            variables: { ...paginationArgs, filter },
          },
        ],
        update: (cache, { data: archiveData }) =>
          evictCacheById(
            cache,
            namedOperations.Query.InvoiceEmails,
            archiveData?.archiveInvoiceEmail.id,
          ),
      });

      if (page !== 0 && data?.invoiceEmails.edges.length === 1) {
        previousPage();
      }
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  useErrorEffect(error);

  return {
    invoiceEmails: data?.invoiceEmails?.edges?.map((edge) => edge.node) || [],
    loading,
    error: !!error,
    totalCount: data?.invoiceEmails?.totalCount || 0,
    archiveInvoiceEmail,
    filter,
    setFilter: setFilterAndUpdateQueryString,
    isFiltered:
      !!filter?.statuses?.length || !!filter?.sellerOrgLocationIds?.length,
    refetch,
    canSeeInbox,
  };
};
